@mixin navigation-button($size) {
    align-items: center;
    appearance: none;
    background: $color-sl600;
    border: 1px solid $color-n50;
    border-radius: 100px;
    display: flex;
    height: $size;
    justify-content: center;
    padding: 0;
    width: $size;
}

/**
 * Targets iOS devices
 */
@mixin target-ios() {
    @supports (-webkit-touch-callout: none) {
        @content;
    }
}
