@import "../variables";
@import "../mixins";

.site-search {
    height: $space-48;
    position: relative;
    width: 100%;

    .search-form {
        height: $space-48;
        position: relative;

        .spinner {
            height: 20px;
            left: auto;
            right: 50px;
            width: 20px;
        }

        .search-field {
            background: $color-n20;
            border: 1px solid $color-n50;
            color: $color-t200;
            font-size: $font-16;
            padding: $space-8 $space-48 $space-8 $space-16;

            &:focus {
                border: 2px solid $color-n900;
                box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
                outline: 0;
            }
        }
    }

    .close-search,
    .icon-search {
        background-color: transparent;
        border: 0;
        padding: 0;
        position: absolute;
        right: $space-16; /* 9/16 */
        top: 10px; /* 9/16 */

        svg {
            height: $space-24;
            width: $space-24;
        }
    }

    .reset-button {
        background-color: transparent;
        border: 0;
        padding: 0;
        position: absolute;
        top: 0.5625em; /* 9/16 */
    }

    input {
        height: $space-48;
        padding-right: $space-16;
    }
}

@include media-breakpoint-down(xs) {
    .header-search {
        .site-search {
            display: none;
        }
    }
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    background-color: $white;
    border: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    padding: 0 $space-16;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 5;

    @include media-breakpoint-up(lg) {
        border: 1px solid $grey3;
        border-radius: $border-radius-4;
        box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.08);
        display: block;
        filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.3));
        height: fit-content;
        max-height: none;
        max-width: 442px;
        position: absolute;
        top: 0;
        width: auto;
    }

    .swatch-img {
        background: $color-n0;
        border: 0;
        border-radius: $border-radius-4;
        display: inline-block;
        height: 32px;
        margin-right: $space-14;
        position: relative;
        width: 32px;
    }

    .suggestions-heading {
        border-bottom: 1px solid $color-n50;
        margin-top: $space-16;
        padding-bottom: $space-8;
    }

    .list {
        list-style-type: none;
        padding: 0;
    }

    .no-ellipsis {
        overflow: initial;
        padding: 0;
        text-overflow: initial;
    }

    .container {
        list-style-type: none;
    }
}

.js-a-suggestion-click > * {
    pointer-events: none;
}
