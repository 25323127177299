@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./variables";
@import "base/productCard";

.minicart,
.summary-order-product {
    position: relative;

    .minicart-popover {
        background: transparent;
        border: 0;
        box-shadow: none;
        left: -321px;
        padding: 0;
        top: 43px;

        &::before,
        &::after {
            display: none;
        }
    }

    .minicart-overlay {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .minicart-container {
        background: $color-n0;
        bottom: -100%;
        height: calc(100% - 65px);
        position: absolute;
        transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);
        width: 100%;

        @include media-breakpoint-up(lg) {
            bottom: unset;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            right: -100%;
            transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
            width: 474px;
        }

        .recommendations-title {
            margin-left: 0;
        }

        .slide-container {
            .slide-carousel:not(.slick-initialized) > div {
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .eistein-carousel-container {
        margin-bottom: 0;
    }

    .custom-slick-arrows {
        .slick-arrow {
            top: 122px;
        }
    }

    .minicart-wrapper {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;

        &.active {
            .minicart-overlay {
                display: block;
            }

            .minicart-container {
                bottom: 0;
                transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);

                @include media-breakpoint-up(lg) {
                    bottom: unset;
                    right: 0;
                    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
                }
            }
        }
    }

    .minicart-header {
        align-items: center;
        border-bottom: 1px solid $color-n50;
        display: flex;
        justify-content: space-between;
        margin: 0 $space-16;
        padding: $space-20 0;

        @include media-breakpoint-up(lg) {
            margin: 0 $space-32;
            padding: $space-32 0 $space-12;
        }
    }

    .minicart-body {
        height: calc(100% - 75px);
        margin-right: 5px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: $space-16 7px $space-16 $space-16;

        @include media-breakpoint-up(lg) {
            height: calc(100vh - 205px);
            margin-right: 11px;
            padding: $space-16 17px $space-32 $space-32;
        }
    }

    // Rewards Block
    .reward-block {
        align-items: center;
        background-color: $color-sa50;
        border: 1px solid $color-sa300;
        border-radius: $border-radius-8;
        display: flex;
        height: 78px;
        justify-content: space-between;

        &.logged {
            height: fit-content;
        }

        &.guest-user {
            display: block;
            height: auto;
        }
    }

    .reward-block-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .link-rewards {
        text-decoration: underline;
    }

    .reward-block-button {
        .btn-reward-login {
            background-color: $color-n0;
            border-color: $color-n300;
            color: $color-t300;
            letter-spacing: 0.05em;
            line-height: 14px;
            padding: $space-12 $space-24;

            &:hover {
                background-color: $color-n0;
            }
        }
    }

    // Rewards Bar
    .reward-bar-container {
        width: 100%;

        .reward-bar {
            background-color: $color-n10;
            border: 1px solid $color-n50;
            border-radius: $border-radius-4;
            box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
            height: $space-24;
            width: 100%;
        }

        .reward-bar-fill {
            background-color: $color-sa300;
            border-radius: $border-radius-4 - 1px 0 0 $border-radius-4 - 1px;
            height: 100%;
            text-align: right;

            div {
                min-width: 25px;
                padding-right: $space-8;
            }
        }

        .reward-bar-counters {
            align-items: center;
            display: flex;
            justify-content: space-between;
            line-height: 120%;
        }
    }

    .reward-actions {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    // Coupon Aplied
    .coupon-aplied-container {
        border: 1px solid $color-n40;
        border-radius: $border-radius-4;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 2px 6px 2px rgba(0, 0, 0, 0.1);
        height: 97px;
        padding: $space-16;
    }

    .line-item-promo {
        color: $color-su400;
    }

    .product-line-item-details {
        overflow-y: unset;
    }

    .minicart-product {
        border-bottom: 1px solid $color-n50;

        .remove-btn {
            display: none;
        }
    }

    .item-image {
        border: 1px solid $color-n40;
        border-radius: $border-radius-4;
        max-height: 74px;
        max-width: 74px;

        .product-image {
            border-radius: $border-radius-4;
            max-height: $space-72;
        }
    }

    .line-item {
        color: $color-t300;
        font-weight: $font-regular;
    }

    // Minicart prices
    .minicart-price {
        display: flex;
        flex-direction: column;
        width: 100%;

        .list-price {
            font-size: $font-14;
            margin-left: $space-72;
            margin-top: -$space-16;
        }

        .sale-from {
            margin-left: $space-72;
            margin-top: -$space-16;
        }

        .sale-price {
            font-size: $font-14;
            margin-left: $space-72;
        }

        .member-price {
            align-items: center;
            background-color: $color-sa300;
            border-radius: $border-radius-4;
            display: flex;
            margin-top: $space-10;
            min-width: 220px;
            padding: $space-4 $space-8;
            width: fit-content;

            @include media-breakpoint-up(lg) {
                min-width: 180px;
            }

            .sale-price {
                margin: 0;
            }

            &-supratext {
                color: $color-t300;
                font-size: $space-12;
                margin-right: $space-4;
            }

            .member-price-value {
                font-size: $font-14;

                @include media-breakpoint-up(lg) {
                    font-size: $font-16;
                }
            }

            .text {
                margin-right: $space-4;
            }
        }
    }

    .minicart-footer {
        box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.08), 0 -2px 6px 2px rgba(0, 0, 0, 0.1);
        padding: $space-16;

        @include media-breakpoint-up(lg) {
            padding: $space-16 $space-32 $space-32;
        }
    }

    .checkout-btn {
        height: $space-56;
        line-height: 14px;
        padding: $space-20 $space-24;
    }

    // Added to Cart Notification elements
    .notification-separator {
        align-items: center;
        display: none;
        gap: 16px;

        .line-separator {
            background-color: $color-n50;
            flex-grow: 1;
            height: 1px;
        }
    }

    .minicart-notification-button {
        button {
            text-transform: uppercase;
        }
    }

    .approaching-discount {
        background-color: $color-nsl35;
        border: 1px solid $color-n50;
        border-radius: $border-radius-8;
        display: none;
        gap: 8px;

        .icon-info {
            background-color: $color-su400;
            border-radius: 50%;
            display: inline-block;
            height: 8px;
            min-width: 8px;
            width: 8px;
        }
    }

    .notification-title,
    .estimated-total-notification,
    .reward-notification,
    .minicart-notification-button {
        display: none;
    }

    .add-to-cart-notification {
        .notification-separator,
        .estimated-total-notification,
        .approaching-discount {
            display: flex;
        }

        .notification-title,
        .reward-notification,
        .minicart-notification-button {
            display: block;
        }

        .minicart-title,
        .reward-block-minicart,
        .minicart-footer,
        .minicart-product:not(:first-child),
        .coupon-aplied-container {
            display: none;
        }

        .minicart-container {
            justify-content: unset;
        }

        .minicart-body {
            @include media-breakpoint-up(lg) {
                height: calc(100vh - 77px);
                padding-bottom: 0;
            }
        }

        &.set-notification {
            .minicart-product {
                display: none;
            }
        }
    }

    // Promo Code Added Notification Elements
    .promocode-title,
    .last-applied-promo {
        display: none;
    }

    .applied-promos {
        .code-wrapper:not(.applied) {
            display: none;
        }
    }

    .promocode-notification {
        .notification-separator {
            display: flex;
        }

        .promocode-title,
        .reward-notification,
        .last-applied-promo,
        .minicart-notification-button {
            display: block;
        }

        .minicart-title,
        .reward-block-minicart,
        .applied-promos,
        .minicart-footer,
        .minicart-product {
            display: none;
        }

        .minicart-container {
            justify-content: unset;
        }

        .minicart-body {
            @include media-breakpoint-up(lg) {
                height: calc(100vh - 77px);
                padding-bottom: 0;
            }
        }
    }

    // Recommendations
    .recommendations-title {
        border: 0;
    }
}

.promo-code-form {
    padding-bottom: $space-16;
}

.code-wrapper {
    &:not(:first-child) {
        .promo-code {
            margin-top: $space-16;
        }
    }

    &.applied {
        .promo-code {
            &:first-of-type {
                margin-top: $space-16;
            }

            .applied-msg {
                display: block;
            }

            .code-expires {
                display: none;
            }

            .btn-apply-code {
                display: none;
            }

            .remove-btn {
                margin: 0;
                margin-left: $space-16;
            }

            .code-title {
                margin-bottom: 0;
            }
        }
    }

    .promo-code {
        align-items: flex-start;
        background-color: $color-n0;
        border: 1px solid $color-n50;
        border-radius: $border-radius-4;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 2px 6px 2px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $space-16;

        .applied-msg {
            display: none;
        }
    }

    .error-wrapper {
        align-items: flex-start;
        display: none;
        flex-direction: column;
        justify-content: center;
        padding-top: $space-16;
        width: 100%;
    }
}

.minicart {
    .promo-code {
        margin-bottom: $space-16;
        margin-top: 0;
    }

    .error-wrapper {
        margin-bottom: $space-16;
        padding: 0;
    }
}

.minicart {
    .punch-cards-block {
        position: relative;
    }

    .punch-banner-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .punch-card {
        align-items: center;
        background-color: $color-n0;
        border-radius: $border-radius-4;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $space-16;
        width: 100%;

        .progress-text {
            display: none;
        }
    }
}

.mini-cart {
    .reward-bronze,
    .reward-silver,
    .reward-gold {
        .bronze-medal,
        .silver-medal,
        .gold-medal,
        .grey-medal {
            @include media-breakpoint-down(sm) {
                height: $space-40;
                width: $space-32;
            }

            &.modal-medal {
                height: $space-40;
                width: $space-32;
            }
        }

        .bronze-medal,
        .silver-medal,
        .gold-medal {
            display: none;
        }

        &.filled {
            .bronze-medal,
            .silver-medal,
            .gold-medal {
                display: block;
            }

            .grey-medal {
                display: none;
            }
        }
    }
}

.minicart {
    .space-bar {
        align-items: center;
        display: flex;
        flex-grow: 1;
        height: $space-4;
        max-width: 100%;
        padding: 0;

        &:first-of-type {
            display: none;
        }

        .space-bar-fill {
            background-color: $color-n40;
            border-radius: $border-radius-8;
            flex-grow: 1;
            height: 100%;

            .animated-bar {
                height: 100%;
                transition: width 0.5s ease-in-out;
                width: 0%;
            }
        }
    }

    .qualified-purchase {
        &.filled {
            color: $color-t300;

            span {
                color: $color-t300;
            }
        }
    }

    .checkpoints-wrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: $space-10;
        width: 100%;

        .checkpoint {
            max-width: fit-content;
            transition: transform 0.5s ease-in-out;

            &.is-transformed {
                transform: scale3d(1.2, 1.2, 1.2);
                transition: transform 0.5s ease-in-out;
            }

            .checkpoint-bullet {
                background-color: $color-n40;
                border-radius: 100%;
                height: 24px;
                margin: 0 $space-8;
                transition: visibility 0.5s linear, opacity 0.5s linear;
                width: 24px;
            }

            img {
                max-width: 100%;
                transition: visibility 0.5s linear, opacity 0.5s linear, transform 0.5s ease-in-out;

            }
        }
    }
}

.punch-banner-wrapper-green {
    .punch-card {
        border: 1px solid $color-p75;
        padding: $space-16;

        .checkpoint {
            &.filled {
                .checkpoint-bullet {
                    background-color: $color-b200;
                }

                span {
                    color: $color-b400;
                }
            }
        }

        .space-bar {
            .animated-bar {
                background-color: $color-b200;
            }
        }
    }
}

.punch-banner-wrapper-red {
    .punch-card {
        border: 1px solid $color-s100;

        .checkpoint {
            &.filled {
                .checkpoint-bullet {
                    background-color: $color-s200;
                }

                span {
                    color: $color-s200;
                }
            }
        }

        .space-bar {
            .animated-bar {
                background-color: $color-s200;
            }
        }
    }
}

.punch-banner-wrapper-yellow {
    .punch-card {
        border: 1px solid $color-sa75;

        .checkpoint {
            &.filled {
                .checkpoint-bullet {
                    background-color: $color-sa400;
                }

                span {
                    color: $color-sa400;
                }
            }
        }

        .space-bar {
            .animated-bar {
                background-color: $color-sa400;
            }
        }
    }
}
