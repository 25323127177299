$arrow-height: $space-18;

.info-icon {
    background: inherit;
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    position: relative;

    .icon {
        background-color: #{var(--skin-primary-color-1)};
        border-radius: 1.25rem;
        color: #fff;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        width: 1.5625rem;
    }

    .tooltip {
        animation: fade-in 0.5s linear forwards;
        background-color: $color-n700;
        border-radius: $border-radius-8;
        bottom: 100%;
        color: $color-n0;
        font-size: $font-14;
        left: 50%;
        min-height: 50px;
        min-width: 279px;
        padding: $space-8 $space-18;
        position: absolute;
        transform: translate(-50%, calc(-1 * $arrow-height / 2));

        @keyframes fade-in {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }

        &::before {
            bottom: -$arrow-height;
            content: " ";
            display: block;
            height: $arrow-height;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &::after {
            border-left: solid transparent calc($arrow-height / 2);
            border-right: solid transparent calc($arrow-height / 2);
            border-top: solid $color-n700 calc($arrow-height / 2);
            bottom: calc(-1 * $arrow-height / 2);
            content: " ";
            height: 0;
            left: 50%;
            margin-left: -13px;
            position: absolute;
            width: 0;
        }
    }
}
