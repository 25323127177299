$breakpoint-name: "sm";
$breakpoint-name: "sm" !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

.menu-toggleable-left.navbar-toggleable-md {
    @include media-breakpoint-down(lg) {
        bottom: 0;
        display: block;
        left: -110%;
        max-width: 319px;
        position: fixed;
        top: 0;
        transition: $slide-out-animation;
        width: 85%;

        &.in {
            left: 0;

            .close-button {
                display: block;
            }
        }
    }

    @include media-breakpoint-only(lg) {
        max-width: 447px;
    }
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    @include media-breakpoint-up(lge) {
        .nav-item {
            margin: 0 $space-24;
            position: inherit;
            width: auto !important;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.show {
                &::after {
                    background-color: $color-s300;
                    content: "";
                    display: block;
                    height: 4px;
                    margin: -$space-4 0;
                    width: 100%;
                }
            }
        }

        .navbar-nav .nav-item + .nav-item {
            margin-left: $space-24;
        }

        .nav .nav-link {
            align-items: center;
            color: $color-t300;
            display: flex;
            justify-content: space-between;
            padding: $space-20 0;
            white-space: nowrap;

            span {
                font-family: $font-sofia;
                font-size: $font-14;
                font-style: normal;
                font-weight: $font-bold;
                line-height: 120%;
            }

            svg {
                display: none;
            }
        }

        .first-navbar:not(.slick-initialized) {
            .nav-item {
                &:nth-child(2) {
                    margin-left: 0;
                }

                &:not(.d-lg-none):last-child {
                    margin-right: 0;
                }
            }
        }

        .slick-list {
            .nav-item {
                @include media-breakpoint-up(lge) {
                    margin: 0 $space-24;
                }
            }

            .slick-slide {
                &:nth-child(2) {
                    .nav-item {
                        @include media-breakpoint-up(lge) {
                            margin-left: 0;
                        }
                    }
                }
                
                &:last-child {
                    .nav-item {
                        @include media-breakpoint-up(lge) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .navbar-nav {
        border-bottom: 1px solid $color-n40;

        @include media-breakpoint-up(lge) {
            border: 0;
            flex-wrap: nowrap;
        }
    }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: $white;
    color: $color-t200;
}

.nav-link:focus-visible {
    outline: none;

    span {
        border: 2px solid #000;
    }
}

.main-menu.menu-toggleable-left {
    @include media-breakpoint-down(lg) {
        background-color: $white;
        z-index: 11;
    }

    @include media-breakpoint-up(lge) {
        position: relative;

        .slick-arrow {
            align-items: center;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, $color-n0 100%);
            cursor: pointer;
            display: flex !important;
            height: 100%;
            justify-content: start;
            left: -1px;
            opacity: 1;
            padding: 0;
            position: absolute;
            right: auto;
            top: 0;
            transition: all 0.8s;
            width: 148px;
            z-index: 1;

            &.slick-next {
                background: linear-gradient(270deg, $color-n0 0%, rgba(255, 255, 255, 0) 100%);
                justify-content: end;
                left: auto;
                right: -1px;
            }

            &.slick-disabled {
                display: none !important;
            }
        }
    }
}

.menu-toggleable-left {
    @include media-breakpoint-down(lg) {
        .bg-inverse {
            background-color: #fff !important;
            color: $color-n700;
        }

        .menu-group {
            height: 100vh;
            overflow-y: auto;
            padding-bottom: $space-80;
        }

        .dropdown-button {
            svg {
                height: 16px;
                margin-left: auto;
                width: 16px;
            }
        }
    }

    .close-menu {
        background-color: $color-n0;
        border-bottom: 0;
        padding: 0;

        @include media-breakpoint-up(lge) {
            display: none;
        }

        button {
            appearance: none;
            background-color: transparent;
            border: 0;
        }

        .close-button {
            background: none;
            border: 0;
            display: none;
            left: 85%;
            margin: 0;
            max-width: 56px;
            padding: 0;
            position: fixed;
            right: auto;
            text-align: center;
            top: $space-16;
            width: 15%;

            @include media-breakpoint-up(sm) {
                left: 328px;
                width: calc(100vw - 328px);
            }

            @include media-breakpoint-up(lg) {
                left: 447px;
                width: calc(100vw - 447px);
            }
        }

        .selected-category {
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .menu-group {
        flex: 0 0 100%;

        @include media-breakpoint-up(lge) {
            position: relative;
            width: 100%;
        }
    }

    li > .close-menu {
        margin-left: 0;
        margin-right: 0;
        margin-top: -0.6rem;
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            margin-right: 1.25em; /* 20/16 */
            right: 0;
        }

        @include media-breakpoint-down(lg) {
            .nav-item + .nav-item {
                border-top: 1px solid $color-n40;
            }

            .nav-item {
                display: block;
                position: static;
                width: 100%;
            }

            .dropdown-button {
                align-items: center;
                color: $color-t300;
                display: flex;
                font-size: $font-14;
                justify-content: space-between;
            }

            .no-dropdown {
                align-items: center;
                display: flex;

                @include media-breakpoint-up(lge) {
                    font-size: $font-14;
                }
            }

            .nav-item .nav-link {
                color: $color-t200;
                height: 48px;
                padding: $space-8 $space-16;

                span {
                    border: 0;
                    font-family: $font-sofia;
                    font-size: $font-16;
                    font-style: normal;
                    font-weight: $font-bold;
                    line-height: 120%;
                    outline: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .secondary-nav-item {
                    font-size: $font-14;
                    font-weight: $font-regular;
                }
            }

            .dropdown .nav-link {
                span {
                    max-width: 75%;
                }
            }

            .show > .dropdown-menu {
                height: 100dvh;
                left: 0;
                margin-top: 0;
                max-width: 319px;
                overflow-y: auto;
                position: fixed;
                width: 85%;

                @include media-breakpoint-only(lg) {
                    max-width: 447px;
                }
            }

            .dropdown-menu {
                border: 0 none;
                display: block;
                height: 100%;
                height: 100dvh;
                left: -110%;
                position: absolute;
                top: 0;
                transition: $slide-out-animation;
                width: 100%;
            }

            .container {
                padding: 0 $space-16;
            }

            .dropdown-item {
                font-size: $font-14;
                line-height: 100%;

                &:not(.semi-bold) {
                    font-weight: $font-regular;
                }

                .dropdown-link {
                    font-size: $font-14;
                    font-weight: $font-regular;
                }

                &--no-border {
                    border-bottom: 0;
                }
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        border: 0;
        border-radius: 0;
        top: 90%;

        @include media-breakpoint-up(lge) {
            background-color: $color-n10;
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
            padding: $space-32 $space-88 0;
            top: 98%;
            width: 100%;

            &.show {
                border-top: 1px solid $color-n50;
                display: flex;
                left: 0;
                margin: 0;
                position: absolute;
                right: 0;
                z-index: 4;
            }
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
        border-top: 0.3em solid transparent;
        display: none;
        margin-top: 0.55em;
        position: absolute;
        right: 0.3em;
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up(lge) {
            left: 99%;
            top: -0.65em;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu {
        .back-button {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-left: $space-8;

            svg {
                height: 24px;
                width: 24px;
            }
        }
    }

    .dropdown-item {
        border-bottom: 1px solid $color-n30;
        padding: 0;

        &:active,
        &:focus,
        &:hover {
            background-color: transparent;
            color: $color-t300;
        }

        + .dropdown-item {
            border-top: 0;
        }

        .dropdown-link {
            color: $color-t300;
            display: block;
            font-family: $font-sofia;
            font-size: $font-16;
            font-weight: $font-medium;
            line-height: 100%;
            overflow-x: clip;
            padding: 0;
            text-overflow: ellipsis;
            width: 100%;

            @include media-breakpoint-up(lge) {
                border: 0;
                font-size: $font-14;
                font-weight: $font-bold;
                padding: $space-8 0;
                text-align: left;
                width: auto;

                &:hover,
                &:focus {
                    background-color: transparent;
                    text-decoration: none;
                }
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &.top-category,
        &.top-sub-category {
            font-size: $font-16;
            font-weight: $font-bold;

            > .nav-link {
                background-color: transparent;
                padding: $space-16 0;
            }

            .category-thumbnail,
            svg {
                display: none;
            }
        }
    }
}

.secondary-nav {
    position: relative;

    .navbar {
        flex-direction: column;
        justify-content: center;

        .nav {
            .nav-link {
                span {
                    font-weight: 400;
                }
            }
        }
    }

    &.multilevel-dropdown {
        .dropdown-menu {
            margin: auto;
            max-width: 878px;
            padding: $space-32;
            width: 100%;

            .subcategory-container {
                padding: 0;
                width: 100%;
            }

            .col > .dropdown-item {
                margin: 0;
                padding: 0 $space-24;
            }

            .subnav .subnav-item {
                margin-right: 0;
            }
        }
    }

    .content-asset-image-menu {
        img {
            max-width: 100%;
        }
    }
}

.category-thumbnail {
    border-radius: $border-radius-4;
    height: 32px;
    margin-right: $space-16;
    width: 32px;

    @include media-breakpoint-up(lge) {
        display: none !important;
    }
}

// Label on top of menu mobile
.label-categories-mobile {
    border-bottom: 1px solid $color-n40;
    color: $color-t200;
}

// Links under categories on mobile
.utility-links-menu-container {
    .navbar-login .user-anchor .logged-user {
        height: 16px;
        width: 16px;

        @include media-breakpoint-up(lge) {
            height: 24px;
            width: 24px;

            .text {
                font-size: $font-12;
            }
        }

        .text {
            font-size: $font-8;
            margin: 0;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.utility-links-menu {
    background-color: $color-n20;
    border: 1px solid $color-n50;
    border-radius: $border-radius-8;
    margin: 0 $space-16 $space-8;
    padding: $space-16 $space-20;

    @include media-breakpoint-up(lge) {
        display: none;
    }

    &:first-of-type {
        margin-top: $space-16;
    }

    .reward-block {
        display: none;
    }

    .navbar-location {
        display: flex;

        &:active,
        &:hover {
            background-color: transparent;
        }

        .location {
            flex-grow: 1;

            .location-anchor {
                padding: 0;
                width: 100%;

                .location-text {
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    justify-content: space-between;
                    margin: 0;
                }
            }
        }
    }

    .navbar-login {
        display: flex;
        padding: 0;

        .user-anchor {
            .account-text {
                display: flex;
                font-weight: $font-regular;

                @include media-breakpoint-up(lge) {
                    font-weight: $font-medium;
                }
            }
        }
    }

    .user {
        .icon-login {
            height: 16px;
            stroke: $color-p500;
            width: 16px;

            @include media-breakpoint-up(lge) {
                height: 24px;
                stroke: $color-t300;
                width: 24px;
            }
        }

        .popover {
            max-width: 319px;
            padding: $space-16;
            top: 0;
        }
    }
}

.inspiration-menu-container {
    border-radius: $border-radius-8;
    color: $color-n0;
    font-family: $font-questa;
    font-size: $font-26;
    font-weight: $font-black;
    height: 148px;
    margin: $space-16 auto;
    width: 287px;

    @include media-breakpoint-up(lge) {
        display: none;
    }

    a {
        align-items: flex-end;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: $space-16 $space-24;

        &:hover,
        &:focus {
            color: $color-n0;
            text-decoration: none;
        }
    }

    .content-asset-wrapper,
    .position-relative,
    .inspiration-menu {
        height: 100%;
    }
}

// Content Asset Menu
.content-asset-menu {
    list-style: none;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(lge) {
        border-right: 1px solid $color-n40;
        flex-direction: column;
        margin: 0 $space-8 $space-32;
        min-width: 250px;
    }

    li {
        @include media-breakpoint-up(lge) {
            margin-bottom: $space-16;
        }

        a {
            display: block;
            font-family: $font-sofia;
            font-size: $font-14;
            padding: $space-8 $space-16;
            white-space: nowrap;

            @include media-breakpoint-up(lge) {
                font-weight: $font-bold;
                line-height: 120%;
                padding: 0;
            }
        }
    }
}

.content-asset-image-menu {
    display: none;

    @include media-breakpoint-up(lge) {
        display: flex;
        flex-direction: column;
    }

    img {
        border-radius: $border-radius-8;
        max-width: 250px;
    }

    a {
        align-items: center;
        color: $color-t300;
        display: flex;
        font-weight: $font-medium;
        justify-content: space-between;
        margin-top: $space-8;
        text-decoration: none;
    }
}

//Subcategories subnav-item menu
.dropdown-menu {
    .nav-menu {
        .close-menu {
            align-items: center;
            background-color: $color-sa50;
            border-bottom: 1px solid $color-n40;
            display: flex;

            .close-button {
                display: none;
            }
        }
    }
}

.subcategory-container {
    border-top: 1px solid $color-n40;
    display: block;
    margin-bottom: $space-16;
    padding-left: 0;
    padding-top: $space-18;

    @include media-breakpoint-up(lge) {
        border: 0;
        height: 100%;
        margin: 0;
        padding: 0 $space-24;
        width: 80%;
    }

    .dropdown-item {
        padding: $space-12 $space-16;
        width: auto;

        @include media-breakpoint-up(lge) {
            border: 0;
            border-radius: unset;
            padding: 0 $space-16;
            position: static;
            white-space: normal;

            a {
                font-size: $font-14;
                font-weight: $font-bold;
            }

            svg {
                display: none;
            }
        }

        @include media-breakpoint-up(xxl) {
            margin-right: $space-48;
        }

        .category-name {
            line-height: 120%;
            max-width: 90%;
            overflow-x: clip;
            text-overflow: ellipsis;

            @include media-breakpoint-up(lge) {
                display: block;
                line-height: 120%;
                margin-bottom: $space-16;
                overflow: visible;
            }
        }
    }

    .dropdown,
    .subnav-item {
        &.show {
            >.subnav {
                left: 0;
                transition: $slide-out-animation;
            }
        }
    }
}

// Subcategories l3 menu
.subnav {
    background-color: $color-n0;
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    transition: $slide-out-animation;
    width: 100%;

    @include media-breakpoint-up(lge) {
        background-color: $color-n10;
        font-family: $font-sofia;
        font-size: $font-16;
        font-weight: $font-medium;
        height: auto;
        left: initial;
        line-height: 110%;
        list-style-type: none;
        margin: 0;
        margin-top: $space-16;
        padding: 0 0 $space-16;
        position: static;
        top: 0;
        width: auto;
    }

    .close-menu {
        background-color: $color-sa50;
        border-bottom: 1px solid $color-n40;
        padding: $space-16 0;

        span {
            color: $color-t300;
            font-family: $font-sofia;
            font-weight: $font-bold;
            padding-left: $space-8;
        }
    }

    .subnav-item {
        border-bottom: 1px solid $color-n40;
        padding: $space-12 $space-16;

        @include media-breakpoint-up(lge) {
            border: 0;
            padding: 0;

            a {
                color: $color-t200;
                display: inline-block;
                font-size: $font-14;
                font-weight: $font-medium;
                line-height: 120%;
                padding: 0 0 $space-14;
            }

            svg {
                display: none;
            }
        }
    }
}
