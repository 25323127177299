@import "../variables";
@import "../utilities/shapes";

@mixin swatch($diameter, $color, $accent, $borderc, $borderw, $lightcheck, $lightborder) {
    @include circle($diameter, $color);
    @include swatch-check-mark($borderc, $borderw, $lightcheck, $lightborder);
    background-clip: content-box;
    background-size: cover;
    display: inline-block;
    margin-right: 0.313em;
    padding: 2px;
    position: relative;

    &.disabled,
    &.disabled.selected {
        opacity: 1;
        pointer-events: none;

        &::after {
            @include line(60px, 1px, $accent);
            left: -6px;
            position: absolute;
            top: 22px;
        }
    }
}

@mixin swatch-check-mark($borderc, $borderw, $lightcheck, $lightborder) {
    &.color-value[data-selected=true],
    &.color-value.selected {
        border: $borderw solid $borderc;

        &::after {
            @include checkmark(6px, 14px, $color-n0);
        }

        &.swatch-circle-white,
        &.swatch-circle-yellow,
        &.swatch-circle-beige,
        &.swatch-circle-ivory {
            border: $borderw solid $lightborder;

            &::after {
                border-color: $lightcheck;
            }
        }

        &.disabled {
            border: $borderw solid $lightborder;

            &::after {
                border-color: $lightcheck;
                border-width: 0 1px 1px 0;
                bottom: auto;
                left: -$space-8;
                top: $space-20;
            }
        }
    }

    &.color-value:hover {
        border: $borderw solid $color-n40;
    }

    &.color-value.selected:hover {
        border: $borderw solid $borderc;
    }
}

$swatch-colors: (
    "grey": #b0b0b0,
    "blue": #607c98,
    "natural": #d7c9a4,
    "green": #73a56b,
    "gray": #808080,
    "gold": #d4af37,
    "brown": #827363,
    "white": #edeeef,
    "black": #333,
    "orange": #d29b60,
    "pink": #e5aaf3,
    "beige": #f5f5dc,
    "metallic": #a8a9ad,
    "navy": #000080,
    "purple": #800080,
    "red": #f00,
    "silver": #c0c0c0,
    "yellow": #ff0
);

@each $color in map-keys($swatch-colors) {
    $color-value: #{map-get($swatch-colors, $color)};

    .swatch-circle-#{$color} {
        @include circle(46px, $color-value);
        @include swatch-check-mark($color-t300, 1px, $color-b300, $color-t300);
        background-clip: content-box;
        background-size: cover;
        display: block;
        padding: $space-2;
        position: relative;

        &.disabled {
            opacity: 1;

            &::after {

                @include line(60px, 1px, $color-b300);
                left: -6px;
                position: absolute;
                top: 22px;
            }
        }
    }

    .swatch-filter-#{$color} {
        @include circle(1.38em, $color-value);
        @include swatch-check-mark($color-t300, 1px, $color-b300, $color-t300);

        background-color: $color-value;
        display: block;
        position: relative;

        &.disabled {
            opacity: 0.2;
        }
    }
}

// Credit: Christian Muecke (cmuecke@demandware.com)
.swatch-circle-miscellaneous {

    @include swatch-check-mark($color-t300, 1px, $color-b300, $color-t300);
    background:
        conic-gradient(from -18deg,
        #612d91 8.3%, #283897 0% 16.6%, #006cb8 0% 24.9%, #00a2b7 0% 33.2%, #00a666 0% 41.5%,
        #a6cf4f 0% 49.8%, #fcf100 0% 58.1%, #faae18 0% 66.4%, #f5811f 0% 74.7%, #f25822 0% 83%, #ed1b24 0% 91.3%, #bc0070 0%);
    background-clip: content-box;
    background-size: cover;
    border: 0;
    border-radius: 23px;
    display: block;
    height: 46px;
    padding: $space-2;
    position: relative;
    transform: none;
    width: 46px;

    &.disabled {
        opacity: 1;

        &::after {

            @include line(60px, 1px, $color-b300);
            left: -6px;
            position: absolute;
            top: 22px;
        }
    }
}

.swatch-count {
    line-height: $font-12;
}
