// Recommendations
.recommendations-title {
    margin-bottom: $space-8;
    margin-left: $space-16;

    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $color-n50;
        margin-bottom: $space-16;
        margin-left: 0;
        padding-bottom: $space-16;
    }

    h2 {
        font-size: $font-23;

        @include media-breakpoint-up(lg) {
            font-size: $font-29;
        }
    }
}

.recommendations-slider {
    margin-bottom: $space-56;
    position: relative;

    .eistein-carousel {
        &:not(.slick-initialized) {
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;

            .product {
                margin: 0 $space-16 0 0;
                min-width: 256px;
                padding: 0;
                width: 33vw;
            }
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slick-track {
        margin: 0;
    }

    .slick-initialized {
        .slick-slide {
            margin-right: $space-24;
        }
    }
}

.slide-wrapper-minicart {
    .product {
        margin: 0 $space-16 0 0;
        padding: 0;
        width: 100%;
    }
}

.custom-slick-arrows {
    .slick-arrow {
        background-color: $color-n0;
        border-radius: 100px;
        bottom: auto;
        cursor: pointer;
        filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
        margin: auto;
        padding: $space-4;
        position: absolute;
        top: calc((#{map-get($container-max-widths, lg)} - (24px * 5)) / 11.5);
        z-index: 2;

        @include media-breakpoint-up(xl) {
            top: calc((#{map-get($container-max-widths, xl)} - (24px * 5)) / 11.5);
        }

        @include media-breakpoint-up(xxl) {
            top: calc((#{map-get($container-max-widths, xxl)} - (24px * 5)) / 11.5);
        }

        &.arrow-right {
            right: $space-16;
        }

        &.arrow-left {
            left: $space-16;
        }

        &.slick-disabled {
            display: none !important;
        }
    }
}

// Related Searches
.related-searches-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin: 0 auto;
    max-width: 710px;
}

.related-searches-link {
    border: 1px solid $color-n50;
    border-radius: 100px;
    display: block;
    font-size: $font-14;
    padding: $space-8 $space-16;
}
