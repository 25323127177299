@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.slide-container[data-carousel-behavior="true"] {
    .slide-carousel {
        &:not(.slick-initialized) {
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            padding-bottom: $space-16;

            @include media-breakpoint-up(lg) {
                margin: 0;
                padding-bottom: $space-24;
            }

            .experience-component {
                &:not(:first-child) {
                    display: block;
                }
            }

            >div {
                width: 33vw;

                @include media-breakpoint-down(md) {
                    margin-right: $space-16;
                }

                @include media-breakpoint-up(lg) {
                    width: calc(100% / 5.5);
                }

                &:first-child {
                    margin-left: $space-16;
                }
            }
        }
    }
}


.slide-container {
    max-height: fit-content;
    position: relative;

    .title-container {
        color: $color-t300;
        font-size: $font-32;

        &.title-key-line {
            border-bottom: 1px solid $color-n50;
            padding-bottom: $space-16;
        }

        .carousel-heading {
            line-height: 120%;

            * {
                color: inherit;
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                margin: 0;
            }
        }
    }

    .slide-carousel {
        .slick-track {
            margin: 0 auto;

            .slick-slide {
                height: auto;

                div {
                    vertical-align: middle;
                }
            }
        }

        .slick-arrow {
            background-color: $color-n0;
            border-radius: 100px;
            cursor: pointer;
            filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
            margin: auto;
            padding: $space-4;
            position: absolute;
            z-index: 2;

            &.arrow-right {
                right: $space-16;
            }

            &.arrow-left {
                left: $space-16;
            }

            &.slick-disabled {
                display: none !important;
            }
        }

        &.container {
            .slick-arrow {
                &.arrow-right {
                    right: 0;
                }

                &.arrow-left {
                    left: 0;
                }
            }
        }

        .slick-dots {
            align-items: center;
            bottom: $space-24;
            display: flex;
            justify-content: center;
            left: 0;
            list-style: none;
            margin: 0 auto;
            padding: 0;
            position: absolute;
            right: 0;

            li {
                button {
                    background-color: $color-n100;
                    border: 0;
                    border-radius: 50%;
                    cursor: pointer;
                    font-size: 0;
                    height: 8px;
                    margin: 0 $space-4;
                    opacity: 0.5;
                    padding: 0;
                    width: 8px;
                }
            }

            .slick-active {
                button {
                    background-color: $color-n0;
                    height: 12px;
                    width: 12px;
                }
            }
        }

        .photo-tile-container {
            .row {
                margin: 0;
                padding: 0;
            }

            .col-12 {
                margin: 0;
                padding: 0;
            }

            .photo-tile-figure {
                margin: 0;
                padding: 0;
            }
        }

        &:not(.slick-initialized) {
            .experience-component {
                &:not(:first-child) {
                    display: none;
                }
            }
        }
    }

    &.peek-slider {
        .slick-list {
            overflow: visible;

            .slick-track {
                margin: 0;
            }
        }
    }

    &[data-slides-to-display-sm="1"] {
        &:not(.slick-initialized) {
            .slide-carousel >div {
                @include media-breakpoint-down(md) {
                    min-width: 60vw;
                }
            }
        }
    }

    &[data-slides-to-display-sm="2"] {
        @include media-breakpoint-down(md) {
            .slick-list {
                margin-left: -$space-12;
                margin-right: -$space-12;
            }

            .slick-slide {
                padding: 0 $space-12;
            }
        }

        .slide-carousel {
            &:not(.slick-initialized) {
                >div {
                    margin: 0 $space-12;
                }
            }
        }
    }

    &[data-slides-to-display-lg="5"] {
        // number of slides: 5;
        // space between slides: 24px;
        // Coefficient for tile sizing: 5.75;
        // coefficient for arrows position: 11.5;

        .slick-initialized {
            .slick-slide {
                @include media-breakpoint-up(lg) {
                    margin: 0 $space-12;
                }
            }

            .slick-arrow {
                @include media-breakpoint-up(xl) {
                    bottom: auto;
                    top: calc((#{map-get($container-max-widths, xl)} - (24px * 5)) / 11.5);
                }

                @include media-breakpoint-up(xxl) {
                    bottom: auto;
                    top: calc((#{map-get($container-max-widths, xxl)} - (24px * 5)) / 11.5);
                }
            }
        }
    }

    &[data-slides-to-display-lg="2"],
    &[data-slides-to-display-lg="7"] {
        @include media-breakpoint-up(lg) {
            .slick-slide {
                padding: 0 $space-12;
            }
        }
    }
}

.arrow-mobile {
    @include media-breakpoint-down(md) {
        &-top {
            .slide-carousel {
                .slick-arrow {
                    bottom: 50%;
                    top: 0;
                }
            }
        }

        &-center {
            .slide-carousel {
                .slick-arrow {
                    bottom: 0;
                    top: 0;
                }
            }
        }

        &-bottom {
            .slide-carousel {
                .slick-arrow {
                    bottom: 0;
                    top: 50%;
                }
            }
        }
    }
}

.arrow-desktop {
    @include media-breakpoint-up(lg) {
        &-top {
            .slide-carousel {
                .slick-arrow {
                    bottom: 50%;
                    top: 0;
                }
            }
        }

        &-center {
            .slide-carousel {
                .slick-arrow {
                    bottom: 0;
                    top: 0;
                }
            }
        }

        &-bottom {
            .slide-carousel {
                .slick-arrow {
                    bottom: 0;
                    top: 50%;
                }
            }
        }
    }
}

// Intentional important setup on element to mirror library styles
.slick-sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
}

.slick-autoplay-toggle-button {
    bottom: $space-24;
    position: absolute;
    right: $space-24;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        right: $space-16;
    }
}

.carousel-container {
    img:not([src]) {
        opacity: 0;
    }
}
