@import "../variables";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: $color-t300;
    font-family: $font-sofia, sans-serif;
    font-style: normal;
    line-height: 120%;
    margin: 0;
    padding: 0;
}

h1,
.h1 {
    font-size: $font-36;
}

h2,
.h2 {
    font-size: $font-32;
}

h3,
.h3 {
    font-size: $font-29;
}

h4,
.h4 {
    font-size: $font-26;
}

h5,
.h5 {
    font-size: $font-23;
}

h6,
.h6 {
    font-size: $font-20;
}
