@import "../scss/variables";

.container {
    padding: 0;
}

// Grid
.col,
[class^="col-"],
[class*=" col-"] {
    padding-left: $space-8;
    padding-right: $space-8;

    @include media-breakpoint-up(md) {
        padding-left: $space-12;
        padding-right: $space-12;
    }
}

.row {
    margin-left: -$space-8;
    margin-right: -$space-8;

    @include media-breakpoint-up(lg) {
        margin-left: -$space-12;
        margin-right: -$space-12;
    }

    &.no-gutters {
        margin-left: 0;
        margin-right: 0;
    }
}

// Buttons

.btn {
    border: 0;
    border-radius: $border-radius-4;
    font-family: $font-sofia, sans-serif;
    font-size: $font-14;
    font-weight: $font-bold;
    letter-spacing: 0.05rem;
    line-height: $font-16;
    padding: $space-16 $space-24;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        text-decoration: underline;
    }

    &:disabled {
        background-color: $color-n40;
        color: $color-t100;
    }

    &:focus {
        box-shadow: 0 0 0 2px $color-n900;
    }

    &.btn-primary {
        background-color: $color-p400;
        color: $color-t0;

        &:not(:disabled):not(.disabled):not(.livechat-links a) {
            &:hover {
                background-color: $color-p500;
            }

            &:active {
                background-color: $color-p500;
                box-shadow: 0 4px 4px 0 #00000040 inset;
            }
        }
    }

    &.btn-secondary {
        background-color: $color-sa300;
        color: $color-t300;

        &:not(:disabled):not(.disabled) {
            &:hover {
                background-color: $color-sa200;
            }

            &:active {
                background-color: $color-sa200;
            }
        }
    }

    &.btn-outline-primary {
        box-shadow: 0 0 0 1px $color-n300 inset;
        color: $color-t300;

        &:not(:disabled):not(.disabled) {
            &:hover {
                background-color: $color-n0;
                box-shadow: 0 0 0 1px $color-p500 inset;
            }

            &:active {
                background-color: $color-n0;
                box-shadow: 0 0 0 1px $color-b300 inset;
                color: $color-t300;
            }
        }
    }

    &.btn-outline-secondary {
        box-shadow: 0 0 0 1px $color-n0 inset;
        color: $color-n0;

        &:not(:disabled):not(.disabled) {
            &:hover,
            &:active {
                background-color: transparent;
                box-shadow: 0 0 0 2px $color-n0 inset;
                font-weight: $font-bold;
            }
        }
    }

    &.btn-text {
        background-color: transparent;
        border: 0;
        color: $color-b300;
        font-size: $font-18;
        font-weight: $font-bold;
        padding: 0;
        text-decoration: underline;
        text-transform: capitalize;
    }

    &.btn-xs {
        padding: $space-8 $space-24;
    }

    &.btn-sm {
        padding: $space-12 $space-24;
    }


    &.btn-lg {
        padding: $space-20 $space-24;
    }

    &-login {
        width: 100%;
    }
}

.modal-dialog {
    max-width: 600px;

    .modal-content {
        border-radius: $border-radius-8;

        .modal-header {
            border: 0;
            font-family: $font-sofia;
            padding: $space-32 $space-32 $space-8 $space-32;

            .close {
                padding-bottom: 0;
            }
        }

        .modal-body {
            font-family: $font-sofia;
            padding: $space-8 $space-32 $space-32 $space-32;
        }
    }
}

.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.skip {
    background: $white;
    left: 0;
    overflow: hidden;
    padding: 1em 1.5em;
    position: absolute;
    top: -4.2em;
    transition: all 0.2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        background: $white;
        height: auto;
        left: 0;
        top: 0;
        transition: all 0.2s ease-in-out;
        width: auto;
        z-index: 10000000;
    }
}

//form input

.form-group {
    position: relative;

    .form-control:not([type="checkbox"]),
    .form-control:not([type="radio"]) {
        background-color: $color-n0;
        border: 1px solid $color-n500;
        border-radius: $border-radius-4;
        box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
        color: $color-t300;
        font-family: $font-sofia;
        font-size: $font-16;
        font-weight: $font-regular;
        height: 48px;
        line-height: 120%;

        &.custom-select {
            appearance: none;
            background: $color-n0 url("../../images/chevron-down-grey.svg") no-repeat center right $space-16;
            border: 1px solid $color-n500;
            border-radius: $border-radius-4;
            color: $color-t200;
            font-size: $space-16;
            padding: $space-14 $space-16;

            &:focus {
                background-image: url("../../images/chevron-up-grey.svg");
                background-position: center right calc($space-16 - 1px);
                padding: calc($space-14 - 1px) calc($space-16 - 1px);
            }
        }

        &:focus {
            border: 2px solid $color-n900;
            box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
            outline: 0;
        }

        &.is-invalid {
            background-color: $color-s50;
            background-image: none;
            border: 2px solid $color-s300;
            padding-left: calc($space-16 - 1px);
        }

        &.has-tooltip {
            padding-right: $space-48;
        }
    }

    .icon-eye {
        position: absolute;
        right: $space-16;
        top: $space-12;
    }

    .icon-tooltip {
        position: absolute;
        right: $space-16;
        top: $space-10;

        .tooltip {
            transform: translate(-89%, -9px);

            &::after {
                left: 90%;
            }
        }
    }

    .invalid-feedback {
        color: $color-e300;
        font-size: $font-14;
        font-weight: $font-medium;
        line-height: 120%;
    }
}


.floating-label {
    color: $color-t200;
    margin-bottom: $space-16;
    position: relative;

    > label {
        border: 1px solid transparent;
        border-radius: $border-radius-4;
        color: $color-t200;
        display: block;
        font-size: $font-16;
        font-weight: $font-regular;
        height: 48px;
        left: 0;
        line-height: 120%;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        transition: all 0.1s ease-in-out;
        width: 100%;
    }

    > .form-control:not([type="checkbox"]),
    > .form-control:not([type="radio"]),
    > label {
        padding: $space-14 $space-16;
    }

    > .form-control:not([type="checkbox"]),
    > .form-control:not([type="radio"]) {
        &::placeholder,
        &:input-placeholder {
            color: transparent;
        }

        .custom-select {
            color: $color-t300;
            font-size: $font-14;
        }

        &:-internal-autofill-selected {
            padding-bottom: calc($space-14 / 2);
            padding-top: calc($space-14 + $space-14 * (2 / 3));

            + label {
                color: $color-t300;
                font-size: 12px;
                font-weight: $font-bold;
                padding-bottom: calc($space-14 / 2);
                padding-top: calc($space-14 / 2);
                pointer-events: none;
            }
        }

        &:focus {
            padding-bottom: calc(($space-14 / 2) - 1px);
            padding-left: calc($space-16 - 1px);
            padding-top: calc(($space-14 + $space-14 * (2 / 3)) - 1px);

            + label {
                color: $color-t300;
                font-size: 12px;
                font-weight: $font-bold;
                padding-bottom: calc($space-14 / 2);
                padding-top: calc($space-14 / 2);
                pointer-events: none;
            }
        }

        &:not(:placeholder-shown) {
            line-height: unset;
            padding-bottom: calc(($space-12 / 2) - 1px);
            padding-top: calc($space-12 + $space-12 * (2 / 4));

            + label {
                color: $color-t300;
                font-size: 12px;
                font-weight: $font-bold;
                padding-bottom: calc($space-12 / 2);
                padding-top: calc($space-12 / 2);
                pointer-events: none;
            }

            &:focus {
                padding-bottom: calc(($space-12 / 2) - 2px);
                padding-left: calc($space-16 - 1px);
                padding-top: calc(($space-12 + $space-12 * (2 / 4)) - 1px);
            }
        }
    }
}

// Card elements

.card {
    border-radius: 5px;
    margin-bottom: $space-8;

    &.ghost .card-header {
        border: 0;
        padding: 0;
    }
}

.card-header-no-border {
    border-bottom: 0;
}

.card-header {
    background-color: transparent;
    padding-bottom: $space-8;
    padding-top: $space-8;
}

.card-body {
    padding: $space-8;
}

.card-header .card-section-number {
    background: $color-p400;
    border: 1px solid $color-p400;
    border-radius: 100px;
    color: $white;
    display: inline-block;
    font-size: $font-14;
    font-weight: $font-bold;
    height: 32px;
    line-height: 30px;
    margin-right: $space-8;
    text-align: center;
    width: 32px;

    .ghost & {
        background: none;
        border: 1px solid $color-n70;
        color: $color-t100;
    }
}

.badge {
    font-size: $font-14;
    font-weight: $font-bold;
    line-height: unset;
    padding: $space-8;
    vertical-align: unset;
}

.nav-tabs {
    align-items: center;
    border-bottom: 1px solid $color-n50;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    .nav-item {
        align-items: center;
        display: flex;
        width: 100%;

        .nav-link {
            border: transparent;
            border-bottom: 1px solid $color-n50;
            color: $color-t100;
            font-family: $font-sofia;
            font-size: $font-16;
            font-weight: $font-bold;
            position: relative;
            text-transform: uppercase;
            width: 100%;

            &.active {
                border-bottom: 4px solid #5c8b8d;
                padding-bottom: $space-12;
            }
        }
    }
}

.custom-checkbox {
    display: flex;
    float: none;
    margin: 0;
    min-height: none;
    padding: 0;

    .custom-control-label {
        color: $color-t300;
        cursor: pointer;
        display: flex;
        font-family: $font-sofia;
        font-size: $space-14;
        font-weight: $font-medium;
        line-height: 120%;

        &::before {
            background: $color-n0;
            border: 1px solid $color-n500;
            border-radius: $border-radius-4;
            box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
            height: 24px;
            left: 0;
            margin-right: $space-8;
            min-width: 24px;
            position: unset;
            top: 0;
        }

        &::after {
            height: 24px;
            left: 0;
            min-width: 24px;
            padding: 5px;
            position: absolute;
            top: 0;
        }
    }

    .custom-control-input:checked + .custom-control-label::after {
        background-image: $svg-check;
        border-radius: $border-radius-4;
        filter: invert(1);
    }

    .custom-control-input:checked + .custom-control-label::before {
        background: $color-p400;
    }
}

.modal-open {
    padding-right: 0 !important;
}

.modal-backdrop {
    &.transparent {
        opacity: 0;
        z-index: 2;
    }
}
