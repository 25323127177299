.location {
    position: relative;

    .location-anchor {
        align-items: center;
        background-color: transparent;
        border: 0;
        display: flex;
        padding: $space-8;
        white-space: nowrap;

        .icon-map-pin {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }

        .location-text {
            display: none;
            flex-direction: column;
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
            margin: 0 0 0 $space-12;
            padding: 0;

            @include media-breakpoint-up(lg) {
                display: flex;
            }

            .find-location-link {
                text-decoration: underline;
            }
        }
    }

    .btn-store-locator {
        width: 100%;
    }
}

.location-modal {
    align-items: center;
    background-color: $white;
    border-radius: 2px;
    border-radius: $space-4;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3), 0 6px 10px 4px rgba(0, 0, 0, 0.08);
    display: none;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    left: -$space-64;
    margin-left: 0;
    max-width: 100vw;
    padding: $space-24 0 $space-24;
    position: absolute;
    top: 48px;
    transition: ease-in-out;
    width: 375px;
    z-index: 1060;

    @include media-breakpoint-up(sm) {
        left: -51px;
        padding: $space-18 0 $space-16;
    }

    @include media-breakpoint-up(md) {
        left: auto;
        margin-left: -30px;
    }

    @include media-breakpoint-up(lg) {
        top: auto;
        width: 400px;
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 0;
    }

    &.show {
        display: flex;
    }

    .close {
        opacity: 1;
    }

    .modal-dialog {
        margin: 0;
        max-width: none;
    }

    .modal-content {
        border: 0;
    }

    .locations-list-container {
        @include media-breakpoint-up(md) {
            padding-right: $space-12;
        }
    }

    .main-stores-list {
        max-height: calc(100dvh - 350px);
        overflow: auto;

        @include media-breakpoint-up(lg) {
            max-height: 514px;
        }
    }

    .location-modal-header {
        background-color: unset;
        border: 0;
        border-bottom: 1px solid $color-n50;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 0 $space-16;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding: 0 $space-24;
        }

        .location-modal-header-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: $space-16;
        }

        .btn-storelocator-search {
            border-radius: 0 4px 4px 0;
            height: 48px;
            position: absolute;
            right: 0;
            top: 0;
            width: 106px;
        }

        .card-body {
            padding: 0;
        }

        .form-control {
            font-size: $font-16;
            height: 48px;
        }
    }

    .btn-store-locator-container {
        border-top: 1px solid $color-n50;
        padding: $space-16;

        @include media-breakpoint-up(md) {
            padding: $space-16 $space-24;
        }

        @include media-breakpoint-up(lg) {
            padding: $space-16 $space-24 0;
        }
    }

    .store-item {
        padding: 0 $space-12 0 $space-16;
        position: relative;

        @include media-breakpoint-up(md) {
            padding: 0 $space-8 0 $space-24;
        }

        &-selected {
            background-color: $color-p50;
        }

        .selected-text {
            display: none;
        }

        &.store-item-selected {
            background-color: $color-p50;

            .selected-text {
                display: flex;

                span {
                    min-height: 20px;
                }
            }

            .select-button {
                display: none;
            }

            .store-card {
                border: 0;
            }

            +.store-item .store-card {
                border: 0;
            }
        }

        &:first-of-type .store-card {
            border-top: 0;
        }

        &:last-of-type .store-card {
            border-bottom: 1px solid $color-n50;

            @include media-breakpoint-down(md) {
                margin-bottom: $space-12;
            }
        }

        .store-card {
            align-items: center;
            border-top: 1px solid $color-n50;
            display: flex;
            flex-direction: column;
            height: fit-content;
            justify-content: center;
            padding: $space-8 0;
            width: 100%;

            .store-card-top {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin-top: $space-8;
                width: 100%;
            }

            .store-card-bottom {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: $space-20 0 $space-8;
                width: 100%;

                .store-details-btn {
                    text-decoration: none;
                }

                button {
                    margin-left: auto;
                }
            }
        }

        .expanded svg {
            transform: rotate(180deg);
        }

        .store-address {
            color: $color-t200;
        }
    }

    .list {
        .store-phone-link {
            color: $color-p500;
            font-weight: $font-regular;
        }
    }

    .store-card {
        .store-details-list li span {
            color: $color-t200;
        }
    }

    &.location-modal-centered {
        bottom: 0;
        left: 0;
        margin: auto;
        max-height: 100vh;
        max-width: 400px;
        overflow: auto;
        position: fixed;
        right: 0;
        top: 0;
        width: 400px;

        @include media-breakpoint-down(md) {
            height: 100%;
            justify-content: flex-start;
            max-width: 100%;
            overflow: auto;
            position: fixed;
            width: 100%;

            .list {
                max-height: calc(100vh - 180px);

                @include target-ios() {
                    max-height: calc(100vh - 180px);
                }
            }
        }

        &::after,
        &::before {
            display: none;
        }
    }

    .form-control-label {
        pointer-events: none;
    }

    .store-locator-no-location {
        line-height: 16.8px;
    }
}

.location-modal::before {
    border-bottom: 10px solid $color-n50;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: -21px;
    width: 0;
}

.location-modal::before,
.location-modal::after {
    left: 70px;

    @include media-breakpoint-up(sm) {
        left: 57px;
        top: -20px;
    }

    @include media-breakpoint-up(md) {
        left: 38px;
    }
}

.location-modal::after {
    border-bottom: 10px solid $white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: -18px;
    width: 0;
}

.store-phone {
    display: none;
}

.in-store-inventory-dialog {
    .main-stores-list {
        max-height: 635px;
    }

    .stock-alert-wrapper {
        flex-flow: row;
        justify-content: space-between;
        margin: $space-10 0;
    }

    .hide-store-select {
        display: none;
    }
}

// PDP only styling
.pdp-store-modal {
    .stock-alert-wrapper-cart {
        display: none;
    }
}

// Cart only styling
.cart-store-modal {
    .stock-alert-wrapper-pdp {
        display: none;
    }
}
