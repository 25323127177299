@mixin circle($diameter, $color) { // Source:  https://gist.github.com/kogakure/4769904
    background: $color;
    border-radius: calc($diameter / 2);
    border-radius: calc($diameter / 2);
    border-width: 0;
    height: $diameter;
    width: $diameter;
}

@mixin checkmark($width, $height, $color) {
    border: solid $color;
    border-width: 0 2px 2px 0;
    bottom: 0;
    content: "";
    display: block;
    height: $height;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(45deg);
    width: $width;
}

@mixin line($width, $height, $color) {
    background: $color;
    content: "";
    display: block;
    height: $height;
    transform: rotate(-45deg);
    width: $width;
}

@mixin pill($radius, $border-color, $background-color, $accent, $bgaccent, $hoverb) {
    background-color: $background-color;
    border: 1px solid $border-color;
    border-radius: $radius;
    color: $color-t400;
    display: inline-flex;
    margin-bottom: $space-8;
    margin-right: $space-8;
    padding: $space-8 $space-24;

    &.disabled {
        color: $color-t75;
        text-decoration: line-through;

        &:hover {
            border: 1px solid $border-color;
        }
    }

    &.selected,
    &.selected:hover {
        background-color: $bgaccent;
        border: 2px solid $accent;
        cursor: default;
        padding: $space-8 calc($space-24 - 1px);

        &.gift-card:hover {
            padding: $space-8 $space-4;
        }
    }

    &:hover {
        border: 1px solid $hoverb;
    }
}
