.modal.modal-aside {
    transition: none;

    .modal-dialog {
        bottom: 0;
        height: 100%;
        height: auto;
        margin: auto;
        position: fixed;
        transition: opacity 0.3s linear, right 0.3s ease-out;
        width: 100%;

        @include media-breakpoint-up(lg) {
            right: -525px;
            top: 0;
            width: 525px;
        }
    }

    &-small .modal-dialog {
        @include media-breakpoint-up(lg) {
            height: 100%;
            right: -472px;
            width: 472px;
        }
    }

    &.show .modal-dialog {
        right: 0;
    }

    .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
        overflow-y: auto;
    }

    .modal-header {
        border-bottom: 1px solid $color-n50;
        margin: $space-20 $space-32 $space-20;
        padding: 0 0 $space-20;

        @include media-breakpoint-up(lg) {
            margin: $space-96 $space-32 $space-20;
        }
    }

    .modal-body {
        padding: $space-8 $space-32 $space-32;
    }

    .close {
        opacity: 1;
    }
}
