@import "collapsibleItem";
@import "base/components/toastMessage";

.footer {
    background-color: $color-n0;
    border-top: 1px solid $color-n40;
    color: $color-t200;
    font-family: $font-sofia;

    ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
    }

    .social {
        @include clearfix;
        margin-top: $space-32;
        padding-left: 0;
        padding-right: 0;
    }

    .footer-top-links {
        color: $color-b300;
        padding: $space-64 0;

        @include media-breakpoint-down(md) {
            padding: $space-32 0;
        }

        .footer-top-heading {
            color: $color-b300;
            font-size: $font-29;

            @include media-breakpoint-up(lg) {
                font-size: $font-36;
            }
        }

        .footer-right-links {
            margin-left: 6.5%;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-top: $space-12;
            }
        }

        .menu-footer {
            color: $color-t300;
            display: block;
            font-size: $font-14;
            margin: 0 $space-24;
            text-decoration: underline;

            @include media-breakpoint-up(lg) {
                margin: 0 $space-32;
            }

            .footer-top-icon {
                display: block;
                height: 35px;
                margin: 0 auto;
                width: 35px;

                @include media-breakpoint-up(lg) {
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }

    .footer-rewards {
        margin-top: $space-32;

        @include media-breakpoint-up(lg) {
            border-left: 1px solid $color-n50;
            margin-top: 0;
            padding-left: $space-32;
            padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
            border-left: 0;
        }

        li {
            margin-bottom: $space-8;
        }

        img {
            margin-bottom: $space-8;
            margin-top: $space-8;
        }

        .rewards-subtitle {
            font-size: $font-20;
        }

        .footer-rewards-title {
            color: $color-b300;
            font-size: $font-29;
        }

        .footer-rewards-subtitle {
            font-size: $font-16;
            margin-bottom: $space-16;
        }

        .footer-rewards-text {
            color: $color-t300;
            font-family: $font-sofia;
            font-size: $font-14;
            line-height: $space-16;
        }

        .rewards-signup {
            display: flex;
        }

        .footer-anchor {
            font-weight: $font-medium;
            margin-right: $space-16;
            text-decoration-line: underline;
        }

        .disclaimer {
            color: $color-p500;
        }

        .rewards-disclaimer {
            font-size: $font-13;
        }

        .ui-icon-check {
            margin-right: $space-12;

            svg {
                height: 15px;
                width: 22px;
            }
        }
    }

    .footer-contact-us {
        font-family: $font-sofia;
        font-size: $font-14;
        margin-top: $space-32;
        padding: 0 0 $space-24;

        @include media-breakpoint-up(lg) {
            margin-top: $space-16;
        }

        .first-item {
            font-size: $font-16;
        }

        .footer-contact-us-title {
            font-size: $font-20;
        }

        .footer-contact-us-row {
            display: flex;

            &.single {
                align-items: center;
            }
        }

        svg {
            height: 32px;
            margin: $space-8 $space-8 0 0;
            width: 32px;
        }
    }

    .footer-email-input {
        display: flex;
        flex-wrap: wrap;
        margin: $space-24 0 $space-16;

        .form-control {
            flex: 1;
        }

        .invalid-feedback {
            flex: 0 0 100%;
        }
    }

    .footer-subscribe-email {
        border-radius: 0 4px 4px 0;
        padding: $space-16;

        &:hover {
            color: $color-p50;
        }

        &:visited,
        &:active {
            border-color: $color-p400;
            box-shadow: unset;
            color: $color-p50;
        }
    }

    .social-title {
        font-size: $font-20;
    }

    .social-links {
        @include clearfix;

        @include media-breakpoint-down(xs) {
            width: 80%;
        }

        li {
            float: left;
            margin: 0.313em;

            @include media-breakpoint-down(xs) {
                text-align: center;
            }
        }

        .footer-social-icon {
            svg {
                height: 40px;
                width: 40px;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .store {
        @include media-breakpoint-down(xs) {
            .content {
                display: none;
            }

            h2 {
                @include clearfix;

                &::after {
                    content: "\f041";
                    float: right;
                    font-family: "FontAwesome";
                }
            }
        }
    }

    .view-rewards {
        background: $color-p400;
        height: 48px;
        margin-bottom: 32px;
        padding: 8px 24px;
        width: 100%;

        &:focus {
            background-color: $color-p400;
        }
    }

    .footer-container {
        @include media-breakpoint-up(lg) {
            padding-bottom: $space-56;
            padding-top: $space-48;
        }

        .footer-item {
            border-top: 1px solid $color-n50;
            font-size: $font-16;
            font-weight: $font-medium;

            @include media-breakpoint-up(lg) {
                border-top: 0;
                padding: 0 $space-32;

                &.first {
                    border-right: 1px solid $color-n50;
                }

                &:not(.first) {
                    margin-left: 0;
                    padding: 0 $space-32;
                }
            }

            @include media-breakpoint-up(lg) {
                border-right: 1px solid $color-n50;
                margin-left: -$space-16;

                &:not(.first) {
                    margin-left: 0;
                }
            }

            .footer-column-title {
                align-items: center;
                color: $color-t300;
                display: flex;
                font-size: $font-16;
                font-weight: $font-bold;
                justify-content: space-between;
                padding: $space-24 0;

                @include media-breakpoint-up(lg) {
                    font-family: $font-sofia;
                    font-size: $font-16;
                    padding: 0 0 $space-24 0;
                }

                &[aria-expanded="true"] {
                    padding-bottom: $space-16;
                }

                .button-title {
                    flex: 1;
                    line-height: 120%;
                    text-transform: none;
                }
            }

            li {
                padding: $space-8 0;

                &:first-child {
                    padding-top: 0;
                }
            }

            a:hover {
                color: $color-t200;
            }
        }
    }

    .copyright-notice {
        border-top: 1px solid $color-n50;

        .copyright-container {
            margin: $space-24 0;

            .copyright {
                color: $color-t300;
                margin-top: $space-24;

                @include media-breakpoint-up(ld) {
                    margin-top: 0;
                }
            }
        }
    }
}

.consent-tracking-cookie {
    bottom: 0;
    height: auto;
    top: auto;

    .modal-dialog {
        margin: 0;
        max-width: 100%;
    }

    .modal-content {
        align-items: flex-end;
        background-color: $color-n0;
        border: 0;
        border-radius: 0;
        display: flex;
        flex-direction: unset;
        justify-content: space-between;
        padding: $space-40;
        position: relative;

        @include media-breakpoint-down(md) {
            display: block;
            padding: $space-40  $space-24 $space-24;
        }

        .close-consent {
            position: absolute;
            right: 42px;
            top: 14px;

            @include media-breakpoint-down(md) {
                right: 18px;
            }

            button {
                opacity: unset;

                &:hover {
                    opacity: unset;
                }
            }
        }

        .modal-header {
            display: block;
            margin-right: $space-20;
            padding: 0;

            @include media-breakpoint-down(md) {
                margin-right: 0;
            }

            .modal-body {
                padding: 0;
            }
        }
    }

    .tracking-consent {
        max-width: 720px;
        width: 100%;

        p {
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                margin-bottom: $space-24;
            }
        }
    }
}

.consent-modal-footer {
    border: 0;
    padding: 0;

    @include media-breakpoint-down(md) {
        justify-content: flex-start;
    }

    .button-wrapper {
        display: flex;
        margin: 0;

        .btn {
            &:first-child {
                padding: $space-16 calc($space-88 + 2px);

                @include media-breakpoint-down(md) {
                    padding: $space-16 calc($space-64 - 1px);
                }
            }

            &:last-child {
                letter-spacing: 0;
                margin-left: $space-32;
                padding: 0;
                text-decoration: underline;
                text-transform: unset;

                @include media-breakpoint-down(md) {
                    margin-left: $space-24;
                }
            }
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}

.email-signup-container {
    max-width: 644px;

    @include media-breakpoint-down(md) {
        margin: calc($space-8 + 2px);
    }

    .form-group {
        .invalid-feedback {
            color: $color-n0;
        }
    }

    .modal-form {
        @include media-breakpoint-down(md) {
            margin-top: $space-8;
        }
    }

    .privacy-text {
        @include media-breakpoint-down(md) {
            font-size: $font-14;
            padding-top: 0;
        }
    }

    .modal-title-center {
        font-size: $font-46;
    }

    .title-modal-signup {
        @include media-breakpoint-down(md) {
            font-size: $font-18;

            span {
                font-size: $font-18;
            }
        }
    }

    .title-modal-subscribe {
        @include media-breakpoint-down(md) {
            font-size: $font-23;
            margin-top: $space-4;

            span {
                font-size: $font-23;
            }
        }
    }

    .modal-image {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .modal-body-email {
        p {
            @include media-breakpoint-down(md) {
                margin-top: 0;
                padding: $space-8;
            }
        }
    }

    .footer-signup-text {
        @include media-breakpoint-down(md) {
            font-size: $font-16;
            margin-bottom: $space-18;
            margin-top: 0;
        }
    }

    .sign-up-button {
        @include media-breakpoint-down(md) {
            margin: 0 16px;
        }
    }

    br {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.confirmation-email-modal {
    background-color: $color-e300;
}

#emailSignupModal {
    z-index: 2001;
}

#toast-container {
    left: 50%;
    position: fixed;
    top: 15%;
    transform: translate(-50%, -50%);
    z-index: 10000;

    .toast {
        background-color: $color-p50;
        border-radius: $border-radius-4;
        box-shadow: $toast-box-shadow;
        color: $color-t300;
        font-size: $font-16;
        font-weight: $font-bold;
        padding: $space-14 $space-16;
    }
}
