@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            &.collapsible-transition {
                min-height: 58px;
                overflow: hidden;
                transition: height 0.5s ease-in-out;

                &.popover-expanded {
                    overflow: visible;
                }

                .collapsible-header {
                    border: 0;
                    flex: initial;
                }

                .collapsible-body {
                    min-height: fit-content;
                }

                &.default-active {
                    height: auto;
                }
            }

            .title {
                background-color: transparent;
                border: 0;
                color: $black;
                line-height: 2.5rem; /* 40/16 */
                padding: 0;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }

                .column-title-plus {
                    display: block;
                }

                .column-title-minus {
                    display: none;
                }
            }

            .content,
            .card-body {
                display: none;
            }

            &.active {
                .title {
                    .column-title-plus {
                        display: none;
                    }

                    .column-title-minus {
                        display: block;
                    }
                }

                .content,
                .card-body {
                    display: block;
                }
            }
        }
    }

    .container div.collapsible-#{$size} button.title {
        background-color: transparent;
        border: 0;
        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}
