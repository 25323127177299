@import "./variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.card-customer {
    padding: $space-8 $space-16 $space-24;

    @include media-breakpoint-up(lg) {
        padding: $space-8 $space-48 $space-32;
    }

    .nav-tabs {
        margin-bottom: $space-16;
    }
}

.login {
    .login-popover {
        background: transparent;
        border: 0;
        box-shadow: none;
        left: -321px;
        padding: 0;
        top: 43px;

        &::before,
        &::after {
            display: none;
        }
    }

    .login-overlay {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .login-page-wrapper {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;

        &.active {
            .login-overlay {
                display: block;
            }

            .login-container {
                bottom: 0;
                transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);

                @include media-breakpoint-up(lg) {
                    bottom: unset;
                    right: 0;
                    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
                }
            }
        }
    }
}

.drawer {
    .drawer-wrapper {
        .drawer-content {
            &.login-content {
                padding-top: $space-8;
            }
        }
    }
}

.customer-information-block {
    .sign-in-redirect {
        cursor: pointer;
        text-decoration: underline;
    }

    .clearfix {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: $space-16;

        &.options-wrapper {
            margin-bottom: $space-24;
        }

        &::after {
            display: none;
        }

        .reset-password {
            color: $color-b300;
            font-family: $font-sofia;
            font-size: $font-16;
            font-weight: $font-medium;
            line-height: 120%;
            text-decoration: underline;
        }
    }
}

.disclaimer-box {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: $space-16;
    max-width: 720px;
    padding-left: $space-32;
}

.reset-password-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

input::-ms-reveal {
    display: none;
}

.registered-form,
.customer-error {
    .alert {
        display: block;

        .close {
            padding: 4px 10px;

            @include media-breakpoint-up(lg) {
                padding: 8px 20px;
            }
        }
    }
}
