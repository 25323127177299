.form-group.floating-label {
    &.required .form-control-label::before {
        color: $color-t200;
        content: "*";
    }
}

.native-arrow-select-container {
    margin-bottom: 0;
}

.custom-radio {
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        left: -9999px;
        position: absolute;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        cursor: pointer;
        position: relative;
    }

    [type="radio"]:checked + label::before,
    [type="radio"]:not(:checked) + label::before {
        border: 1px solid $color-n500;
        border-radius: 12px;
        box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
        content: "";
        display: inline-block;
        height: 24px;
        left: 0;
        min-width: 24px;
        top: 0;
    }

    [type="radio"]:checked + label::after,
    [type="radio"]:not(:checked) + label::after {
        background: $color-n0;
        border-radius: 10px;
        content: "";
        height: 10px;
        left: 7px;
        position: absolute;
        top: 7px;
        width: 10px;
    }

    [type="radio"]:checked + label::before {
        background-color: $color-p400;
        border-color: $color-p400;
    }
}

// Pseudo-checkbox styling, applied on buttons
.pseudo-checkbox {
    background: none;
    border: 0;

    .pseudo-checkbox-inner {
        display: inline-block;
        margin-right: $space-4;
        position: relative;

        &::before {
            border: 1px solid $color-n500;
            border-radius: 4px;
            content: "";
            cursor: pointer;
            display: inline-block;
            padding: 11px;
            vertical-align: middle;
        }
    }

    &.selected {
        .pseudo-checkbox-inner {
            &::before {
                background-color: $color-p400;
                border-color: $color-p400;
            }

            &::after {
                @include checkmark(6px, 14px, $color-n0);
            }
        }
    }
}

// Pseudo-radio styling, applied on buttons
.pseudo-radio {
    background: none;
    border: 0;

    .pseudo-radio-inner {
        display: inline-block;
        margin-right: $space-10;
        position: relative;

        &::before {
            border: 1px solid $color-n500;
            border-radius: 12px;
            box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
            content: "";
            display: inline-block;
            height: 24px;
            left: 0;
            top: 0;
            width: 24px;
        }
    }

    &.selected {
        .pseudo-radio-inner {
            &::before {
                background-color: $color-p400;
                border-color: $color-p400;
            }

            &::after {
                background: $color-n0;
                border-radius: 10px;
                content: "";
                height: 10px;
                left: 7px;
                position: absolute;
                top: 7px;
                width: 10px;
            }
        }
    }
}
