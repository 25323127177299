@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 375px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-lge: 1024px;
  --breakpoint-llg: 1260px;
  --breakpoint-xl: 1440px;
  --breakpoint-xxl: 1920px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 300px;
}

@media (min-width: 375px) {
  .container-sm, .container {
    max-width: 343px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 940px;
  }
}
@media (min-width: 1440px) {
  .container-xl, .container-llg, .container-lge, .container-lg, .container-md, .container-sm, .container {
    max-width: 1328px;
  }
}
@media (min-width: 1920px) {
  .container-xxl, .container-xl, .container-llg, .container-lge, .container-lg, .container-md, .container-sm, .container {
    max-width: 1696px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-llg,
.col-llg-auto, .col-llg-12, .col-llg-11, .col-llg-10, .col-llg-9, .col-llg-8, .col-llg-7, .col-llg-6, .col-llg-5, .col-llg-4, .col-llg-3, .col-llg-2, .col-llg-1, .col-lge,
.col-lge-auto, .col-lge-12, .col-lge-11, .col-lge-10, .col-lge-9, .col-lge-8, .col-lge-7, .col-lge-6, .col-lge-5, .col-lge-4, .col-lge-3, .col-lge-2, .col-lge-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 375px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1024px) {
  .col-lge {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lge-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lge-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lge-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lge-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lge-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lge-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lge-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lge-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lge-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lge-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lge-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lge-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lge-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lge-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lge-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lge-first {
    order: -1;
  }
  .order-lge-last {
    order: 13;
  }
  .order-lge-0 {
    order: 0;
  }
  .order-lge-1 {
    order: 1;
  }
  .order-lge-2 {
    order: 2;
  }
  .order-lge-3 {
    order: 3;
  }
  .order-lge-4 {
    order: 4;
  }
  .order-lge-5 {
    order: 5;
  }
  .order-lge-6 {
    order: 6;
  }
  .order-lge-7 {
    order: 7;
  }
  .order-lge-8 {
    order: 8;
  }
  .order-lge-9 {
    order: 9;
  }
  .order-lge-10 {
    order: 10;
  }
  .order-lge-11 {
    order: 11;
  }
  .order-lge-12 {
    order: 12;
  }
  .offset-lge-0 {
    margin-left: 0;
  }
  .offset-lge-1 {
    margin-left: 8.33333333%;
  }
  .offset-lge-2 {
    margin-left: 16.66666667%;
  }
  .offset-lge-3 {
    margin-left: 25%;
  }
  .offset-lge-4 {
    margin-left: 33.33333333%;
  }
  .offset-lge-5 {
    margin-left: 41.66666667%;
  }
  .offset-lge-6 {
    margin-left: 50%;
  }
  .offset-lge-7 {
    margin-left: 58.33333333%;
  }
  .offset-lge-8 {
    margin-left: 66.66666667%;
  }
  .offset-lge-9 {
    margin-left: 75%;
  }
  .offset-lge-10 {
    margin-left: 83.33333333%;
  }
  .offset-lge-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1260px) {
  .col-llg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-llg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-llg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-llg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-llg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-llg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-llg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-llg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-llg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-llg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-llg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-llg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-llg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-llg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-llg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-llg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-llg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-llg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-llg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-llg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-llg-first {
    order: -1;
  }
  .order-llg-last {
    order: 13;
  }
  .order-llg-0 {
    order: 0;
  }
  .order-llg-1 {
    order: 1;
  }
  .order-llg-2 {
    order: 2;
  }
  .order-llg-3 {
    order: 3;
  }
  .order-llg-4 {
    order: 4;
  }
  .order-llg-5 {
    order: 5;
  }
  .order-llg-6 {
    order: 6;
  }
  .order-llg-7 {
    order: 7;
  }
  .order-llg-8 {
    order: 8;
  }
  .order-llg-9 {
    order: 9;
  }
  .order-llg-10 {
    order: 10;
  }
  .order-llg-11 {
    order: 11;
  }
  .order-llg-12 {
    order: 12;
  }
  .offset-llg-0 {
    margin-left: 0;
  }
  .offset-llg-1 {
    margin-left: 8.33333333%;
  }
  .offset-llg-2 {
    margin-left: 16.66666667%;
  }
  .offset-llg-3 {
    margin-left: 25%;
  }
  .offset-llg-4 {
    margin-left: 33.33333333%;
  }
  .offset-llg-5 {
    margin-left: 41.66666667%;
  }
  .offset-llg-6 {
    margin-left: 50%;
  }
  .offset-llg-7 {
    margin-left: 58.33333333%;
  }
  .offset-llg-8 {
    margin-left: 66.66666667%;
  }
  .offset-llg-9 {
    margin-left: 75%;
  }
  .offset-llg-10 {
    margin-left: 83.33333333%;
  }
  .offset-llg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 374.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1023.98px) {
  .table-responsive-lge {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lge > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1259.98px) {
  .table-responsive-llg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-llg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1919.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 375px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 375px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lge-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lge-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1260px) {
  .dropdown-menu-llg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-llg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1920px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-xs,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 374.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xs,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 375px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xs,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xs,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xs,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xs,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xs,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .navbar-expand-lge > .container,
  .navbar-expand-lge > .container-fluid,
  .navbar-expand-lge > .container-xs,
  .navbar-expand-lge > .container-sm,
  .navbar-expand-lge > .container-md,
  .navbar-expand-lge > .container-lg,
  .navbar-expand-lge > .container-xl,
  .navbar-expand-lge > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lge {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lge .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lge .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lge .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lge > .container,
  .navbar-expand-lge > .container-fluid,
  .navbar-expand-lge > .container-xs,
  .navbar-expand-lge > .container-sm,
  .navbar-expand-lge > .container-md,
  .navbar-expand-lge > .container-lg,
  .navbar-expand-lge > .container-xl,
  .navbar-expand-lge > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lge .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lge .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lge .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1259.98px) {
  .navbar-expand-llg > .container,
  .navbar-expand-llg > .container-fluid,
  .navbar-expand-llg > .container-xs,
  .navbar-expand-llg > .container-sm,
  .navbar-expand-llg > .container-md,
  .navbar-expand-llg > .container-lg,
  .navbar-expand-llg > .container-xl,
  .navbar-expand-llg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1260px) {
  .navbar-expand-llg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-llg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-llg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-llg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-llg > .container,
  .navbar-expand-llg > .container-fluid,
  .navbar-expand-llg > .container-xs,
  .navbar-expand-llg > .container-sm,
  .navbar-expand-llg > .container-md,
  .navbar-expand-llg > .container-lg,
  .navbar-expand-llg > .container-xl,
  .navbar-expand-llg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-llg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-llg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-llg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xs,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xs,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1919.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xs,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1920px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xs,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xs,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xs,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 375px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 375px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 375px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 375px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-lge {
    flex-direction: row;
  }
  .list-group-horizontal-lge > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lge > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lge > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lge > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lge > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1260px) {
  .list-group-horizontal-llg {
    flex-direction: row;
  }
  .list-group-horizontal-llg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-llg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-llg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-llg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-llg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1920px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 375px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "sofia-pro";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "sofia-pro";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 375px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1024px) {
  .d-lge-none {
    display: none !important;
  }
  .d-lge-inline {
    display: inline !important;
  }
  .d-lge-inline-block {
    display: inline-block !important;
  }
  .d-lge-block {
    display: block !important;
  }
  .d-lge-table {
    display: table !important;
  }
  .d-lge-table-row {
    display: table-row !important;
  }
  .d-lge-table-cell {
    display: table-cell !important;
  }
  .d-lge-flex {
    display: flex !important;
  }
  .d-lge-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1260px) {
  .d-llg-none {
    display: none !important;
  }
  .d-llg-inline {
    display: inline !important;
  }
  .d-llg-inline-block {
    display: inline-block !important;
  }
  .d-llg-block {
    display: block !important;
  }
  .d-llg-table {
    display: table !important;
  }
  .d-llg-table-row {
    display: table-row !important;
  }
  .d-llg-table-cell {
    display: table-cell !important;
  }
  .d-llg-flex {
    display: flex !important;
  }
  .d-llg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1024px) {
  .flex-lge-row {
    flex-direction: row !important;
  }
  .flex-lge-column {
    flex-direction: column !important;
  }
  .flex-lge-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lge-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lge-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lge-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lge-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lge-fill {
    flex: 1 1 auto !important;
  }
  .flex-lge-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lge-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lge-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lge-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lge-start {
    justify-content: flex-start !important;
  }
  .justify-content-lge-end {
    justify-content: flex-end !important;
  }
  .justify-content-lge-center {
    justify-content: center !important;
  }
  .justify-content-lge-between {
    justify-content: space-between !important;
  }
  .justify-content-lge-around {
    justify-content: space-around !important;
  }
  .align-items-lge-start {
    align-items: flex-start !important;
  }
  .align-items-lge-end {
    align-items: flex-end !important;
  }
  .align-items-lge-center {
    align-items: center !important;
  }
  .align-items-lge-baseline {
    align-items: baseline !important;
  }
  .align-items-lge-stretch {
    align-items: stretch !important;
  }
  .align-content-lge-start {
    align-content: flex-start !important;
  }
  .align-content-lge-end {
    align-content: flex-end !important;
  }
  .align-content-lge-center {
    align-content: center !important;
  }
  .align-content-lge-between {
    align-content: space-between !important;
  }
  .align-content-lge-around {
    align-content: space-around !important;
  }
  .align-content-lge-stretch {
    align-content: stretch !important;
  }
  .align-self-lge-auto {
    align-self: auto !important;
  }
  .align-self-lge-start {
    align-self: flex-start !important;
  }
  .align-self-lge-end {
    align-self: flex-end !important;
  }
  .align-self-lge-center {
    align-self: center !important;
  }
  .align-self-lge-baseline {
    align-self: baseline !important;
  }
  .align-self-lge-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1260px) {
  .flex-llg-row {
    flex-direction: row !important;
  }
  .flex-llg-column {
    flex-direction: column !important;
  }
  .flex-llg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-llg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-llg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-llg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-llg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-llg-fill {
    flex: 1 1 auto !important;
  }
  .flex-llg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-llg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-llg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-llg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-llg-start {
    justify-content: flex-start !important;
  }
  .justify-content-llg-end {
    justify-content: flex-end !important;
  }
  .justify-content-llg-center {
    justify-content: center !important;
  }
  .justify-content-llg-between {
    justify-content: space-between !important;
  }
  .justify-content-llg-around {
    justify-content: space-around !important;
  }
  .align-items-llg-start {
    align-items: flex-start !important;
  }
  .align-items-llg-end {
    align-items: flex-end !important;
  }
  .align-items-llg-center {
    align-items: center !important;
  }
  .align-items-llg-baseline {
    align-items: baseline !important;
  }
  .align-items-llg-stretch {
    align-items: stretch !important;
  }
  .align-content-llg-start {
    align-content: flex-start !important;
  }
  .align-content-llg-end {
    align-content: flex-end !important;
  }
  .align-content-llg-center {
    align-content: center !important;
  }
  .align-content-llg-between {
    align-content: space-between !important;
  }
  .align-content-llg-around {
    align-content: space-around !important;
  }
  .align-content-llg-stretch {
    align-content: stretch !important;
  }
  .align-self-llg-auto {
    align-self: auto !important;
  }
  .align-self-llg-start {
    align-self: flex-start !important;
  }
  .align-self-llg-end {
    align-self: flex-end !important;
  }
  .align-self-llg-center {
    align-self: center !important;
  }
  .align-self-llg-baseline {
    align-self: baseline !important;
  }
  .align-self-llg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1920px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1024px) {
  .float-lge-left {
    float: left !important;
  }
  .float-lge-right {
    float: right !important;
  }
  .float-lge-none {
    float: none !important;
  }
}
@media (min-width: 1260px) {
  .float-llg-left {
    float: left !important;
  }
  .float-llg-right {
    float: right !important;
  }
  .float-llg-none {
    float: none !important;
  }
}
@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1920px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 375px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1024px) {
  .m-lge-0 {
    margin: 0 !important;
  }
  .mt-lge-0,
  .my-lge-0 {
    margin-top: 0 !important;
  }
  .mr-lge-0,
  .mx-lge-0 {
    margin-right: 0 !important;
  }
  .mb-lge-0,
  .my-lge-0 {
    margin-bottom: 0 !important;
  }
  .ml-lge-0,
  .mx-lge-0 {
    margin-left: 0 !important;
  }
  .m-lge-1 {
    margin: 0.25rem !important;
  }
  .mt-lge-1,
  .my-lge-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lge-1,
  .mx-lge-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lge-1,
  .my-lge-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lge-1,
  .mx-lge-1 {
    margin-left: 0.25rem !important;
  }
  .m-lge-2 {
    margin: 0.5rem !important;
  }
  .mt-lge-2,
  .my-lge-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lge-2,
  .mx-lge-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lge-2,
  .my-lge-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lge-2,
  .mx-lge-2 {
    margin-left: 0.5rem !important;
  }
  .m-lge-3 {
    margin: 1rem !important;
  }
  .mt-lge-3,
  .my-lge-3 {
    margin-top: 1rem !important;
  }
  .mr-lge-3,
  .mx-lge-3 {
    margin-right: 1rem !important;
  }
  .mb-lge-3,
  .my-lge-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lge-3,
  .mx-lge-3 {
    margin-left: 1rem !important;
  }
  .m-lge-4 {
    margin: 1.5rem !important;
  }
  .mt-lge-4,
  .my-lge-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lge-4,
  .mx-lge-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lge-4,
  .my-lge-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lge-4,
  .mx-lge-4 {
    margin-left: 1.5rem !important;
  }
  .m-lge-5 {
    margin: 3rem !important;
  }
  .mt-lge-5,
  .my-lge-5 {
    margin-top: 3rem !important;
  }
  .mr-lge-5,
  .mx-lge-5 {
    margin-right: 3rem !important;
  }
  .mb-lge-5,
  .my-lge-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lge-5,
  .mx-lge-5 {
    margin-left: 3rem !important;
  }
  .p-lge-0 {
    padding: 0 !important;
  }
  .pt-lge-0,
  .py-lge-0 {
    padding-top: 0 !important;
  }
  .pr-lge-0,
  .px-lge-0 {
    padding-right: 0 !important;
  }
  .pb-lge-0,
  .py-lge-0 {
    padding-bottom: 0 !important;
  }
  .pl-lge-0,
  .px-lge-0 {
    padding-left: 0 !important;
  }
  .p-lge-1 {
    padding: 0.25rem !important;
  }
  .pt-lge-1,
  .py-lge-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lge-1,
  .px-lge-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lge-1,
  .py-lge-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lge-1,
  .px-lge-1 {
    padding-left: 0.25rem !important;
  }
  .p-lge-2 {
    padding: 0.5rem !important;
  }
  .pt-lge-2,
  .py-lge-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lge-2,
  .px-lge-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lge-2,
  .py-lge-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lge-2,
  .px-lge-2 {
    padding-left: 0.5rem !important;
  }
  .p-lge-3 {
    padding: 1rem !important;
  }
  .pt-lge-3,
  .py-lge-3 {
    padding-top: 1rem !important;
  }
  .pr-lge-3,
  .px-lge-3 {
    padding-right: 1rem !important;
  }
  .pb-lge-3,
  .py-lge-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lge-3,
  .px-lge-3 {
    padding-left: 1rem !important;
  }
  .p-lge-4 {
    padding: 1.5rem !important;
  }
  .pt-lge-4,
  .py-lge-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lge-4,
  .px-lge-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lge-4,
  .py-lge-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lge-4,
  .px-lge-4 {
    padding-left: 1.5rem !important;
  }
  .p-lge-5 {
    padding: 3rem !important;
  }
  .pt-lge-5,
  .py-lge-5 {
    padding-top: 3rem !important;
  }
  .pr-lge-5,
  .px-lge-5 {
    padding-right: 3rem !important;
  }
  .pb-lge-5,
  .py-lge-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lge-5,
  .px-lge-5 {
    padding-left: 3rem !important;
  }
  .m-lge-n1 {
    margin: -0.25rem !important;
  }
  .mt-lge-n1,
  .my-lge-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lge-n1,
  .mx-lge-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lge-n1,
  .my-lge-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lge-n1,
  .mx-lge-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lge-n2 {
    margin: -0.5rem !important;
  }
  .mt-lge-n2,
  .my-lge-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lge-n2,
  .mx-lge-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lge-n2,
  .my-lge-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lge-n2,
  .mx-lge-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lge-n3 {
    margin: -1rem !important;
  }
  .mt-lge-n3,
  .my-lge-n3 {
    margin-top: -1rem !important;
  }
  .mr-lge-n3,
  .mx-lge-n3 {
    margin-right: -1rem !important;
  }
  .mb-lge-n3,
  .my-lge-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lge-n3,
  .mx-lge-n3 {
    margin-left: -1rem !important;
  }
  .m-lge-n4 {
    margin: -1.5rem !important;
  }
  .mt-lge-n4,
  .my-lge-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lge-n4,
  .mx-lge-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lge-n4,
  .my-lge-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lge-n4,
  .mx-lge-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lge-n5 {
    margin: -3rem !important;
  }
  .mt-lge-n5,
  .my-lge-n5 {
    margin-top: -3rem !important;
  }
  .mr-lge-n5,
  .mx-lge-n5 {
    margin-right: -3rem !important;
  }
  .mb-lge-n5,
  .my-lge-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lge-n5,
  .mx-lge-n5 {
    margin-left: -3rem !important;
  }
  .m-lge-auto {
    margin: auto !important;
  }
  .mt-lge-auto,
  .my-lge-auto {
    margin-top: auto !important;
  }
  .mr-lge-auto,
  .mx-lge-auto {
    margin-right: auto !important;
  }
  .mb-lge-auto,
  .my-lge-auto {
    margin-bottom: auto !important;
  }
  .ml-lge-auto,
  .mx-lge-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1260px) {
  .m-llg-0 {
    margin: 0 !important;
  }
  .mt-llg-0,
  .my-llg-0 {
    margin-top: 0 !important;
  }
  .mr-llg-0,
  .mx-llg-0 {
    margin-right: 0 !important;
  }
  .mb-llg-0,
  .my-llg-0 {
    margin-bottom: 0 !important;
  }
  .ml-llg-0,
  .mx-llg-0 {
    margin-left: 0 !important;
  }
  .m-llg-1 {
    margin: 0.25rem !important;
  }
  .mt-llg-1,
  .my-llg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-llg-1,
  .mx-llg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-llg-1,
  .my-llg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-llg-1,
  .mx-llg-1 {
    margin-left: 0.25rem !important;
  }
  .m-llg-2 {
    margin: 0.5rem !important;
  }
  .mt-llg-2,
  .my-llg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-llg-2,
  .mx-llg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-llg-2,
  .my-llg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-llg-2,
  .mx-llg-2 {
    margin-left: 0.5rem !important;
  }
  .m-llg-3 {
    margin: 1rem !important;
  }
  .mt-llg-3,
  .my-llg-3 {
    margin-top: 1rem !important;
  }
  .mr-llg-3,
  .mx-llg-3 {
    margin-right: 1rem !important;
  }
  .mb-llg-3,
  .my-llg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-llg-3,
  .mx-llg-3 {
    margin-left: 1rem !important;
  }
  .m-llg-4 {
    margin: 1.5rem !important;
  }
  .mt-llg-4,
  .my-llg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-llg-4,
  .mx-llg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-llg-4,
  .my-llg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-llg-4,
  .mx-llg-4 {
    margin-left: 1.5rem !important;
  }
  .m-llg-5 {
    margin: 3rem !important;
  }
  .mt-llg-5,
  .my-llg-5 {
    margin-top: 3rem !important;
  }
  .mr-llg-5,
  .mx-llg-5 {
    margin-right: 3rem !important;
  }
  .mb-llg-5,
  .my-llg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-llg-5,
  .mx-llg-5 {
    margin-left: 3rem !important;
  }
  .p-llg-0 {
    padding: 0 !important;
  }
  .pt-llg-0,
  .py-llg-0 {
    padding-top: 0 !important;
  }
  .pr-llg-0,
  .px-llg-0 {
    padding-right: 0 !important;
  }
  .pb-llg-0,
  .py-llg-0 {
    padding-bottom: 0 !important;
  }
  .pl-llg-0,
  .px-llg-0 {
    padding-left: 0 !important;
  }
  .p-llg-1 {
    padding: 0.25rem !important;
  }
  .pt-llg-1,
  .py-llg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-llg-1,
  .px-llg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-llg-1,
  .py-llg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-llg-1,
  .px-llg-1 {
    padding-left: 0.25rem !important;
  }
  .p-llg-2 {
    padding: 0.5rem !important;
  }
  .pt-llg-2,
  .py-llg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-llg-2,
  .px-llg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-llg-2,
  .py-llg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-llg-2,
  .px-llg-2 {
    padding-left: 0.5rem !important;
  }
  .p-llg-3 {
    padding: 1rem !important;
  }
  .pt-llg-3,
  .py-llg-3 {
    padding-top: 1rem !important;
  }
  .pr-llg-3,
  .px-llg-3 {
    padding-right: 1rem !important;
  }
  .pb-llg-3,
  .py-llg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-llg-3,
  .px-llg-3 {
    padding-left: 1rem !important;
  }
  .p-llg-4 {
    padding: 1.5rem !important;
  }
  .pt-llg-4,
  .py-llg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-llg-4,
  .px-llg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-llg-4,
  .py-llg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-llg-4,
  .px-llg-4 {
    padding-left: 1.5rem !important;
  }
  .p-llg-5 {
    padding: 3rem !important;
  }
  .pt-llg-5,
  .py-llg-5 {
    padding-top: 3rem !important;
  }
  .pr-llg-5,
  .px-llg-5 {
    padding-right: 3rem !important;
  }
  .pb-llg-5,
  .py-llg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-llg-5,
  .px-llg-5 {
    padding-left: 3rem !important;
  }
  .m-llg-n1 {
    margin: -0.25rem !important;
  }
  .mt-llg-n1,
  .my-llg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-llg-n1,
  .mx-llg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-llg-n1,
  .my-llg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-llg-n1,
  .mx-llg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-llg-n2 {
    margin: -0.5rem !important;
  }
  .mt-llg-n2,
  .my-llg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-llg-n2,
  .mx-llg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-llg-n2,
  .my-llg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-llg-n2,
  .mx-llg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-llg-n3 {
    margin: -1rem !important;
  }
  .mt-llg-n3,
  .my-llg-n3 {
    margin-top: -1rem !important;
  }
  .mr-llg-n3,
  .mx-llg-n3 {
    margin-right: -1rem !important;
  }
  .mb-llg-n3,
  .my-llg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-llg-n3,
  .mx-llg-n3 {
    margin-left: -1rem !important;
  }
  .m-llg-n4 {
    margin: -1.5rem !important;
  }
  .mt-llg-n4,
  .my-llg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-llg-n4,
  .mx-llg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-llg-n4,
  .my-llg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-llg-n4,
  .mx-llg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-llg-n5 {
    margin: -3rem !important;
  }
  .mt-llg-n5,
  .my-llg-n5 {
    margin-top: -3rem !important;
  }
  .mr-llg-n5,
  .mx-llg-n5 {
    margin-right: -3rem !important;
  }
  .mb-llg-n5,
  .my-llg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-llg-n5,
  .mx-llg-n5 {
    margin-left: -3rem !important;
  }
  .m-llg-auto {
    margin: auto !important;
  }
  .mt-llg-auto,
  .my-llg-auto {
    margin-top: auto !important;
  }
  .mr-llg-auto,
  .mx-llg-auto {
    margin-right: auto !important;
  }
  .mb-llg-auto,
  .my-llg-auto {
    margin-bottom: auto !important;
  }
  .ml-llg-auto,
  .mx-llg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .text-lge-left {
    text-align: left !important;
  }
  .text-lge-right {
    text-align: right !important;
  }
  .text-lge-center {
    text-align: center !important;
  }
}
@media (min-width: 1260px) {
  .text-llg-left {
    text-align: left !important;
  }
  .text-llg-right {
    text-align: right !important;
  }
  .text-llg-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.container {
  padding: 0;
}

.col,
[class^=col-],
[class*=" col-"] {
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 768px) {
  .col,
  [class^=col-],
  [class*=" col-"] {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.row {
  margin-left: -8px;
  margin-right: -8px;
}
@media (min-width: 992px) {
  .row {
    margin-left: -12px;
    margin-right: -12px;
  }
}
.row.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.btn {
  border: 0;
  border-radius: 4px;
  font-family: "sofia-pro", sans-serif;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 0.05rem;
  line-height: 1rem;
  padding: 16px 24px;
  text-transform: uppercase;
  white-space: nowrap;
}
.btn:hover {
  text-decoration: underline;
}
.btn:disabled {
  background-color: #ececec;
  color: #818181;
}
.btn:focus {
  box-shadow: 0 0 0 2px #6d6d6d;
}
.btn.btn-primary {
  background-color: #537c7f;
  color: #fff;
}
.btn.btn-primary:not(:disabled):not(.disabled):not(.livechat-links a):hover {
  background-color: #50797b;
}
.btn.btn-primary:not(:disabled):not(.disabled):not(.livechat-links a):active {
  background-color: #50797b;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2509803922) inset;
}
.btn.btn-secondary {
  background-color: #f6c862;
  color: #262626;
}
.btn.btn-secondary:not(:disabled):not(.disabled):hover {
  background-color: #f8d17d;
}
.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #f8d17d;
}
.btn.btn-outline-primary {
  box-shadow: 0 0 0 1px #9f9f9f inset;
  color: #262626;
}
.btn.btn-outline-primary:not(:disabled):not(.disabled):hover {
  background-color: #fff;
  box-shadow: 0 0 0 1px #50797b inset;
}
.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #fff;
  box-shadow: 0 0 0 1px #244b5a inset;
  color: #262626;
}
.btn.btn-outline-secondary {
  box-shadow: 0 0 0 1px #fff inset;
  color: #fff;
}
.btn.btn-outline-secondary:not(:disabled):not(.disabled):hover, .btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  box-shadow: 0 0 0 2px #fff inset;
  font-weight: 800;
}
.btn.btn-text {
  background-color: transparent;
  border: 0;
  color: #244b5a;
  font-size: 1.125rem;
  font-weight: 800;
  padding: 0;
  text-decoration: underline;
  text-transform: capitalize;
}
.btn.btn-xs {
  padding: 8px 24px;
}
.btn.btn-sm, .btn-group-sm > .btn {
  padding: 12px 24px;
}
.btn.btn-lg, .btn-group-lg > .btn {
  padding: 20px 24px;
}
.btn-login {
  width: 100%;
}

.modal-dialog {
  max-width: 600px;
}
.modal-dialog .modal-content {
  border-radius: 8px;
}
.modal-dialog .modal-content .modal-header {
  border: 0;
  font-family: "sofia-pro";
  padding: 32px 32px 8px 32px;
}
.modal-dialog .modal-content .modal-header .close {
  padding-bottom: 0;
}
.modal-dialog .modal-content .modal-body {
  font-family: "sofia-pro";
  padding: 8px 32px 32px 32px;
}

.modal-background {
  background-color: #000;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.skip {
  background: #fff;
  left: 0;
  overflow: hidden;
  padding: 1em 1.5em;
  position: absolute;
  top: -4.2em;
  transition: all 0.2s ease-in-out;
}
.skip:active, .skip:focus, .skip:hover {
  background: #fff;
  height: auto;
  left: 0;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: auto;
  z-index: 10000000;
}

.form-group {
  position: relative;
}
.form-group .form-control:not([type=checkbox]),
.form-group .form-control:not([type=radio]) {
  background-color: #fff;
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  color: #262626;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-weight: 400;
  height: 48px;
  line-height: 120%;
}
.form-group .form-control:not([type=checkbox]).custom-select,
.form-group .form-control:not([type=radio]).custom-select {
  appearance: none;
  background: #fff url("../../images/chevron-down-grey.svg") no-repeat center right 16px;
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  color: #4b4b4b;
  font-size: 16px;
  padding: 14px 16px;
}
.form-group .form-control:not([type=checkbox]).custom-select:focus,
.form-group .form-control:not([type=radio]).custom-select:focus {
  background-image: url("../../images/chevron-up-grey.svg");
  background-position: center right 15px;
  padding: 13px 15px;
}
.form-group .form-control:not([type=checkbox]):focus,
.form-group .form-control:not([type=radio]):focus {
  border: 2px solid #6d6d6d;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  outline: 0;
}
.form-group .form-control:not([type=checkbox]).is-invalid,
.form-group .form-control:not([type=radio]).is-invalid {
  background-color: #f8eaea;
  background-image: none;
  border: 2px solid #b4292f;
  padding-left: 15px;
}
.form-group .form-control:not([type=checkbox]).has-tooltip,
.form-group .form-control:not([type=radio]).has-tooltip {
  padding-right: 48px;
}
.form-group .icon-eye {
  position: absolute;
  right: 16px;
  top: 12px;
}
.form-group .icon-tooltip {
  position: absolute;
  right: 16px;
  top: 10px;
}
.form-group .icon-tooltip .tooltip {
  transform: translate(-89%, -9px);
}
.form-group .icon-tooltip .tooltip::after {
  left: 90%;
}
.form-group .invalid-feedback {
  color: #b4292f;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 120%;
}

.floating-label {
  color: #4b4b4b;
  margin-bottom: 16px;
  position: relative;
}
.floating-label > label {
  border: 1px solid transparent;
  border-radius: 4px;
  color: #4b4b4b;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: 48px;
  left: 0;
  line-height: 120%;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  transition: all 0.1s ease-in-out;
  width: 100%;
}
.floating-label > .form-control:not([type=checkbox]),
.floating-label > .form-control:not([type=radio]),
.floating-label > label {
  padding: 14px 16px;
}
.floating-label > .form-control:not([type=checkbox])::placeholder, .floating-label > .form-control:not([type=checkbox]):input-placeholder,
.floating-label > .form-control:not([type=radio])::placeholder,
.floating-label > .form-control:not([type=radio]):input-placeholder {
  color: transparent;
}
.floating-label > .form-control:not([type=checkbox]) .custom-select,
.floating-label > .form-control:not([type=radio]) .custom-select {
  color: #262626;
  font-size: 0.875rem;
}
.floating-label > .form-control:not([type=checkbox]):-internal-autofill-selected,
.floating-label > .form-control:not([type=radio]):-internal-autofill-selected {
  padding-bottom: 7px;
  padding-top: 23.3333333333px;
}
.floating-label > .form-control:not([type=checkbox]):-internal-autofill-selected + label,
.floating-label > .form-control:not([type=radio]):-internal-autofill-selected + label {
  color: #262626;
  font-size: 12px;
  font-weight: 800;
  padding-bottom: 7px;
  padding-top: 7px;
  pointer-events: none;
}
.floating-label > .form-control:not([type=checkbox]):focus,
.floating-label > .form-control:not([type=radio]):focus {
  padding-bottom: 6px;
  padding-left: 15px;
  padding-top: 22.3333333333px;
}
.floating-label > .form-control:not([type=checkbox]):focus + label,
.floating-label > .form-control:not([type=radio]):focus + label {
  color: #262626;
  font-size: 12px;
  font-weight: 800;
  padding-bottom: 7px;
  padding-top: 7px;
  pointer-events: none;
}
.floating-label > .form-control:not([type=checkbox]):not(:placeholder-shown),
.floating-label > .form-control:not([type=radio]):not(:placeholder-shown) {
  line-height: unset;
  padding-bottom: 5px;
  padding-top: 18px;
}
.floating-label > .form-control:not([type=checkbox]):not(:placeholder-shown) + label,
.floating-label > .form-control:not([type=radio]):not(:placeholder-shown) + label {
  color: #262626;
  font-size: 12px;
  font-weight: 800;
  padding-bottom: 6px;
  padding-top: 6px;
  pointer-events: none;
}
.floating-label > .form-control:not([type=checkbox]):not(:placeholder-shown):focus,
.floating-label > .form-control:not([type=radio]):not(:placeholder-shown):focus {
  padding-bottom: 4px;
  padding-left: 15px;
  padding-top: 17px;
}

.card {
  border-radius: 5px;
  margin-bottom: 8px;
}
.card.ghost .card-header {
  border: 0;
  padding: 0;
}

.card-header-no-border {
  border-bottom: 0;
}

.card-header {
  background-color: transparent;
  padding-bottom: 8px;
  padding-top: 8px;
}

.card-body {
  padding: 8px;
}

.card-header .card-section-number {
  background: #537c7f;
  border: 1px solid #537c7f;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 800;
  height: 32px;
  line-height: 30px;
  margin-right: 8px;
  text-align: center;
  width: 32px;
}
.ghost .card-header .card-section-number {
  background: none;
  border: 1px solid #cacaca;
  color: #818181;
}

.badge {
  font-size: 0.875rem;
  font-weight: 800;
  line-height: unset;
  padding: 8px;
  vertical-align: unset;
}

.nav-tabs {
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
.nav-tabs .nav-item {
  align-items: center;
  display: flex;
  width: 100%;
}
.nav-tabs .nav-item .nav-link {
  border: transparent;
  border-bottom: 1px solid #dbdbdb;
  color: #818181;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-weight: 800;
  position: relative;
  text-transform: uppercase;
  width: 100%;
}
.nav-tabs .nav-item .nav-link.active {
  border-bottom: 4px solid #5c8b8d;
  padding-bottom: 12px;
}

.custom-checkbox {
  display: flex;
  float: none;
  margin: 0;
  min-height: none;
  padding: 0;
}
.custom-checkbox .custom-control-label {
  color: #262626;
  cursor: pointer;
  display: flex;
  font-family: "sofia-pro";
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}
.custom-checkbox .custom-control-label::before {
  background: #fff;
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  height: 24px;
  left: 0;
  margin-right: 8px;
  min-width: 24px;
  position: unset;
  top: 0;
}
.custom-checkbox .custom-control-label::after {
  height: 24px;
  left: 0;
  min-width: 24px;
  padding: 5px;
  position: absolute;
  top: 0;
}
.custom-checkbox .custom-control-input:checked + .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  border-radius: 4px;
  filter: invert(1);
}
.custom-checkbox .custom-control-input:checked + .custom-control-label::before {
  background: #537c7f;
}

.modal-open {
  padding-right: 0 !important;
}

.modal-backdrop.transparent {
  opacity: 0;
  z-index: 2;
}

.pull-left-xs {
  float: left;
}

.pull-right-xs {
  float: right;
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .pull-left-sm {
    float: left;
  }
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left;
  }
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 992px) and (max-width: 1023.98px) {
  .pull-left-lg {
    float: left;
  }
  .pull-right-lg {
    float: right;
  }
}
@media (min-width: 1024px) and (max-width: 1259.98px) {
  .pull-left-lge {
    float: left;
  }
  .pull-right-lge {
    float: right;
  }
}
@media (min-width: 1260px) and (max-width: 1439.98px) {
  .pull-left-llg {
    float: left;
  }
  .pull-right-llg {
    float: right;
  }
}
@media (min-width: 1440px) and (max-width: 1919.98px) {
  .pull-left-xl {
    float: left;
  }
  .pull-right-xl {
    float: right;
  }
}
@media (min-width: 1920px) {
  .pull-left-xxl {
    float: left;
  }
  .pull-right-xxl {
    float: right;
  }
}
@media (max-width: 374.98px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (max-width: 1023.98px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (max-width: 1259.98px) {
  .hidden-lge-down {
    display: none !important;
  }
}

@media (max-width: 1439.98px) {
  .hidden-llg-down {
    display: none !important;
  }
}

@media (max-width: 1919.98px) {
  .hidden-xl-down {
    display: none !important;
  }
}

.hidden-xxl-down {
  display: none !important;
}

.row.equal-height > [class^=col] {
  display: flex;
}

.pdd-auto {
  padding: auto;
}
.pdd-auto-vert {
  padding-bottom: auto;
  padding-top: auto;
}
.pdd-auto-hor {
  padding-left: auto;
  padding-right: auto;
}
.pdd-auto-top {
  padding-top: auto;
}
.pdd-auto-bot {
  padding-bottom: auto;
}
.pdd-auto-left {
  padding-left: auto;
}
.pdd-auto-right {
  padding-right: auto;
}

.margin-auto {
  margin: auto;
}
.margin-auto-vert {
  margin-bottom: auto;
  margin-top: auto;
}
.margin-auto-hor {
  margin-left: auto;
  margin-right: auto;
}
.margin-auto-top {
  margin-top: auto;
}
.margin-auto-bot {
  margin-bottom: auto;
}
.margin-auto-left {
  margin-left: auto;
}
.margin-auto-right {
  margin-right: auto;
}

.negative-margin-auto {
  margin: -auto;
}
.negative-margin-auto-vert {
  margin-bottom: -auto;
  margin-top: -auto;
}
.negative-margin-auto-hor {
  margin-left: -auto;
  margin-right: -auto;
}
.negative-margin-auto-top {
  margin-top: -auto;
}
.negative-margin-auto-bot {
  margin-bottom: -auto;
}
.negative-margin-auto-left {
  margin-left: -auto;
}
.negative-margin-auto-right {
  margin-right: -auto;
}

.gap-auto {
  gap: auto;
}

.pdd-0 {
  padding: 0;
}
.pdd-0-vert {
  padding-bottom: 0;
  padding-top: 0;
}
.pdd-0-hor {
  padding-left: 0;
  padding-right: 0;
}
.pdd-0-top {
  padding-top: 0;
}
.pdd-0-bot {
  padding-bottom: 0;
}
.pdd-0-left {
  padding-left: 0;
}
.pdd-0-right {
  padding-right: 0;
}

.margin-0 {
  margin: 0;
}
.margin-0-vert {
  margin-bottom: 0;
  margin-top: 0;
}
.margin-0-hor {
  margin-left: 0;
  margin-right: 0;
}
.margin-0-top {
  margin-top: 0;
}
.margin-0-bot {
  margin-bottom: 0;
}
.margin-0-left {
  margin-left: 0;
}
.margin-0-right {
  margin-right: 0;
}

.negative-margin-0 {
  margin: -0;
}
.negative-margin-0-vert {
  margin-bottom: -0;
  margin-top: -0;
}
.negative-margin-0-hor {
  margin-left: -0;
  margin-right: -0;
}
.negative-margin-0-top {
  margin-top: -0;
}
.negative-margin-0-bot {
  margin-bottom: -0;
}
.negative-margin-0-left {
  margin-left: -0;
}
.negative-margin-0-right {
  margin-right: -0;
}

.gap-0 {
  gap: 0;
}

.pdd-2 {
  padding: 2px;
}
.pdd-2-vert {
  padding-bottom: 2px;
  padding-top: 2px;
}
.pdd-2-hor {
  padding-left: 2px;
  padding-right: 2px;
}
.pdd-2-top {
  padding-top: 2px;
}
.pdd-2-bot {
  padding-bottom: 2px;
}
.pdd-2-left {
  padding-left: 2px;
}
.pdd-2-right {
  padding-right: 2px;
}

.margin-2 {
  margin: 2px;
}
.margin-2-vert {
  margin-bottom: 2px;
  margin-top: 2px;
}
.margin-2-hor {
  margin-left: 2px;
  margin-right: 2px;
}
.margin-2-top {
  margin-top: 2px;
}
.margin-2-bot {
  margin-bottom: 2px;
}
.margin-2-left {
  margin-left: 2px;
}
.margin-2-right {
  margin-right: 2px;
}

.negative-margin-2 {
  margin: -2px;
}
.negative-margin-2-vert {
  margin-bottom: -2px;
  margin-top: -2px;
}
.negative-margin-2-hor {
  margin-left: -2px;
  margin-right: -2px;
}
.negative-margin-2-top {
  margin-top: -2px;
}
.negative-margin-2-bot {
  margin-bottom: -2px;
}
.negative-margin-2-left {
  margin-left: -2px;
}
.negative-margin-2-right {
  margin-right: -2px;
}

.gap-2 {
  gap: 2px;
}

.pdd-4 {
  padding: 4px;
}
.pdd-4-vert {
  padding-bottom: 4px;
  padding-top: 4px;
}
.pdd-4-hor {
  padding-left: 4px;
  padding-right: 4px;
}
.pdd-4-top {
  padding-top: 4px;
}
.pdd-4-bot {
  padding-bottom: 4px;
}
.pdd-4-left {
  padding-left: 4px;
}
.pdd-4-right {
  padding-right: 4px;
}

.margin-4 {
  margin: 4px;
}
.margin-4-vert {
  margin-bottom: 4px;
  margin-top: 4px;
}
.margin-4-hor {
  margin-left: 4px;
  margin-right: 4px;
}
.margin-4-top {
  margin-top: 4px;
}
.margin-4-bot {
  margin-bottom: 4px;
}
.margin-4-left {
  margin-left: 4px;
}
.margin-4-right {
  margin-right: 4px;
}

.negative-margin-4 {
  margin: -4px;
}
.negative-margin-4-vert {
  margin-bottom: -4px;
  margin-top: -4px;
}
.negative-margin-4-hor {
  margin-left: -4px;
  margin-right: -4px;
}
.negative-margin-4-top {
  margin-top: -4px;
}
.negative-margin-4-bot {
  margin-bottom: -4px;
}
.negative-margin-4-left {
  margin-left: -4px;
}
.negative-margin-4-right {
  margin-right: -4px;
}

.gap-4 {
  gap: 4px;
}

.pdd-6 {
  padding: 6px;
}
.pdd-6-vert {
  padding-bottom: 6px;
  padding-top: 6px;
}
.pdd-6-hor {
  padding-left: 6px;
  padding-right: 6px;
}
.pdd-6-top {
  padding-top: 6px;
}
.pdd-6-bot {
  padding-bottom: 6px;
}
.pdd-6-left {
  padding-left: 6px;
}
.pdd-6-right {
  padding-right: 6px;
}

.margin-6 {
  margin: 6px;
}
.margin-6-vert {
  margin-bottom: 6px;
  margin-top: 6px;
}
.margin-6-hor {
  margin-left: 6px;
  margin-right: 6px;
}
.margin-6-top {
  margin-top: 6px;
}
.margin-6-bot {
  margin-bottom: 6px;
}
.margin-6-left {
  margin-left: 6px;
}
.margin-6-right {
  margin-right: 6px;
}

.negative-margin-6 {
  margin: -6px;
}
.negative-margin-6-vert {
  margin-bottom: -6px;
  margin-top: -6px;
}
.negative-margin-6-hor {
  margin-left: -6px;
  margin-right: -6px;
}
.negative-margin-6-top {
  margin-top: -6px;
}
.negative-margin-6-bot {
  margin-bottom: -6px;
}
.negative-margin-6-left {
  margin-left: -6px;
}
.negative-margin-6-right {
  margin-right: -6px;
}

.gap-6 {
  gap: 6px;
}

.pdd-7 {
  padding: 7px;
}
.pdd-7-vert {
  padding-bottom: 7px;
  padding-top: 7px;
}
.pdd-7-hor {
  padding-left: 7px;
  padding-right: 7px;
}
.pdd-7-top {
  padding-top: 7px;
}
.pdd-7-bot {
  padding-bottom: 7px;
}
.pdd-7-left {
  padding-left: 7px;
}
.pdd-7-right {
  padding-right: 7px;
}

.margin-7 {
  margin: 7px;
}
.margin-7-vert {
  margin-bottom: 7px;
  margin-top: 7px;
}
.margin-7-hor {
  margin-left: 7px;
  margin-right: 7px;
}
.margin-7-top {
  margin-top: 7px;
}
.margin-7-bot {
  margin-bottom: 7px;
}
.margin-7-left {
  margin-left: 7px;
}
.margin-7-right {
  margin-right: 7px;
}

.negative-margin-7 {
  margin: -7px;
}
.negative-margin-7-vert {
  margin-bottom: -7px;
  margin-top: -7px;
}
.negative-margin-7-hor {
  margin-left: -7px;
  margin-right: -7px;
}
.negative-margin-7-top {
  margin-top: -7px;
}
.negative-margin-7-bot {
  margin-bottom: -7px;
}
.negative-margin-7-left {
  margin-left: -7px;
}
.negative-margin-7-right {
  margin-right: -7px;
}

.gap-7 {
  gap: 7px;
}

.pdd-8 {
  padding: 8px;
}
.pdd-8-vert {
  padding-bottom: 8px;
  padding-top: 8px;
}
.pdd-8-hor {
  padding-left: 8px;
  padding-right: 8px;
}
.pdd-8-top {
  padding-top: 8px;
}
.pdd-8-bot {
  padding-bottom: 8px;
}
.pdd-8-left {
  padding-left: 8px;
}
.pdd-8-right {
  padding-right: 8px;
}

.margin-8 {
  margin: 8px;
}
.margin-8-vert {
  margin-bottom: 8px;
  margin-top: 8px;
}
.margin-8-hor {
  margin-left: 8px;
  margin-right: 8px;
}
.margin-8-top {
  margin-top: 8px;
}
.margin-8-bot {
  margin-bottom: 8px;
}
.margin-8-left {
  margin-left: 8px;
}
.margin-8-right {
  margin-right: 8px;
}

.negative-margin-8 {
  margin: -8px;
}
.negative-margin-8-vert {
  margin-bottom: -8px;
  margin-top: -8px;
}
.negative-margin-8-hor {
  margin-left: -8px;
  margin-right: -8px;
}
.negative-margin-8-top {
  margin-top: -8px;
}
.negative-margin-8-bot {
  margin-bottom: -8px;
}
.negative-margin-8-left {
  margin-left: -8px;
}
.negative-margin-8-right {
  margin-right: -8px;
}

.gap-8 {
  gap: 8px;
}

.pdd-10 {
  padding: 10px;
}
.pdd-10-vert {
  padding-bottom: 10px;
  padding-top: 10px;
}
.pdd-10-hor {
  padding-left: 10px;
  padding-right: 10px;
}
.pdd-10-top {
  padding-top: 10px;
}
.pdd-10-bot {
  padding-bottom: 10px;
}
.pdd-10-left {
  padding-left: 10px;
}
.pdd-10-right {
  padding-right: 10px;
}

.margin-10 {
  margin: 10px;
}
.margin-10-vert {
  margin-bottom: 10px;
  margin-top: 10px;
}
.margin-10-hor {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-10-top {
  margin-top: 10px;
}
.margin-10-bot {
  margin-bottom: 10px;
}
.margin-10-left {
  margin-left: 10px;
}
.margin-10-right {
  margin-right: 10px;
}

.negative-margin-10 {
  margin: -10px;
}
.negative-margin-10-vert {
  margin-bottom: -10px;
  margin-top: -10px;
}
.negative-margin-10-hor {
  margin-left: -10px;
  margin-right: -10px;
}
.negative-margin-10-top {
  margin-top: -10px;
}
.negative-margin-10-bot {
  margin-bottom: -10px;
}
.negative-margin-10-left {
  margin-left: -10px;
}
.negative-margin-10-right {
  margin-right: -10px;
}

.gap-10 {
  gap: 10px;
}

.pdd-12 {
  padding: 12px;
}
.pdd-12-vert {
  padding-bottom: 12px;
  padding-top: 12px;
}
.pdd-12-hor {
  padding-left: 12px;
  padding-right: 12px;
}
.pdd-12-top {
  padding-top: 12px;
}
.pdd-12-bot {
  padding-bottom: 12px;
}
.pdd-12-left {
  padding-left: 12px;
}
.pdd-12-right {
  padding-right: 12px;
}

.margin-12 {
  margin: 12px;
}
.margin-12-vert {
  margin-bottom: 12px;
  margin-top: 12px;
}
.margin-12-hor {
  margin-left: 12px;
  margin-right: 12px;
}
.margin-12-top {
  margin-top: 12px;
}
.margin-12-bot {
  margin-bottom: 12px;
}
.margin-12-left {
  margin-left: 12px;
}
.margin-12-right {
  margin-right: 12px;
}

.negative-margin-12 {
  margin: -12px;
}
.negative-margin-12-vert {
  margin-bottom: -12px;
  margin-top: -12px;
}
.negative-margin-12-hor {
  margin-left: -12px;
  margin-right: -12px;
}
.negative-margin-12-top {
  margin-top: -12px;
}
.negative-margin-12-bot {
  margin-bottom: -12px;
}
.negative-margin-12-left {
  margin-left: -12px;
}
.negative-margin-12-right {
  margin-right: -12px;
}

.gap-12 {
  gap: 12px;
}

.pdd-14 {
  padding: 14px;
}
.pdd-14-vert {
  padding-bottom: 14px;
  padding-top: 14px;
}
.pdd-14-hor {
  padding-left: 14px;
  padding-right: 14px;
}
.pdd-14-top {
  padding-top: 14px;
}
.pdd-14-bot {
  padding-bottom: 14px;
}
.pdd-14-left {
  padding-left: 14px;
}
.pdd-14-right {
  padding-right: 14px;
}

.margin-14 {
  margin: 14px;
}
.margin-14-vert {
  margin-bottom: 14px;
  margin-top: 14px;
}
.margin-14-hor {
  margin-left: 14px;
  margin-right: 14px;
}
.margin-14-top {
  margin-top: 14px;
}
.margin-14-bot {
  margin-bottom: 14px;
}
.margin-14-left {
  margin-left: 14px;
}
.margin-14-right {
  margin-right: 14px;
}

.negative-margin-14 {
  margin: -14px;
}
.negative-margin-14-vert {
  margin-bottom: -14px;
  margin-top: -14px;
}
.negative-margin-14-hor {
  margin-left: -14px;
  margin-right: -14px;
}
.negative-margin-14-top {
  margin-top: -14px;
}
.negative-margin-14-bot {
  margin-bottom: -14px;
}
.negative-margin-14-left {
  margin-left: -14px;
}
.negative-margin-14-right {
  margin-right: -14px;
}

.gap-14 {
  gap: 14px;
}

.pdd-16 {
  padding: 16px;
}
.pdd-16-vert {
  padding-bottom: 16px;
  padding-top: 16px;
}
.pdd-16-hor {
  padding-left: 16px;
  padding-right: 16px;
}
.pdd-16-top {
  padding-top: 16px;
}
.pdd-16-bot {
  padding-bottom: 16px;
}
.pdd-16-left {
  padding-left: 16px;
}
.pdd-16-right {
  padding-right: 16px;
}

.margin-16 {
  margin: 16px;
}
.margin-16-vert {
  margin-bottom: 16px;
  margin-top: 16px;
}
.margin-16-hor {
  margin-left: 16px;
  margin-right: 16px;
}
.margin-16-top {
  margin-top: 16px;
}
.margin-16-bot {
  margin-bottom: 16px;
}
.margin-16-left {
  margin-left: 16px;
}
.margin-16-right {
  margin-right: 16px;
}

.negative-margin-16 {
  margin: -16px;
}
.negative-margin-16-vert {
  margin-bottom: -16px;
  margin-top: -16px;
}
.negative-margin-16-hor {
  margin-left: -16px;
  margin-right: -16px;
}
.negative-margin-16-top {
  margin-top: -16px;
}
.negative-margin-16-bot {
  margin-bottom: -16px;
}
.negative-margin-16-left {
  margin-left: -16px;
}
.negative-margin-16-right {
  margin-right: -16px;
}

.gap-16 {
  gap: 16px;
}

.pdd-18 {
  padding: 18px;
}
.pdd-18-vert {
  padding-bottom: 18px;
  padding-top: 18px;
}
.pdd-18-hor {
  padding-left: 18px;
  padding-right: 18px;
}
.pdd-18-top {
  padding-top: 18px;
}
.pdd-18-bot {
  padding-bottom: 18px;
}
.pdd-18-left {
  padding-left: 18px;
}
.pdd-18-right {
  padding-right: 18px;
}

.margin-18 {
  margin: 18px;
}
.margin-18-vert {
  margin-bottom: 18px;
  margin-top: 18px;
}
.margin-18-hor {
  margin-left: 18px;
  margin-right: 18px;
}
.margin-18-top {
  margin-top: 18px;
}
.margin-18-bot {
  margin-bottom: 18px;
}
.margin-18-left {
  margin-left: 18px;
}
.margin-18-right {
  margin-right: 18px;
}

.negative-margin-18 {
  margin: -18px;
}
.negative-margin-18-vert {
  margin-bottom: -18px;
  margin-top: -18px;
}
.negative-margin-18-hor {
  margin-left: -18px;
  margin-right: -18px;
}
.negative-margin-18-top {
  margin-top: -18px;
}
.negative-margin-18-bot {
  margin-bottom: -18px;
}
.negative-margin-18-left {
  margin-left: -18px;
}
.negative-margin-18-right {
  margin-right: -18px;
}

.gap-18 {
  gap: 18px;
}

.pdd-20 {
  padding: 20px;
}
.pdd-20-vert {
  padding-bottom: 20px;
  padding-top: 20px;
}
.pdd-20-hor {
  padding-left: 20px;
  padding-right: 20px;
}
.pdd-20-top {
  padding-top: 20px;
}
.pdd-20-bot {
  padding-bottom: 20px;
}
.pdd-20-left {
  padding-left: 20px;
}
.pdd-20-right {
  padding-right: 20px;
}

.margin-20 {
  margin: 20px;
}
.margin-20-vert {
  margin-bottom: 20px;
  margin-top: 20px;
}
.margin-20-hor {
  margin-left: 20px;
  margin-right: 20px;
}
.margin-20-top {
  margin-top: 20px;
}
.margin-20-bot {
  margin-bottom: 20px;
}
.margin-20-left {
  margin-left: 20px;
}
.margin-20-right {
  margin-right: 20px;
}

.negative-margin-20 {
  margin: -20px;
}
.negative-margin-20-vert {
  margin-bottom: -20px;
  margin-top: -20px;
}
.negative-margin-20-hor {
  margin-left: -20px;
  margin-right: -20px;
}
.negative-margin-20-top {
  margin-top: -20px;
}
.negative-margin-20-bot {
  margin-bottom: -20px;
}
.negative-margin-20-left {
  margin-left: -20px;
}
.negative-margin-20-right {
  margin-right: -20px;
}

.gap-20 {
  gap: 20px;
}

.pdd-24 {
  padding: 24px;
}
.pdd-24-vert {
  padding-bottom: 24px;
  padding-top: 24px;
}
.pdd-24-hor {
  padding-left: 24px;
  padding-right: 24px;
}
.pdd-24-top {
  padding-top: 24px;
}
.pdd-24-bot {
  padding-bottom: 24px;
}
.pdd-24-left {
  padding-left: 24px;
}
.pdd-24-right {
  padding-right: 24px;
}

.margin-24 {
  margin: 24px;
}
.margin-24-vert {
  margin-bottom: 24px;
  margin-top: 24px;
}
.margin-24-hor {
  margin-left: 24px;
  margin-right: 24px;
}
.margin-24-top {
  margin-top: 24px;
}
.margin-24-bot {
  margin-bottom: 24px;
}
.margin-24-left {
  margin-left: 24px;
}
.margin-24-right {
  margin-right: 24px;
}

.negative-margin-24 {
  margin: -24px;
}
.negative-margin-24-vert {
  margin-bottom: -24px;
  margin-top: -24px;
}
.negative-margin-24-hor {
  margin-left: -24px;
  margin-right: -24px;
}
.negative-margin-24-top {
  margin-top: -24px;
}
.negative-margin-24-bot {
  margin-bottom: -24px;
}
.negative-margin-24-left {
  margin-left: -24px;
}
.negative-margin-24-right {
  margin-right: -24px;
}

.gap-24 {
  gap: 24px;
}

.pdd-26 {
  padding: 26px;
}
.pdd-26-vert {
  padding-bottom: 26px;
  padding-top: 26px;
}
.pdd-26-hor {
  padding-left: 26px;
  padding-right: 26px;
}
.pdd-26-top {
  padding-top: 26px;
}
.pdd-26-bot {
  padding-bottom: 26px;
}
.pdd-26-left {
  padding-left: 26px;
}
.pdd-26-right {
  padding-right: 26px;
}

.margin-26 {
  margin: 26px;
}
.margin-26-vert {
  margin-bottom: 26px;
  margin-top: 26px;
}
.margin-26-hor {
  margin-left: 26px;
  margin-right: 26px;
}
.margin-26-top {
  margin-top: 26px;
}
.margin-26-bot {
  margin-bottom: 26px;
}
.margin-26-left {
  margin-left: 26px;
}
.margin-26-right {
  margin-right: 26px;
}

.negative-margin-26 {
  margin: -26px;
}
.negative-margin-26-vert {
  margin-bottom: -26px;
  margin-top: -26px;
}
.negative-margin-26-hor {
  margin-left: -26px;
  margin-right: -26px;
}
.negative-margin-26-top {
  margin-top: -26px;
}
.negative-margin-26-bot {
  margin-bottom: -26px;
}
.negative-margin-26-left {
  margin-left: -26px;
}
.negative-margin-26-right {
  margin-right: -26px;
}

.gap-26 {
  gap: 26px;
}

.pdd-28 {
  padding: 28px;
}
.pdd-28-vert {
  padding-bottom: 28px;
  padding-top: 28px;
}
.pdd-28-hor {
  padding-left: 28px;
  padding-right: 28px;
}
.pdd-28-top {
  padding-top: 28px;
}
.pdd-28-bot {
  padding-bottom: 28px;
}
.pdd-28-left {
  padding-left: 28px;
}
.pdd-28-right {
  padding-right: 28px;
}

.margin-28 {
  margin: 28px;
}
.margin-28-vert {
  margin-bottom: 28px;
  margin-top: 28px;
}
.margin-28-hor {
  margin-left: 28px;
  margin-right: 28px;
}
.margin-28-top {
  margin-top: 28px;
}
.margin-28-bot {
  margin-bottom: 28px;
}
.margin-28-left {
  margin-left: 28px;
}
.margin-28-right {
  margin-right: 28px;
}

.negative-margin-28 {
  margin: -28px;
}
.negative-margin-28-vert {
  margin-bottom: -28px;
  margin-top: -28px;
}
.negative-margin-28-hor {
  margin-left: -28px;
  margin-right: -28px;
}
.negative-margin-28-top {
  margin-top: -28px;
}
.negative-margin-28-bot {
  margin-bottom: -28px;
}
.negative-margin-28-left {
  margin-left: -28px;
}
.negative-margin-28-right {
  margin-right: -28px;
}

.gap-28 {
  gap: 28px;
}

.pdd-32 {
  padding: 32px;
}
.pdd-32-vert {
  padding-bottom: 32px;
  padding-top: 32px;
}
.pdd-32-hor {
  padding-left: 32px;
  padding-right: 32px;
}
.pdd-32-top {
  padding-top: 32px;
}
.pdd-32-bot {
  padding-bottom: 32px;
}
.pdd-32-left {
  padding-left: 32px;
}
.pdd-32-right {
  padding-right: 32px;
}

.margin-32 {
  margin: 32px;
}
.margin-32-vert {
  margin-bottom: 32px;
  margin-top: 32px;
}
.margin-32-hor {
  margin-left: 32px;
  margin-right: 32px;
}
.margin-32-top {
  margin-top: 32px;
}
.margin-32-bot {
  margin-bottom: 32px;
}
.margin-32-left {
  margin-left: 32px;
}
.margin-32-right {
  margin-right: 32px;
}

.negative-margin-32 {
  margin: -32px;
}
.negative-margin-32-vert {
  margin-bottom: -32px;
  margin-top: -32px;
}
.negative-margin-32-hor {
  margin-left: -32px;
  margin-right: -32px;
}
.negative-margin-32-top {
  margin-top: -32px;
}
.negative-margin-32-bot {
  margin-bottom: -32px;
}
.negative-margin-32-left {
  margin-left: -32px;
}
.negative-margin-32-right {
  margin-right: -32px;
}

.gap-32 {
  gap: 32px;
}

.pdd-36 {
  padding: 36px;
}
.pdd-36-vert {
  padding-bottom: 36px;
  padding-top: 36px;
}
.pdd-36-hor {
  padding-left: 36px;
  padding-right: 36px;
}
.pdd-36-top {
  padding-top: 36px;
}
.pdd-36-bot {
  padding-bottom: 36px;
}
.pdd-36-left {
  padding-left: 36px;
}
.pdd-36-right {
  padding-right: 36px;
}

.margin-36 {
  margin: 36px;
}
.margin-36-vert {
  margin-bottom: 36px;
  margin-top: 36px;
}
.margin-36-hor {
  margin-left: 36px;
  margin-right: 36px;
}
.margin-36-top {
  margin-top: 36px;
}
.margin-36-bot {
  margin-bottom: 36px;
}
.margin-36-left {
  margin-left: 36px;
}
.margin-36-right {
  margin-right: 36px;
}

.negative-margin-36 {
  margin: -36px;
}
.negative-margin-36-vert {
  margin-bottom: -36px;
  margin-top: -36px;
}
.negative-margin-36-hor {
  margin-left: -36px;
  margin-right: -36px;
}
.negative-margin-36-top {
  margin-top: -36px;
}
.negative-margin-36-bot {
  margin-bottom: -36px;
}
.negative-margin-36-left {
  margin-left: -36px;
}
.negative-margin-36-right {
  margin-right: -36px;
}

.gap-36 {
  gap: 36px;
}

.pdd-38 {
  padding: 38px;
}
.pdd-38-vert {
  padding-bottom: 38px;
  padding-top: 38px;
}
.pdd-38-hor {
  padding-left: 38px;
  padding-right: 38px;
}
.pdd-38-top {
  padding-top: 38px;
}
.pdd-38-bot {
  padding-bottom: 38px;
}
.pdd-38-left {
  padding-left: 38px;
}
.pdd-38-right {
  padding-right: 38px;
}

.margin-38 {
  margin: 38px;
}
.margin-38-vert {
  margin-bottom: 38px;
  margin-top: 38px;
}
.margin-38-hor {
  margin-left: 38px;
  margin-right: 38px;
}
.margin-38-top {
  margin-top: 38px;
}
.margin-38-bot {
  margin-bottom: 38px;
}
.margin-38-left {
  margin-left: 38px;
}
.margin-38-right {
  margin-right: 38px;
}

.negative-margin-38 {
  margin: -38px;
}
.negative-margin-38-vert {
  margin-bottom: -38px;
  margin-top: -38px;
}
.negative-margin-38-hor {
  margin-left: -38px;
  margin-right: -38px;
}
.negative-margin-38-top {
  margin-top: -38px;
}
.negative-margin-38-bot {
  margin-bottom: -38px;
}
.negative-margin-38-left {
  margin-left: -38px;
}
.negative-margin-38-right {
  margin-right: -38px;
}

.gap-38 {
  gap: 38px;
}

.pdd-40 {
  padding: 40px;
}
.pdd-40-vert {
  padding-bottom: 40px;
  padding-top: 40px;
}
.pdd-40-hor {
  padding-left: 40px;
  padding-right: 40px;
}
.pdd-40-top {
  padding-top: 40px;
}
.pdd-40-bot {
  padding-bottom: 40px;
}
.pdd-40-left {
  padding-left: 40px;
}
.pdd-40-right {
  padding-right: 40px;
}

.margin-40 {
  margin: 40px;
}
.margin-40-vert {
  margin-bottom: 40px;
  margin-top: 40px;
}
.margin-40-hor {
  margin-left: 40px;
  margin-right: 40px;
}
.margin-40-top {
  margin-top: 40px;
}
.margin-40-bot {
  margin-bottom: 40px;
}
.margin-40-left {
  margin-left: 40px;
}
.margin-40-right {
  margin-right: 40px;
}

.negative-margin-40 {
  margin: -40px;
}
.negative-margin-40-vert {
  margin-bottom: -40px;
  margin-top: -40px;
}
.negative-margin-40-hor {
  margin-left: -40px;
  margin-right: -40px;
}
.negative-margin-40-top {
  margin-top: -40px;
}
.negative-margin-40-bot {
  margin-bottom: -40px;
}
.negative-margin-40-left {
  margin-left: -40px;
}
.negative-margin-40-right {
  margin-right: -40px;
}

.gap-40 {
  gap: 40px;
}

.pdd-44 {
  padding: 44px;
}
.pdd-44-vert {
  padding-bottom: 44px;
  padding-top: 44px;
}
.pdd-44-hor {
  padding-left: 44px;
  padding-right: 44px;
}
.pdd-44-top {
  padding-top: 44px;
}
.pdd-44-bot {
  padding-bottom: 44px;
}
.pdd-44-left {
  padding-left: 44px;
}
.pdd-44-right {
  padding-right: 44px;
}

.margin-44 {
  margin: 44px;
}
.margin-44-vert {
  margin-bottom: 44px;
  margin-top: 44px;
}
.margin-44-hor {
  margin-left: 44px;
  margin-right: 44px;
}
.margin-44-top {
  margin-top: 44px;
}
.margin-44-bot {
  margin-bottom: 44px;
}
.margin-44-left {
  margin-left: 44px;
}
.margin-44-right {
  margin-right: 44px;
}

.negative-margin-44 {
  margin: -44px;
}
.negative-margin-44-vert {
  margin-bottom: -44px;
  margin-top: -44px;
}
.negative-margin-44-hor {
  margin-left: -44px;
  margin-right: -44px;
}
.negative-margin-44-top {
  margin-top: -44px;
}
.negative-margin-44-bot {
  margin-bottom: -44px;
}
.negative-margin-44-left {
  margin-left: -44px;
}
.negative-margin-44-right {
  margin-right: -44px;
}

.gap-44 {
  gap: 44px;
}

.pdd-48 {
  padding: 48px;
}
.pdd-48-vert {
  padding-bottom: 48px;
  padding-top: 48px;
}
.pdd-48-hor {
  padding-left: 48px;
  padding-right: 48px;
}
.pdd-48-top {
  padding-top: 48px;
}
.pdd-48-bot {
  padding-bottom: 48px;
}
.pdd-48-left {
  padding-left: 48px;
}
.pdd-48-right {
  padding-right: 48px;
}

.margin-48 {
  margin: 48px;
}
.margin-48-vert {
  margin-bottom: 48px;
  margin-top: 48px;
}
.margin-48-hor {
  margin-left: 48px;
  margin-right: 48px;
}
.margin-48-top {
  margin-top: 48px;
}
.margin-48-bot {
  margin-bottom: 48px;
}
.margin-48-left {
  margin-left: 48px;
}
.margin-48-right {
  margin-right: 48px;
}

.negative-margin-48 {
  margin: -48px;
}
.negative-margin-48-vert {
  margin-bottom: -48px;
  margin-top: -48px;
}
.negative-margin-48-hor {
  margin-left: -48px;
  margin-right: -48px;
}
.negative-margin-48-top {
  margin-top: -48px;
}
.negative-margin-48-bot {
  margin-bottom: -48px;
}
.negative-margin-48-left {
  margin-left: -48px;
}
.negative-margin-48-right {
  margin-right: -48px;
}

.gap-48 {
  gap: 48px;
}

.pdd-56 {
  padding: 56px;
}
.pdd-56-vert {
  padding-bottom: 56px;
  padding-top: 56px;
}
.pdd-56-hor {
  padding-left: 56px;
  padding-right: 56px;
}
.pdd-56-top {
  padding-top: 56px;
}
.pdd-56-bot {
  padding-bottom: 56px;
}
.pdd-56-left {
  padding-left: 56px;
}
.pdd-56-right {
  padding-right: 56px;
}

.margin-56 {
  margin: 56px;
}
.margin-56-vert {
  margin-bottom: 56px;
  margin-top: 56px;
}
.margin-56-hor {
  margin-left: 56px;
  margin-right: 56px;
}
.margin-56-top {
  margin-top: 56px;
}
.margin-56-bot {
  margin-bottom: 56px;
}
.margin-56-left {
  margin-left: 56px;
}
.margin-56-right {
  margin-right: 56px;
}

.negative-margin-56 {
  margin: -56px;
}
.negative-margin-56-vert {
  margin-bottom: -56px;
  margin-top: -56px;
}
.negative-margin-56-hor {
  margin-left: -56px;
  margin-right: -56px;
}
.negative-margin-56-top {
  margin-top: -56px;
}
.negative-margin-56-bot {
  margin-bottom: -56px;
}
.negative-margin-56-left {
  margin-left: -56px;
}
.negative-margin-56-right {
  margin-right: -56px;
}

.gap-56 {
  gap: 56px;
}

.pdd-60 {
  padding: 60px;
}
.pdd-60-vert {
  padding-bottom: 60px;
  padding-top: 60px;
}
.pdd-60-hor {
  padding-left: 60px;
  padding-right: 60px;
}
.pdd-60-top {
  padding-top: 60px;
}
.pdd-60-bot {
  padding-bottom: 60px;
}
.pdd-60-left {
  padding-left: 60px;
}
.pdd-60-right {
  padding-right: 60px;
}

.margin-60 {
  margin: 60px;
}
.margin-60-vert {
  margin-bottom: 60px;
  margin-top: 60px;
}
.margin-60-hor {
  margin-left: 60px;
  margin-right: 60px;
}
.margin-60-top {
  margin-top: 60px;
}
.margin-60-bot {
  margin-bottom: 60px;
}
.margin-60-left {
  margin-left: 60px;
}
.margin-60-right {
  margin-right: 60px;
}

.negative-margin-60 {
  margin: -60px;
}
.negative-margin-60-vert {
  margin-bottom: -60px;
  margin-top: -60px;
}
.negative-margin-60-hor {
  margin-left: -60px;
  margin-right: -60px;
}
.negative-margin-60-top {
  margin-top: -60px;
}
.negative-margin-60-bot {
  margin-bottom: -60px;
}
.negative-margin-60-left {
  margin-left: -60px;
}
.negative-margin-60-right {
  margin-right: -60px;
}

.gap-60 {
  gap: 60px;
}

.pdd-64 {
  padding: 64px;
}
.pdd-64-vert {
  padding-bottom: 64px;
  padding-top: 64px;
}
.pdd-64-hor {
  padding-left: 64px;
  padding-right: 64px;
}
.pdd-64-top {
  padding-top: 64px;
}
.pdd-64-bot {
  padding-bottom: 64px;
}
.pdd-64-left {
  padding-left: 64px;
}
.pdd-64-right {
  padding-right: 64px;
}

.margin-64 {
  margin: 64px;
}
.margin-64-vert {
  margin-bottom: 64px;
  margin-top: 64px;
}
.margin-64-hor {
  margin-left: 64px;
  margin-right: 64px;
}
.margin-64-top {
  margin-top: 64px;
}
.margin-64-bot {
  margin-bottom: 64px;
}
.margin-64-left {
  margin-left: 64px;
}
.margin-64-right {
  margin-right: 64px;
}

.negative-margin-64 {
  margin: -64px;
}
.negative-margin-64-vert {
  margin-bottom: -64px;
  margin-top: -64px;
}
.negative-margin-64-hor {
  margin-left: -64px;
  margin-right: -64px;
}
.negative-margin-64-top {
  margin-top: -64px;
}
.negative-margin-64-bot {
  margin-bottom: -64px;
}
.negative-margin-64-left {
  margin-left: -64px;
}
.negative-margin-64-right {
  margin-right: -64px;
}

.gap-64 {
  gap: 64px;
}

.pdd-68 {
  padding: 68px;
}
.pdd-68-vert {
  padding-bottom: 68px;
  padding-top: 68px;
}
.pdd-68-hor {
  padding-left: 68px;
  padding-right: 68px;
}
.pdd-68-top {
  padding-top: 68px;
}
.pdd-68-bot {
  padding-bottom: 68px;
}
.pdd-68-left {
  padding-left: 68px;
}
.pdd-68-right {
  padding-right: 68px;
}

.margin-68 {
  margin: 68px;
}
.margin-68-vert {
  margin-bottom: 68px;
  margin-top: 68px;
}
.margin-68-hor {
  margin-left: 68px;
  margin-right: 68px;
}
.margin-68-top {
  margin-top: 68px;
}
.margin-68-bot {
  margin-bottom: 68px;
}
.margin-68-left {
  margin-left: 68px;
}
.margin-68-right {
  margin-right: 68px;
}

.negative-margin-68 {
  margin: -68px;
}
.negative-margin-68-vert {
  margin-bottom: -68px;
  margin-top: -68px;
}
.negative-margin-68-hor {
  margin-left: -68px;
  margin-right: -68px;
}
.negative-margin-68-top {
  margin-top: -68px;
}
.negative-margin-68-bot {
  margin-bottom: -68px;
}
.negative-margin-68-left {
  margin-left: -68px;
}
.negative-margin-68-right {
  margin-right: -68px;
}

.gap-68 {
  gap: 68px;
}

.pdd-72 {
  padding: 72px;
}
.pdd-72-vert {
  padding-bottom: 72px;
  padding-top: 72px;
}
.pdd-72-hor {
  padding-left: 72px;
  padding-right: 72px;
}
.pdd-72-top {
  padding-top: 72px;
}
.pdd-72-bot {
  padding-bottom: 72px;
}
.pdd-72-left {
  padding-left: 72px;
}
.pdd-72-right {
  padding-right: 72px;
}

.margin-72 {
  margin: 72px;
}
.margin-72-vert {
  margin-bottom: 72px;
  margin-top: 72px;
}
.margin-72-hor {
  margin-left: 72px;
  margin-right: 72px;
}
.margin-72-top {
  margin-top: 72px;
}
.margin-72-bot {
  margin-bottom: 72px;
}
.margin-72-left {
  margin-left: 72px;
}
.margin-72-right {
  margin-right: 72px;
}

.negative-margin-72 {
  margin: -72px;
}
.negative-margin-72-vert {
  margin-bottom: -72px;
  margin-top: -72px;
}
.negative-margin-72-hor {
  margin-left: -72px;
  margin-right: -72px;
}
.negative-margin-72-top {
  margin-top: -72px;
}
.negative-margin-72-bot {
  margin-bottom: -72px;
}
.negative-margin-72-left {
  margin-left: -72px;
}
.negative-margin-72-right {
  margin-right: -72px;
}

.gap-72 {
  gap: 72px;
}

.pdd-80 {
  padding: 80px;
}
.pdd-80-vert {
  padding-bottom: 80px;
  padding-top: 80px;
}
.pdd-80-hor {
  padding-left: 80px;
  padding-right: 80px;
}
.pdd-80-top {
  padding-top: 80px;
}
.pdd-80-bot {
  padding-bottom: 80px;
}
.pdd-80-left {
  padding-left: 80px;
}
.pdd-80-right {
  padding-right: 80px;
}

.margin-80 {
  margin: 80px;
}
.margin-80-vert {
  margin-bottom: 80px;
  margin-top: 80px;
}
.margin-80-hor {
  margin-left: 80px;
  margin-right: 80px;
}
.margin-80-top {
  margin-top: 80px;
}
.margin-80-bot {
  margin-bottom: 80px;
}
.margin-80-left {
  margin-left: 80px;
}
.margin-80-right {
  margin-right: 80px;
}

.negative-margin-80 {
  margin: -80px;
}
.negative-margin-80-vert {
  margin-bottom: -80px;
  margin-top: -80px;
}
.negative-margin-80-hor {
  margin-left: -80px;
  margin-right: -80px;
}
.negative-margin-80-top {
  margin-top: -80px;
}
.negative-margin-80-bot {
  margin-bottom: -80px;
}
.negative-margin-80-left {
  margin-left: -80px;
}
.negative-margin-80-right {
  margin-right: -80px;
}

.gap-80 {
  gap: 80px;
}

.pdd-88 {
  padding: 88px;
}
.pdd-88-vert {
  padding-bottom: 88px;
  padding-top: 88px;
}
.pdd-88-hor {
  padding-left: 88px;
  padding-right: 88px;
}
.pdd-88-top {
  padding-top: 88px;
}
.pdd-88-bot {
  padding-bottom: 88px;
}
.pdd-88-left {
  padding-left: 88px;
}
.pdd-88-right {
  padding-right: 88px;
}

.margin-88 {
  margin: 88px;
}
.margin-88-vert {
  margin-bottom: 88px;
  margin-top: 88px;
}
.margin-88-hor {
  margin-left: 88px;
  margin-right: 88px;
}
.margin-88-top {
  margin-top: 88px;
}
.margin-88-bot {
  margin-bottom: 88px;
}
.margin-88-left {
  margin-left: 88px;
}
.margin-88-right {
  margin-right: 88px;
}

.negative-margin-88 {
  margin: -88px;
}
.negative-margin-88-vert {
  margin-bottom: -88px;
  margin-top: -88px;
}
.negative-margin-88-hor {
  margin-left: -88px;
  margin-right: -88px;
}
.negative-margin-88-top {
  margin-top: -88px;
}
.negative-margin-88-bot {
  margin-bottom: -88px;
}
.negative-margin-88-left {
  margin-left: -88px;
}
.negative-margin-88-right {
  margin-right: -88px;
}

.gap-88 {
  gap: 88px;
}

.pdd-96 {
  padding: 96px;
}
.pdd-96-vert {
  padding-bottom: 96px;
  padding-top: 96px;
}
.pdd-96-hor {
  padding-left: 96px;
  padding-right: 96px;
}
.pdd-96-top {
  padding-top: 96px;
}
.pdd-96-bot {
  padding-bottom: 96px;
}
.pdd-96-left {
  padding-left: 96px;
}
.pdd-96-right {
  padding-right: 96px;
}

.margin-96 {
  margin: 96px;
}
.margin-96-vert {
  margin-bottom: 96px;
  margin-top: 96px;
}
.margin-96-hor {
  margin-left: 96px;
  margin-right: 96px;
}
.margin-96-top {
  margin-top: 96px;
}
.margin-96-bot {
  margin-bottom: 96px;
}
.margin-96-left {
  margin-left: 96px;
}
.margin-96-right {
  margin-right: 96px;
}

.negative-margin-96 {
  margin: -96px;
}
.negative-margin-96-vert {
  margin-bottom: -96px;
  margin-top: -96px;
}
.negative-margin-96-hor {
  margin-left: -96px;
  margin-right: -96px;
}
.negative-margin-96-top {
  margin-top: -96px;
}
.negative-margin-96-bot {
  margin-bottom: -96px;
}
.negative-margin-96-left {
  margin-left: -96px;
}
.negative-margin-96-right {
  margin-right: -96px;
}

.gap-96 {
  gap: 96px;
}

.pdd-98 {
  padding: 98px;
}
.pdd-98-vert {
  padding-bottom: 98px;
  padding-top: 98px;
}
.pdd-98-hor {
  padding-left: 98px;
  padding-right: 98px;
}
.pdd-98-top {
  padding-top: 98px;
}
.pdd-98-bot {
  padding-bottom: 98px;
}
.pdd-98-left {
  padding-left: 98px;
}
.pdd-98-right {
  padding-right: 98px;
}

.margin-98 {
  margin: 98px;
}
.margin-98-vert {
  margin-bottom: 98px;
  margin-top: 98px;
}
.margin-98-hor {
  margin-left: 98px;
  margin-right: 98px;
}
.margin-98-top {
  margin-top: 98px;
}
.margin-98-bot {
  margin-bottom: 98px;
}
.margin-98-left {
  margin-left: 98px;
}
.margin-98-right {
  margin-right: 98px;
}

.negative-margin-98 {
  margin: -98px;
}
.negative-margin-98-vert {
  margin-bottom: -98px;
  margin-top: -98px;
}
.negative-margin-98-hor {
  margin-left: -98px;
  margin-right: -98px;
}
.negative-margin-98-top {
  margin-top: -98px;
}
.negative-margin-98-bot {
  margin-bottom: -98px;
}
.negative-margin-98-left {
  margin-left: -98px;
}
.negative-margin-98-right {
  margin-right: -98px;
}

.gap-98 {
  gap: 98px;
}

.pdd-100 {
  padding: 100px;
}
.pdd-100-vert {
  padding-bottom: 100px;
  padding-top: 100px;
}
.pdd-100-hor {
  padding-left: 100px;
  padding-right: 100px;
}
.pdd-100-top {
  padding-top: 100px;
}
.pdd-100-bot {
  padding-bottom: 100px;
}
.pdd-100-left {
  padding-left: 100px;
}
.pdd-100-right {
  padding-right: 100px;
}

.margin-100 {
  margin: 100px;
}
.margin-100-vert {
  margin-bottom: 100px;
  margin-top: 100px;
}
.margin-100-hor {
  margin-left: 100px;
  margin-right: 100px;
}
.margin-100-top {
  margin-top: 100px;
}
.margin-100-bot {
  margin-bottom: 100px;
}
.margin-100-left {
  margin-left: 100px;
}
.margin-100-right {
  margin-right: 100px;
}

.negative-margin-100 {
  margin: -100px;
}
.negative-margin-100-vert {
  margin-bottom: -100px;
  margin-top: -100px;
}
.negative-margin-100-hor {
  margin-left: -100px;
  margin-right: -100px;
}
.negative-margin-100-top {
  margin-top: -100px;
}
.negative-margin-100-bot {
  margin-bottom: -100px;
}
.negative-margin-100-left {
  margin-left: -100px;
}
.negative-margin-100-right {
  margin-right: -100px;
}

.gap-100 {
  gap: 100px;
}

.pdd-102 {
  padding: 102px;
}
.pdd-102-vert {
  padding-bottom: 102px;
  padding-top: 102px;
}
.pdd-102-hor {
  padding-left: 102px;
  padding-right: 102px;
}
.pdd-102-top {
  padding-top: 102px;
}
.pdd-102-bot {
  padding-bottom: 102px;
}
.pdd-102-left {
  padding-left: 102px;
}
.pdd-102-right {
  padding-right: 102px;
}

.margin-102 {
  margin: 102px;
}
.margin-102-vert {
  margin-bottom: 102px;
  margin-top: 102px;
}
.margin-102-hor {
  margin-left: 102px;
  margin-right: 102px;
}
.margin-102-top {
  margin-top: 102px;
}
.margin-102-bot {
  margin-bottom: 102px;
}
.margin-102-left {
  margin-left: 102px;
}
.margin-102-right {
  margin-right: 102px;
}

.negative-margin-102 {
  margin: -102px;
}
.negative-margin-102-vert {
  margin-bottom: -102px;
  margin-top: -102px;
}
.negative-margin-102-hor {
  margin-left: -102px;
  margin-right: -102px;
}
.negative-margin-102-top {
  margin-top: -102px;
}
.negative-margin-102-bot {
  margin-bottom: -102px;
}
.negative-margin-102-left {
  margin-left: -102px;
}
.negative-margin-102-right {
  margin-right: -102px;
}

.gap-102 {
  gap: 102px;
}

.pdd-104 {
  padding: 104px;
}
.pdd-104-vert {
  padding-bottom: 104px;
  padding-top: 104px;
}
.pdd-104-hor {
  padding-left: 104px;
  padding-right: 104px;
}
.pdd-104-top {
  padding-top: 104px;
}
.pdd-104-bot {
  padding-bottom: 104px;
}
.pdd-104-left {
  padding-left: 104px;
}
.pdd-104-right {
  padding-right: 104px;
}

.margin-104 {
  margin: 104px;
}
.margin-104-vert {
  margin-bottom: 104px;
  margin-top: 104px;
}
.margin-104-hor {
  margin-left: 104px;
  margin-right: 104px;
}
.margin-104-top {
  margin-top: 104px;
}
.margin-104-bot {
  margin-bottom: 104px;
}
.margin-104-left {
  margin-left: 104px;
}
.margin-104-right {
  margin-right: 104px;
}

.negative-margin-104 {
  margin: -104px;
}
.negative-margin-104-vert {
  margin-bottom: -104px;
  margin-top: -104px;
}
.negative-margin-104-hor {
  margin-left: -104px;
  margin-right: -104px;
}
.negative-margin-104-top {
  margin-top: -104px;
}
.negative-margin-104-bot {
  margin-bottom: -104px;
}
.negative-margin-104-left {
  margin-left: -104px;
}
.negative-margin-104-right {
  margin-right: -104px;
}

.gap-104 {
  gap: 104px;
}

.pdd-106 {
  padding: 106px;
}
.pdd-106-vert {
  padding-bottom: 106px;
  padding-top: 106px;
}
.pdd-106-hor {
  padding-left: 106px;
  padding-right: 106px;
}
.pdd-106-top {
  padding-top: 106px;
}
.pdd-106-bot {
  padding-bottom: 106px;
}
.pdd-106-left {
  padding-left: 106px;
}
.pdd-106-right {
  padding-right: 106px;
}

.margin-106 {
  margin: 106px;
}
.margin-106-vert {
  margin-bottom: 106px;
  margin-top: 106px;
}
.margin-106-hor {
  margin-left: 106px;
  margin-right: 106px;
}
.margin-106-top {
  margin-top: 106px;
}
.margin-106-bot {
  margin-bottom: 106px;
}
.margin-106-left {
  margin-left: 106px;
}
.margin-106-right {
  margin-right: 106px;
}

.negative-margin-106 {
  margin: -106px;
}
.negative-margin-106-vert {
  margin-bottom: -106px;
  margin-top: -106px;
}
.negative-margin-106-hor {
  margin-left: -106px;
  margin-right: -106px;
}
.negative-margin-106-top {
  margin-top: -106px;
}
.negative-margin-106-bot {
  margin-bottom: -106px;
}
.negative-margin-106-left {
  margin-left: -106px;
}
.negative-margin-106-right {
  margin-right: -106px;
}

.gap-106 {
  gap: 106px;
}

.pdd-108 {
  padding: 108px;
}
.pdd-108-vert {
  padding-bottom: 108px;
  padding-top: 108px;
}
.pdd-108-hor {
  padding-left: 108px;
  padding-right: 108px;
}
.pdd-108-top {
  padding-top: 108px;
}
.pdd-108-bot {
  padding-bottom: 108px;
}
.pdd-108-left {
  padding-left: 108px;
}
.pdd-108-right {
  padding-right: 108px;
}

.margin-108 {
  margin: 108px;
}
.margin-108-vert {
  margin-bottom: 108px;
  margin-top: 108px;
}
.margin-108-hor {
  margin-left: 108px;
  margin-right: 108px;
}
.margin-108-top {
  margin-top: 108px;
}
.margin-108-bot {
  margin-bottom: 108px;
}
.margin-108-left {
  margin-left: 108px;
}
.margin-108-right {
  margin-right: 108px;
}

.negative-margin-108 {
  margin: -108px;
}
.negative-margin-108-vert {
  margin-bottom: -108px;
  margin-top: -108px;
}
.negative-margin-108-hor {
  margin-left: -108px;
  margin-right: -108px;
}
.negative-margin-108-top {
  margin-top: -108px;
}
.negative-margin-108-bot {
  margin-bottom: -108px;
}
.negative-margin-108-left {
  margin-left: -108px;
}
.negative-margin-108-right {
  margin-right: -108px;
}

.gap-108 {
  gap: 108px;
}

.pdd-110 {
  padding: 110px;
}
.pdd-110-vert {
  padding-bottom: 110px;
  padding-top: 110px;
}
.pdd-110-hor {
  padding-left: 110px;
  padding-right: 110px;
}
.pdd-110-top {
  padding-top: 110px;
}
.pdd-110-bot {
  padding-bottom: 110px;
}
.pdd-110-left {
  padding-left: 110px;
}
.pdd-110-right {
  padding-right: 110px;
}

.margin-110 {
  margin: 110px;
}
.margin-110-vert {
  margin-bottom: 110px;
  margin-top: 110px;
}
.margin-110-hor {
  margin-left: 110px;
  margin-right: 110px;
}
.margin-110-top {
  margin-top: 110px;
}
.margin-110-bot {
  margin-bottom: 110px;
}
.margin-110-left {
  margin-left: 110px;
}
.margin-110-right {
  margin-right: 110px;
}

.negative-margin-110 {
  margin: -110px;
}
.negative-margin-110-vert {
  margin-bottom: -110px;
  margin-top: -110px;
}
.negative-margin-110-hor {
  margin-left: -110px;
  margin-right: -110px;
}
.negative-margin-110-top {
  margin-top: -110px;
}
.negative-margin-110-bot {
  margin-bottom: -110px;
}
.negative-margin-110-left {
  margin-left: -110px;
}
.negative-margin-110-right {
  margin-right: -110px;
}

.gap-110 {
  gap: 110px;
}

.pdd-112 {
  padding: 112px;
}
.pdd-112-vert {
  padding-bottom: 112px;
  padding-top: 112px;
}
.pdd-112-hor {
  padding-left: 112px;
  padding-right: 112px;
}
.pdd-112-top {
  padding-top: 112px;
}
.pdd-112-bot {
  padding-bottom: 112px;
}
.pdd-112-left {
  padding-left: 112px;
}
.pdd-112-right {
  padding-right: 112px;
}

.margin-112 {
  margin: 112px;
}
.margin-112-vert {
  margin-bottom: 112px;
  margin-top: 112px;
}
.margin-112-hor {
  margin-left: 112px;
  margin-right: 112px;
}
.margin-112-top {
  margin-top: 112px;
}
.margin-112-bot {
  margin-bottom: 112px;
}
.margin-112-left {
  margin-left: 112px;
}
.margin-112-right {
  margin-right: 112px;
}

.negative-margin-112 {
  margin: -112px;
}
.negative-margin-112-vert {
  margin-bottom: -112px;
  margin-top: -112px;
}
.negative-margin-112-hor {
  margin-left: -112px;
  margin-right: -112px;
}
.negative-margin-112-top {
  margin-top: -112px;
}
.negative-margin-112-bot {
  margin-bottom: -112px;
}
.negative-margin-112-left {
  margin-left: -112px;
}
.negative-margin-112-right {
  margin-right: -112px;
}

.gap-112 {
  gap: 112px;
}

.pdd-120 {
  padding: 120px;
}
.pdd-120-vert {
  padding-bottom: 120px;
  padding-top: 120px;
}
.pdd-120-hor {
  padding-left: 120px;
  padding-right: 120px;
}
.pdd-120-top {
  padding-top: 120px;
}
.pdd-120-bot {
  padding-bottom: 120px;
}
.pdd-120-left {
  padding-left: 120px;
}
.pdd-120-right {
  padding-right: 120px;
}

.margin-120 {
  margin: 120px;
}
.margin-120-vert {
  margin-bottom: 120px;
  margin-top: 120px;
}
.margin-120-hor {
  margin-left: 120px;
  margin-right: 120px;
}
.margin-120-top {
  margin-top: 120px;
}
.margin-120-bot {
  margin-bottom: 120px;
}
.margin-120-left {
  margin-left: 120px;
}
.margin-120-right {
  margin-right: 120px;
}

.negative-margin-120 {
  margin: -120px;
}
.negative-margin-120-vert {
  margin-bottom: -120px;
  margin-top: -120px;
}
.negative-margin-120-hor {
  margin-left: -120px;
  margin-right: -120px;
}
.negative-margin-120-top {
  margin-top: -120px;
}
.negative-margin-120-bot {
  margin-bottom: -120px;
}
.negative-margin-120-left {
  margin-left: -120px;
}
.negative-margin-120-right {
  margin-right: -120px;
}

.gap-120 {
  gap: 120px;
}

.pdd-186 {
  padding: 186px;
}
.pdd-186-vert {
  padding-bottom: 186px;
  padding-top: 186px;
}
.pdd-186-hor {
  padding-left: 186px;
  padding-right: 186px;
}
.pdd-186-top {
  padding-top: 186px;
}
.pdd-186-bot {
  padding-bottom: 186px;
}
.pdd-186-left {
  padding-left: 186px;
}
.pdd-186-right {
  padding-right: 186px;
}

.margin-186 {
  margin: 186px;
}
.margin-186-vert {
  margin-bottom: 186px;
  margin-top: 186px;
}
.margin-186-hor {
  margin-left: 186px;
  margin-right: 186px;
}
.margin-186-top {
  margin-top: 186px;
}
.margin-186-bot {
  margin-bottom: 186px;
}
.margin-186-left {
  margin-left: 186px;
}
.margin-186-right {
  margin-right: 186px;
}

.negative-margin-186 {
  margin: -186px;
}
.negative-margin-186-vert {
  margin-bottom: -186px;
  margin-top: -186px;
}
.negative-margin-186-hor {
  margin-left: -186px;
  margin-right: -186px;
}
.negative-margin-186-top {
  margin-top: -186px;
}
.negative-margin-186-bot {
  margin-bottom: -186px;
}
.negative-margin-186-left {
  margin-left: -186px;
}
.negative-margin-186-right {
  margin-right: -186px;
}

.gap-186 {
  gap: 186px;
}

@media (max-width: 991.98px) {
  .pdd-mobile-auto {
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
    padding-top: auto;
  }
  .pdd-mobile-auto-vert {
    padding-bottom: auto;
    padding-top: auto;
  }
  .pdd-mobile-auto-hor {
    padding-left: auto;
    padding-right: auto;
  }
  .pdd-mobile-auto-top {
    padding-top: auto;
  }
  .pdd-mobile-auto-bot {
    padding-bottom: auto;
  }
  .pdd-mobile-auto-left {
    padding-left: auto;
  }
  .pdd-mobile-auto-right {
    padding-right: auto;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-auto {
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
    padding-top: auto;
  }
  .pdd-desktop-auto-vert {
    padding-bottom: auto;
    padding-top: auto;
  }
  .pdd-desktop-auto-hor {
    padding-left: auto;
    padding-right: auto;
  }
  .pdd-desktop-auto-top {
    padding-top: auto;
  }
  .pdd-desktop-auto-bot {
    padding-bottom: auto;
  }
  .pdd-desktop-auto-left {
    padding-left: auto;
  }
  .pdd-desktop-auto-right {
    padding-right: auto;
  }
}

@media (min-width: 992px) {
  .margin-desktop-auto {
    margin: auto;
  }
  .margin-desktop-auto-vert {
    margin-bottom: auto;
    margin-top: auto;
  }
  .margin-desktop-auto-hor {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-desktop-auto-top {
    margin-top: auto;
  }
  .margin-desktop-auto-bot {
    margin-bottom: auto;
  }
  .margin-desktop-auto-left {
    margin-left: auto;
  }
  .margin-desktop-auto-right {
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-auto {
    margin: -auto;
  }
  .negative-margin-desktop-auto-vert {
    margin-bottom: -auto;
    margin-top: -auto;
  }
  .negative-margin-desktop-auto-hor {
    margin-left: -auto;
    margin-right: -auto;
  }
  .negative-margin-desktop-auto-top {
    margin-top: -auto;
  }
  .negative-margin-desktop-auto-bot {
    margin-bottom: -auto;
  }
  .negative-margin-desktop-auto-left {
    margin-left: -auto;
  }
  .negative-margin-desktop-auto-right {
    margin-right: -auto;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-auto {
    margin: auto;
  }
  .margin-mobile-auto-vert {
    margin-bottom: auto;
    margin-top: auto;
  }
  .margin-mobile-auto-hor {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-mobile-auto-top {
    margin-top: auto;
  }
  .margin-mobile-auto-bot {
    margin-bottom: auto;
  }
  .margin-mobile-auto-left {
    margin-left: auto;
  }
  .margin-mobile-auto-right {
    margin-right: auto;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-auto {
    margin: -auto;
  }
  .negative-margin-mobile-auto-vert {
    margin-bottom: -auto;
    margin-top: -auto;
  }
  .negative-margin-mobile-auto-hor {
    margin-left: -auto;
    margin-right: -auto;
  }
  .negative-margin-mobile-auto-top {
    margin-top: -auto;
  }
  .negative-margin-mobile-auto-bot {
    margin-bottom: -auto;
  }
  .negative-margin-mobile-auto-left {
    margin-left: -auto;
  }
  .negative-margin-mobile-auto-right {
    margin-right: -auto;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-0 {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .pdd-mobile-0-vert {
    padding-bottom: 0;
    padding-top: 0;
  }
  .pdd-mobile-0-hor {
    padding-left: 0;
    padding-right: 0;
  }
  .pdd-mobile-0-top {
    padding-top: 0;
  }
  .pdd-mobile-0-bot {
    padding-bottom: 0;
  }
  .pdd-mobile-0-left {
    padding-left: 0;
  }
  .pdd-mobile-0-right {
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-0 {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .pdd-desktop-0-vert {
    padding-bottom: 0;
    padding-top: 0;
  }
  .pdd-desktop-0-hor {
    padding-left: 0;
    padding-right: 0;
  }
  .pdd-desktop-0-top {
    padding-top: 0;
  }
  .pdd-desktop-0-bot {
    padding-bottom: 0;
  }
  .pdd-desktop-0-left {
    padding-left: 0;
  }
  .pdd-desktop-0-right {
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .margin-desktop-0 {
    margin: 0;
  }
  .margin-desktop-0-vert {
    margin-bottom: 0;
    margin-top: 0;
  }
  .margin-desktop-0-hor {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-desktop-0-top {
    margin-top: 0;
  }
  .margin-desktop-0-bot {
    margin-bottom: 0;
  }
  .margin-desktop-0-left {
    margin-left: 0;
  }
  .margin-desktop-0-right {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-0 {
    margin: -0;
  }
  .negative-margin-desktop-0-vert {
    margin-bottom: -0;
    margin-top: -0;
  }
  .negative-margin-desktop-0-hor {
    margin-left: -0;
    margin-right: -0;
  }
  .negative-margin-desktop-0-top {
    margin-top: -0;
  }
  .negative-margin-desktop-0-bot {
    margin-bottom: -0;
  }
  .negative-margin-desktop-0-left {
    margin-left: -0;
  }
  .negative-margin-desktop-0-right {
    margin-right: -0;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-0 {
    margin: 0;
  }
  .margin-mobile-0-vert {
    margin-bottom: 0;
    margin-top: 0;
  }
  .margin-mobile-0-hor {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-mobile-0-top {
    margin-top: 0;
  }
  .margin-mobile-0-bot {
    margin-bottom: 0;
  }
  .margin-mobile-0-left {
    margin-left: 0;
  }
  .margin-mobile-0-right {
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-0 {
    margin: -0;
  }
  .negative-margin-mobile-0-vert {
    margin-bottom: -0;
    margin-top: -0;
  }
  .negative-margin-mobile-0-hor {
    margin-left: -0;
    margin-right: -0;
  }
  .negative-margin-mobile-0-top {
    margin-top: -0;
  }
  .negative-margin-mobile-0-bot {
    margin-bottom: -0;
  }
  .negative-margin-mobile-0-left {
    margin-left: -0;
  }
  .negative-margin-mobile-0-right {
    margin-right: -0;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-2 {
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
  }
  .pdd-mobile-2-vert {
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .pdd-mobile-2-hor {
    padding-left: 2px;
    padding-right: 2px;
  }
  .pdd-mobile-2-top {
    padding-top: 2px;
  }
  .pdd-mobile-2-bot {
    padding-bottom: 2px;
  }
  .pdd-mobile-2-left {
    padding-left: 2px;
  }
  .pdd-mobile-2-right {
    padding-right: 2px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-2 {
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
  }
  .pdd-desktop-2-vert {
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .pdd-desktop-2-hor {
    padding-left: 2px;
    padding-right: 2px;
  }
  .pdd-desktop-2-top {
    padding-top: 2px;
  }
  .pdd-desktop-2-bot {
    padding-bottom: 2px;
  }
  .pdd-desktop-2-left {
    padding-left: 2px;
  }
  .pdd-desktop-2-right {
    padding-right: 2px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-2 {
    margin: 2px;
  }
  .margin-desktop-2-vert {
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .margin-desktop-2-hor {
    margin-left: 2px;
    margin-right: 2px;
  }
  .margin-desktop-2-top {
    margin-top: 2px;
  }
  .margin-desktop-2-bot {
    margin-bottom: 2px;
  }
  .margin-desktop-2-left {
    margin-left: 2px;
  }
  .margin-desktop-2-right {
    margin-right: 2px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-2 {
    margin: -2px;
  }
  .negative-margin-desktop-2-vert {
    margin-bottom: -2px;
    margin-top: -2px;
  }
  .negative-margin-desktop-2-hor {
    margin-left: -2px;
    margin-right: -2px;
  }
  .negative-margin-desktop-2-top {
    margin-top: -2px;
  }
  .negative-margin-desktop-2-bot {
    margin-bottom: -2px;
  }
  .negative-margin-desktop-2-left {
    margin-left: -2px;
  }
  .negative-margin-desktop-2-right {
    margin-right: -2px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-2 {
    margin: 2px;
  }
  .margin-mobile-2-vert {
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .margin-mobile-2-hor {
    margin-left: 2px;
    margin-right: 2px;
  }
  .margin-mobile-2-top {
    margin-top: 2px;
  }
  .margin-mobile-2-bot {
    margin-bottom: 2px;
  }
  .margin-mobile-2-left {
    margin-left: 2px;
  }
  .margin-mobile-2-right {
    margin-right: 2px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-2 {
    margin: -2px;
  }
  .negative-margin-mobile-2-vert {
    margin-bottom: -2px;
    margin-top: -2px;
  }
  .negative-margin-mobile-2-hor {
    margin-left: -2px;
    margin-right: -2px;
  }
  .negative-margin-mobile-2-top {
    margin-top: -2px;
  }
  .negative-margin-mobile-2-bot {
    margin-bottom: -2px;
  }
  .negative-margin-mobile-2-left {
    margin-left: -2px;
  }
  .negative-margin-mobile-2-right {
    margin-right: -2px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-4 {
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
  }
  .pdd-mobile-4-vert {
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .pdd-mobile-4-hor {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pdd-mobile-4-top {
    padding-top: 4px;
  }
  .pdd-mobile-4-bot {
    padding-bottom: 4px;
  }
  .pdd-mobile-4-left {
    padding-left: 4px;
  }
  .pdd-mobile-4-right {
    padding-right: 4px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-4 {
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
  }
  .pdd-desktop-4-vert {
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .pdd-desktop-4-hor {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pdd-desktop-4-top {
    padding-top: 4px;
  }
  .pdd-desktop-4-bot {
    padding-bottom: 4px;
  }
  .pdd-desktop-4-left {
    padding-left: 4px;
  }
  .pdd-desktop-4-right {
    padding-right: 4px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-4 {
    margin: 4px;
  }
  .margin-desktop-4-vert {
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .margin-desktop-4-hor {
    margin-left: 4px;
    margin-right: 4px;
  }
  .margin-desktop-4-top {
    margin-top: 4px;
  }
  .margin-desktop-4-bot {
    margin-bottom: 4px;
  }
  .margin-desktop-4-left {
    margin-left: 4px;
  }
  .margin-desktop-4-right {
    margin-right: 4px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-4 {
    margin: -4px;
  }
  .negative-margin-desktop-4-vert {
    margin-bottom: -4px;
    margin-top: -4px;
  }
  .negative-margin-desktop-4-hor {
    margin-left: -4px;
    margin-right: -4px;
  }
  .negative-margin-desktop-4-top {
    margin-top: -4px;
  }
  .negative-margin-desktop-4-bot {
    margin-bottom: -4px;
  }
  .negative-margin-desktop-4-left {
    margin-left: -4px;
  }
  .negative-margin-desktop-4-right {
    margin-right: -4px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-4 {
    margin: 4px;
  }
  .margin-mobile-4-vert {
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .margin-mobile-4-hor {
    margin-left: 4px;
    margin-right: 4px;
  }
  .margin-mobile-4-top {
    margin-top: 4px;
  }
  .margin-mobile-4-bot {
    margin-bottom: 4px;
  }
  .margin-mobile-4-left {
    margin-left: 4px;
  }
  .margin-mobile-4-right {
    margin-right: 4px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-4 {
    margin: -4px;
  }
  .negative-margin-mobile-4-vert {
    margin-bottom: -4px;
    margin-top: -4px;
  }
  .negative-margin-mobile-4-hor {
    margin-left: -4px;
    margin-right: -4px;
  }
  .negative-margin-mobile-4-top {
    margin-top: -4px;
  }
  .negative-margin-mobile-4-bot {
    margin-bottom: -4px;
  }
  .negative-margin-mobile-4-left {
    margin-left: -4px;
  }
  .negative-margin-mobile-4-right {
    margin-right: -4px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-6 {
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
  }
  .pdd-mobile-6-vert {
    padding-bottom: 6px;
    padding-top: 6px;
  }
  .pdd-mobile-6-hor {
    padding-left: 6px;
    padding-right: 6px;
  }
  .pdd-mobile-6-top {
    padding-top: 6px;
  }
  .pdd-mobile-6-bot {
    padding-bottom: 6px;
  }
  .pdd-mobile-6-left {
    padding-left: 6px;
  }
  .pdd-mobile-6-right {
    padding-right: 6px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-6 {
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
  }
  .pdd-desktop-6-vert {
    padding-bottom: 6px;
    padding-top: 6px;
  }
  .pdd-desktop-6-hor {
    padding-left: 6px;
    padding-right: 6px;
  }
  .pdd-desktop-6-top {
    padding-top: 6px;
  }
  .pdd-desktop-6-bot {
    padding-bottom: 6px;
  }
  .pdd-desktop-6-left {
    padding-left: 6px;
  }
  .pdd-desktop-6-right {
    padding-right: 6px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-6 {
    margin: 6px;
  }
  .margin-desktop-6-vert {
    margin-bottom: 6px;
    margin-top: 6px;
  }
  .margin-desktop-6-hor {
    margin-left: 6px;
    margin-right: 6px;
  }
  .margin-desktop-6-top {
    margin-top: 6px;
  }
  .margin-desktop-6-bot {
    margin-bottom: 6px;
  }
  .margin-desktop-6-left {
    margin-left: 6px;
  }
  .margin-desktop-6-right {
    margin-right: 6px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-6 {
    margin: -6px;
  }
  .negative-margin-desktop-6-vert {
    margin-bottom: -6px;
    margin-top: -6px;
  }
  .negative-margin-desktop-6-hor {
    margin-left: -6px;
    margin-right: -6px;
  }
  .negative-margin-desktop-6-top {
    margin-top: -6px;
  }
  .negative-margin-desktop-6-bot {
    margin-bottom: -6px;
  }
  .negative-margin-desktop-6-left {
    margin-left: -6px;
  }
  .negative-margin-desktop-6-right {
    margin-right: -6px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-6 {
    margin: 6px;
  }
  .margin-mobile-6-vert {
    margin-bottom: 6px;
    margin-top: 6px;
  }
  .margin-mobile-6-hor {
    margin-left: 6px;
    margin-right: 6px;
  }
  .margin-mobile-6-top {
    margin-top: 6px;
  }
  .margin-mobile-6-bot {
    margin-bottom: 6px;
  }
  .margin-mobile-6-left {
    margin-left: 6px;
  }
  .margin-mobile-6-right {
    margin-right: 6px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-6 {
    margin: -6px;
  }
  .negative-margin-mobile-6-vert {
    margin-bottom: -6px;
    margin-top: -6px;
  }
  .negative-margin-mobile-6-hor {
    margin-left: -6px;
    margin-right: -6px;
  }
  .negative-margin-mobile-6-top {
    margin-top: -6px;
  }
  .negative-margin-mobile-6-bot {
    margin-bottom: -6px;
  }
  .negative-margin-mobile-6-left {
    margin-left: -6px;
  }
  .negative-margin-mobile-6-right {
    margin-right: -6px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-7 {
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
  }
  .pdd-mobile-7-vert {
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .pdd-mobile-7-hor {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pdd-mobile-7-top {
    padding-top: 7px;
  }
  .pdd-mobile-7-bot {
    padding-bottom: 7px;
  }
  .pdd-mobile-7-left {
    padding-left: 7px;
  }
  .pdd-mobile-7-right {
    padding-right: 7px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-7 {
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
  }
  .pdd-desktop-7-vert {
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .pdd-desktop-7-hor {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pdd-desktop-7-top {
    padding-top: 7px;
  }
  .pdd-desktop-7-bot {
    padding-bottom: 7px;
  }
  .pdd-desktop-7-left {
    padding-left: 7px;
  }
  .pdd-desktop-7-right {
    padding-right: 7px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-7 {
    margin: 7px;
  }
  .margin-desktop-7-vert {
    margin-bottom: 7px;
    margin-top: 7px;
  }
  .margin-desktop-7-hor {
    margin-left: 7px;
    margin-right: 7px;
  }
  .margin-desktop-7-top {
    margin-top: 7px;
  }
  .margin-desktop-7-bot {
    margin-bottom: 7px;
  }
  .margin-desktop-7-left {
    margin-left: 7px;
  }
  .margin-desktop-7-right {
    margin-right: 7px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-7 {
    margin: -7px;
  }
  .negative-margin-desktop-7-vert {
    margin-bottom: -7px;
    margin-top: -7px;
  }
  .negative-margin-desktop-7-hor {
    margin-left: -7px;
    margin-right: -7px;
  }
  .negative-margin-desktop-7-top {
    margin-top: -7px;
  }
  .negative-margin-desktop-7-bot {
    margin-bottom: -7px;
  }
  .negative-margin-desktop-7-left {
    margin-left: -7px;
  }
  .negative-margin-desktop-7-right {
    margin-right: -7px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-7 {
    margin: 7px;
  }
  .margin-mobile-7-vert {
    margin-bottom: 7px;
    margin-top: 7px;
  }
  .margin-mobile-7-hor {
    margin-left: 7px;
    margin-right: 7px;
  }
  .margin-mobile-7-top {
    margin-top: 7px;
  }
  .margin-mobile-7-bot {
    margin-bottom: 7px;
  }
  .margin-mobile-7-left {
    margin-left: 7px;
  }
  .margin-mobile-7-right {
    margin-right: 7px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-7 {
    margin: -7px;
  }
  .negative-margin-mobile-7-vert {
    margin-bottom: -7px;
    margin-top: -7px;
  }
  .negative-margin-mobile-7-hor {
    margin-left: -7px;
    margin-right: -7px;
  }
  .negative-margin-mobile-7-top {
    margin-top: -7px;
  }
  .negative-margin-mobile-7-bot {
    margin-bottom: -7px;
  }
  .negative-margin-mobile-7-left {
    margin-left: -7px;
  }
  .negative-margin-mobile-7-right {
    margin-right: -7px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-8 {
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
  }
  .pdd-mobile-8-vert {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .pdd-mobile-8-hor {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pdd-mobile-8-top {
    padding-top: 8px;
  }
  .pdd-mobile-8-bot {
    padding-bottom: 8px;
  }
  .pdd-mobile-8-left {
    padding-left: 8px;
  }
  .pdd-mobile-8-right {
    padding-right: 8px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-8 {
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
  }
  .pdd-desktop-8-vert {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .pdd-desktop-8-hor {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pdd-desktop-8-top {
    padding-top: 8px;
  }
  .pdd-desktop-8-bot {
    padding-bottom: 8px;
  }
  .pdd-desktop-8-left {
    padding-left: 8px;
  }
  .pdd-desktop-8-right {
    padding-right: 8px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-8 {
    margin: 8px;
  }
  .margin-desktop-8-vert {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .margin-desktop-8-hor {
    margin-left: 8px;
    margin-right: 8px;
  }
  .margin-desktop-8-top {
    margin-top: 8px;
  }
  .margin-desktop-8-bot {
    margin-bottom: 8px;
  }
  .margin-desktop-8-left {
    margin-left: 8px;
  }
  .margin-desktop-8-right {
    margin-right: 8px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-8 {
    margin: -8px;
  }
  .negative-margin-desktop-8-vert {
    margin-bottom: -8px;
    margin-top: -8px;
  }
  .negative-margin-desktop-8-hor {
    margin-left: -8px;
    margin-right: -8px;
  }
  .negative-margin-desktop-8-top {
    margin-top: -8px;
  }
  .negative-margin-desktop-8-bot {
    margin-bottom: -8px;
  }
  .negative-margin-desktop-8-left {
    margin-left: -8px;
  }
  .negative-margin-desktop-8-right {
    margin-right: -8px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-8 {
    margin: 8px;
  }
  .margin-mobile-8-vert {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .margin-mobile-8-hor {
    margin-left: 8px;
    margin-right: 8px;
  }
  .margin-mobile-8-top {
    margin-top: 8px;
  }
  .margin-mobile-8-bot {
    margin-bottom: 8px;
  }
  .margin-mobile-8-left {
    margin-left: 8px;
  }
  .margin-mobile-8-right {
    margin-right: 8px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-8 {
    margin: -8px;
  }
  .negative-margin-mobile-8-vert {
    margin-bottom: -8px;
    margin-top: -8px;
  }
  .negative-margin-mobile-8-hor {
    margin-left: -8px;
    margin-right: -8px;
  }
  .negative-margin-mobile-8-top {
    margin-top: -8px;
  }
  .negative-margin-mobile-8-bot {
    margin-bottom: -8px;
  }
  .negative-margin-mobile-8-left {
    margin-left: -8px;
  }
  .negative-margin-mobile-8-right {
    margin-right: -8px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-10 {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
  .pdd-mobile-10-vert {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .pdd-mobile-10-hor {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pdd-mobile-10-top {
    padding-top: 10px;
  }
  .pdd-mobile-10-bot {
    padding-bottom: 10px;
  }
  .pdd-mobile-10-left {
    padding-left: 10px;
  }
  .pdd-mobile-10-right {
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-10 {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
  .pdd-desktop-10-vert {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .pdd-desktop-10-hor {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pdd-desktop-10-top {
    padding-top: 10px;
  }
  .pdd-desktop-10-bot {
    padding-bottom: 10px;
  }
  .pdd-desktop-10-left {
    padding-left: 10px;
  }
  .pdd-desktop-10-right {
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-10 {
    margin: 10px;
  }
  .margin-desktop-10-vert {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .margin-desktop-10-hor {
    margin-left: 10px;
    margin-right: 10px;
  }
  .margin-desktop-10-top {
    margin-top: 10px;
  }
  .margin-desktop-10-bot {
    margin-bottom: 10px;
  }
  .margin-desktop-10-left {
    margin-left: 10px;
  }
  .margin-desktop-10-right {
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-10 {
    margin: -10px;
  }
  .negative-margin-desktop-10-vert {
    margin-bottom: -10px;
    margin-top: -10px;
  }
  .negative-margin-desktop-10-hor {
    margin-left: -10px;
    margin-right: -10px;
  }
  .negative-margin-desktop-10-top {
    margin-top: -10px;
  }
  .negative-margin-desktop-10-bot {
    margin-bottom: -10px;
  }
  .negative-margin-desktop-10-left {
    margin-left: -10px;
  }
  .negative-margin-desktop-10-right {
    margin-right: -10px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-10 {
    margin: 10px;
  }
  .margin-mobile-10-vert {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .margin-mobile-10-hor {
    margin-left: 10px;
    margin-right: 10px;
  }
  .margin-mobile-10-top {
    margin-top: 10px;
  }
  .margin-mobile-10-bot {
    margin-bottom: 10px;
  }
  .margin-mobile-10-left {
    margin-left: 10px;
  }
  .margin-mobile-10-right {
    margin-right: 10px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-10 {
    margin: -10px;
  }
  .negative-margin-mobile-10-vert {
    margin-bottom: -10px;
    margin-top: -10px;
  }
  .negative-margin-mobile-10-hor {
    margin-left: -10px;
    margin-right: -10px;
  }
  .negative-margin-mobile-10-top {
    margin-top: -10px;
  }
  .negative-margin-mobile-10-bot {
    margin-bottom: -10px;
  }
  .negative-margin-mobile-10-left {
    margin-left: -10px;
  }
  .negative-margin-mobile-10-right {
    margin-right: -10px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-12 {
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
  }
  .pdd-mobile-12-vert {
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .pdd-mobile-12-hor {
    padding-left: 12px;
    padding-right: 12px;
  }
  .pdd-mobile-12-top {
    padding-top: 12px;
  }
  .pdd-mobile-12-bot {
    padding-bottom: 12px;
  }
  .pdd-mobile-12-left {
    padding-left: 12px;
  }
  .pdd-mobile-12-right {
    padding-right: 12px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-12 {
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
  }
  .pdd-desktop-12-vert {
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .pdd-desktop-12-hor {
    padding-left: 12px;
    padding-right: 12px;
  }
  .pdd-desktop-12-top {
    padding-top: 12px;
  }
  .pdd-desktop-12-bot {
    padding-bottom: 12px;
  }
  .pdd-desktop-12-left {
    padding-left: 12px;
  }
  .pdd-desktop-12-right {
    padding-right: 12px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-12 {
    margin: 12px;
  }
  .margin-desktop-12-vert {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .margin-desktop-12-hor {
    margin-left: 12px;
    margin-right: 12px;
  }
  .margin-desktop-12-top {
    margin-top: 12px;
  }
  .margin-desktop-12-bot {
    margin-bottom: 12px;
  }
  .margin-desktop-12-left {
    margin-left: 12px;
  }
  .margin-desktop-12-right {
    margin-right: 12px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-12 {
    margin: -12px;
  }
  .negative-margin-desktop-12-vert {
    margin-bottom: -12px;
    margin-top: -12px;
  }
  .negative-margin-desktop-12-hor {
    margin-left: -12px;
    margin-right: -12px;
  }
  .negative-margin-desktop-12-top {
    margin-top: -12px;
  }
  .negative-margin-desktop-12-bot {
    margin-bottom: -12px;
  }
  .negative-margin-desktop-12-left {
    margin-left: -12px;
  }
  .negative-margin-desktop-12-right {
    margin-right: -12px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-12 {
    margin: 12px;
  }
  .margin-mobile-12-vert {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .margin-mobile-12-hor {
    margin-left: 12px;
    margin-right: 12px;
  }
  .margin-mobile-12-top {
    margin-top: 12px;
  }
  .margin-mobile-12-bot {
    margin-bottom: 12px;
  }
  .margin-mobile-12-left {
    margin-left: 12px;
  }
  .margin-mobile-12-right {
    margin-right: 12px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-12 {
    margin: -12px;
  }
  .negative-margin-mobile-12-vert {
    margin-bottom: -12px;
    margin-top: -12px;
  }
  .negative-margin-mobile-12-hor {
    margin-left: -12px;
    margin-right: -12px;
  }
  .negative-margin-mobile-12-top {
    margin-top: -12px;
  }
  .negative-margin-mobile-12-bot {
    margin-bottom: -12px;
  }
  .negative-margin-mobile-12-left {
    margin-left: -12px;
  }
  .negative-margin-mobile-12-right {
    margin-right: -12px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-14 {
    padding-bottom: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
  }
  .pdd-mobile-14-vert {
    padding-bottom: 14px;
    padding-top: 14px;
  }
  .pdd-mobile-14-hor {
    padding-left: 14px;
    padding-right: 14px;
  }
  .pdd-mobile-14-top {
    padding-top: 14px;
  }
  .pdd-mobile-14-bot {
    padding-bottom: 14px;
  }
  .pdd-mobile-14-left {
    padding-left: 14px;
  }
  .pdd-mobile-14-right {
    padding-right: 14px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-14 {
    padding-bottom: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
  }
  .pdd-desktop-14-vert {
    padding-bottom: 14px;
    padding-top: 14px;
  }
  .pdd-desktop-14-hor {
    padding-left: 14px;
    padding-right: 14px;
  }
  .pdd-desktop-14-top {
    padding-top: 14px;
  }
  .pdd-desktop-14-bot {
    padding-bottom: 14px;
  }
  .pdd-desktop-14-left {
    padding-left: 14px;
  }
  .pdd-desktop-14-right {
    padding-right: 14px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-14 {
    margin: 14px;
  }
  .margin-desktop-14-vert {
    margin-bottom: 14px;
    margin-top: 14px;
  }
  .margin-desktop-14-hor {
    margin-left: 14px;
    margin-right: 14px;
  }
  .margin-desktop-14-top {
    margin-top: 14px;
  }
  .margin-desktop-14-bot {
    margin-bottom: 14px;
  }
  .margin-desktop-14-left {
    margin-left: 14px;
  }
  .margin-desktop-14-right {
    margin-right: 14px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-14 {
    margin: -14px;
  }
  .negative-margin-desktop-14-vert {
    margin-bottom: -14px;
    margin-top: -14px;
  }
  .negative-margin-desktop-14-hor {
    margin-left: -14px;
    margin-right: -14px;
  }
  .negative-margin-desktop-14-top {
    margin-top: -14px;
  }
  .negative-margin-desktop-14-bot {
    margin-bottom: -14px;
  }
  .negative-margin-desktop-14-left {
    margin-left: -14px;
  }
  .negative-margin-desktop-14-right {
    margin-right: -14px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-14 {
    margin: 14px;
  }
  .margin-mobile-14-vert {
    margin-bottom: 14px;
    margin-top: 14px;
  }
  .margin-mobile-14-hor {
    margin-left: 14px;
    margin-right: 14px;
  }
  .margin-mobile-14-top {
    margin-top: 14px;
  }
  .margin-mobile-14-bot {
    margin-bottom: 14px;
  }
  .margin-mobile-14-left {
    margin-left: 14px;
  }
  .margin-mobile-14-right {
    margin-right: 14px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-14 {
    margin: -14px;
  }
  .negative-margin-mobile-14-vert {
    margin-bottom: -14px;
    margin-top: -14px;
  }
  .negative-margin-mobile-14-hor {
    margin-left: -14px;
    margin-right: -14px;
  }
  .negative-margin-mobile-14-top {
    margin-top: -14px;
  }
  .negative-margin-mobile-14-bot {
    margin-bottom: -14px;
  }
  .negative-margin-mobile-14-left {
    margin-left: -14px;
  }
  .negative-margin-mobile-14-right {
    margin-right: -14px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-16 {
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }
  .pdd-mobile-16-vert {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .pdd-mobile-16-hor {
    padding-left: 16px;
    padding-right: 16px;
  }
  .pdd-mobile-16-top {
    padding-top: 16px;
  }
  .pdd-mobile-16-bot {
    padding-bottom: 16px;
  }
  .pdd-mobile-16-left {
    padding-left: 16px;
  }
  .pdd-mobile-16-right {
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-16 {
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }
  .pdd-desktop-16-vert {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .pdd-desktop-16-hor {
    padding-left: 16px;
    padding-right: 16px;
  }
  .pdd-desktop-16-top {
    padding-top: 16px;
  }
  .pdd-desktop-16-bot {
    padding-bottom: 16px;
  }
  .pdd-desktop-16-left {
    padding-left: 16px;
  }
  .pdd-desktop-16-right {
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-16 {
    margin: 16px;
  }
  .margin-desktop-16-vert {
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .margin-desktop-16-hor {
    margin-left: 16px;
    margin-right: 16px;
  }
  .margin-desktop-16-top {
    margin-top: 16px;
  }
  .margin-desktop-16-bot {
    margin-bottom: 16px;
  }
  .margin-desktop-16-left {
    margin-left: 16px;
  }
  .margin-desktop-16-right {
    margin-right: 16px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-16 {
    margin: -16px;
  }
  .negative-margin-desktop-16-vert {
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .negative-margin-desktop-16-hor {
    margin-left: -16px;
    margin-right: -16px;
  }
  .negative-margin-desktop-16-top {
    margin-top: -16px;
  }
  .negative-margin-desktop-16-bot {
    margin-bottom: -16px;
  }
  .negative-margin-desktop-16-left {
    margin-left: -16px;
  }
  .negative-margin-desktop-16-right {
    margin-right: -16px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-16 {
    margin: 16px;
  }
  .margin-mobile-16-vert {
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .margin-mobile-16-hor {
    margin-left: 16px;
    margin-right: 16px;
  }
  .margin-mobile-16-top {
    margin-top: 16px;
  }
  .margin-mobile-16-bot {
    margin-bottom: 16px;
  }
  .margin-mobile-16-left {
    margin-left: 16px;
  }
  .margin-mobile-16-right {
    margin-right: 16px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-16 {
    margin: -16px;
  }
  .negative-margin-mobile-16-vert {
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .negative-margin-mobile-16-hor {
    margin-left: -16px;
    margin-right: -16px;
  }
  .negative-margin-mobile-16-top {
    margin-top: -16px;
  }
  .negative-margin-mobile-16-bot {
    margin-bottom: -16px;
  }
  .negative-margin-mobile-16-left {
    margin-left: -16px;
  }
  .negative-margin-mobile-16-right {
    margin-right: -16px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-18 {
    padding-bottom: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 18px;
  }
  .pdd-mobile-18-vert {
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .pdd-mobile-18-hor {
    padding-left: 18px;
    padding-right: 18px;
  }
  .pdd-mobile-18-top {
    padding-top: 18px;
  }
  .pdd-mobile-18-bot {
    padding-bottom: 18px;
  }
  .pdd-mobile-18-left {
    padding-left: 18px;
  }
  .pdd-mobile-18-right {
    padding-right: 18px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-18 {
    padding-bottom: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 18px;
  }
  .pdd-desktop-18-vert {
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .pdd-desktop-18-hor {
    padding-left: 18px;
    padding-right: 18px;
  }
  .pdd-desktop-18-top {
    padding-top: 18px;
  }
  .pdd-desktop-18-bot {
    padding-bottom: 18px;
  }
  .pdd-desktop-18-left {
    padding-left: 18px;
  }
  .pdd-desktop-18-right {
    padding-right: 18px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-18 {
    margin: 18px;
  }
  .margin-desktop-18-vert {
    margin-bottom: 18px;
    margin-top: 18px;
  }
  .margin-desktop-18-hor {
    margin-left: 18px;
    margin-right: 18px;
  }
  .margin-desktop-18-top {
    margin-top: 18px;
  }
  .margin-desktop-18-bot {
    margin-bottom: 18px;
  }
  .margin-desktop-18-left {
    margin-left: 18px;
  }
  .margin-desktop-18-right {
    margin-right: 18px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-18 {
    margin: -18px;
  }
  .negative-margin-desktop-18-vert {
    margin-bottom: -18px;
    margin-top: -18px;
  }
  .negative-margin-desktop-18-hor {
    margin-left: -18px;
    margin-right: -18px;
  }
  .negative-margin-desktop-18-top {
    margin-top: -18px;
  }
  .negative-margin-desktop-18-bot {
    margin-bottom: -18px;
  }
  .negative-margin-desktop-18-left {
    margin-left: -18px;
  }
  .negative-margin-desktop-18-right {
    margin-right: -18px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-18 {
    margin: 18px;
  }
  .margin-mobile-18-vert {
    margin-bottom: 18px;
    margin-top: 18px;
  }
  .margin-mobile-18-hor {
    margin-left: 18px;
    margin-right: 18px;
  }
  .margin-mobile-18-top {
    margin-top: 18px;
  }
  .margin-mobile-18-bot {
    margin-bottom: 18px;
  }
  .margin-mobile-18-left {
    margin-left: 18px;
  }
  .margin-mobile-18-right {
    margin-right: 18px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-18 {
    margin: -18px;
  }
  .negative-margin-mobile-18-vert {
    margin-bottom: -18px;
    margin-top: -18px;
  }
  .negative-margin-mobile-18-hor {
    margin-left: -18px;
    margin-right: -18px;
  }
  .negative-margin-mobile-18-top {
    margin-top: -18px;
  }
  .negative-margin-mobile-18-bot {
    margin-bottom: -18px;
  }
  .negative-margin-mobile-18-left {
    margin-left: -18px;
  }
  .negative-margin-mobile-18-right {
    margin-right: -18px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-20 {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
  .pdd-mobile-20-vert {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .pdd-mobile-20-hor {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pdd-mobile-20-top {
    padding-top: 20px;
  }
  .pdd-mobile-20-bot {
    padding-bottom: 20px;
  }
  .pdd-mobile-20-left {
    padding-left: 20px;
  }
  .pdd-mobile-20-right {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-20 {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
  .pdd-desktop-20-vert {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .pdd-desktop-20-hor {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pdd-desktop-20-top {
    padding-top: 20px;
  }
  .pdd-desktop-20-bot {
    padding-bottom: 20px;
  }
  .pdd-desktop-20-left {
    padding-left: 20px;
  }
  .pdd-desktop-20-right {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-20 {
    margin: 20px;
  }
  .margin-desktop-20-vert {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .margin-desktop-20-hor {
    margin-left: 20px;
    margin-right: 20px;
  }
  .margin-desktop-20-top {
    margin-top: 20px;
  }
  .margin-desktop-20-bot {
    margin-bottom: 20px;
  }
  .margin-desktop-20-left {
    margin-left: 20px;
  }
  .margin-desktop-20-right {
    margin-right: 20px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-20 {
    margin: -20px;
  }
  .negative-margin-desktop-20-vert {
    margin-bottom: -20px;
    margin-top: -20px;
  }
  .negative-margin-desktop-20-hor {
    margin-left: -20px;
    margin-right: -20px;
  }
  .negative-margin-desktop-20-top {
    margin-top: -20px;
  }
  .negative-margin-desktop-20-bot {
    margin-bottom: -20px;
  }
  .negative-margin-desktop-20-left {
    margin-left: -20px;
  }
  .negative-margin-desktop-20-right {
    margin-right: -20px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-20 {
    margin: 20px;
  }
  .margin-mobile-20-vert {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .margin-mobile-20-hor {
    margin-left: 20px;
    margin-right: 20px;
  }
  .margin-mobile-20-top {
    margin-top: 20px;
  }
  .margin-mobile-20-bot {
    margin-bottom: 20px;
  }
  .margin-mobile-20-left {
    margin-left: 20px;
  }
  .margin-mobile-20-right {
    margin-right: 20px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-20 {
    margin: -20px;
  }
  .negative-margin-mobile-20-vert {
    margin-bottom: -20px;
    margin-top: -20px;
  }
  .negative-margin-mobile-20-hor {
    margin-left: -20px;
    margin-right: -20px;
  }
  .negative-margin-mobile-20-top {
    margin-top: -20px;
  }
  .negative-margin-mobile-20-bot {
    margin-bottom: -20px;
  }
  .negative-margin-mobile-20-left {
    margin-left: -20px;
  }
  .negative-margin-mobile-20-right {
    margin-right: -20px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-24 {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }
  .pdd-mobile-24-vert {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .pdd-mobile-24-hor {
    padding-left: 24px;
    padding-right: 24px;
  }
  .pdd-mobile-24-top {
    padding-top: 24px;
  }
  .pdd-mobile-24-bot {
    padding-bottom: 24px;
  }
  .pdd-mobile-24-left {
    padding-left: 24px;
  }
  .pdd-mobile-24-right {
    padding-right: 24px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-24 {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }
  .pdd-desktop-24-vert {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .pdd-desktop-24-hor {
    padding-left: 24px;
    padding-right: 24px;
  }
  .pdd-desktop-24-top {
    padding-top: 24px;
  }
  .pdd-desktop-24-bot {
    padding-bottom: 24px;
  }
  .pdd-desktop-24-left {
    padding-left: 24px;
  }
  .pdd-desktop-24-right {
    padding-right: 24px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-24 {
    margin: 24px;
  }
  .margin-desktop-24-vert {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .margin-desktop-24-hor {
    margin-left: 24px;
    margin-right: 24px;
  }
  .margin-desktop-24-top {
    margin-top: 24px;
  }
  .margin-desktop-24-bot {
    margin-bottom: 24px;
  }
  .margin-desktop-24-left {
    margin-left: 24px;
  }
  .margin-desktop-24-right {
    margin-right: 24px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-24 {
    margin: -24px;
  }
  .negative-margin-desktop-24-vert {
    margin-bottom: -24px;
    margin-top: -24px;
  }
  .negative-margin-desktop-24-hor {
    margin-left: -24px;
    margin-right: -24px;
  }
  .negative-margin-desktop-24-top {
    margin-top: -24px;
  }
  .negative-margin-desktop-24-bot {
    margin-bottom: -24px;
  }
  .negative-margin-desktop-24-left {
    margin-left: -24px;
  }
  .negative-margin-desktop-24-right {
    margin-right: -24px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-24 {
    margin: 24px;
  }
  .margin-mobile-24-vert {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .margin-mobile-24-hor {
    margin-left: 24px;
    margin-right: 24px;
  }
  .margin-mobile-24-top {
    margin-top: 24px;
  }
  .margin-mobile-24-bot {
    margin-bottom: 24px;
  }
  .margin-mobile-24-left {
    margin-left: 24px;
  }
  .margin-mobile-24-right {
    margin-right: 24px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-24 {
    margin: -24px;
  }
  .negative-margin-mobile-24-vert {
    margin-bottom: -24px;
    margin-top: -24px;
  }
  .negative-margin-mobile-24-hor {
    margin-left: -24px;
    margin-right: -24px;
  }
  .negative-margin-mobile-24-top {
    margin-top: -24px;
  }
  .negative-margin-mobile-24-bot {
    margin-bottom: -24px;
  }
  .negative-margin-mobile-24-left {
    margin-left: -24px;
  }
  .negative-margin-mobile-24-right {
    margin-right: -24px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-26 {
    padding-bottom: 26px;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 26px;
  }
  .pdd-mobile-26-vert {
    padding-bottom: 26px;
    padding-top: 26px;
  }
  .pdd-mobile-26-hor {
    padding-left: 26px;
    padding-right: 26px;
  }
  .pdd-mobile-26-top {
    padding-top: 26px;
  }
  .pdd-mobile-26-bot {
    padding-bottom: 26px;
  }
  .pdd-mobile-26-left {
    padding-left: 26px;
  }
  .pdd-mobile-26-right {
    padding-right: 26px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-26 {
    padding-bottom: 26px;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 26px;
  }
  .pdd-desktop-26-vert {
    padding-bottom: 26px;
    padding-top: 26px;
  }
  .pdd-desktop-26-hor {
    padding-left: 26px;
    padding-right: 26px;
  }
  .pdd-desktop-26-top {
    padding-top: 26px;
  }
  .pdd-desktop-26-bot {
    padding-bottom: 26px;
  }
  .pdd-desktop-26-left {
    padding-left: 26px;
  }
  .pdd-desktop-26-right {
    padding-right: 26px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-26 {
    margin: 26px;
  }
  .margin-desktop-26-vert {
    margin-bottom: 26px;
    margin-top: 26px;
  }
  .margin-desktop-26-hor {
    margin-left: 26px;
    margin-right: 26px;
  }
  .margin-desktop-26-top {
    margin-top: 26px;
  }
  .margin-desktop-26-bot {
    margin-bottom: 26px;
  }
  .margin-desktop-26-left {
    margin-left: 26px;
  }
  .margin-desktop-26-right {
    margin-right: 26px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-26 {
    margin: -26px;
  }
  .negative-margin-desktop-26-vert {
    margin-bottom: -26px;
    margin-top: -26px;
  }
  .negative-margin-desktop-26-hor {
    margin-left: -26px;
    margin-right: -26px;
  }
  .negative-margin-desktop-26-top {
    margin-top: -26px;
  }
  .negative-margin-desktop-26-bot {
    margin-bottom: -26px;
  }
  .negative-margin-desktop-26-left {
    margin-left: -26px;
  }
  .negative-margin-desktop-26-right {
    margin-right: -26px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-26 {
    margin: 26px;
  }
  .margin-mobile-26-vert {
    margin-bottom: 26px;
    margin-top: 26px;
  }
  .margin-mobile-26-hor {
    margin-left: 26px;
    margin-right: 26px;
  }
  .margin-mobile-26-top {
    margin-top: 26px;
  }
  .margin-mobile-26-bot {
    margin-bottom: 26px;
  }
  .margin-mobile-26-left {
    margin-left: 26px;
  }
  .margin-mobile-26-right {
    margin-right: 26px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-26 {
    margin: -26px;
  }
  .negative-margin-mobile-26-vert {
    margin-bottom: -26px;
    margin-top: -26px;
  }
  .negative-margin-mobile-26-hor {
    margin-left: -26px;
    margin-right: -26px;
  }
  .negative-margin-mobile-26-top {
    margin-top: -26px;
  }
  .negative-margin-mobile-26-bot {
    margin-bottom: -26px;
  }
  .negative-margin-mobile-26-left {
    margin-left: -26px;
  }
  .negative-margin-mobile-26-right {
    margin-right: -26px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-28 {
    padding-bottom: 28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 28px;
  }
  .pdd-mobile-28-vert {
    padding-bottom: 28px;
    padding-top: 28px;
  }
  .pdd-mobile-28-hor {
    padding-left: 28px;
    padding-right: 28px;
  }
  .pdd-mobile-28-top {
    padding-top: 28px;
  }
  .pdd-mobile-28-bot {
    padding-bottom: 28px;
  }
  .pdd-mobile-28-left {
    padding-left: 28px;
  }
  .pdd-mobile-28-right {
    padding-right: 28px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-28 {
    padding-bottom: 28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 28px;
  }
  .pdd-desktop-28-vert {
    padding-bottom: 28px;
    padding-top: 28px;
  }
  .pdd-desktop-28-hor {
    padding-left: 28px;
    padding-right: 28px;
  }
  .pdd-desktop-28-top {
    padding-top: 28px;
  }
  .pdd-desktop-28-bot {
    padding-bottom: 28px;
  }
  .pdd-desktop-28-left {
    padding-left: 28px;
  }
  .pdd-desktop-28-right {
    padding-right: 28px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-28 {
    margin: 28px;
  }
  .margin-desktop-28-vert {
    margin-bottom: 28px;
    margin-top: 28px;
  }
  .margin-desktop-28-hor {
    margin-left: 28px;
    margin-right: 28px;
  }
  .margin-desktop-28-top {
    margin-top: 28px;
  }
  .margin-desktop-28-bot {
    margin-bottom: 28px;
  }
  .margin-desktop-28-left {
    margin-left: 28px;
  }
  .margin-desktop-28-right {
    margin-right: 28px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-28 {
    margin: -28px;
  }
  .negative-margin-desktop-28-vert {
    margin-bottom: -28px;
    margin-top: -28px;
  }
  .negative-margin-desktop-28-hor {
    margin-left: -28px;
    margin-right: -28px;
  }
  .negative-margin-desktop-28-top {
    margin-top: -28px;
  }
  .negative-margin-desktop-28-bot {
    margin-bottom: -28px;
  }
  .negative-margin-desktop-28-left {
    margin-left: -28px;
  }
  .negative-margin-desktop-28-right {
    margin-right: -28px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-28 {
    margin: 28px;
  }
  .margin-mobile-28-vert {
    margin-bottom: 28px;
    margin-top: 28px;
  }
  .margin-mobile-28-hor {
    margin-left: 28px;
    margin-right: 28px;
  }
  .margin-mobile-28-top {
    margin-top: 28px;
  }
  .margin-mobile-28-bot {
    margin-bottom: 28px;
  }
  .margin-mobile-28-left {
    margin-left: 28px;
  }
  .margin-mobile-28-right {
    margin-right: 28px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-28 {
    margin: -28px;
  }
  .negative-margin-mobile-28-vert {
    margin-bottom: -28px;
    margin-top: -28px;
  }
  .negative-margin-mobile-28-hor {
    margin-left: -28px;
    margin-right: -28px;
  }
  .negative-margin-mobile-28-top {
    margin-top: -28px;
  }
  .negative-margin-mobile-28-bot {
    margin-bottom: -28px;
  }
  .negative-margin-mobile-28-left {
    margin-left: -28px;
  }
  .negative-margin-mobile-28-right {
    margin-right: -28px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-32 {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
  }
  .pdd-mobile-32-vert {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  .pdd-mobile-32-hor {
    padding-left: 32px;
    padding-right: 32px;
  }
  .pdd-mobile-32-top {
    padding-top: 32px;
  }
  .pdd-mobile-32-bot {
    padding-bottom: 32px;
  }
  .pdd-mobile-32-left {
    padding-left: 32px;
  }
  .pdd-mobile-32-right {
    padding-right: 32px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-32 {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
  }
  .pdd-desktop-32-vert {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  .pdd-desktop-32-hor {
    padding-left: 32px;
    padding-right: 32px;
  }
  .pdd-desktop-32-top {
    padding-top: 32px;
  }
  .pdd-desktop-32-bot {
    padding-bottom: 32px;
  }
  .pdd-desktop-32-left {
    padding-left: 32px;
  }
  .pdd-desktop-32-right {
    padding-right: 32px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-32 {
    margin: 32px;
  }
  .margin-desktop-32-vert {
    margin-bottom: 32px;
    margin-top: 32px;
  }
  .margin-desktop-32-hor {
    margin-left: 32px;
    margin-right: 32px;
  }
  .margin-desktop-32-top {
    margin-top: 32px;
  }
  .margin-desktop-32-bot {
    margin-bottom: 32px;
  }
  .margin-desktop-32-left {
    margin-left: 32px;
  }
  .margin-desktop-32-right {
    margin-right: 32px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-32 {
    margin: -32px;
  }
  .negative-margin-desktop-32-vert {
    margin-bottom: -32px;
    margin-top: -32px;
  }
  .negative-margin-desktop-32-hor {
    margin-left: -32px;
    margin-right: -32px;
  }
  .negative-margin-desktop-32-top {
    margin-top: -32px;
  }
  .negative-margin-desktop-32-bot {
    margin-bottom: -32px;
  }
  .negative-margin-desktop-32-left {
    margin-left: -32px;
  }
  .negative-margin-desktop-32-right {
    margin-right: -32px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-32 {
    margin: 32px;
  }
  .margin-mobile-32-vert {
    margin-bottom: 32px;
    margin-top: 32px;
  }
  .margin-mobile-32-hor {
    margin-left: 32px;
    margin-right: 32px;
  }
  .margin-mobile-32-top {
    margin-top: 32px;
  }
  .margin-mobile-32-bot {
    margin-bottom: 32px;
  }
  .margin-mobile-32-left {
    margin-left: 32px;
  }
  .margin-mobile-32-right {
    margin-right: 32px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-32 {
    margin: -32px;
  }
  .negative-margin-mobile-32-vert {
    margin-bottom: -32px;
    margin-top: -32px;
  }
  .negative-margin-mobile-32-hor {
    margin-left: -32px;
    margin-right: -32px;
  }
  .negative-margin-mobile-32-top {
    margin-top: -32px;
  }
  .negative-margin-mobile-32-bot {
    margin-bottom: -32px;
  }
  .negative-margin-mobile-32-left {
    margin-left: -32px;
  }
  .negative-margin-mobile-32-right {
    margin-right: -32px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-36 {
    padding-bottom: 36px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 36px;
  }
  .pdd-mobile-36-vert {
    padding-bottom: 36px;
    padding-top: 36px;
  }
  .pdd-mobile-36-hor {
    padding-left: 36px;
    padding-right: 36px;
  }
  .pdd-mobile-36-top {
    padding-top: 36px;
  }
  .pdd-mobile-36-bot {
    padding-bottom: 36px;
  }
  .pdd-mobile-36-left {
    padding-left: 36px;
  }
  .pdd-mobile-36-right {
    padding-right: 36px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-36 {
    padding-bottom: 36px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 36px;
  }
  .pdd-desktop-36-vert {
    padding-bottom: 36px;
    padding-top: 36px;
  }
  .pdd-desktop-36-hor {
    padding-left: 36px;
    padding-right: 36px;
  }
  .pdd-desktop-36-top {
    padding-top: 36px;
  }
  .pdd-desktop-36-bot {
    padding-bottom: 36px;
  }
  .pdd-desktop-36-left {
    padding-left: 36px;
  }
  .pdd-desktop-36-right {
    padding-right: 36px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-36 {
    margin: 36px;
  }
  .margin-desktop-36-vert {
    margin-bottom: 36px;
    margin-top: 36px;
  }
  .margin-desktop-36-hor {
    margin-left: 36px;
    margin-right: 36px;
  }
  .margin-desktop-36-top {
    margin-top: 36px;
  }
  .margin-desktop-36-bot {
    margin-bottom: 36px;
  }
  .margin-desktop-36-left {
    margin-left: 36px;
  }
  .margin-desktop-36-right {
    margin-right: 36px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-36 {
    margin: -36px;
  }
  .negative-margin-desktop-36-vert {
    margin-bottom: -36px;
    margin-top: -36px;
  }
  .negative-margin-desktop-36-hor {
    margin-left: -36px;
    margin-right: -36px;
  }
  .negative-margin-desktop-36-top {
    margin-top: -36px;
  }
  .negative-margin-desktop-36-bot {
    margin-bottom: -36px;
  }
  .negative-margin-desktop-36-left {
    margin-left: -36px;
  }
  .negative-margin-desktop-36-right {
    margin-right: -36px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-36 {
    margin: 36px;
  }
  .margin-mobile-36-vert {
    margin-bottom: 36px;
    margin-top: 36px;
  }
  .margin-mobile-36-hor {
    margin-left: 36px;
    margin-right: 36px;
  }
  .margin-mobile-36-top {
    margin-top: 36px;
  }
  .margin-mobile-36-bot {
    margin-bottom: 36px;
  }
  .margin-mobile-36-left {
    margin-left: 36px;
  }
  .margin-mobile-36-right {
    margin-right: 36px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-36 {
    margin: -36px;
  }
  .negative-margin-mobile-36-vert {
    margin-bottom: -36px;
    margin-top: -36px;
  }
  .negative-margin-mobile-36-hor {
    margin-left: -36px;
    margin-right: -36px;
  }
  .negative-margin-mobile-36-top {
    margin-top: -36px;
  }
  .negative-margin-mobile-36-bot {
    margin-bottom: -36px;
  }
  .negative-margin-mobile-36-left {
    margin-left: -36px;
  }
  .negative-margin-mobile-36-right {
    margin-right: -36px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-38 {
    padding-bottom: 38px;
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 38px;
  }
  .pdd-mobile-38-vert {
    padding-bottom: 38px;
    padding-top: 38px;
  }
  .pdd-mobile-38-hor {
    padding-left: 38px;
    padding-right: 38px;
  }
  .pdd-mobile-38-top {
    padding-top: 38px;
  }
  .pdd-mobile-38-bot {
    padding-bottom: 38px;
  }
  .pdd-mobile-38-left {
    padding-left: 38px;
  }
  .pdd-mobile-38-right {
    padding-right: 38px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-38 {
    padding-bottom: 38px;
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 38px;
  }
  .pdd-desktop-38-vert {
    padding-bottom: 38px;
    padding-top: 38px;
  }
  .pdd-desktop-38-hor {
    padding-left: 38px;
    padding-right: 38px;
  }
  .pdd-desktop-38-top {
    padding-top: 38px;
  }
  .pdd-desktop-38-bot {
    padding-bottom: 38px;
  }
  .pdd-desktop-38-left {
    padding-left: 38px;
  }
  .pdd-desktop-38-right {
    padding-right: 38px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-38 {
    margin: 38px;
  }
  .margin-desktop-38-vert {
    margin-bottom: 38px;
    margin-top: 38px;
  }
  .margin-desktop-38-hor {
    margin-left: 38px;
    margin-right: 38px;
  }
  .margin-desktop-38-top {
    margin-top: 38px;
  }
  .margin-desktop-38-bot {
    margin-bottom: 38px;
  }
  .margin-desktop-38-left {
    margin-left: 38px;
  }
  .margin-desktop-38-right {
    margin-right: 38px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-38 {
    margin: -38px;
  }
  .negative-margin-desktop-38-vert {
    margin-bottom: -38px;
    margin-top: -38px;
  }
  .negative-margin-desktop-38-hor {
    margin-left: -38px;
    margin-right: -38px;
  }
  .negative-margin-desktop-38-top {
    margin-top: -38px;
  }
  .negative-margin-desktop-38-bot {
    margin-bottom: -38px;
  }
  .negative-margin-desktop-38-left {
    margin-left: -38px;
  }
  .negative-margin-desktop-38-right {
    margin-right: -38px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-38 {
    margin: 38px;
  }
  .margin-mobile-38-vert {
    margin-bottom: 38px;
    margin-top: 38px;
  }
  .margin-mobile-38-hor {
    margin-left: 38px;
    margin-right: 38px;
  }
  .margin-mobile-38-top {
    margin-top: 38px;
  }
  .margin-mobile-38-bot {
    margin-bottom: 38px;
  }
  .margin-mobile-38-left {
    margin-left: 38px;
  }
  .margin-mobile-38-right {
    margin-right: 38px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-38 {
    margin: -38px;
  }
  .negative-margin-mobile-38-vert {
    margin-bottom: -38px;
    margin-top: -38px;
  }
  .negative-margin-mobile-38-hor {
    margin-left: -38px;
    margin-right: -38px;
  }
  .negative-margin-mobile-38-top {
    margin-top: -38px;
  }
  .negative-margin-mobile-38-bot {
    margin-bottom: -38px;
  }
  .negative-margin-mobile-38-left {
    margin-left: -38px;
  }
  .negative-margin-mobile-38-right {
    margin-right: -38px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-40 {
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
  }
  .pdd-mobile-40-vert {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .pdd-mobile-40-hor {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pdd-mobile-40-top {
    padding-top: 40px;
  }
  .pdd-mobile-40-bot {
    padding-bottom: 40px;
  }
  .pdd-mobile-40-left {
    padding-left: 40px;
  }
  .pdd-mobile-40-right {
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-40 {
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
  }
  .pdd-desktop-40-vert {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .pdd-desktop-40-hor {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pdd-desktop-40-top {
    padding-top: 40px;
  }
  .pdd-desktop-40-bot {
    padding-bottom: 40px;
  }
  .pdd-desktop-40-left {
    padding-left: 40px;
  }
  .pdd-desktop-40-right {
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-40 {
    margin: 40px;
  }
  .margin-desktop-40-vert {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .margin-desktop-40-hor {
    margin-left: 40px;
    margin-right: 40px;
  }
  .margin-desktop-40-top {
    margin-top: 40px;
  }
  .margin-desktop-40-bot {
    margin-bottom: 40px;
  }
  .margin-desktop-40-left {
    margin-left: 40px;
  }
  .margin-desktop-40-right {
    margin-right: 40px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-40 {
    margin: -40px;
  }
  .negative-margin-desktop-40-vert {
    margin-bottom: -40px;
    margin-top: -40px;
  }
  .negative-margin-desktop-40-hor {
    margin-left: -40px;
    margin-right: -40px;
  }
  .negative-margin-desktop-40-top {
    margin-top: -40px;
  }
  .negative-margin-desktop-40-bot {
    margin-bottom: -40px;
  }
  .negative-margin-desktop-40-left {
    margin-left: -40px;
  }
  .negative-margin-desktop-40-right {
    margin-right: -40px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-40 {
    margin: 40px;
  }
  .margin-mobile-40-vert {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .margin-mobile-40-hor {
    margin-left: 40px;
    margin-right: 40px;
  }
  .margin-mobile-40-top {
    margin-top: 40px;
  }
  .margin-mobile-40-bot {
    margin-bottom: 40px;
  }
  .margin-mobile-40-left {
    margin-left: 40px;
  }
  .margin-mobile-40-right {
    margin-right: 40px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-40 {
    margin: -40px;
  }
  .negative-margin-mobile-40-vert {
    margin-bottom: -40px;
    margin-top: -40px;
  }
  .negative-margin-mobile-40-hor {
    margin-left: -40px;
    margin-right: -40px;
  }
  .negative-margin-mobile-40-top {
    margin-top: -40px;
  }
  .negative-margin-mobile-40-bot {
    margin-bottom: -40px;
  }
  .negative-margin-mobile-40-left {
    margin-left: -40px;
  }
  .negative-margin-mobile-40-right {
    margin-right: -40px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-44 {
    padding-bottom: 44px;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 44px;
  }
  .pdd-mobile-44-vert {
    padding-bottom: 44px;
    padding-top: 44px;
  }
  .pdd-mobile-44-hor {
    padding-left: 44px;
    padding-right: 44px;
  }
  .pdd-mobile-44-top {
    padding-top: 44px;
  }
  .pdd-mobile-44-bot {
    padding-bottom: 44px;
  }
  .pdd-mobile-44-left {
    padding-left: 44px;
  }
  .pdd-mobile-44-right {
    padding-right: 44px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-44 {
    padding-bottom: 44px;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 44px;
  }
  .pdd-desktop-44-vert {
    padding-bottom: 44px;
    padding-top: 44px;
  }
  .pdd-desktop-44-hor {
    padding-left: 44px;
    padding-right: 44px;
  }
  .pdd-desktop-44-top {
    padding-top: 44px;
  }
  .pdd-desktop-44-bot {
    padding-bottom: 44px;
  }
  .pdd-desktop-44-left {
    padding-left: 44px;
  }
  .pdd-desktop-44-right {
    padding-right: 44px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-44 {
    margin: 44px;
  }
  .margin-desktop-44-vert {
    margin-bottom: 44px;
    margin-top: 44px;
  }
  .margin-desktop-44-hor {
    margin-left: 44px;
    margin-right: 44px;
  }
  .margin-desktop-44-top {
    margin-top: 44px;
  }
  .margin-desktop-44-bot {
    margin-bottom: 44px;
  }
  .margin-desktop-44-left {
    margin-left: 44px;
  }
  .margin-desktop-44-right {
    margin-right: 44px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-44 {
    margin: -44px;
  }
  .negative-margin-desktop-44-vert {
    margin-bottom: -44px;
    margin-top: -44px;
  }
  .negative-margin-desktop-44-hor {
    margin-left: -44px;
    margin-right: -44px;
  }
  .negative-margin-desktop-44-top {
    margin-top: -44px;
  }
  .negative-margin-desktop-44-bot {
    margin-bottom: -44px;
  }
  .negative-margin-desktop-44-left {
    margin-left: -44px;
  }
  .negative-margin-desktop-44-right {
    margin-right: -44px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-44 {
    margin: 44px;
  }
  .margin-mobile-44-vert {
    margin-bottom: 44px;
    margin-top: 44px;
  }
  .margin-mobile-44-hor {
    margin-left: 44px;
    margin-right: 44px;
  }
  .margin-mobile-44-top {
    margin-top: 44px;
  }
  .margin-mobile-44-bot {
    margin-bottom: 44px;
  }
  .margin-mobile-44-left {
    margin-left: 44px;
  }
  .margin-mobile-44-right {
    margin-right: 44px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-44 {
    margin: -44px;
  }
  .negative-margin-mobile-44-vert {
    margin-bottom: -44px;
    margin-top: -44px;
  }
  .negative-margin-mobile-44-hor {
    margin-left: -44px;
    margin-right: -44px;
  }
  .negative-margin-mobile-44-top {
    margin-top: -44px;
  }
  .negative-margin-mobile-44-bot {
    margin-bottom: -44px;
  }
  .negative-margin-mobile-44-left {
    margin-left: -44px;
  }
  .negative-margin-mobile-44-right {
    margin-right: -44px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-48 {
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 48px;
  }
  .pdd-mobile-48-vert {
    padding-bottom: 48px;
    padding-top: 48px;
  }
  .pdd-mobile-48-hor {
    padding-left: 48px;
    padding-right: 48px;
  }
  .pdd-mobile-48-top {
    padding-top: 48px;
  }
  .pdd-mobile-48-bot {
    padding-bottom: 48px;
  }
  .pdd-mobile-48-left {
    padding-left: 48px;
  }
  .pdd-mobile-48-right {
    padding-right: 48px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-48 {
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 48px;
  }
  .pdd-desktop-48-vert {
    padding-bottom: 48px;
    padding-top: 48px;
  }
  .pdd-desktop-48-hor {
    padding-left: 48px;
    padding-right: 48px;
  }
  .pdd-desktop-48-top {
    padding-top: 48px;
  }
  .pdd-desktop-48-bot {
    padding-bottom: 48px;
  }
  .pdd-desktop-48-left {
    padding-left: 48px;
  }
  .pdd-desktop-48-right {
    padding-right: 48px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-48 {
    margin: 48px;
  }
  .margin-desktop-48-vert {
    margin-bottom: 48px;
    margin-top: 48px;
  }
  .margin-desktop-48-hor {
    margin-left: 48px;
    margin-right: 48px;
  }
  .margin-desktop-48-top {
    margin-top: 48px;
  }
  .margin-desktop-48-bot {
    margin-bottom: 48px;
  }
  .margin-desktop-48-left {
    margin-left: 48px;
  }
  .margin-desktop-48-right {
    margin-right: 48px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-48 {
    margin: -48px;
  }
  .negative-margin-desktop-48-vert {
    margin-bottom: -48px;
    margin-top: -48px;
  }
  .negative-margin-desktop-48-hor {
    margin-left: -48px;
    margin-right: -48px;
  }
  .negative-margin-desktop-48-top {
    margin-top: -48px;
  }
  .negative-margin-desktop-48-bot {
    margin-bottom: -48px;
  }
  .negative-margin-desktop-48-left {
    margin-left: -48px;
  }
  .negative-margin-desktop-48-right {
    margin-right: -48px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-48 {
    margin: 48px;
  }
  .margin-mobile-48-vert {
    margin-bottom: 48px;
    margin-top: 48px;
  }
  .margin-mobile-48-hor {
    margin-left: 48px;
    margin-right: 48px;
  }
  .margin-mobile-48-top {
    margin-top: 48px;
  }
  .margin-mobile-48-bot {
    margin-bottom: 48px;
  }
  .margin-mobile-48-left {
    margin-left: 48px;
  }
  .margin-mobile-48-right {
    margin-right: 48px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-48 {
    margin: -48px;
  }
  .negative-margin-mobile-48-vert {
    margin-bottom: -48px;
    margin-top: -48px;
  }
  .negative-margin-mobile-48-hor {
    margin-left: -48px;
    margin-right: -48px;
  }
  .negative-margin-mobile-48-top {
    margin-top: -48px;
  }
  .negative-margin-mobile-48-bot {
    margin-bottom: -48px;
  }
  .negative-margin-mobile-48-left {
    margin-left: -48px;
  }
  .negative-margin-mobile-48-right {
    margin-right: -48px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-56 {
    padding-bottom: 56px;
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 56px;
  }
  .pdd-mobile-56-vert {
    padding-bottom: 56px;
    padding-top: 56px;
  }
  .pdd-mobile-56-hor {
    padding-left: 56px;
    padding-right: 56px;
  }
  .pdd-mobile-56-top {
    padding-top: 56px;
  }
  .pdd-mobile-56-bot {
    padding-bottom: 56px;
  }
  .pdd-mobile-56-left {
    padding-left: 56px;
  }
  .pdd-mobile-56-right {
    padding-right: 56px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-56 {
    padding-bottom: 56px;
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 56px;
  }
  .pdd-desktop-56-vert {
    padding-bottom: 56px;
    padding-top: 56px;
  }
  .pdd-desktop-56-hor {
    padding-left: 56px;
    padding-right: 56px;
  }
  .pdd-desktop-56-top {
    padding-top: 56px;
  }
  .pdd-desktop-56-bot {
    padding-bottom: 56px;
  }
  .pdd-desktop-56-left {
    padding-left: 56px;
  }
  .pdd-desktop-56-right {
    padding-right: 56px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-56 {
    margin: 56px;
  }
  .margin-desktop-56-vert {
    margin-bottom: 56px;
    margin-top: 56px;
  }
  .margin-desktop-56-hor {
    margin-left: 56px;
    margin-right: 56px;
  }
  .margin-desktop-56-top {
    margin-top: 56px;
  }
  .margin-desktop-56-bot {
    margin-bottom: 56px;
  }
  .margin-desktop-56-left {
    margin-left: 56px;
  }
  .margin-desktop-56-right {
    margin-right: 56px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-56 {
    margin: -56px;
  }
  .negative-margin-desktop-56-vert {
    margin-bottom: -56px;
    margin-top: -56px;
  }
  .negative-margin-desktop-56-hor {
    margin-left: -56px;
    margin-right: -56px;
  }
  .negative-margin-desktop-56-top {
    margin-top: -56px;
  }
  .negative-margin-desktop-56-bot {
    margin-bottom: -56px;
  }
  .negative-margin-desktop-56-left {
    margin-left: -56px;
  }
  .negative-margin-desktop-56-right {
    margin-right: -56px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-56 {
    margin: 56px;
  }
  .margin-mobile-56-vert {
    margin-bottom: 56px;
    margin-top: 56px;
  }
  .margin-mobile-56-hor {
    margin-left: 56px;
    margin-right: 56px;
  }
  .margin-mobile-56-top {
    margin-top: 56px;
  }
  .margin-mobile-56-bot {
    margin-bottom: 56px;
  }
  .margin-mobile-56-left {
    margin-left: 56px;
  }
  .margin-mobile-56-right {
    margin-right: 56px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-56 {
    margin: -56px;
  }
  .negative-margin-mobile-56-vert {
    margin-bottom: -56px;
    margin-top: -56px;
  }
  .negative-margin-mobile-56-hor {
    margin-left: -56px;
    margin-right: -56px;
  }
  .negative-margin-mobile-56-top {
    margin-top: -56px;
  }
  .negative-margin-mobile-56-bot {
    margin-bottom: -56px;
  }
  .negative-margin-mobile-56-left {
    margin-left: -56px;
  }
  .negative-margin-mobile-56-right {
    margin-right: -56px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-60 {
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
  }
  .pdd-mobile-60-vert {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .pdd-mobile-60-hor {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pdd-mobile-60-top {
    padding-top: 60px;
  }
  .pdd-mobile-60-bot {
    padding-bottom: 60px;
  }
  .pdd-mobile-60-left {
    padding-left: 60px;
  }
  .pdd-mobile-60-right {
    padding-right: 60px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-60 {
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
  }
  .pdd-desktop-60-vert {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .pdd-desktop-60-hor {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pdd-desktop-60-top {
    padding-top: 60px;
  }
  .pdd-desktop-60-bot {
    padding-bottom: 60px;
  }
  .pdd-desktop-60-left {
    padding-left: 60px;
  }
  .pdd-desktop-60-right {
    padding-right: 60px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-60 {
    margin: 60px;
  }
  .margin-desktop-60-vert {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .margin-desktop-60-hor {
    margin-left: 60px;
    margin-right: 60px;
  }
  .margin-desktop-60-top {
    margin-top: 60px;
  }
  .margin-desktop-60-bot {
    margin-bottom: 60px;
  }
  .margin-desktop-60-left {
    margin-left: 60px;
  }
  .margin-desktop-60-right {
    margin-right: 60px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-60 {
    margin: -60px;
  }
  .negative-margin-desktop-60-vert {
    margin-bottom: -60px;
    margin-top: -60px;
  }
  .negative-margin-desktop-60-hor {
    margin-left: -60px;
    margin-right: -60px;
  }
  .negative-margin-desktop-60-top {
    margin-top: -60px;
  }
  .negative-margin-desktop-60-bot {
    margin-bottom: -60px;
  }
  .negative-margin-desktop-60-left {
    margin-left: -60px;
  }
  .negative-margin-desktop-60-right {
    margin-right: -60px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-60 {
    margin: 60px;
  }
  .margin-mobile-60-vert {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .margin-mobile-60-hor {
    margin-left: 60px;
    margin-right: 60px;
  }
  .margin-mobile-60-top {
    margin-top: 60px;
  }
  .margin-mobile-60-bot {
    margin-bottom: 60px;
  }
  .margin-mobile-60-left {
    margin-left: 60px;
  }
  .margin-mobile-60-right {
    margin-right: 60px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-60 {
    margin: -60px;
  }
  .negative-margin-mobile-60-vert {
    margin-bottom: -60px;
    margin-top: -60px;
  }
  .negative-margin-mobile-60-hor {
    margin-left: -60px;
    margin-right: -60px;
  }
  .negative-margin-mobile-60-top {
    margin-top: -60px;
  }
  .negative-margin-mobile-60-bot {
    margin-bottom: -60px;
  }
  .negative-margin-mobile-60-left {
    margin-left: -60px;
  }
  .negative-margin-mobile-60-right {
    margin-right: -60px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-64 {
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 64px;
  }
  .pdd-mobile-64-vert {
    padding-bottom: 64px;
    padding-top: 64px;
  }
  .pdd-mobile-64-hor {
    padding-left: 64px;
    padding-right: 64px;
  }
  .pdd-mobile-64-top {
    padding-top: 64px;
  }
  .pdd-mobile-64-bot {
    padding-bottom: 64px;
  }
  .pdd-mobile-64-left {
    padding-left: 64px;
  }
  .pdd-mobile-64-right {
    padding-right: 64px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-64 {
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 64px;
  }
  .pdd-desktop-64-vert {
    padding-bottom: 64px;
    padding-top: 64px;
  }
  .pdd-desktop-64-hor {
    padding-left: 64px;
    padding-right: 64px;
  }
  .pdd-desktop-64-top {
    padding-top: 64px;
  }
  .pdd-desktop-64-bot {
    padding-bottom: 64px;
  }
  .pdd-desktop-64-left {
    padding-left: 64px;
  }
  .pdd-desktop-64-right {
    padding-right: 64px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-64 {
    margin: 64px;
  }
  .margin-desktop-64-vert {
    margin-bottom: 64px;
    margin-top: 64px;
  }
  .margin-desktop-64-hor {
    margin-left: 64px;
    margin-right: 64px;
  }
  .margin-desktop-64-top {
    margin-top: 64px;
  }
  .margin-desktop-64-bot {
    margin-bottom: 64px;
  }
  .margin-desktop-64-left {
    margin-left: 64px;
  }
  .margin-desktop-64-right {
    margin-right: 64px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-64 {
    margin: -64px;
  }
  .negative-margin-desktop-64-vert {
    margin-bottom: -64px;
    margin-top: -64px;
  }
  .negative-margin-desktop-64-hor {
    margin-left: -64px;
    margin-right: -64px;
  }
  .negative-margin-desktop-64-top {
    margin-top: -64px;
  }
  .negative-margin-desktop-64-bot {
    margin-bottom: -64px;
  }
  .negative-margin-desktop-64-left {
    margin-left: -64px;
  }
  .negative-margin-desktop-64-right {
    margin-right: -64px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-64 {
    margin: 64px;
  }
  .margin-mobile-64-vert {
    margin-bottom: 64px;
    margin-top: 64px;
  }
  .margin-mobile-64-hor {
    margin-left: 64px;
    margin-right: 64px;
  }
  .margin-mobile-64-top {
    margin-top: 64px;
  }
  .margin-mobile-64-bot {
    margin-bottom: 64px;
  }
  .margin-mobile-64-left {
    margin-left: 64px;
  }
  .margin-mobile-64-right {
    margin-right: 64px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-64 {
    margin: -64px;
  }
  .negative-margin-mobile-64-vert {
    margin-bottom: -64px;
    margin-top: -64px;
  }
  .negative-margin-mobile-64-hor {
    margin-left: -64px;
    margin-right: -64px;
  }
  .negative-margin-mobile-64-top {
    margin-top: -64px;
  }
  .negative-margin-mobile-64-bot {
    margin-bottom: -64px;
  }
  .negative-margin-mobile-64-left {
    margin-left: -64px;
  }
  .negative-margin-mobile-64-right {
    margin-right: -64px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-68 {
    padding-bottom: 68px;
    padding-left: 68px;
    padding-right: 68px;
    padding-top: 68px;
  }
  .pdd-mobile-68-vert {
    padding-bottom: 68px;
    padding-top: 68px;
  }
  .pdd-mobile-68-hor {
    padding-left: 68px;
    padding-right: 68px;
  }
  .pdd-mobile-68-top {
    padding-top: 68px;
  }
  .pdd-mobile-68-bot {
    padding-bottom: 68px;
  }
  .pdd-mobile-68-left {
    padding-left: 68px;
  }
  .pdd-mobile-68-right {
    padding-right: 68px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-68 {
    padding-bottom: 68px;
    padding-left: 68px;
    padding-right: 68px;
    padding-top: 68px;
  }
  .pdd-desktop-68-vert {
    padding-bottom: 68px;
    padding-top: 68px;
  }
  .pdd-desktop-68-hor {
    padding-left: 68px;
    padding-right: 68px;
  }
  .pdd-desktop-68-top {
    padding-top: 68px;
  }
  .pdd-desktop-68-bot {
    padding-bottom: 68px;
  }
  .pdd-desktop-68-left {
    padding-left: 68px;
  }
  .pdd-desktop-68-right {
    padding-right: 68px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-68 {
    margin: 68px;
  }
  .margin-desktop-68-vert {
    margin-bottom: 68px;
    margin-top: 68px;
  }
  .margin-desktop-68-hor {
    margin-left: 68px;
    margin-right: 68px;
  }
  .margin-desktop-68-top {
    margin-top: 68px;
  }
  .margin-desktop-68-bot {
    margin-bottom: 68px;
  }
  .margin-desktop-68-left {
    margin-left: 68px;
  }
  .margin-desktop-68-right {
    margin-right: 68px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-68 {
    margin: -68px;
  }
  .negative-margin-desktop-68-vert {
    margin-bottom: -68px;
    margin-top: -68px;
  }
  .negative-margin-desktop-68-hor {
    margin-left: -68px;
    margin-right: -68px;
  }
  .negative-margin-desktop-68-top {
    margin-top: -68px;
  }
  .negative-margin-desktop-68-bot {
    margin-bottom: -68px;
  }
  .negative-margin-desktop-68-left {
    margin-left: -68px;
  }
  .negative-margin-desktop-68-right {
    margin-right: -68px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-68 {
    margin: 68px;
  }
  .margin-mobile-68-vert {
    margin-bottom: 68px;
    margin-top: 68px;
  }
  .margin-mobile-68-hor {
    margin-left: 68px;
    margin-right: 68px;
  }
  .margin-mobile-68-top {
    margin-top: 68px;
  }
  .margin-mobile-68-bot {
    margin-bottom: 68px;
  }
  .margin-mobile-68-left {
    margin-left: 68px;
  }
  .margin-mobile-68-right {
    margin-right: 68px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-68 {
    margin: -68px;
  }
  .negative-margin-mobile-68-vert {
    margin-bottom: -68px;
    margin-top: -68px;
  }
  .negative-margin-mobile-68-hor {
    margin-left: -68px;
    margin-right: -68px;
  }
  .negative-margin-mobile-68-top {
    margin-top: -68px;
  }
  .negative-margin-mobile-68-bot {
    margin-bottom: -68px;
  }
  .negative-margin-mobile-68-left {
    margin-left: -68px;
  }
  .negative-margin-mobile-68-right {
    margin-right: -68px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-72 {
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
    padding-top: 72px;
  }
  .pdd-mobile-72-vert {
    padding-bottom: 72px;
    padding-top: 72px;
  }
  .pdd-mobile-72-hor {
    padding-left: 72px;
    padding-right: 72px;
  }
  .pdd-mobile-72-top {
    padding-top: 72px;
  }
  .pdd-mobile-72-bot {
    padding-bottom: 72px;
  }
  .pdd-mobile-72-left {
    padding-left: 72px;
  }
  .pdd-mobile-72-right {
    padding-right: 72px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-72 {
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
    padding-top: 72px;
  }
  .pdd-desktop-72-vert {
    padding-bottom: 72px;
    padding-top: 72px;
  }
  .pdd-desktop-72-hor {
    padding-left: 72px;
    padding-right: 72px;
  }
  .pdd-desktop-72-top {
    padding-top: 72px;
  }
  .pdd-desktop-72-bot {
    padding-bottom: 72px;
  }
  .pdd-desktop-72-left {
    padding-left: 72px;
  }
  .pdd-desktop-72-right {
    padding-right: 72px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-72 {
    margin: 72px;
  }
  .margin-desktop-72-vert {
    margin-bottom: 72px;
    margin-top: 72px;
  }
  .margin-desktop-72-hor {
    margin-left: 72px;
    margin-right: 72px;
  }
  .margin-desktop-72-top {
    margin-top: 72px;
  }
  .margin-desktop-72-bot {
    margin-bottom: 72px;
  }
  .margin-desktop-72-left {
    margin-left: 72px;
  }
  .margin-desktop-72-right {
    margin-right: 72px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-72 {
    margin: -72px;
  }
  .negative-margin-desktop-72-vert {
    margin-bottom: -72px;
    margin-top: -72px;
  }
  .negative-margin-desktop-72-hor {
    margin-left: -72px;
    margin-right: -72px;
  }
  .negative-margin-desktop-72-top {
    margin-top: -72px;
  }
  .negative-margin-desktop-72-bot {
    margin-bottom: -72px;
  }
  .negative-margin-desktop-72-left {
    margin-left: -72px;
  }
  .negative-margin-desktop-72-right {
    margin-right: -72px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-72 {
    margin: 72px;
  }
  .margin-mobile-72-vert {
    margin-bottom: 72px;
    margin-top: 72px;
  }
  .margin-mobile-72-hor {
    margin-left: 72px;
    margin-right: 72px;
  }
  .margin-mobile-72-top {
    margin-top: 72px;
  }
  .margin-mobile-72-bot {
    margin-bottom: 72px;
  }
  .margin-mobile-72-left {
    margin-left: 72px;
  }
  .margin-mobile-72-right {
    margin-right: 72px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-72 {
    margin: -72px;
  }
  .negative-margin-mobile-72-vert {
    margin-bottom: -72px;
    margin-top: -72px;
  }
  .negative-margin-mobile-72-hor {
    margin-left: -72px;
    margin-right: -72px;
  }
  .negative-margin-mobile-72-top {
    margin-top: -72px;
  }
  .negative-margin-mobile-72-bot {
    margin-bottom: -72px;
  }
  .negative-margin-mobile-72-left {
    margin-left: -72px;
  }
  .negative-margin-mobile-72-right {
    margin-right: -72px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-80 {
    padding-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 80px;
  }
  .pdd-mobile-80-vert {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .pdd-mobile-80-hor {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pdd-mobile-80-top {
    padding-top: 80px;
  }
  .pdd-mobile-80-bot {
    padding-bottom: 80px;
  }
  .pdd-mobile-80-left {
    padding-left: 80px;
  }
  .pdd-mobile-80-right {
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-80 {
    padding-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 80px;
  }
  .pdd-desktop-80-vert {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .pdd-desktop-80-hor {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pdd-desktop-80-top {
    padding-top: 80px;
  }
  .pdd-desktop-80-bot {
    padding-bottom: 80px;
  }
  .pdd-desktop-80-left {
    padding-left: 80px;
  }
  .pdd-desktop-80-right {
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-80 {
    margin: 80px;
  }
  .margin-desktop-80-vert {
    margin-bottom: 80px;
    margin-top: 80px;
  }
  .margin-desktop-80-hor {
    margin-left: 80px;
    margin-right: 80px;
  }
  .margin-desktop-80-top {
    margin-top: 80px;
  }
  .margin-desktop-80-bot {
    margin-bottom: 80px;
  }
  .margin-desktop-80-left {
    margin-left: 80px;
  }
  .margin-desktop-80-right {
    margin-right: 80px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-80 {
    margin: -80px;
  }
  .negative-margin-desktop-80-vert {
    margin-bottom: -80px;
    margin-top: -80px;
  }
  .negative-margin-desktop-80-hor {
    margin-left: -80px;
    margin-right: -80px;
  }
  .negative-margin-desktop-80-top {
    margin-top: -80px;
  }
  .negative-margin-desktop-80-bot {
    margin-bottom: -80px;
  }
  .negative-margin-desktop-80-left {
    margin-left: -80px;
  }
  .negative-margin-desktop-80-right {
    margin-right: -80px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-80 {
    margin: 80px;
  }
  .margin-mobile-80-vert {
    margin-bottom: 80px;
    margin-top: 80px;
  }
  .margin-mobile-80-hor {
    margin-left: 80px;
    margin-right: 80px;
  }
  .margin-mobile-80-top {
    margin-top: 80px;
  }
  .margin-mobile-80-bot {
    margin-bottom: 80px;
  }
  .margin-mobile-80-left {
    margin-left: 80px;
  }
  .margin-mobile-80-right {
    margin-right: 80px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-80 {
    margin: -80px;
  }
  .negative-margin-mobile-80-vert {
    margin-bottom: -80px;
    margin-top: -80px;
  }
  .negative-margin-mobile-80-hor {
    margin-left: -80px;
    margin-right: -80px;
  }
  .negative-margin-mobile-80-top {
    margin-top: -80px;
  }
  .negative-margin-mobile-80-bot {
    margin-bottom: -80px;
  }
  .negative-margin-mobile-80-left {
    margin-left: -80px;
  }
  .negative-margin-mobile-80-right {
    margin-right: -80px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-88 {
    padding-bottom: 88px;
    padding-left: 88px;
    padding-right: 88px;
    padding-top: 88px;
  }
  .pdd-mobile-88-vert {
    padding-bottom: 88px;
    padding-top: 88px;
  }
  .pdd-mobile-88-hor {
    padding-left: 88px;
    padding-right: 88px;
  }
  .pdd-mobile-88-top {
    padding-top: 88px;
  }
  .pdd-mobile-88-bot {
    padding-bottom: 88px;
  }
  .pdd-mobile-88-left {
    padding-left: 88px;
  }
  .pdd-mobile-88-right {
    padding-right: 88px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-88 {
    padding-bottom: 88px;
    padding-left: 88px;
    padding-right: 88px;
    padding-top: 88px;
  }
  .pdd-desktop-88-vert {
    padding-bottom: 88px;
    padding-top: 88px;
  }
  .pdd-desktop-88-hor {
    padding-left: 88px;
    padding-right: 88px;
  }
  .pdd-desktop-88-top {
    padding-top: 88px;
  }
  .pdd-desktop-88-bot {
    padding-bottom: 88px;
  }
  .pdd-desktop-88-left {
    padding-left: 88px;
  }
  .pdd-desktop-88-right {
    padding-right: 88px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-88 {
    margin: 88px;
  }
  .margin-desktop-88-vert {
    margin-bottom: 88px;
    margin-top: 88px;
  }
  .margin-desktop-88-hor {
    margin-left: 88px;
    margin-right: 88px;
  }
  .margin-desktop-88-top {
    margin-top: 88px;
  }
  .margin-desktop-88-bot {
    margin-bottom: 88px;
  }
  .margin-desktop-88-left {
    margin-left: 88px;
  }
  .margin-desktop-88-right {
    margin-right: 88px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-88 {
    margin: -88px;
  }
  .negative-margin-desktop-88-vert {
    margin-bottom: -88px;
    margin-top: -88px;
  }
  .negative-margin-desktop-88-hor {
    margin-left: -88px;
    margin-right: -88px;
  }
  .negative-margin-desktop-88-top {
    margin-top: -88px;
  }
  .negative-margin-desktop-88-bot {
    margin-bottom: -88px;
  }
  .negative-margin-desktop-88-left {
    margin-left: -88px;
  }
  .negative-margin-desktop-88-right {
    margin-right: -88px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-88 {
    margin: 88px;
  }
  .margin-mobile-88-vert {
    margin-bottom: 88px;
    margin-top: 88px;
  }
  .margin-mobile-88-hor {
    margin-left: 88px;
    margin-right: 88px;
  }
  .margin-mobile-88-top {
    margin-top: 88px;
  }
  .margin-mobile-88-bot {
    margin-bottom: 88px;
  }
  .margin-mobile-88-left {
    margin-left: 88px;
  }
  .margin-mobile-88-right {
    margin-right: 88px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-88 {
    margin: -88px;
  }
  .negative-margin-mobile-88-vert {
    margin-bottom: -88px;
    margin-top: -88px;
  }
  .negative-margin-mobile-88-hor {
    margin-left: -88px;
    margin-right: -88px;
  }
  .negative-margin-mobile-88-top {
    margin-top: -88px;
  }
  .negative-margin-mobile-88-bot {
    margin-bottom: -88px;
  }
  .negative-margin-mobile-88-left {
    margin-left: -88px;
  }
  .negative-margin-mobile-88-right {
    margin-right: -88px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-96 {
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 96px;
  }
  .pdd-mobile-96-vert {
    padding-bottom: 96px;
    padding-top: 96px;
  }
  .pdd-mobile-96-hor {
    padding-left: 96px;
    padding-right: 96px;
  }
  .pdd-mobile-96-top {
    padding-top: 96px;
  }
  .pdd-mobile-96-bot {
    padding-bottom: 96px;
  }
  .pdd-mobile-96-left {
    padding-left: 96px;
  }
  .pdd-mobile-96-right {
    padding-right: 96px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-96 {
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 96px;
  }
  .pdd-desktop-96-vert {
    padding-bottom: 96px;
    padding-top: 96px;
  }
  .pdd-desktop-96-hor {
    padding-left: 96px;
    padding-right: 96px;
  }
  .pdd-desktop-96-top {
    padding-top: 96px;
  }
  .pdd-desktop-96-bot {
    padding-bottom: 96px;
  }
  .pdd-desktop-96-left {
    padding-left: 96px;
  }
  .pdd-desktop-96-right {
    padding-right: 96px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-96 {
    margin: 96px;
  }
  .margin-desktop-96-vert {
    margin-bottom: 96px;
    margin-top: 96px;
  }
  .margin-desktop-96-hor {
    margin-left: 96px;
    margin-right: 96px;
  }
  .margin-desktop-96-top {
    margin-top: 96px;
  }
  .margin-desktop-96-bot {
    margin-bottom: 96px;
  }
  .margin-desktop-96-left {
    margin-left: 96px;
  }
  .margin-desktop-96-right {
    margin-right: 96px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-96 {
    margin: -96px;
  }
  .negative-margin-desktop-96-vert {
    margin-bottom: -96px;
    margin-top: -96px;
  }
  .negative-margin-desktop-96-hor {
    margin-left: -96px;
    margin-right: -96px;
  }
  .negative-margin-desktop-96-top {
    margin-top: -96px;
  }
  .negative-margin-desktop-96-bot {
    margin-bottom: -96px;
  }
  .negative-margin-desktop-96-left {
    margin-left: -96px;
  }
  .negative-margin-desktop-96-right {
    margin-right: -96px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-96 {
    margin: 96px;
  }
  .margin-mobile-96-vert {
    margin-bottom: 96px;
    margin-top: 96px;
  }
  .margin-mobile-96-hor {
    margin-left: 96px;
    margin-right: 96px;
  }
  .margin-mobile-96-top {
    margin-top: 96px;
  }
  .margin-mobile-96-bot {
    margin-bottom: 96px;
  }
  .margin-mobile-96-left {
    margin-left: 96px;
  }
  .margin-mobile-96-right {
    margin-right: 96px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-96 {
    margin: -96px;
  }
  .negative-margin-mobile-96-vert {
    margin-bottom: -96px;
    margin-top: -96px;
  }
  .negative-margin-mobile-96-hor {
    margin-left: -96px;
    margin-right: -96px;
  }
  .negative-margin-mobile-96-top {
    margin-top: -96px;
  }
  .negative-margin-mobile-96-bot {
    margin-bottom: -96px;
  }
  .negative-margin-mobile-96-left {
    margin-left: -96px;
  }
  .negative-margin-mobile-96-right {
    margin-right: -96px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-98 {
    padding-bottom: 98px;
    padding-left: 98px;
    padding-right: 98px;
    padding-top: 98px;
  }
  .pdd-mobile-98-vert {
    padding-bottom: 98px;
    padding-top: 98px;
  }
  .pdd-mobile-98-hor {
    padding-left: 98px;
    padding-right: 98px;
  }
  .pdd-mobile-98-top {
    padding-top: 98px;
  }
  .pdd-mobile-98-bot {
    padding-bottom: 98px;
  }
  .pdd-mobile-98-left {
    padding-left: 98px;
  }
  .pdd-mobile-98-right {
    padding-right: 98px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-98 {
    padding-bottom: 98px;
    padding-left: 98px;
    padding-right: 98px;
    padding-top: 98px;
  }
  .pdd-desktop-98-vert {
    padding-bottom: 98px;
    padding-top: 98px;
  }
  .pdd-desktop-98-hor {
    padding-left: 98px;
    padding-right: 98px;
  }
  .pdd-desktop-98-top {
    padding-top: 98px;
  }
  .pdd-desktop-98-bot {
    padding-bottom: 98px;
  }
  .pdd-desktop-98-left {
    padding-left: 98px;
  }
  .pdd-desktop-98-right {
    padding-right: 98px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-98 {
    margin: 98px;
  }
  .margin-desktop-98-vert {
    margin-bottom: 98px;
    margin-top: 98px;
  }
  .margin-desktop-98-hor {
    margin-left: 98px;
    margin-right: 98px;
  }
  .margin-desktop-98-top {
    margin-top: 98px;
  }
  .margin-desktop-98-bot {
    margin-bottom: 98px;
  }
  .margin-desktop-98-left {
    margin-left: 98px;
  }
  .margin-desktop-98-right {
    margin-right: 98px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-98 {
    margin: -98px;
  }
  .negative-margin-desktop-98-vert {
    margin-bottom: -98px;
    margin-top: -98px;
  }
  .negative-margin-desktop-98-hor {
    margin-left: -98px;
    margin-right: -98px;
  }
  .negative-margin-desktop-98-top {
    margin-top: -98px;
  }
  .negative-margin-desktop-98-bot {
    margin-bottom: -98px;
  }
  .negative-margin-desktop-98-left {
    margin-left: -98px;
  }
  .negative-margin-desktop-98-right {
    margin-right: -98px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-98 {
    margin: 98px;
  }
  .margin-mobile-98-vert {
    margin-bottom: 98px;
    margin-top: 98px;
  }
  .margin-mobile-98-hor {
    margin-left: 98px;
    margin-right: 98px;
  }
  .margin-mobile-98-top {
    margin-top: 98px;
  }
  .margin-mobile-98-bot {
    margin-bottom: 98px;
  }
  .margin-mobile-98-left {
    margin-left: 98px;
  }
  .margin-mobile-98-right {
    margin-right: 98px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-98 {
    margin: -98px;
  }
  .negative-margin-mobile-98-vert {
    margin-bottom: -98px;
    margin-top: -98px;
  }
  .negative-margin-mobile-98-hor {
    margin-left: -98px;
    margin-right: -98px;
  }
  .negative-margin-mobile-98-top {
    margin-top: -98px;
  }
  .negative-margin-mobile-98-bot {
    margin-bottom: -98px;
  }
  .negative-margin-mobile-98-left {
    margin-left: -98px;
  }
  .negative-margin-mobile-98-right {
    margin-right: -98px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-100 {
    padding-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
  }
  .pdd-mobile-100-vert {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .pdd-mobile-100-hor {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pdd-mobile-100-top {
    padding-top: 100px;
  }
  .pdd-mobile-100-bot {
    padding-bottom: 100px;
  }
  .pdd-mobile-100-left {
    padding-left: 100px;
  }
  .pdd-mobile-100-right {
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-100 {
    padding-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
  }
  .pdd-desktop-100-vert {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .pdd-desktop-100-hor {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pdd-desktop-100-top {
    padding-top: 100px;
  }
  .pdd-desktop-100-bot {
    padding-bottom: 100px;
  }
  .pdd-desktop-100-left {
    padding-left: 100px;
  }
  .pdd-desktop-100-right {
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-100 {
    margin: 100px;
  }
  .margin-desktop-100-vert {
    margin-bottom: 100px;
    margin-top: 100px;
  }
  .margin-desktop-100-hor {
    margin-left: 100px;
    margin-right: 100px;
  }
  .margin-desktop-100-top {
    margin-top: 100px;
  }
  .margin-desktop-100-bot {
    margin-bottom: 100px;
  }
  .margin-desktop-100-left {
    margin-left: 100px;
  }
  .margin-desktop-100-right {
    margin-right: 100px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-100 {
    margin: -100px;
  }
  .negative-margin-desktop-100-vert {
    margin-bottom: -100px;
    margin-top: -100px;
  }
  .negative-margin-desktop-100-hor {
    margin-left: -100px;
    margin-right: -100px;
  }
  .negative-margin-desktop-100-top {
    margin-top: -100px;
  }
  .negative-margin-desktop-100-bot {
    margin-bottom: -100px;
  }
  .negative-margin-desktop-100-left {
    margin-left: -100px;
  }
  .negative-margin-desktop-100-right {
    margin-right: -100px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-100 {
    margin: 100px;
  }
  .margin-mobile-100-vert {
    margin-bottom: 100px;
    margin-top: 100px;
  }
  .margin-mobile-100-hor {
    margin-left: 100px;
    margin-right: 100px;
  }
  .margin-mobile-100-top {
    margin-top: 100px;
  }
  .margin-mobile-100-bot {
    margin-bottom: 100px;
  }
  .margin-mobile-100-left {
    margin-left: 100px;
  }
  .margin-mobile-100-right {
    margin-right: 100px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-100 {
    margin: -100px;
  }
  .negative-margin-mobile-100-vert {
    margin-bottom: -100px;
    margin-top: -100px;
  }
  .negative-margin-mobile-100-hor {
    margin-left: -100px;
    margin-right: -100px;
  }
  .negative-margin-mobile-100-top {
    margin-top: -100px;
  }
  .negative-margin-mobile-100-bot {
    margin-bottom: -100px;
  }
  .negative-margin-mobile-100-left {
    margin-left: -100px;
  }
  .negative-margin-mobile-100-right {
    margin-right: -100px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-102 {
    padding-bottom: 102px;
    padding-left: 102px;
    padding-right: 102px;
    padding-top: 102px;
  }
  .pdd-mobile-102-vert {
    padding-bottom: 102px;
    padding-top: 102px;
  }
  .pdd-mobile-102-hor {
    padding-left: 102px;
    padding-right: 102px;
  }
  .pdd-mobile-102-top {
    padding-top: 102px;
  }
  .pdd-mobile-102-bot {
    padding-bottom: 102px;
  }
  .pdd-mobile-102-left {
    padding-left: 102px;
  }
  .pdd-mobile-102-right {
    padding-right: 102px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-102 {
    padding-bottom: 102px;
    padding-left: 102px;
    padding-right: 102px;
    padding-top: 102px;
  }
  .pdd-desktop-102-vert {
    padding-bottom: 102px;
    padding-top: 102px;
  }
  .pdd-desktop-102-hor {
    padding-left: 102px;
    padding-right: 102px;
  }
  .pdd-desktop-102-top {
    padding-top: 102px;
  }
  .pdd-desktop-102-bot {
    padding-bottom: 102px;
  }
  .pdd-desktop-102-left {
    padding-left: 102px;
  }
  .pdd-desktop-102-right {
    padding-right: 102px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-102 {
    margin: 102px;
  }
  .margin-desktop-102-vert {
    margin-bottom: 102px;
    margin-top: 102px;
  }
  .margin-desktop-102-hor {
    margin-left: 102px;
    margin-right: 102px;
  }
  .margin-desktop-102-top {
    margin-top: 102px;
  }
  .margin-desktop-102-bot {
    margin-bottom: 102px;
  }
  .margin-desktop-102-left {
    margin-left: 102px;
  }
  .margin-desktop-102-right {
    margin-right: 102px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-102 {
    margin: -102px;
  }
  .negative-margin-desktop-102-vert {
    margin-bottom: -102px;
    margin-top: -102px;
  }
  .negative-margin-desktop-102-hor {
    margin-left: -102px;
    margin-right: -102px;
  }
  .negative-margin-desktop-102-top {
    margin-top: -102px;
  }
  .negative-margin-desktop-102-bot {
    margin-bottom: -102px;
  }
  .negative-margin-desktop-102-left {
    margin-left: -102px;
  }
  .negative-margin-desktop-102-right {
    margin-right: -102px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-102 {
    margin: 102px;
  }
  .margin-mobile-102-vert {
    margin-bottom: 102px;
    margin-top: 102px;
  }
  .margin-mobile-102-hor {
    margin-left: 102px;
    margin-right: 102px;
  }
  .margin-mobile-102-top {
    margin-top: 102px;
  }
  .margin-mobile-102-bot {
    margin-bottom: 102px;
  }
  .margin-mobile-102-left {
    margin-left: 102px;
  }
  .margin-mobile-102-right {
    margin-right: 102px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-102 {
    margin: -102px;
  }
  .negative-margin-mobile-102-vert {
    margin-bottom: -102px;
    margin-top: -102px;
  }
  .negative-margin-mobile-102-hor {
    margin-left: -102px;
    margin-right: -102px;
  }
  .negative-margin-mobile-102-top {
    margin-top: -102px;
  }
  .negative-margin-mobile-102-bot {
    margin-bottom: -102px;
  }
  .negative-margin-mobile-102-left {
    margin-left: -102px;
  }
  .negative-margin-mobile-102-right {
    margin-right: -102px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-104 {
    padding-bottom: 104px;
    padding-left: 104px;
    padding-right: 104px;
    padding-top: 104px;
  }
  .pdd-mobile-104-vert {
    padding-bottom: 104px;
    padding-top: 104px;
  }
  .pdd-mobile-104-hor {
    padding-left: 104px;
    padding-right: 104px;
  }
  .pdd-mobile-104-top {
    padding-top: 104px;
  }
  .pdd-mobile-104-bot {
    padding-bottom: 104px;
  }
  .pdd-mobile-104-left {
    padding-left: 104px;
  }
  .pdd-mobile-104-right {
    padding-right: 104px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-104 {
    padding-bottom: 104px;
    padding-left: 104px;
    padding-right: 104px;
    padding-top: 104px;
  }
  .pdd-desktop-104-vert {
    padding-bottom: 104px;
    padding-top: 104px;
  }
  .pdd-desktop-104-hor {
    padding-left: 104px;
    padding-right: 104px;
  }
  .pdd-desktop-104-top {
    padding-top: 104px;
  }
  .pdd-desktop-104-bot {
    padding-bottom: 104px;
  }
  .pdd-desktop-104-left {
    padding-left: 104px;
  }
  .pdd-desktop-104-right {
    padding-right: 104px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-104 {
    margin: 104px;
  }
  .margin-desktop-104-vert {
    margin-bottom: 104px;
    margin-top: 104px;
  }
  .margin-desktop-104-hor {
    margin-left: 104px;
    margin-right: 104px;
  }
  .margin-desktop-104-top {
    margin-top: 104px;
  }
  .margin-desktop-104-bot {
    margin-bottom: 104px;
  }
  .margin-desktop-104-left {
    margin-left: 104px;
  }
  .margin-desktop-104-right {
    margin-right: 104px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-104 {
    margin: -104px;
  }
  .negative-margin-desktop-104-vert {
    margin-bottom: -104px;
    margin-top: -104px;
  }
  .negative-margin-desktop-104-hor {
    margin-left: -104px;
    margin-right: -104px;
  }
  .negative-margin-desktop-104-top {
    margin-top: -104px;
  }
  .negative-margin-desktop-104-bot {
    margin-bottom: -104px;
  }
  .negative-margin-desktop-104-left {
    margin-left: -104px;
  }
  .negative-margin-desktop-104-right {
    margin-right: -104px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-104 {
    margin: 104px;
  }
  .margin-mobile-104-vert {
    margin-bottom: 104px;
    margin-top: 104px;
  }
  .margin-mobile-104-hor {
    margin-left: 104px;
    margin-right: 104px;
  }
  .margin-mobile-104-top {
    margin-top: 104px;
  }
  .margin-mobile-104-bot {
    margin-bottom: 104px;
  }
  .margin-mobile-104-left {
    margin-left: 104px;
  }
  .margin-mobile-104-right {
    margin-right: 104px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-104 {
    margin: -104px;
  }
  .negative-margin-mobile-104-vert {
    margin-bottom: -104px;
    margin-top: -104px;
  }
  .negative-margin-mobile-104-hor {
    margin-left: -104px;
    margin-right: -104px;
  }
  .negative-margin-mobile-104-top {
    margin-top: -104px;
  }
  .negative-margin-mobile-104-bot {
    margin-bottom: -104px;
  }
  .negative-margin-mobile-104-left {
    margin-left: -104px;
  }
  .negative-margin-mobile-104-right {
    margin-right: -104px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-106 {
    padding-bottom: 106px;
    padding-left: 106px;
    padding-right: 106px;
    padding-top: 106px;
  }
  .pdd-mobile-106-vert {
    padding-bottom: 106px;
    padding-top: 106px;
  }
  .pdd-mobile-106-hor {
    padding-left: 106px;
    padding-right: 106px;
  }
  .pdd-mobile-106-top {
    padding-top: 106px;
  }
  .pdd-mobile-106-bot {
    padding-bottom: 106px;
  }
  .pdd-mobile-106-left {
    padding-left: 106px;
  }
  .pdd-mobile-106-right {
    padding-right: 106px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-106 {
    padding-bottom: 106px;
    padding-left: 106px;
    padding-right: 106px;
    padding-top: 106px;
  }
  .pdd-desktop-106-vert {
    padding-bottom: 106px;
    padding-top: 106px;
  }
  .pdd-desktop-106-hor {
    padding-left: 106px;
    padding-right: 106px;
  }
  .pdd-desktop-106-top {
    padding-top: 106px;
  }
  .pdd-desktop-106-bot {
    padding-bottom: 106px;
  }
  .pdd-desktop-106-left {
    padding-left: 106px;
  }
  .pdd-desktop-106-right {
    padding-right: 106px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-106 {
    margin: 106px;
  }
  .margin-desktop-106-vert {
    margin-bottom: 106px;
    margin-top: 106px;
  }
  .margin-desktop-106-hor {
    margin-left: 106px;
    margin-right: 106px;
  }
  .margin-desktop-106-top {
    margin-top: 106px;
  }
  .margin-desktop-106-bot {
    margin-bottom: 106px;
  }
  .margin-desktop-106-left {
    margin-left: 106px;
  }
  .margin-desktop-106-right {
    margin-right: 106px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-106 {
    margin: -106px;
  }
  .negative-margin-desktop-106-vert {
    margin-bottom: -106px;
    margin-top: -106px;
  }
  .negative-margin-desktop-106-hor {
    margin-left: -106px;
    margin-right: -106px;
  }
  .negative-margin-desktop-106-top {
    margin-top: -106px;
  }
  .negative-margin-desktop-106-bot {
    margin-bottom: -106px;
  }
  .negative-margin-desktop-106-left {
    margin-left: -106px;
  }
  .negative-margin-desktop-106-right {
    margin-right: -106px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-106 {
    margin: 106px;
  }
  .margin-mobile-106-vert {
    margin-bottom: 106px;
    margin-top: 106px;
  }
  .margin-mobile-106-hor {
    margin-left: 106px;
    margin-right: 106px;
  }
  .margin-mobile-106-top {
    margin-top: 106px;
  }
  .margin-mobile-106-bot {
    margin-bottom: 106px;
  }
  .margin-mobile-106-left {
    margin-left: 106px;
  }
  .margin-mobile-106-right {
    margin-right: 106px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-106 {
    margin: -106px;
  }
  .negative-margin-mobile-106-vert {
    margin-bottom: -106px;
    margin-top: -106px;
  }
  .negative-margin-mobile-106-hor {
    margin-left: -106px;
    margin-right: -106px;
  }
  .negative-margin-mobile-106-top {
    margin-top: -106px;
  }
  .negative-margin-mobile-106-bot {
    margin-bottom: -106px;
  }
  .negative-margin-mobile-106-left {
    margin-left: -106px;
  }
  .negative-margin-mobile-106-right {
    margin-right: -106px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-108 {
    padding-bottom: 108px;
    padding-left: 108px;
    padding-right: 108px;
    padding-top: 108px;
  }
  .pdd-mobile-108-vert {
    padding-bottom: 108px;
    padding-top: 108px;
  }
  .pdd-mobile-108-hor {
    padding-left: 108px;
    padding-right: 108px;
  }
  .pdd-mobile-108-top {
    padding-top: 108px;
  }
  .pdd-mobile-108-bot {
    padding-bottom: 108px;
  }
  .pdd-mobile-108-left {
    padding-left: 108px;
  }
  .pdd-mobile-108-right {
    padding-right: 108px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-108 {
    padding-bottom: 108px;
    padding-left: 108px;
    padding-right: 108px;
    padding-top: 108px;
  }
  .pdd-desktop-108-vert {
    padding-bottom: 108px;
    padding-top: 108px;
  }
  .pdd-desktop-108-hor {
    padding-left: 108px;
    padding-right: 108px;
  }
  .pdd-desktop-108-top {
    padding-top: 108px;
  }
  .pdd-desktop-108-bot {
    padding-bottom: 108px;
  }
  .pdd-desktop-108-left {
    padding-left: 108px;
  }
  .pdd-desktop-108-right {
    padding-right: 108px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-108 {
    margin: 108px;
  }
  .margin-desktop-108-vert {
    margin-bottom: 108px;
    margin-top: 108px;
  }
  .margin-desktop-108-hor {
    margin-left: 108px;
    margin-right: 108px;
  }
  .margin-desktop-108-top {
    margin-top: 108px;
  }
  .margin-desktop-108-bot {
    margin-bottom: 108px;
  }
  .margin-desktop-108-left {
    margin-left: 108px;
  }
  .margin-desktop-108-right {
    margin-right: 108px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-108 {
    margin: -108px;
  }
  .negative-margin-desktop-108-vert {
    margin-bottom: -108px;
    margin-top: -108px;
  }
  .negative-margin-desktop-108-hor {
    margin-left: -108px;
    margin-right: -108px;
  }
  .negative-margin-desktop-108-top {
    margin-top: -108px;
  }
  .negative-margin-desktop-108-bot {
    margin-bottom: -108px;
  }
  .negative-margin-desktop-108-left {
    margin-left: -108px;
  }
  .negative-margin-desktop-108-right {
    margin-right: -108px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-108 {
    margin: 108px;
  }
  .margin-mobile-108-vert {
    margin-bottom: 108px;
    margin-top: 108px;
  }
  .margin-mobile-108-hor {
    margin-left: 108px;
    margin-right: 108px;
  }
  .margin-mobile-108-top {
    margin-top: 108px;
  }
  .margin-mobile-108-bot {
    margin-bottom: 108px;
  }
  .margin-mobile-108-left {
    margin-left: 108px;
  }
  .margin-mobile-108-right {
    margin-right: 108px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-108 {
    margin: -108px;
  }
  .negative-margin-mobile-108-vert {
    margin-bottom: -108px;
    margin-top: -108px;
  }
  .negative-margin-mobile-108-hor {
    margin-left: -108px;
    margin-right: -108px;
  }
  .negative-margin-mobile-108-top {
    margin-top: -108px;
  }
  .negative-margin-mobile-108-bot {
    margin-bottom: -108px;
  }
  .negative-margin-mobile-108-left {
    margin-left: -108px;
  }
  .negative-margin-mobile-108-right {
    margin-right: -108px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-110 {
    padding-bottom: 110px;
    padding-left: 110px;
    padding-right: 110px;
    padding-top: 110px;
  }
  .pdd-mobile-110-vert {
    padding-bottom: 110px;
    padding-top: 110px;
  }
  .pdd-mobile-110-hor {
    padding-left: 110px;
    padding-right: 110px;
  }
  .pdd-mobile-110-top {
    padding-top: 110px;
  }
  .pdd-mobile-110-bot {
    padding-bottom: 110px;
  }
  .pdd-mobile-110-left {
    padding-left: 110px;
  }
  .pdd-mobile-110-right {
    padding-right: 110px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-110 {
    padding-bottom: 110px;
    padding-left: 110px;
    padding-right: 110px;
    padding-top: 110px;
  }
  .pdd-desktop-110-vert {
    padding-bottom: 110px;
    padding-top: 110px;
  }
  .pdd-desktop-110-hor {
    padding-left: 110px;
    padding-right: 110px;
  }
  .pdd-desktop-110-top {
    padding-top: 110px;
  }
  .pdd-desktop-110-bot {
    padding-bottom: 110px;
  }
  .pdd-desktop-110-left {
    padding-left: 110px;
  }
  .pdd-desktop-110-right {
    padding-right: 110px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-110 {
    margin: 110px;
  }
  .margin-desktop-110-vert {
    margin-bottom: 110px;
    margin-top: 110px;
  }
  .margin-desktop-110-hor {
    margin-left: 110px;
    margin-right: 110px;
  }
  .margin-desktop-110-top {
    margin-top: 110px;
  }
  .margin-desktop-110-bot {
    margin-bottom: 110px;
  }
  .margin-desktop-110-left {
    margin-left: 110px;
  }
  .margin-desktop-110-right {
    margin-right: 110px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-110 {
    margin: -110px;
  }
  .negative-margin-desktop-110-vert {
    margin-bottom: -110px;
    margin-top: -110px;
  }
  .negative-margin-desktop-110-hor {
    margin-left: -110px;
    margin-right: -110px;
  }
  .negative-margin-desktop-110-top {
    margin-top: -110px;
  }
  .negative-margin-desktop-110-bot {
    margin-bottom: -110px;
  }
  .negative-margin-desktop-110-left {
    margin-left: -110px;
  }
  .negative-margin-desktop-110-right {
    margin-right: -110px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-110 {
    margin: 110px;
  }
  .margin-mobile-110-vert {
    margin-bottom: 110px;
    margin-top: 110px;
  }
  .margin-mobile-110-hor {
    margin-left: 110px;
    margin-right: 110px;
  }
  .margin-mobile-110-top {
    margin-top: 110px;
  }
  .margin-mobile-110-bot {
    margin-bottom: 110px;
  }
  .margin-mobile-110-left {
    margin-left: 110px;
  }
  .margin-mobile-110-right {
    margin-right: 110px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-110 {
    margin: -110px;
  }
  .negative-margin-mobile-110-vert {
    margin-bottom: -110px;
    margin-top: -110px;
  }
  .negative-margin-mobile-110-hor {
    margin-left: -110px;
    margin-right: -110px;
  }
  .negative-margin-mobile-110-top {
    margin-top: -110px;
  }
  .negative-margin-mobile-110-bot {
    margin-bottom: -110px;
  }
  .negative-margin-mobile-110-left {
    margin-left: -110px;
  }
  .negative-margin-mobile-110-right {
    margin-right: -110px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-112 {
    padding-bottom: 112px;
    padding-left: 112px;
    padding-right: 112px;
    padding-top: 112px;
  }
  .pdd-mobile-112-vert {
    padding-bottom: 112px;
    padding-top: 112px;
  }
  .pdd-mobile-112-hor {
    padding-left: 112px;
    padding-right: 112px;
  }
  .pdd-mobile-112-top {
    padding-top: 112px;
  }
  .pdd-mobile-112-bot {
    padding-bottom: 112px;
  }
  .pdd-mobile-112-left {
    padding-left: 112px;
  }
  .pdd-mobile-112-right {
    padding-right: 112px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-112 {
    padding-bottom: 112px;
    padding-left: 112px;
    padding-right: 112px;
    padding-top: 112px;
  }
  .pdd-desktop-112-vert {
    padding-bottom: 112px;
    padding-top: 112px;
  }
  .pdd-desktop-112-hor {
    padding-left: 112px;
    padding-right: 112px;
  }
  .pdd-desktop-112-top {
    padding-top: 112px;
  }
  .pdd-desktop-112-bot {
    padding-bottom: 112px;
  }
  .pdd-desktop-112-left {
    padding-left: 112px;
  }
  .pdd-desktop-112-right {
    padding-right: 112px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-112 {
    margin: 112px;
  }
  .margin-desktop-112-vert {
    margin-bottom: 112px;
    margin-top: 112px;
  }
  .margin-desktop-112-hor {
    margin-left: 112px;
    margin-right: 112px;
  }
  .margin-desktop-112-top {
    margin-top: 112px;
  }
  .margin-desktop-112-bot {
    margin-bottom: 112px;
  }
  .margin-desktop-112-left {
    margin-left: 112px;
  }
  .margin-desktop-112-right {
    margin-right: 112px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-112 {
    margin: -112px;
  }
  .negative-margin-desktop-112-vert {
    margin-bottom: -112px;
    margin-top: -112px;
  }
  .negative-margin-desktop-112-hor {
    margin-left: -112px;
    margin-right: -112px;
  }
  .negative-margin-desktop-112-top {
    margin-top: -112px;
  }
  .negative-margin-desktop-112-bot {
    margin-bottom: -112px;
  }
  .negative-margin-desktop-112-left {
    margin-left: -112px;
  }
  .negative-margin-desktop-112-right {
    margin-right: -112px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-112 {
    margin: 112px;
  }
  .margin-mobile-112-vert {
    margin-bottom: 112px;
    margin-top: 112px;
  }
  .margin-mobile-112-hor {
    margin-left: 112px;
    margin-right: 112px;
  }
  .margin-mobile-112-top {
    margin-top: 112px;
  }
  .margin-mobile-112-bot {
    margin-bottom: 112px;
  }
  .margin-mobile-112-left {
    margin-left: 112px;
  }
  .margin-mobile-112-right {
    margin-right: 112px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-112 {
    margin: -112px;
  }
  .negative-margin-mobile-112-vert {
    margin-bottom: -112px;
    margin-top: -112px;
  }
  .negative-margin-mobile-112-hor {
    margin-left: -112px;
    margin-right: -112px;
  }
  .negative-margin-mobile-112-top {
    margin-top: -112px;
  }
  .negative-margin-mobile-112-bot {
    margin-bottom: -112px;
  }
  .negative-margin-mobile-112-left {
    margin-left: -112px;
  }
  .negative-margin-mobile-112-right {
    margin-right: -112px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-120 {
    padding-bottom: 120px;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 120px;
  }
  .pdd-mobile-120-vert {
    padding-bottom: 120px;
    padding-top: 120px;
  }
  .pdd-mobile-120-hor {
    padding-left: 120px;
    padding-right: 120px;
  }
  .pdd-mobile-120-top {
    padding-top: 120px;
  }
  .pdd-mobile-120-bot {
    padding-bottom: 120px;
  }
  .pdd-mobile-120-left {
    padding-left: 120px;
  }
  .pdd-mobile-120-right {
    padding-right: 120px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-120 {
    padding-bottom: 120px;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 120px;
  }
  .pdd-desktop-120-vert {
    padding-bottom: 120px;
    padding-top: 120px;
  }
  .pdd-desktop-120-hor {
    padding-left: 120px;
    padding-right: 120px;
  }
  .pdd-desktop-120-top {
    padding-top: 120px;
  }
  .pdd-desktop-120-bot {
    padding-bottom: 120px;
  }
  .pdd-desktop-120-left {
    padding-left: 120px;
  }
  .pdd-desktop-120-right {
    padding-right: 120px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-120 {
    margin: 120px;
  }
  .margin-desktop-120-vert {
    margin-bottom: 120px;
    margin-top: 120px;
  }
  .margin-desktop-120-hor {
    margin-left: 120px;
    margin-right: 120px;
  }
  .margin-desktop-120-top {
    margin-top: 120px;
  }
  .margin-desktop-120-bot {
    margin-bottom: 120px;
  }
  .margin-desktop-120-left {
    margin-left: 120px;
  }
  .margin-desktop-120-right {
    margin-right: 120px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-120 {
    margin: -120px;
  }
  .negative-margin-desktop-120-vert {
    margin-bottom: -120px;
    margin-top: -120px;
  }
  .negative-margin-desktop-120-hor {
    margin-left: -120px;
    margin-right: -120px;
  }
  .negative-margin-desktop-120-top {
    margin-top: -120px;
  }
  .negative-margin-desktop-120-bot {
    margin-bottom: -120px;
  }
  .negative-margin-desktop-120-left {
    margin-left: -120px;
  }
  .negative-margin-desktop-120-right {
    margin-right: -120px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-120 {
    margin: 120px;
  }
  .margin-mobile-120-vert {
    margin-bottom: 120px;
    margin-top: 120px;
  }
  .margin-mobile-120-hor {
    margin-left: 120px;
    margin-right: 120px;
  }
  .margin-mobile-120-top {
    margin-top: 120px;
  }
  .margin-mobile-120-bot {
    margin-bottom: 120px;
  }
  .margin-mobile-120-left {
    margin-left: 120px;
  }
  .margin-mobile-120-right {
    margin-right: 120px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-120 {
    margin: -120px;
  }
  .negative-margin-mobile-120-vert {
    margin-bottom: -120px;
    margin-top: -120px;
  }
  .negative-margin-mobile-120-hor {
    margin-left: -120px;
    margin-right: -120px;
  }
  .negative-margin-mobile-120-top {
    margin-top: -120px;
  }
  .negative-margin-mobile-120-bot {
    margin-bottom: -120px;
  }
  .negative-margin-mobile-120-left {
    margin-left: -120px;
  }
  .negative-margin-mobile-120-right {
    margin-right: -120px;
  }
}

@media (max-width: 991.98px) {
  .pdd-mobile-186 {
    padding-bottom: 186px;
    padding-left: 186px;
    padding-right: 186px;
    padding-top: 186px;
  }
  .pdd-mobile-186-vert {
    padding-bottom: 186px;
    padding-top: 186px;
  }
  .pdd-mobile-186-hor {
    padding-left: 186px;
    padding-right: 186px;
  }
  .pdd-mobile-186-top {
    padding-top: 186px;
  }
  .pdd-mobile-186-bot {
    padding-bottom: 186px;
  }
  .pdd-mobile-186-left {
    padding-left: 186px;
  }
  .pdd-mobile-186-right {
    padding-right: 186px;
  }
}

@media (min-width: 992px) {
  .pdd-desktop-186 {
    padding-bottom: 186px;
    padding-left: 186px;
    padding-right: 186px;
    padding-top: 186px;
  }
  .pdd-desktop-186-vert {
    padding-bottom: 186px;
    padding-top: 186px;
  }
  .pdd-desktop-186-hor {
    padding-left: 186px;
    padding-right: 186px;
  }
  .pdd-desktop-186-top {
    padding-top: 186px;
  }
  .pdd-desktop-186-bot {
    padding-bottom: 186px;
  }
  .pdd-desktop-186-left {
    padding-left: 186px;
  }
  .pdd-desktop-186-right {
    padding-right: 186px;
  }
}

@media (min-width: 992px) {
  .margin-desktop-186 {
    margin: 186px;
  }
  .margin-desktop-186-vert {
    margin-bottom: 186px;
    margin-top: 186px;
  }
  .margin-desktop-186-hor {
    margin-left: 186px;
    margin-right: 186px;
  }
  .margin-desktop-186-top {
    margin-top: 186px;
  }
  .margin-desktop-186-bot {
    margin-bottom: 186px;
  }
  .margin-desktop-186-left {
    margin-left: 186px;
  }
  .margin-desktop-186-right {
    margin-right: 186px;
  }
}

@media (min-width: 992px) {
  .negative-margin-desktop-186 {
    margin: -186px;
  }
  .negative-margin-desktop-186-vert {
    margin-bottom: -186px;
    margin-top: -186px;
  }
  .negative-margin-desktop-186-hor {
    margin-left: -186px;
    margin-right: -186px;
  }
  .negative-margin-desktop-186-top {
    margin-top: -186px;
  }
  .negative-margin-desktop-186-bot {
    margin-bottom: -186px;
  }
  .negative-margin-desktop-186-left {
    margin-left: -186px;
  }
  .negative-margin-desktop-186-right {
    margin-right: -186px;
  }
}

@media (max-width: 991.98px) {
  .margin-mobile-186 {
    margin: 186px;
  }
  .margin-mobile-186-vert {
    margin-bottom: 186px;
    margin-top: 186px;
  }
  .margin-mobile-186-hor {
    margin-left: 186px;
    margin-right: 186px;
  }
  .margin-mobile-186-top {
    margin-top: 186px;
  }
  .margin-mobile-186-bot {
    margin-bottom: 186px;
  }
  .margin-mobile-186-left {
    margin-left: 186px;
  }
  .margin-mobile-186-right {
    margin-right: 186px;
  }
}

@media (max-width: 991.98px) {
  .negative-margin-mobile-186 {
    margin: -186px;
  }
  .negative-margin-mobile-186-vert {
    margin-bottom: -186px;
    margin-top: -186px;
  }
  .negative-margin-mobile-186-hor {
    margin-left: -186px;
    margin-right: -186px;
  }
  .negative-margin-mobile-186-top {
    margin-top: -186px;
  }
  .negative-margin-mobile-186-bot {
    margin-bottom: -186px;
  }
  .negative-margin-mobile-186-left {
    margin-left: -186px;
  }
  .negative-margin-mobile-186-right {
    margin-right: -186px;
  }
}

.form-group.floating-label.required .form-control-label::before {
  color: #4b4b4b;
  content: "*";
}

.native-arrow-select-container {
  margin-bottom: 0;
}

.custom-radio [type=radio]:checked,
.custom-radio [type=radio]:not(:checked) {
  left: -9999px;
  position: absolute;
}
.custom-radio [type=radio]:checked + label,
.custom-radio [type=radio]:not(:checked) + label {
  cursor: pointer;
  position: relative;
}
.custom-radio [type=radio]:checked + label::before,
.custom-radio [type=radio]:not(:checked) + label::before {
  border: 1px solid #8f8f8f;
  border-radius: 12px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  min-width: 24px;
  top: 0;
}
.custom-radio [type=radio]:checked + label::after,
.custom-radio [type=radio]:not(:checked) + label::after {
  background: #fff;
  border-radius: 10px;
  content: "";
  height: 10px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 10px;
}
.custom-radio [type=radio]:checked + label::before {
  background-color: #537c7f;
  border-color: #537c7f;
}

.pseudo-checkbox {
  background: none;
  border: 0;
}
.pseudo-checkbox .pseudo-checkbox-inner {
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.pseudo-checkbox .pseudo-checkbox-inner::before {
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  content: "";
  cursor: pointer;
  display: inline-block;
  padding: 11px;
  vertical-align: middle;
}
.pseudo-checkbox.selected .pseudo-checkbox-inner::before {
  background-color: #537c7f;
  border-color: #537c7f;
}
.pseudo-checkbox.selected .pseudo-checkbox-inner::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}

.pseudo-radio {
  background: none;
  border: 0;
}
.pseudo-radio .pseudo-radio-inner {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.pseudo-radio .pseudo-radio-inner::before {
  border: 1px solid #8f8f8f;
  border-radius: 12px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  top: 0;
  width: 24px;
}
.pseudo-radio.selected .pseudo-radio-inner::before {
  background-color: #537c7f;
  border-color: #537c7f;
}
.pseudo-radio.selected .pseudo-radio-inner::after {
  background: #fff;
  border-radius: 10px;
  content: "";
  height: 10px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 10px;
}

body,
html {
  background-color: #fff;
  transition: opacity 0.2s ease-in-out;
}

.prevent-scroll {
  height: 100dvh;
  overflow: hidden;
}

.page.prevent-scroll {
  width: 100vw;
}
@media (min-width: 992px) {
  .page.prevent-scroll {
    padding-right: 16px;
  }
}

strong {
  font-weight: 800;
}

figure {
  margin: 0;
}

.loaded {
  opacity: 1;
}

.maincontent {
  overflow-x: hidden;
}

@media (max-width: 991.98px) {
  .no-overflow {
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100vw;
  }
}

@media (min-width: 992px) {
  .full-width-desktop {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .full-width-desktop .region:first-of-type {
    padding-left: 0;
  }
  .full-width-desktop .region:last-of-type {
    padding-right: 0;
  }
}

@media (max-width: 991.98px) {
  .full-width-mobile {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .full-width-mobile .region {
    padding-left: 0;
    padding-right: 0;
  }
}

button {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}

p {
  margin: 0 0 16px 0;
  padding: 0;
}
p a {
  background: 0;
  border: 0;
  color: #262626;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin: 0;
  padding: 0;
  text-decoration-line: underline;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 700;
}

.bold {
  font-weight: 800;
}

.questa {
  font-family: "questa-grande", serif !important;
  font-weight: 900;
}

.content-asset-wrapper {
  overflow: hidden;
  position: relative;
}
.content-asset-wrapper .content-asset-bg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.content-asset-wrapper .content-asset-bg .bg-img {
  height: auto;
  min-height: 100%;
  min-width: 100%;
  width: auto;
}

.livechat-links {
  bottom: 50px;
  position: fixed;
  right: 46px;
  z-index: 10;
}
@media (max-width: 991.98px) {
  .livechat-links {
    right: 0;
  }
}
.livechat-links a {
  border-radius: 2px;
  padding: 10px 10px 5px 10px;
  text-decoration: none;
  text-transform: none;
}
.livechat-links a:hover {
  text-decoration: unset;
}
@media (max-width: 991.98px) {
  .livechat-links a .livechat-text {
    display: none;
  }
}
.livechat-links a .livechat-icon svg {
  margin: 0 0 5px 6px;
}
@media (max-width: 991.98px) {
  .livechat-links a .livechat-icon svg {
    margin-left: 0;
  }
}

.text {
  color: #262626;
  font-family: "sofia-pro";
  font-style: normal;
  line-height: 120%;
}
.text-xxs {
  font-size: 0.75rem;
}
.text-xs {
  font-size: 0.813rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-xxl {
  font-size: 1.4375rem;
}
.text-h1 {
  font-size: 2.25rem;
}
.text-h2 {
  font-size: 2rem;
}
.text-h3 {
  font-size: 1.8125rem;
}
.text-h4 {
  font-size: 1.625rem;
}
.text-h5 {
  font-size: 1.4375rem;
}
.text-h6 {
  font-size: 1.25rem;
}
.text-italic {
  font-style: italic;
}
.text-underline {
  text-decoration: underline;
}
.text-through {
  text-decoration: line-through;
}
.text-nowrap {
  white-space: nowrap;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-color-inherit {
  color: inherit;
}
.text-vertical-middle {
  vertical-align: middle;
}
.text-color-red {
  color: #b4292f;
}
.text-color-red:hover {
  color: #b4292f;
}
.text-color-primary-green {
  color: #537c7f;
}
.text-color-primary-green:hover {
  color: #537c7f;
}
.text-color-paprika {
  color: #ff595a;
}
.text-color-paprika:hover {
  color: #ff595a;
}
.text-color-merlot {
  color: #561c27;
}
.text-color-merlot:hover {
  color: #561c27;
}
.text-color-ship-lap {
  color: #f6f4f1;
}
.text-color-ship-lap:hover {
  color: #f6f4f1;
}
.text-color-new-adirondack {
  color: #83c6c9;
}
.text-color-new-adirondack:hover {
  color: #83c6c9;
}
.text-color-teal {
  color: #537c7f;
}
.text-color-teal:hover {
  color: #537c7f;
}
.text-color-blue-melamine {
  color: #244b5a;
}
.text-color-blue-melamine:hover {
  color: #244b5a;
}
.text-color-saffron {
  color: #f6c862;
}
.text-color-saffron:hover {
  color: #f6c862;
}
.text-color-black {
  color: #000;
}
.text-color-black:hover {
  color: #000;
}
.text-color-white {
  color: #fff;
}
.text-color-white:hover {
  color: #fff;
}
.text-color-primary {
  color: #537c7f;
}
.text-color-primary:hover {
  color: #537c7f;
}
.text-color-p50 {
  color: #f3f9fa;
}
.text-color-p50:hover {
  color: #f3f9fa;
}
.text-color-p75 {
  color: #cce8e9;
}
.text-color-p75:hover {
  color: #cce8e9;
}
.text-color-p100 {
  color: #b7dee0;
}
.text-color-p100:hover {
  color: #b7dee0;
}
.text-color-p200 {
  color: #98d0d2;
}
.text-color-p200:hover {
  color: #98d0d2;
}
.text-color-p300 {
  color: #83c6c9;
}
.text-color-p300:hover {
  color: #83c6c9;
}
.text-color-p400 {
  color: #537c7f;
}
.text-color-p400:hover {
  color: #537c7f;
}
.text-color-p500 {
  color: #50797b;
}
.text-color-p500:hover {
  color: #50797b;
}
.text-color-s50 {
  color: #f8eaea;
}
.text-color-s50:hover {
  color: #f8eaea;
}
.text-color-s75 {
  color: #e0a7aa;
}
.text-color-s75:hover {
  color: #e0a7aa;
}
.text-color-s100 {
  color: #d48386;
}
.text-color-s100:hover {
  color: #d48386;
}
.text-color-s200 {
  color: #c14d52;
}
.text-color-s200:hover {
  color: #c14d52;
}
.text-color-s300 {
  color: #b4292f;
}
.text-color-s300:hover {
  color: #b4292f;
}
.text-color-s400 {
  color: #7e1d21;
}
.text-color-s400:hover {
  color: #7e1d21;
}
.text-color-s500 {
  color: #561c27;
}
.text-color-s500:hover {
  color: #561c27;
}
.text-color-sl50 {
  color: #fefefe;
}
.text-color-sl50:hover {
  color: #fefefe;
}
.text-color-sl75 {
  color: #fcf8ef;
}
.text-color-sl75:hover {
  color: #fcf8ef;
}
.text-color-sl100 {
  color: #faf6e9;
}
.text-color-sl100:hover {
  color: #faf6e9;
}
.text-color-sl200 {
  color: #ecf8fc;
}
.text-color-sl200:hover {
  color: #ecf8fc;
}
.text-color-sl300 {
  color: #f7efd9;
}
.text-color-sl300:hover {
  color: #f7efd9;
}
.text-color-sl400 {
  color: #ada798;
}
.text-color-sl400:hover {
  color: #ada798;
}
.text-color-sl500 {
  color: #979284;
}
.text-color-sl500:hover {
  color: #979284;
}
.text-color-sl600 {
  color: #faf9f7;
}
.text-color-sl600:hover {
  color: #faf9f7;
}
.text-color-b50 {
  color: #e9edef;
}
.text-color-b50:hover {
  color: #e9edef;
}
.text-color-b75 {
  color: #a5b5bb;
}
.text-color-b75:hover {
  color: #a5b5bb;
}
.text-color-b100 {
  color: #80979f;
}
.text-color-b100:hover {
  color: #80979f;
}
.text-color-b200 {
  color: #496a76;
}
.text-color-b200:hover {
  color: #496a76;
}
.text-color-b300 {
  color: #244b5a;
}
.text-color-b300:hover {
  color: #244b5a;
}
.text-color-b400 {
  color: #19353f;
}
.text-color-b400:hover {
  color: #19353f;
}
.text-color-b500 {
  color: #162e37;
}
.text-color-b500:hover {
  color: #162e37;
}
.text-color-sa50 {
  color: #fefaef;
}
.text-color-sa50:hover {
  color: #fefaef;
}
.text-color-sa75 {
  color: #fbe8bf;
}
.text-color-sa75:hover {
  color: #fbe8bf;
}
.text-color-sa100 {
  color: #fadfa4;
}
.text-color-sa100:hover {
  color: #fadfa4;
}
.text-color-sa200 {
  color: #f8d17d;
}
.text-color-sa200:hover {
  color: #f8d17d;
}
.text-color-sa300 {
  color: #f6c862;
}
.text-color-sa300:hover {
  color: #f6c862;
}
.text-color-sa400 {
  color: #ac8c45;
}
.text-color-sa400:hover {
  color: #ac8c45;
}
.text-color-sa500 {
  color: #967a3c;
}
.text-color-sa500:hover {
  color: #967a3c;
}
.text-color-su50 {
  color: #ebf8f2;
}
.text-color-su50:hover {
  color: #ebf8f2;
}
.text-color-su200 {
  color: #58c390;
}
.text-color-su200:hover {
  color: #58c390;
}
.text-color-su400 {
  color: #268055;
}
.text-color-su400:hover {
  color: #268055;
}
.text-color-e50 {
  color: #f8eaea;
}
.text-color-e50:hover {
  color: #f8eaea;
}
.text-color-e300 {
  color: #b4292f;
}
.text-color-e300:hover {
  color: #b4292f;
}
.text-color-e500 {
  color: #6e191d;
}
.text-color-e500:hover {
  color: #6e191d;
}
.text-color-w50 {
  color: #fff7e6;
}
.text-color-w50:hover {
  color: #fff7e6;
}
.text-color-w300 {
  color: #ffab00;
}
.text-color-w300:hover {
  color: #ffab00;
}
.text-color-w400 {
  color: #b37800;
}
.text-color-w400:hover {
  color: #b37800;
}
.text-color-n0 {
  color: #fff;
}
.text-color-n0:hover {
  color: #fff;
}
.text-color-n10 {
  color: #fcfcfc;
}
.text-color-n10:hover {
  color: #fcfcfc;
}
.text-color-n20 {
  color: #f9f9f9;
}
.text-color-n20:hover {
  color: #f9f9f9;
}
.text-color-n30 {
  color: #f3f3f3;
}
.text-color-n30:hover {
  color: #f3f3f3;
}
.text-color-n40 {
  color: #ececec;
}
.text-color-n40:hover {
  color: #ececec;
}
.text-color-n50 {
  color: #dbdbdb;
}
.text-color-n50:hover {
  color: #dbdbdb;
}
.text-color-n70 {
  color: #cacaca;
}
.text-color-n70:hover {
  color: #cacaca;
}
.text-color-n100 {
  color: #b0b0b0;
}
.text-color-n100:hover {
  color: #b0b0b0;
}
.text-color-n300 {
  color: #9f9f9f;
}
.text-color-n300:hover {
  color: #9f9f9f;
}
.text-color-n500 {
  color: #8f8f8f;
}
.text-color-n500:hover {
  color: #8f8f8f;
}
.text-color-n700 {
  color: #7d7d7d;
}
.text-color-n700:hover {
  color: #7d7d7d;
}
.text-color-n900 {
  color: #6d6d6d;
}
.text-color-n900:hover {
  color: #6d6d6d;
}
.text-color-t0 {
  color: #fff;
}
.text-color-t0:hover {
  color: #fff;
}
.text-color-t50 {
  color: #e9e9e9;
}
.text-color-t50:hover {
  color: #e9e9e9;
}
.text-color-t75 {
  color: #a6a6a6;
}
.text-color-t75:hover {
  color: #a6a6a6;
}
.text-color-t100 {
  color: #818181;
}
.text-color-t100:hover {
  color: #818181;
}
.text-color-t200 {
  color: #4b4b4b;
}
.text-color-t200:hover {
  color: #4b4b4b;
}
.text-color-t300 {
  color: #262626;
}
.text-color-t300:hover {
  color: #262626;
}
.text-color-t400 {
  color: #1b1b1b;
}
.text-color-t400:hover {
  color: #1b1b1b;
}
.text-color-t500 {
  color: #171717;
}
.text-color-t500:hover {
  color: #171717;
}
.text-color-nsl100 {
  color: #faf9f7;
}
.text-color-nsl100:hover {
  color: #faf9f7;
}
.text-color-nsl300 {
  color: #f6f4f1;
}
.text-color-nsl300:hover {
  color: #f6f4f1;
}

.background-red {
  background-color: #b4292f;
}
.background-primary-green {
  background-color: #537c7f;
}
.background-paprika {
  background-color: #ff595a;
}
.background-merlot {
  background-color: #561c27;
}
.background-ship-lap {
  background-color: #f6f4f1;
}
.background-new-adirondack {
  background-color: #83c6c9;
}
.background-teal {
  background-color: #537c7f;
}
.background-blue-melamine {
  background-color: #244b5a;
}
.background-saffron {
  background-color: #f6c862;
}
.background-black {
  background-color: #000;
}
.background-white {
  background-color: #fff;
}
.background-primary {
  background-color: #537c7f;
}
.background-color-p50 {
  background-color: #f3f9fa;
}
.background-color-p75 {
  background-color: #cce8e9;
}
.background-color-p100 {
  background-color: #b7dee0;
}
.background-color-p200 {
  background-color: #98d0d2;
}
.background-color-p300 {
  background-color: #83c6c9;
}
.background-color-p400 {
  background-color: #537c7f;
}
.background-color-p500 {
  background-color: #50797b;
}
.background-color-s50 {
  background-color: #f8eaea;
}
.background-color-s75 {
  background-color: #e0a7aa;
}
.background-color-s100 {
  background-color: #d48386;
}
.background-color-s200 {
  background-color: #c14d52;
}
.background-color-s300 {
  background-color: #b4292f;
}
.background-color-s400 {
  background-color: #7e1d21;
}
.background-color-s500 {
  background-color: #561c27;
}
.background-color-sl50 {
  background-color: #fefefe;
}
.background-color-sl75 {
  background-color: #fcf8ef;
}
.background-color-sl100 {
  background-color: #faf6e9;
}
.background-color-sl200 {
  background-color: #ecf8fc;
}
.background-color-sl300 {
  background-color: #f7efd9;
}
.background-color-sl400 {
  background-color: #ada798;
}
.background-color-sl500 {
  background-color: #979284;
}
.background-color-sl600 {
  background-color: #faf9f7;
}
.background-color-b50 {
  background-color: #e9edef;
}
.background-color-b75 {
  background-color: #a5b5bb;
}
.background-color-b100 {
  background-color: #80979f;
}
.background-color-b200 {
  background-color: #496a76;
}
.background-color-b300 {
  background-color: #244b5a;
}
.background-color-b400 {
  background-color: #19353f;
}
.background-color-b500 {
  background-color: #162e37;
}
.background-color-sa50 {
  background-color: #fefaef;
}
.background-color-sa75 {
  background-color: #fbe8bf;
}
.background-color-sa100 {
  background-color: #fadfa4;
}
.background-color-sa200 {
  background-color: #f8d17d;
}
.background-color-sa300 {
  background-color: #f6c862;
}
.background-color-sa400 {
  background-color: #ac8c45;
}
.background-color-sa500 {
  background-color: #967a3c;
}
.background-color-su50 {
  background-color: #ebf8f2;
}
.background-color-su200 {
  background-color: #58c390;
}
.background-color-su400 {
  background-color: #268055;
}
.background-color-e50 {
  background-color: #f8eaea;
}
.background-color-e300 {
  background-color: #b4292f;
}
.background-color-e500 {
  background-color: #6e191d;
}
.background-color-w50 {
  background-color: #fff7e6;
}
.background-color-w300 {
  background-color: #ffab00;
}
.background-color-w400 {
  background-color: #b37800;
}
.background-color-n0 {
  background-color: #fff;
}
.background-color-n10 {
  background-color: #fcfcfc;
}
.background-color-n20 {
  background-color: #f9f9f9;
}
.background-color-n30 {
  background-color: #f3f3f3;
}
.background-color-n40 {
  background-color: #ececec;
}
.background-color-n50 {
  background-color: #dbdbdb;
}
.background-color-n70 {
  background-color: #cacaca;
}
.background-color-n100 {
  background-color: #b0b0b0;
}
.background-color-n300 {
  background-color: #9f9f9f;
}
.background-color-n500 {
  background-color: #8f8f8f;
}
.background-color-n700 {
  background-color: #7d7d7d;
}
.background-color-n900 {
  background-color: #6d6d6d;
}
.background-color-t0 {
  background-color: #fff;
}
.background-color-t50 {
  background-color: #e9e9e9;
}
.background-color-t75 {
  background-color: #a6a6a6;
}
.background-color-t100 {
  background-color: #818181;
}
.background-color-t200 {
  background-color: #4b4b4b;
}
.background-color-t300 {
  background-color: #262626;
}
.background-color-t400 {
  background-color: #1b1b1b;
}
.background-color-t500 {
  background-color: #171717;
}
.background-color-nsl100 {
  background-color: #faf9f7;
}
.background-color-nsl300 {
  background-color: #f6f4f1;
}

.icon-sm {
  height: 16px;
  width: 16px;
}
.icon-md {
  height: 24px;
  width: 24px;
}
.icon-lg {
  height: 32px;
  width: 32px;
}
.icon-xl {
  height: 44px;
  width: 44px;
}
.icon-xxl {
  height: 48px;
  width: 48px;
}
.icon-logo {
  height: 24px;
  width: 100%;
}
@media (min-width: 375px) {
  .icon-logo {
    height: 44px;
  }
}
@media (min-width: 992px) {
  .icon-desktop-sm {
    height: 16px;
    width: 16px;
  }
}
@media (min-width: 992px) {
  .icon-desktop-md {
    height: 24px;
    width: 24px;
  }
}
@media (min-width: 992px) {
  .icon-desktop-lg {
    height: 32px;
    width: 32px;
  }
}
@media (min-width: 992px) {
  .icon-desktop-xl {
    height: 44px;
    width: 44px;
  }
}
@media (min-width: 992px) {
  .icon-desktop-xxl {
    height: 48px;
    width: 48px;
  }
}
@media (min-width: 992px) {
  .icon-desktop-medal {
    height: 70px;
    width: 70px;
  }
}
.icon-red {
  stroke: #b4292f;
}
.icon-red:hover {
  stroke: #b4292f;
}
.icon-primary-green {
  stroke: #537c7f;
}
.icon-primary-green:hover {
  stroke: #537c7f;
}
.icon-paprika {
  stroke: #ff595a;
}
.icon-paprika:hover {
  stroke: #ff595a;
}
.icon-merlot {
  stroke: #561c27;
}
.icon-merlot:hover {
  stroke: #561c27;
}
.icon-ship-lap {
  stroke: #f6f4f1;
}
.icon-ship-lap:hover {
  stroke: #f6f4f1;
}
.icon-new-adirondack {
  stroke: #83c6c9;
}
.icon-new-adirondack:hover {
  stroke: #83c6c9;
}
.icon-teal {
  stroke: #537c7f;
}
.icon-teal:hover {
  stroke: #537c7f;
}
.icon-blue-melamine {
  stroke: #244b5a;
}
.icon-blue-melamine:hover {
  stroke: #244b5a;
}
.icon-saffron {
  stroke: #f6c862;
}
.icon-saffron:hover {
  stroke: #f6c862;
}
.icon-black {
  stroke: #000;
}
.icon-black:hover {
  stroke: #000;
}
.icon-white {
  stroke: #fff;
}
.icon-white:hover {
  stroke: #fff;
}
.icon-primary {
  stroke: #537c7f;
}
.icon-primary:hover {
  stroke: #537c7f;
}
.icon-color-p50 {
  stroke: #f3f9fa;
}
.icon-color-p50:hover {
  stroke: #f3f9fa;
}
.icon-color-p75 {
  stroke: #cce8e9;
}
.icon-color-p75:hover {
  stroke: #cce8e9;
}
.icon-color-p100 {
  stroke: #b7dee0;
}
.icon-color-p100:hover {
  stroke: #b7dee0;
}
.icon-color-p200 {
  stroke: #98d0d2;
}
.icon-color-p200:hover {
  stroke: #98d0d2;
}
.icon-color-p300 {
  stroke: #83c6c9;
}
.icon-color-p300:hover {
  stroke: #83c6c9;
}
.icon-color-p400 {
  stroke: #537c7f;
}
.icon-color-p400:hover {
  stroke: #537c7f;
}
.icon-color-p500 {
  stroke: #50797b;
}
.icon-color-p500:hover {
  stroke: #50797b;
}
.icon-color-s50 {
  stroke: #f8eaea;
}
.icon-color-s50:hover {
  stroke: #f8eaea;
}
.icon-color-s75 {
  stroke: #e0a7aa;
}
.icon-color-s75:hover {
  stroke: #e0a7aa;
}
.icon-color-s100 {
  stroke: #d48386;
}
.icon-color-s100:hover {
  stroke: #d48386;
}
.icon-color-s200 {
  stroke: #c14d52;
}
.icon-color-s200:hover {
  stroke: #c14d52;
}
.icon-color-s300 {
  stroke: #b4292f;
}
.icon-color-s300:hover {
  stroke: #b4292f;
}
.icon-color-s400 {
  stroke: #7e1d21;
}
.icon-color-s400:hover {
  stroke: #7e1d21;
}
.icon-color-s500 {
  stroke: #561c27;
}
.icon-color-s500:hover {
  stroke: #561c27;
}
.icon-color-sl50 {
  stroke: #fefefe;
}
.icon-color-sl50:hover {
  stroke: #fefefe;
}
.icon-color-sl75 {
  stroke: #fcf8ef;
}
.icon-color-sl75:hover {
  stroke: #fcf8ef;
}
.icon-color-sl100 {
  stroke: #faf6e9;
}
.icon-color-sl100:hover {
  stroke: #faf6e9;
}
.icon-color-sl200 {
  stroke: #ecf8fc;
}
.icon-color-sl200:hover {
  stroke: #ecf8fc;
}
.icon-color-sl300 {
  stroke: #f7efd9;
}
.icon-color-sl300:hover {
  stroke: #f7efd9;
}
.icon-color-sl400 {
  stroke: #ada798;
}
.icon-color-sl400:hover {
  stroke: #ada798;
}
.icon-color-sl500 {
  stroke: #979284;
}
.icon-color-sl500:hover {
  stroke: #979284;
}
.icon-color-sl600 {
  stroke: #faf9f7;
}
.icon-color-sl600:hover {
  stroke: #faf9f7;
}
.icon-color-b50 {
  stroke: #e9edef;
}
.icon-color-b50:hover {
  stroke: #e9edef;
}
.icon-color-b75 {
  stroke: #a5b5bb;
}
.icon-color-b75:hover {
  stroke: #a5b5bb;
}
.icon-color-b100 {
  stroke: #80979f;
}
.icon-color-b100:hover {
  stroke: #80979f;
}
.icon-color-b200 {
  stroke: #496a76;
}
.icon-color-b200:hover {
  stroke: #496a76;
}
.icon-color-b300 {
  stroke: #244b5a;
}
.icon-color-b300:hover {
  stroke: #244b5a;
}
.icon-color-b400 {
  stroke: #19353f;
}
.icon-color-b400:hover {
  stroke: #19353f;
}
.icon-color-b500 {
  stroke: #162e37;
}
.icon-color-b500:hover {
  stroke: #162e37;
}
.icon-color-sa50 {
  stroke: #fefaef;
}
.icon-color-sa50:hover {
  stroke: #fefaef;
}
.icon-color-sa75 {
  stroke: #fbe8bf;
}
.icon-color-sa75:hover {
  stroke: #fbe8bf;
}
.icon-color-sa100 {
  stroke: #fadfa4;
}
.icon-color-sa100:hover {
  stroke: #fadfa4;
}
.icon-color-sa200 {
  stroke: #f8d17d;
}
.icon-color-sa200:hover {
  stroke: #f8d17d;
}
.icon-color-sa300 {
  stroke: #f6c862;
}
.icon-color-sa300:hover {
  stroke: #f6c862;
}
.icon-color-sa400 {
  stroke: #ac8c45;
}
.icon-color-sa400:hover {
  stroke: #ac8c45;
}
.icon-color-sa500 {
  stroke: #967a3c;
}
.icon-color-sa500:hover {
  stroke: #967a3c;
}
.icon-color-su50 {
  stroke: #ebf8f2;
}
.icon-color-su50:hover {
  stroke: #ebf8f2;
}
.icon-color-su200 {
  stroke: #58c390;
}
.icon-color-su200:hover {
  stroke: #58c390;
}
.icon-color-su400 {
  stroke: #268055;
}
.icon-color-su400:hover {
  stroke: #268055;
}
.icon-color-e50 {
  stroke: #f8eaea;
}
.icon-color-e50:hover {
  stroke: #f8eaea;
}
.icon-color-e300 {
  stroke: #b4292f;
}
.icon-color-e300:hover {
  stroke: #b4292f;
}
.icon-color-e500 {
  stroke: #6e191d;
}
.icon-color-e500:hover {
  stroke: #6e191d;
}
.icon-color-w50 {
  stroke: #fff7e6;
}
.icon-color-w50:hover {
  stroke: #fff7e6;
}
.icon-color-w300 {
  stroke: #ffab00;
}
.icon-color-w300:hover {
  stroke: #ffab00;
}
.icon-color-w400 {
  stroke: #b37800;
}
.icon-color-w400:hover {
  stroke: #b37800;
}
.icon-color-n0 {
  stroke: #fff;
}
.icon-color-n0:hover {
  stroke: #fff;
}
.icon-color-n10 {
  stroke: #fcfcfc;
}
.icon-color-n10:hover {
  stroke: #fcfcfc;
}
.icon-color-n20 {
  stroke: #f9f9f9;
}
.icon-color-n20:hover {
  stroke: #f9f9f9;
}
.icon-color-n30 {
  stroke: #f3f3f3;
}
.icon-color-n30:hover {
  stroke: #f3f3f3;
}
.icon-color-n40 {
  stroke: #ececec;
}
.icon-color-n40:hover {
  stroke: #ececec;
}
.icon-color-n50 {
  stroke: #dbdbdb;
}
.icon-color-n50:hover {
  stroke: #dbdbdb;
}
.icon-color-n70 {
  stroke: #cacaca;
}
.icon-color-n70:hover {
  stroke: #cacaca;
}
.icon-color-n100 {
  stroke: #b0b0b0;
}
.icon-color-n100:hover {
  stroke: #b0b0b0;
}
.icon-color-n300 {
  stroke: #9f9f9f;
}
.icon-color-n300:hover {
  stroke: #9f9f9f;
}
.icon-color-n500 {
  stroke: #8f8f8f;
}
.icon-color-n500:hover {
  stroke: #8f8f8f;
}
.icon-color-n700 {
  stroke: #7d7d7d;
}
.icon-color-n700:hover {
  stroke: #7d7d7d;
}
.icon-color-n900 {
  stroke: #6d6d6d;
}
.icon-color-n900:hover {
  stroke: #6d6d6d;
}
.icon-color-t0 {
  stroke: #fff;
}
.icon-color-t0:hover {
  stroke: #fff;
}
.icon-color-t50 {
  stroke: #e9e9e9;
}
.icon-color-t50:hover {
  stroke: #e9e9e9;
}
.icon-color-t75 {
  stroke: #a6a6a6;
}
.icon-color-t75:hover {
  stroke: #a6a6a6;
}
.icon-color-t100 {
  stroke: #818181;
}
.icon-color-t100:hover {
  stroke: #818181;
}
.icon-color-t200 {
  stroke: #4b4b4b;
}
.icon-color-t200:hover {
  stroke: #4b4b4b;
}
.icon-color-t300 {
  stroke: #262626;
}
.icon-color-t300:hover {
  stroke: #262626;
}
.icon-color-t400 {
  stroke: #1b1b1b;
}
.icon-color-t400:hover {
  stroke: #1b1b1b;
}
.icon-color-t500 {
  stroke: #171717;
}
.icon-color-t500:hover {
  stroke: #171717;
}
.icon-color-nsl100 {
  stroke: #faf9f7;
}
.icon-color-nsl100:hover {
  stroke: #faf9f7;
}
.icon-color-nsl300 {
  stroke: #f6f4f1;
}
.icon-color-nsl300:hover {
  stroke: #f6f4f1;
}

.cursor-pointer {
  cursor: pointer;
}

.link-style {
  background: 0;
  border: 0;
  color: #262626;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin: 0;
  padding: 0;
  text-decoration-line: underline;
}
.link-style-primary {
  color: #537c7f;
}

.pill-style {
  background: #fbfaf9;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  color: #244b5a;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 8px 16px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.sync-height .experience-component,
.sync-height .herobanner,
.sync-height .herobanner-wrapper,
.sync-height .imageAndText,
.sync-height .imageAndText-wrapper,
.sync-height .highlight-card,
.sync-height .highlight-card-wrapper {
  height: 100%;
}
.sync-height .experience-component.experience-commerce_assets-spacing, .sync-height .experience-component.experience-commerce_assets-editorialRichText, .sync-height .experience-component.experience-commerce_assets-punchCardComponent, .sync-height .experience-component.experience-commerce_assets-ctaComponent, .sync-height .experience-component.experience-commerce_layouts-tableLayout {
  height: fit-content;
}
.sync-height .herobanner-wrapper,
.sync-height .imageAndText-wrapper,
.sync-height .highlight-card-wrapper {
  display: flex;
  flex-direction: column;
}
.sync-height .herobanner-wrapper .herobanner-block,
.sync-height .herobanner-wrapper .imageAndText-block,
.sync-height .herobanner-wrapper .highlight-card-block,
.sync-height .imageAndText-wrapper .herobanner-block,
.sync-height .imageAndText-wrapper .imageAndText-block,
.sync-height .imageAndText-wrapper .highlight-card-block,
.sync-height .highlight-card-wrapper .herobanner-block,
.sync-height .highlight-card-wrapper .imageAndText-block,
.sync-height .highlight-card-wrapper .highlight-card-block {
  flex-grow: 1;
}

.separator {
  background-color: #6d6d6d;
  height: 24px;
  margin: 0 16px;
  width: 1px;
}
.separator-small {
  height: 20px;
}
.separator-grey {
  background-color: #dbdbdb;
}
.separator-xs {
  height: 15px;
}

.separator-hr {
  border-color: #dbdbdb;
  margin-bottom: 24px;
  margin-top: 24px;
}

.btn-separator {
  align-items: center;
  display: flex;
  gap: 8px;
  width: 100%;
}
.btn-separator .separator-line {
  background-color: #dbdbdb;
  display: block;
  flex-grow: 1;
  height: 1px;
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #dbdbdb;
  border-left: 1px solid #fff;
  border-radius: 4px;
  border-right: 1px solid #fff;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #969593;
  border-radius: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track:horizontal {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
}

.back-to-top {
  background-color: #fff;
  border: 0;
  border-radius: 100%;
  bottom: 16px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  height: 50px;
  opacity: 0;
  position: fixed;
  right: 16px;
  visibility: hidden;
  width: 50px;
  z-index: 9;
}
.back-to-top.show {
  opacity: 1;
  visibility: visible;
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.no-scroll-top {
  margin: 0;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 992px) {
  .no-scroll-top {
    padding-right: 8px;
  }
}

@media (max-width: 991.98px) {
  .mobile-visibility {
    visibility: visible;
  }
  .mobile-visibility.mobile-hidden {
    visibility: hidden;
  }
}
.custom-list {
  line-height: 1.25rem;
  list-style: none;
  max-width: 836px;
  padding-left: 4px;
}
.custom-list li {
  padding: 0 0 8px 20px;
  position: relative;
}
.custom-list li::before {
  background-color: #4b4b4b;
  border-radius: 50%;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 5px;
}

.link-container {
  line-height: 1rem;
}

.drawer {
  background: transparent;
  border: 0;
  box-shadow: none;
  left: -321px;
  padding: 0;
  top: 43px;
}
.drawer .drawer-wrapper {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}
.drawer .drawer-wrapper .drawer-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.drawer .drawer-wrapper .drawer-container {
  background: #fff;
  bottom: -100%;
  max-height: calc(100% - 65px);
  position: absolute;
  transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);
  width: 100%;
}
@media (min-width: 992px) {
  .drawer .drawer-wrapper .drawer-container {
    bottom: unset;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    max-height: none;
    right: -100%;
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
    width: 474px;
  }
}
.drawer .drawer-wrapper .drawer-container.login-page {
  overflow-y: auto;
}
.drawer .drawer-wrapper .drawer-container .drawer-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
  padding: 16px 0 0;
}
@media (min-width: 992px) {
  .drawer .drawer-wrapper .drawer-container .drawer-header {
    margin: 0 32px;
  }
}
.drawer .drawer-wrapper .drawer-container .drawer-header-border {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 16px;
}
.drawer .drawer-wrapper .drawer-container .drawer-content {
  margin: 0 8px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding: 20px 8px;
}
@media (min-width: 992px) {
  .drawer .drawer-wrapper .drawer-container .drawer-content {
    height: calc(100% - 57px);
    margin: 0 16px;
    padding: 24px 16px;
  }
}
.drawer .drawer-wrapper .drawer-container .drawer-content .btn-change-address,
.drawer .drawer-wrapper .drawer-container .drawer-content .btn-select-suggestion {
  height: 48px;
  width: calc(50% - 8px);
}
.drawer .drawer-wrapper .drawer-container .drawer-content .thumb-image,
.drawer .drawer-wrapper .drawer-container .drawer-content .drawer-tile-container-image {
  display: inline-block;
  width: 72px;
}
.drawer .drawer-wrapper .drawer-container .drawer-content .thumb-image {
  border: 1px solid #ececec;
  border-radius: 4px;
}
.drawer .drawer-wrapper .drawer-container .drawer-content .wishlist-drawer-option {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 16px;
}
.drawer .drawer-wrapper .drawer-container .drawer-footer {
  border-top: 1px solid #dbdbdb;
  gap: 16px;
  padding: 24px 0;
}
.drawer.active .drawer-overlay {
  display: block;
}
.drawer.active .drawer-container {
  bottom: 0;
  transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);
}
@media (min-width: 992px) {
  .drawer.active .drawer-container {
    bottom: unset;
    right: 0;
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
  }
}

.drawer-addresses-option {
  cursor: pointer;
}
.drawer-addresses-option .drawer-address-label {
  align-items: flex-start;
  display: flex;
  padding-bottom: 16px;
  width: 100%;
}
.drawer-addresses-option .drawer-address-label .original-address-line,
.drawer-addresses-option .drawer-address-label .original-address-line2 {
  max-width: 365px;
  width: 100%;
}
.drawer-addresses-option .drawer-address-label .drawer-address-input {
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #8f8f8f;
  border-radius: 100%;
  cursor: pointer;
  margin: 0;
  margin-right: 16px;
  min-height: 24px;
  min-width: 24px;
  padding: 0;
  position: relative;
}

.suggested-addresses-wrapper .drawer-address-label:last-of-type {
  border-bottom: 1px solid #dbdbdb;
  margin: 0;
}

.unit-price {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.unit-price .list-price {
  font-size: 0.875rem;
  margin-left: 72px;
  margin-top: -18px;
}
.unit-price .sale-from {
  margin-left: 72px;
  margin-top: -18px;
}
.unit-price .sale-price {
  font-size: 0.875rem;
  margin-left: 72px;
}
.unit-price .member-price {
  align-items: center;
  background-color: #f6c862;
  border-radius: 4px;
  display: flex;
  margin-top: 10px;
  min-width: 220px;
  padding: 4px 8px;
  width: fit-content;
}
@media (min-width: 992px) {
  .unit-price .member-price {
    min-width: 180px;
  }
}
.unit-price .member-price .sale-price {
  margin: 0;
}
.unit-price .member-price .sale-price .member-price-value {
  font-size: 1rem;
}

.wishlist-messages,
.promo-error-message,
.toast-alert-message {
  display: none;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 15%;
  white-space: nowrap;
  width: fit-content;
  z-index: 991;
}

.toast-alert-message {
  align-items: center;
  display: flex;
  justify-content: center;
}
.toast-alert-message .toast-alert {
  align-items: center;
  border-radius: 4px;
  color: #262626;
  display: flex;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
  font-size: 1rem;
  font-weight: 800;
  height: 48px;
  justify-content: center;
  line-height: 100%;
  max-width: 90vw;
  padding: 8px 16px;
}
.toast-alert-message .toast-alert.alert-success {
  background-color: #f3f9fa;
}
.toast-alert-message .toast-alert.alert-danger {
  background-color: #f8eaea;
}

.add-to-wishlist-alert,
.update-wishlist-messages,
.remove-from-wishlist-alert {
  align-items: center;
  background-color: #f3f9fa;
  border-radius: 4px;
  color: #262626;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
  font-size: 1rem;
  font-weight: 800;
  padding: 12px 16px;
}
.add-to-wishlist-alert.show,
.update-wishlist-messages.show,
.remove-from-wishlist-alert.show {
  display: block;
}

.grecaptcha-badge {
  bottom: 80px !important;
  z-index: 8;
}

.wrapper-border-top {
  border-top: 1px solid #e9e9e9;
}

.opacity-zero {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.opacity-one {
  opacity: 1;
}

@media (max-width: 374.98px) {
  .collapsible-xs.collapsible-transition {
    min-height: 58px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }
  .collapsible-xs.collapsible-transition.popover-expanded {
    overflow: visible;
  }
  .collapsible-xs.collapsible-transition .collapsible-header {
    border: 0;
    flex: initial;
  }
  .collapsible-xs.collapsible-transition .collapsible-body {
    min-height: fit-content;
  }
  .collapsible-xs.collapsible-transition.default-active {
    height: auto;
  }
  .collapsible-xs .title {
    background-color: transparent;
    border: 0;
    color: #000;
    line-height: 2.5rem; /* 40/16 */
    padding: 0;
    text-decoration: none;
  }
  .collapsible-xs .title:hover {
    text-decoration: none;
  }
  .collapsible-xs .title .column-title-plus {
    display: block;
  }
  .collapsible-xs .title .column-title-minus {
    display: none;
  }
  .collapsible-xs .content,
  .collapsible-xs .card-body {
    display: none;
  }
  .collapsible-xs.active .title .column-title-plus {
    display: none;
  }
  .collapsible-xs.active .title .column-title-minus {
    display: block;
  }
  .collapsible-xs.active .content,
  .collapsible-xs.active .card-body {
    display: block;
  }
}
.container div.collapsible-xs button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-xs button.title:hover {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .collapsible-sm.collapsible-transition {
    min-height: 58px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }
  .collapsible-sm.collapsible-transition.popover-expanded {
    overflow: visible;
  }
  .collapsible-sm.collapsible-transition .collapsible-header {
    border: 0;
    flex: initial;
  }
  .collapsible-sm.collapsible-transition .collapsible-body {
    min-height: fit-content;
  }
  .collapsible-sm.collapsible-transition.default-active {
    height: auto;
  }
  .collapsible-sm .title {
    background-color: transparent;
    border: 0;
    color: #000;
    line-height: 2.5rem; /* 40/16 */
    padding: 0;
    text-decoration: none;
  }
  .collapsible-sm .title:hover {
    text-decoration: none;
  }
  .collapsible-sm .title .column-title-plus {
    display: block;
  }
  .collapsible-sm .title .column-title-minus {
    display: none;
  }
  .collapsible-sm .content,
  .collapsible-sm .card-body {
    display: none;
  }
  .collapsible-sm.active .title .column-title-plus {
    display: none;
  }
  .collapsible-sm.active .title .column-title-minus {
    display: block;
  }
  .collapsible-sm.active .content,
  .collapsible-sm.active .card-body {
    display: block;
  }
}
.container div.collapsible-sm button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-sm button.title:hover {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .collapsible-md.collapsible-transition {
    min-height: 58px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }
  .collapsible-md.collapsible-transition.popover-expanded {
    overflow: visible;
  }
  .collapsible-md.collapsible-transition .collapsible-header {
    border: 0;
    flex: initial;
  }
  .collapsible-md.collapsible-transition .collapsible-body {
    min-height: fit-content;
  }
  .collapsible-md.collapsible-transition.default-active {
    height: auto;
  }
  .collapsible-md .title {
    background-color: transparent;
    border: 0;
    color: #000;
    line-height: 2.5rem; /* 40/16 */
    padding: 0;
    text-decoration: none;
  }
  .collapsible-md .title:hover {
    text-decoration: none;
  }
  .collapsible-md .title .column-title-plus {
    display: block;
  }
  .collapsible-md .title .column-title-minus {
    display: none;
  }
  .collapsible-md .content,
  .collapsible-md .card-body {
    display: none;
  }
  .collapsible-md.active .title .column-title-plus {
    display: none;
  }
  .collapsible-md.active .title .column-title-minus {
    display: block;
  }
  .collapsible-md.active .content,
  .collapsible-md.active .card-body {
    display: block;
  }
}
.container div.collapsible-md button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-md button.title:hover {
  text-decoration: none;
}

@media (max-width: 1023.98px) {
  .collapsible-lg.collapsible-transition {
    min-height: 58px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }
  .collapsible-lg.collapsible-transition.popover-expanded {
    overflow: visible;
  }
  .collapsible-lg.collapsible-transition .collapsible-header {
    border: 0;
    flex: initial;
  }
  .collapsible-lg.collapsible-transition .collapsible-body {
    min-height: fit-content;
  }
  .collapsible-lg.collapsible-transition.default-active {
    height: auto;
  }
  .collapsible-lg .title {
    background-color: transparent;
    border: 0;
    color: #000;
    line-height: 2.5rem; /* 40/16 */
    padding: 0;
    text-decoration: none;
  }
  .collapsible-lg .title:hover {
    text-decoration: none;
  }
  .collapsible-lg .title .column-title-plus {
    display: block;
  }
  .collapsible-lg .title .column-title-minus {
    display: none;
  }
  .collapsible-lg .content,
  .collapsible-lg .card-body {
    display: none;
  }
  .collapsible-lg.active .title .column-title-plus {
    display: none;
  }
  .collapsible-lg.active .title .column-title-minus {
    display: block;
  }
  .collapsible-lg.active .content,
  .collapsible-lg.active .card-body {
    display: block;
  }
}
.container div.collapsible-lg button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-lg button.title:hover {
  text-decoration: none;
}

@media (max-width: 1259.98px) {
  .collapsible-lge.collapsible-transition {
    min-height: 58px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }
  .collapsible-lge.collapsible-transition.popover-expanded {
    overflow: visible;
  }
  .collapsible-lge.collapsible-transition .collapsible-header {
    border: 0;
    flex: initial;
  }
  .collapsible-lge.collapsible-transition .collapsible-body {
    min-height: fit-content;
  }
  .collapsible-lge.collapsible-transition.default-active {
    height: auto;
  }
  .collapsible-lge .title {
    background-color: transparent;
    border: 0;
    color: #000;
    line-height: 2.5rem; /* 40/16 */
    padding: 0;
    text-decoration: none;
  }
  .collapsible-lge .title:hover {
    text-decoration: none;
  }
  .collapsible-lge .title .column-title-plus {
    display: block;
  }
  .collapsible-lge .title .column-title-minus {
    display: none;
  }
  .collapsible-lge .content,
  .collapsible-lge .card-body {
    display: none;
  }
  .collapsible-lge.active .title .column-title-plus {
    display: none;
  }
  .collapsible-lge.active .title .column-title-minus {
    display: block;
  }
  .collapsible-lge.active .content,
  .collapsible-lge.active .card-body {
    display: block;
  }
}
.container div.collapsible-lge button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-lge button.title:hover {
  text-decoration: none;
}

@media (max-width: 1439.98px) {
  .collapsible-llg.collapsible-transition {
    min-height: 58px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }
  .collapsible-llg.collapsible-transition.popover-expanded {
    overflow: visible;
  }
  .collapsible-llg.collapsible-transition .collapsible-header {
    border: 0;
    flex: initial;
  }
  .collapsible-llg.collapsible-transition .collapsible-body {
    min-height: fit-content;
  }
  .collapsible-llg.collapsible-transition.default-active {
    height: auto;
  }
  .collapsible-llg .title {
    background-color: transparent;
    border: 0;
    color: #000;
    line-height: 2.5rem; /* 40/16 */
    padding: 0;
    text-decoration: none;
  }
  .collapsible-llg .title:hover {
    text-decoration: none;
  }
  .collapsible-llg .title .column-title-plus {
    display: block;
  }
  .collapsible-llg .title .column-title-minus {
    display: none;
  }
  .collapsible-llg .content,
  .collapsible-llg .card-body {
    display: none;
  }
  .collapsible-llg.active .title .column-title-plus {
    display: none;
  }
  .collapsible-llg.active .title .column-title-minus {
    display: block;
  }
  .collapsible-llg.active .content,
  .collapsible-llg.active .card-body {
    display: block;
  }
}
.container div.collapsible-llg button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-llg button.title:hover {
  text-decoration: none;
}

@media (max-width: 1919.98px) {
  .collapsible-xl.collapsible-transition {
    min-height: 58px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }
  .collapsible-xl.collapsible-transition.popover-expanded {
    overflow: visible;
  }
  .collapsible-xl.collapsible-transition .collapsible-header {
    border: 0;
    flex: initial;
  }
  .collapsible-xl.collapsible-transition .collapsible-body {
    min-height: fit-content;
  }
  .collapsible-xl.collapsible-transition.default-active {
    height: auto;
  }
  .collapsible-xl .title {
    background-color: transparent;
    border: 0;
    color: #000;
    line-height: 2.5rem; /* 40/16 */
    padding: 0;
    text-decoration: none;
  }
  .collapsible-xl .title:hover {
    text-decoration: none;
  }
  .collapsible-xl .title .column-title-plus {
    display: block;
  }
  .collapsible-xl .title .column-title-minus {
    display: none;
  }
  .collapsible-xl .content,
  .collapsible-xl .card-body {
    display: none;
  }
  .collapsible-xl.active .title .column-title-plus {
    display: none;
  }
  .collapsible-xl.active .title .column-title-minus {
    display: block;
  }
  .collapsible-xl.active .content,
  .collapsible-xl.active .card-body {
    display: block;
  }
}
.container div.collapsible-xl button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-xl button.title:hover {
  text-decoration: none;
}

.collapsible-xxl.collapsible-transition {
  min-height: 58px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}
.collapsible-xxl.collapsible-transition.popover-expanded {
  overflow: visible;
}
.collapsible-xxl.collapsible-transition .collapsible-header {
  border: 0;
  flex: initial;
}
.collapsible-xxl.collapsible-transition .collapsible-body {
  min-height: fit-content;
}
.collapsible-xxl.collapsible-transition.default-active {
  height: auto;
}
.collapsible-xxl .title {
  background-color: transparent;
  border: 0;
  color: #000;
  line-height: 2.5rem; /* 40/16 */
  padding: 0;
  text-decoration: none;
}
.collapsible-xxl .title:hover {
  text-decoration: none;
}
.collapsible-xxl .title .column-title-plus {
  display: block;
}
.collapsible-xxl .title .column-title-minus {
  display: none;
}
.collapsible-xxl .content,
.collapsible-xxl .card-body {
  display: none;
}
.collapsible-xxl.active .title .column-title-plus {
  display: none;
}
.collapsible-xxl.active .title .column-title-minus {
  display: block;
}
.collapsible-xxl.active .content,
.collapsible-xxl.active .card-body {
  display: block;
}

.container div.collapsible-xxl button.title {
  background-color: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.container div.collapsible-xxl button.title:hover {
  text-decoration: none;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #ececec;
  color: #4b4b4b;
  font-family: "sofia-pro";
}
.footer ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.footer .social {
  margin-top: 32px;
  padding-left: 0;
  padding-right: 0;
}
.footer .social::after {
  display: block;
  clear: both;
  content: "";
}
.footer .footer-top-links {
  color: #244b5a;
  padding: 64px 0;
}
@media (max-width: 991.98px) {
  .footer .footer-top-links {
    padding: 32px 0;
  }
}
.footer .footer-top-links .footer-top-heading {
  color: #244b5a;
  font-size: 1.8125rem;
}
@media (min-width: 992px) {
  .footer .footer-top-links .footer-top-heading {
    font-size: 2.25rem;
  }
}
.footer .footer-top-links .footer-right-links {
  margin-left: 6.5%;
}
@media (max-width: 991.98px) {
  .footer .footer-top-links .footer-right-links {
    margin-left: 0;
    margin-top: 12px;
  }
}
.footer .footer-top-links .menu-footer {
  color: #262626;
  display: block;
  font-size: 0.875rem;
  margin: 0 24px;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .footer .footer-top-links .menu-footer {
    margin: 0 32px;
  }
}
.footer .footer-top-links .menu-footer .footer-top-icon {
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 35px;
}
@media (min-width: 992px) {
  .footer .footer-top-links .menu-footer .footer-top-icon {
    height: 50px;
    width: 50px;
  }
}
.footer .footer-rewards {
  margin-top: 32px;
}
@media (min-width: 992px) {
  .footer .footer-rewards {
    border-left: 1px solid #dbdbdb;
    margin-top: 0;
    padding-left: 32px;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .footer .footer-rewards {
    border-left: 0;
  }
}
.footer .footer-rewards li {
  margin-bottom: 8px;
}
.footer .footer-rewards img {
  margin-bottom: 8px;
  margin-top: 8px;
}
.footer .footer-rewards .rewards-subtitle {
  font-size: 1.25rem;
}
.footer .footer-rewards .footer-rewards-title {
  color: #244b5a;
  font-size: 1.8125rem;
}
.footer .footer-rewards .footer-rewards-subtitle {
  font-size: 1rem;
  margin-bottom: 16px;
}
.footer .footer-rewards .footer-rewards-text {
  color: #262626;
  font-family: "sofia-pro";
  font-size: 0.875rem;
  line-height: 16px;
}
.footer .footer-rewards .rewards-signup {
  display: flex;
}
.footer .footer-rewards .footer-anchor {
  font-weight: 500;
  margin-right: 16px;
  text-decoration-line: underline;
}
.footer .footer-rewards .disclaimer {
  color: #50797b;
}
.footer .footer-rewards .rewards-disclaimer {
  font-size: 0.813rem;
}
.footer .footer-rewards .ui-icon-check {
  margin-right: 12px;
}
.footer .footer-rewards .ui-icon-check svg {
  height: 15px;
  width: 22px;
}
.footer .footer-contact-us {
  font-family: "sofia-pro";
  font-size: 0.875rem;
  margin-top: 32px;
  padding: 0 0 24px;
}
@media (min-width: 992px) {
  .footer .footer-contact-us {
    margin-top: 16px;
  }
}
.footer .footer-contact-us .first-item {
  font-size: 1rem;
}
.footer .footer-contact-us .footer-contact-us-title {
  font-size: 1.25rem;
}
.footer .footer-contact-us .footer-contact-us-row {
  display: flex;
}
.footer .footer-contact-us .footer-contact-us-row.single {
  align-items: center;
}
.footer .footer-contact-us svg {
  height: 32px;
  margin: 8px 8px 0 0;
  width: 32px;
}
.footer .footer-email-input {
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 16px;
}
.footer .footer-email-input .form-control {
  flex: 1;
}
.footer .footer-email-input .invalid-feedback {
  flex: 0 0 100%;
}
.footer .footer-subscribe-email {
  border-radius: 0 4px 4px 0;
  padding: 16px;
}
.footer .footer-subscribe-email:hover {
  color: #f3f9fa;
}
.footer .footer-subscribe-email:visited, .footer .footer-subscribe-email:active {
  border-color: #537c7f;
  box-shadow: unset;
  color: #f3f9fa;
}
.footer .social-title {
  font-size: 1.25rem;
}
.footer .social-links::after {
  display: block;
  clear: both;
  content: "";
}
@media (max-width: 374.98px) {
  .footer .social-links {
    width: 80%;
  }
}
.footer .social-links li {
  float: left;
  margin: 0.313em;
}
@media (max-width: 374.98px) {
  .footer .social-links li {
    text-align: center;
  }
}
.footer .social-links .footer-social-icon svg {
  height: 40px;
  width: 40px;
}
.footer .social-links .footer-social-icon svg:hover {
  text-decoration: none;
}
@media (max-width: 374.98px) {
  .footer .store .content {
    display: none;
  }
  .footer .store h2::after {
    display: block;
    clear: both;
    content: "";
  }
  .footer .store h2::after {
    content: "\f041";
    float: right;
    font-family: "FontAwesome";
  }
}
.footer .view-rewards {
  background: #537c7f;
  height: 48px;
  margin-bottom: 32px;
  padding: 8px 24px;
  width: 100%;
}
.footer .view-rewards:focus {
  background-color: #537c7f;
}
@media (min-width: 992px) {
  .footer .footer-container {
    padding-bottom: 56px;
    padding-top: 48px;
  }
}
.footer .footer-container .footer-item {
  border-top: 1px solid #dbdbdb;
  font-size: 1rem;
  font-weight: 500;
}
@media (min-width: 992px) {
  .footer .footer-container .footer-item {
    border-top: 0;
    padding: 0 32px;
  }
  .footer .footer-container .footer-item.first {
    border-right: 1px solid #dbdbdb;
  }
  .footer .footer-container .footer-item:not(.first) {
    margin-left: 0;
    padding: 0 32px;
  }
}
@media (min-width: 992px) {
  .footer .footer-container .footer-item {
    border-right: 1px solid #dbdbdb;
    margin-left: -16px;
  }
  .footer .footer-container .footer-item:not(.first) {
    margin-left: 0;
  }
}
.footer .footer-container .footer-item .footer-column-title {
  align-items: center;
  color: #262626;
  display: flex;
  font-size: 1rem;
  font-weight: 800;
  justify-content: space-between;
  padding: 24px 0;
}
@media (min-width: 992px) {
  .footer .footer-container .footer-item .footer-column-title {
    font-family: "sofia-pro";
    font-size: 1rem;
    padding: 0 0 24px 0;
  }
}
.footer .footer-container .footer-item .footer-column-title[aria-expanded=true] {
  padding-bottom: 16px;
}
.footer .footer-container .footer-item .footer-column-title .button-title {
  flex: 1;
  line-height: 120%;
  text-transform: none;
}
.footer .footer-container .footer-item li {
  padding: 8px 0;
}
.footer .footer-container .footer-item li:first-child {
  padding-top: 0;
}
.footer .footer-container .footer-item a:hover {
  color: #4b4b4b;
}
.footer .copyright-notice {
  border-top: 1px solid #dbdbdb;
}
.footer .copyright-notice .copyright-container {
  margin: 24px 0;
}
.footer .copyright-notice .copyright-container .copyright {
  color: #262626;
  margin-top: 24px;
  margin-top: 0;
}

.consent-tracking-cookie {
  bottom: 0;
  height: auto;
  top: auto;
}
.consent-tracking-cookie .modal-dialog {
  margin: 0;
  max-width: 100%;
}
.consent-tracking-cookie .modal-content {
  align-items: flex-end;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: unset;
  justify-content: space-between;
  padding: 40px;
  position: relative;
}
@media (max-width: 991.98px) {
  .consent-tracking-cookie .modal-content {
    display: block;
    padding: 40px 24px 24px;
  }
}
.consent-tracking-cookie .modal-content .close-consent {
  position: absolute;
  right: 42px;
  top: 14px;
}
@media (max-width: 991.98px) {
  .consent-tracking-cookie .modal-content .close-consent {
    right: 18px;
  }
}
.consent-tracking-cookie .modal-content .close-consent button {
  opacity: unset;
}
.consent-tracking-cookie .modal-content .close-consent button:hover {
  opacity: unset;
}
.consent-tracking-cookie .modal-content .modal-header {
  display: block;
  margin-right: 20px;
  padding: 0;
}
@media (max-width: 991.98px) {
  .consent-tracking-cookie .modal-content .modal-header {
    margin-right: 0;
  }
}
.consent-tracking-cookie .modal-content .modal-header .modal-body {
  padding: 0;
}
.consent-tracking-cookie .tracking-consent {
  max-width: 720px;
  width: 100%;
}
.consent-tracking-cookie .tracking-consent p {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .consent-tracking-cookie .tracking-consent p {
    margin-bottom: 24px;
  }
}

.consent-modal-footer {
  border: 0;
  padding: 0;
}
@media (max-width: 991.98px) {
  .consent-modal-footer {
    justify-content: flex-start;
  }
}
.consent-modal-footer .button-wrapper {
  display: flex;
  margin: 0;
}
.consent-modal-footer .button-wrapper .btn:first-child {
  padding: 16px 90px;
}
@media (max-width: 991.98px) {
  .consent-modal-footer .button-wrapper .btn:first-child {
    padding: 16px 63px;
  }
}
.consent-modal-footer .button-wrapper .btn:last-child {
  letter-spacing: 0;
  margin-left: 32px;
  padding: 0;
  text-decoration: underline;
  text-transform: unset;
}
@media (max-width: 991.98px) {
  .consent-modal-footer .button-wrapper .btn:last-child {
    margin-left: 24px;
  }
}

.email-signup-message {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.email-signup-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.email-signup-alert.show {
  display: block;
}

.email-signup-container {
  max-width: 644px;
}
@media (max-width: 991.98px) {
  .email-signup-container {
    margin: 10px;
  }
}
.email-signup-container .form-group .invalid-feedback {
  color: #fff;
}
@media (max-width: 991.98px) {
  .email-signup-container .modal-form {
    margin-top: 8px;
  }
}
@media (max-width: 991.98px) {
  .email-signup-container .privacy-text {
    font-size: 0.875rem;
    padding-top: 0;
  }
}
.email-signup-container .modal-title-center {
  font-size: 2.875rem;
}
@media (max-width: 991.98px) {
  .email-signup-container .title-modal-signup {
    font-size: 1.125rem;
  }
  .email-signup-container .title-modal-signup span {
    font-size: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .email-signup-container .title-modal-subscribe {
    font-size: 1.4375rem;
    margin-top: 4px;
  }
  .email-signup-container .title-modal-subscribe span {
    font-size: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .email-signup-container .modal-image {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .email-signup-container .modal-body-email p {
    margin-top: 0;
    padding: 8px;
  }
}
@media (max-width: 991.98px) {
  .email-signup-container .footer-signup-text {
    font-size: 1rem;
    margin-bottom: 18px;
    margin-top: 0;
  }
}
@media (max-width: 991.98px) {
  .email-signup-container .sign-up-button {
    margin: 0 16px;
  }
}
@media (max-width: 991.98px) {
  .email-signup-container br {
    display: none;
  }
}

.confirmation-email-modal {
  background-color: #b4292f;
}

#emailSignupModal {
  z-index: 2001;
}

#toast-container {
  left: 50%;
  position: fixed;
  top: 15%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
#toast-container .toast {
  background-color: #f3f9fa;
  border-radius: 4px;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  color: #262626;
  font-size: 1rem;
  font-weight: 800;
  padding: 14px 16px;
}

@media (max-width: 1023.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    bottom: 0;
    display: block;
    left: -110%;
    max-width: 319px;
    position: fixed;
    top: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    width: 85%;
  }
  .menu-toggleable-left.navbar-toggleable-md.in {
    left: 0;
  }
  .menu-toggleable-left.navbar-toggleable-md.in .close-button {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1023.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    max-width: 447px;
  }
}

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0;
}
@media (min-width: 1024px) {
  .navbar.bg-inverse .nav-item {
    margin: 0 24px;
    position: inherit;
    width: auto !important;
  }
  .navbar.bg-inverse .nav-item:first-child {
    margin-left: 0;
  }
  .navbar.bg-inverse .nav-item:last-child {
    margin-right: 0;
  }
  .navbar.bg-inverse .nav-item.show::after {
    background-color: #b4292f;
    content: "";
    display: block;
    height: 4px;
    margin: -4px 0;
    width: 100%;
  }
  .navbar.bg-inverse .navbar-nav .nav-item + .nav-item {
    margin-left: 24px;
  }
  .navbar.bg-inverse .nav .nav-link {
    align-items: center;
    color: #262626;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    white-space: nowrap;
  }
  .navbar.bg-inverse .nav .nav-link span {
    font-family: "sofia-pro";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
  }
  .navbar.bg-inverse .nav .nav-link svg {
    display: none;
  }
  .navbar.bg-inverse .first-navbar:not(.slick-initialized) .nav-item:nth-child(2) {
    margin-left: 0;
  }
  .navbar.bg-inverse .first-navbar:not(.slick-initialized) .nav-item:not(.d-lg-none):last-child {
    margin-right: 0;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .navbar.bg-inverse .slick-list .nav-item {
    margin: 0 24px;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .navbar.bg-inverse .slick-list .slick-slide:nth-child(2) .nav-item {
    margin-left: 0;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .navbar.bg-inverse .slick-list .slick-slide:last-child .nav-item {
    margin-right: 0;
  }
}
.navbar.bg-inverse .navbar-nav {
  border-bottom: 1px solid #ececec;
}
@media (min-width: 1024px) {
  .navbar.bg-inverse .navbar-nav {
    border: 0;
    flex-wrap: nowrap;
  }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: #fff;
  color: #4b4b4b;
}

.nav-link:focus-visible {
  outline: none;
}
.nav-link:focus-visible span {
  border: 2px solid #000;
}

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left {
    background-color: #fff;
    z-index: 11;
  }
}
@media (min-width: 1024px) {
  .main-menu.menu-toggleable-left {
    position: relative;
  }
  .main-menu.menu-toggleable-left .slick-arrow {
    align-items: center;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    cursor: pointer;
    display: flex !important;
    height: 100%;
    justify-content: start;
    left: -1px;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: auto;
    top: 0;
    transition: all 0.8s;
    width: 148px;
    z-index: 1;
  }
  .main-menu.menu-toggleable-left .slick-arrow.slick-next {
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    justify-content: end;
    left: auto;
    right: -1px;
  }
  .main-menu.menu-toggleable-left .slick-arrow.slick-disabled {
    display: none !important;
  }
}

@media (max-width: 1023.98px) {
  .menu-toggleable-left .bg-inverse {
    background-color: #fff !important;
    color: #7d7d7d;
  }
  .menu-toggleable-left .menu-group {
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 80px;
  }
  .menu-toggleable-left .dropdown-button svg {
    height: 16px;
    margin-left: auto;
    width: 16px;
  }
}
.menu-toggleable-left .close-menu {
  background-color: #fff;
  border-bottom: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .menu-toggleable-left .close-menu {
    display: none;
  }
}
.menu-toggleable-left .close-menu button {
  appearance: none;
  background-color: transparent;
  border: 0;
}
.menu-toggleable-left .close-menu .close-button {
  background: none;
  border: 0;
  display: none;
  left: 85%;
  margin: 0;
  max-width: 56px;
  padding: 0;
  position: fixed;
  right: auto;
  text-align: center;
  top: 16px;
  width: 15%;
}
@media (min-width: 375px) {
  .menu-toggleable-left .close-menu .close-button {
    left: 328px;
    width: calc(100vw - 328px);
  }
}
@media (min-width: 992px) {
  .menu-toggleable-left .close-menu .close-button {
    left: 447px;
    width: calc(100vw - 447px);
  }
}
.menu-toggleable-left .close-menu .selected-category {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-toggleable-left .menu-group {
  flex: 0 0 100%;
}
@media (min-width: 1024px) {
  .menu-toggleable-left .menu-group {
    position: relative;
    width: 100%;
  }
}
.menu-toggleable-left li > .close-menu {
  margin-left: 0;
  margin-right: 0;
  margin-top: -0.6rem;
}
@media (max-width: 374.98px) {
  .menu-toggleable-left.in {
    margin-right: 1.25em; /* 20/16 */
    right: 0;
  }
}
@media (max-width: 1023.98px) {
  .menu-toggleable-left.in .nav-item + .nav-item {
    border-top: 1px solid #ececec;
  }
  .menu-toggleable-left.in .nav-item {
    display: block;
    position: static;
    width: 100%;
  }
  .menu-toggleable-left.in .dropdown-button {
    align-items: center;
    color: #262626;
    display: flex;
    font-size: 0.875rem;
    justify-content: space-between;
  }
  .menu-toggleable-left.in .no-dropdown {
    align-items: center;
    display: flex;
  }
}
@media (max-width: 1023.98px) and (min-width: 1024px) {
  .menu-toggleable-left.in .no-dropdown {
    font-size: 0.875rem;
  }
}
@media (max-width: 1023.98px) {
  .menu-toggleable-left.in .nav-item .nav-link {
    color: #4b4b4b;
    height: 48px;
    padding: 8px 16px;
  }
  .menu-toggleable-left.in .nav-item .nav-link span {
    border: 0;
    font-family: "sofia-pro";
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    outline: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .menu-toggleable-left.in .nav-item .nav-link .secondary-nav-item {
    font-size: 0.875rem;
    font-weight: 400;
  }
}
@media (max-width: 1023.98px) {
  .menu-toggleable-left.in .dropdown .nav-link span {
    max-width: 75%;
  }
}
@media (max-width: 1023.98px) {
  .menu-toggleable-left.in .show > .dropdown-menu {
    height: 100dvh;
    left: 0;
    margin-top: 0;
    max-width: 319px;
    overflow-y: auto;
    position: fixed;
    width: 85%;
  }
}
@media (max-width: 1023.98px) and (min-width: 992px) and (max-width: 1023.98px) {
  .menu-toggleable-left.in .show > .dropdown-menu {
    max-width: 447px;
  }
}
@media (max-width: 1023.98px) {
  .menu-toggleable-left.in .dropdown-menu {
    border: 0 none;
    display: block;
    height: 100%;
    height: 100dvh;
    left: -110%;
    position: absolute;
    top: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    width: 100%;
  }
}
@media (max-width: 1023.98px) {
  .menu-toggleable-left.in .container {
    padding: 0 16px;
  }
}
@media (max-width: 1023.98px) {
  .menu-toggleable-left.in .dropdown-item {
    font-size: 0.875rem;
    line-height: 100%;
  }
  .menu-toggleable-left.in .dropdown-item:not(.semi-bold) {
    font-weight: 400;
  }
  .menu-toggleable-left.in .dropdown-item .dropdown-link {
    font-size: 0.875rem;
    font-weight: 400;
  }
  .menu-toggleable-left.in .dropdown-item--no-border {
    border-bottom: 0;
  }
}

.multilevel-dropdown .dropdown-menu {
  border: 0;
  border-radius: 0;
  top: 90%;
}
@media (min-width: 1024px) {
  .multilevel-dropdown .dropdown-menu {
    background-color: #fcfcfc;
    box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
    padding: 32px 88px 0;
    top: 98%;
    width: 100%;
  }
  .multilevel-dropdown .dropdown-menu.show {
    border-top: 1px solid #dbdbdb;
    display: flex;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    z-index: 4;
  }
}
.multilevel-dropdown .dropdown-item.dropdown > .dropdown-toggle::after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-top: 0.3em solid transparent;
  display: none;
  margin-top: 0.55em;
  position: absolute;
  right: 0.3em;
}
@media (min-width: 1024px) {
  .multilevel-dropdown .dropdown-menu > .dropdown > .dropdown-menu {
    left: 99%;
    top: -0.65em;
  }
}
.multilevel-dropdown .navbar > .close-menu > .back {
  display: none;
}
.multilevel-dropdown .close-menu .back-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
}
.multilevel-dropdown .close-menu .back-button svg {
  height: 24px;
  width: 24px;
}
.multilevel-dropdown .dropdown-item {
  border-bottom: 1px solid #f3f3f3;
  padding: 0;
}
.multilevel-dropdown .dropdown-item:active, .multilevel-dropdown .dropdown-item:focus, .multilevel-dropdown .dropdown-item:hover {
  background-color: transparent;
  color: #262626;
}
.multilevel-dropdown .dropdown-item + .dropdown-item {
  border-top: 0;
}
.multilevel-dropdown .dropdown-item .dropdown-link {
  color: #262626;
  display: block;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%;
  overflow-x: clip;
  padding: 0;
  text-overflow: ellipsis;
  width: 100%;
}
@media (min-width: 1024px) {
  .multilevel-dropdown .dropdown-item .dropdown-link {
    border: 0;
    font-size: 0.875rem;
    font-weight: 800;
    padding: 8px 0;
    text-align: left;
    width: auto;
  }
  .multilevel-dropdown .dropdown-item .dropdown-link:hover, .multilevel-dropdown .dropdown-item .dropdown-link:focus {
    background-color: transparent;
    text-decoration: none;
  }
}
.multilevel-dropdown .dropdown-item .dropdown-link:hover, .multilevel-dropdown .dropdown-item .dropdown-link:focus {
  text-decoration: none;
}
.multilevel-dropdown .dropdown-item.top-category, .multilevel-dropdown .dropdown-item.top-sub-category {
  font-size: 1rem;
  font-weight: 800;
}
.multilevel-dropdown .dropdown-item.top-category > .nav-link, .multilevel-dropdown .dropdown-item.top-sub-category > .nav-link {
  background-color: transparent;
  padding: 16px 0;
}
.multilevel-dropdown .dropdown-item.top-category .category-thumbnail,
.multilevel-dropdown .dropdown-item.top-category svg, .multilevel-dropdown .dropdown-item.top-sub-category .category-thumbnail,
.multilevel-dropdown .dropdown-item.top-sub-category svg {
  display: none;
}

.secondary-nav {
  position: relative;
}
.secondary-nav .navbar {
  flex-direction: column;
  justify-content: center;
}
.secondary-nav .navbar .nav .nav-link span {
  font-weight: 400;
}
.secondary-nav.multilevel-dropdown .dropdown-menu {
  margin: auto;
  max-width: 878px;
  padding: 32px;
  width: 100%;
}
.secondary-nav.multilevel-dropdown .dropdown-menu .subcategory-container {
  padding: 0;
  width: 100%;
}
.secondary-nav.multilevel-dropdown .dropdown-menu .col > .dropdown-item {
  margin: 0;
  padding: 0 24px;
}
.secondary-nav.multilevel-dropdown .dropdown-menu .subnav .subnav-item {
  margin-right: 0;
}
.secondary-nav .content-asset-image-menu img {
  max-width: 100%;
}

.category-thumbnail {
  border-radius: 4px;
  height: 32px;
  margin-right: 16px;
  width: 32px;
}
@media (min-width: 1024px) {
  .category-thumbnail {
    display: none !important;
  }
}

.label-categories-mobile {
  border-bottom: 1px solid #ececec;
  color: #4b4b4b;
}

.utility-links-menu-container .navbar-login .user-anchor .logged-user {
  height: 16px;
  width: 16px;
}
@media (min-width: 1024px) {
  .utility-links-menu-container .navbar-login .user-anchor .logged-user {
    height: 24px;
    width: 24px;
  }
  .utility-links-menu-container .navbar-login .user-anchor .logged-user .text {
    font-size: 0.75rem;
  }
}
.utility-links-menu-container .navbar-login .user-anchor .logged-user .text {
  font-size: 0.5rem;
  margin: 0;
}
.utility-links-menu-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.utility-links-menu {
  background-color: #f9f9f9;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin: 0 16px 8px;
  padding: 16px 20px;
}
@media (min-width: 1024px) {
  .utility-links-menu {
    display: none;
  }
}
.utility-links-menu:first-of-type {
  margin-top: 16px;
}
.utility-links-menu .reward-block {
  display: none;
}
.utility-links-menu .navbar-location {
  display: flex;
}
.utility-links-menu .navbar-location:active, .utility-links-menu .navbar-location:hover {
  background-color: transparent;
}
.utility-links-menu .navbar-location .location {
  flex-grow: 1;
}
.utility-links-menu .navbar-location .location .location-anchor {
  padding: 0;
  width: 100%;
}
.utility-links-menu .navbar-location .location .location-anchor .location-text {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0;
}
.utility-links-menu .navbar-login {
  display: flex;
  padding: 0;
}
.utility-links-menu .navbar-login .user-anchor .account-text {
  display: flex;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .utility-links-menu .navbar-login .user-anchor .account-text {
    font-weight: 500;
  }
}
.utility-links-menu .user .icon-login {
  height: 16px;
  stroke: #50797b;
  width: 16px;
}
@media (min-width: 1024px) {
  .utility-links-menu .user .icon-login {
    height: 24px;
    stroke: #262626;
    width: 24px;
  }
}
.utility-links-menu .user .popover {
  max-width: 319px;
  padding: 16px;
  top: 0;
}

.inspiration-menu-container {
  border-radius: 8px;
  color: #fff;
  font-family: "questa-grande";
  font-size: 1.625rem;
  font-weight: 900;
  height: 148px;
  margin: 16px auto;
  width: 287px;
}
@media (min-width: 1024px) {
  .inspiration-menu-container {
    display: none;
  }
}
.inspiration-menu-container a {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 16px 24px;
}
.inspiration-menu-container a:hover, .inspiration-menu-container a:focus {
  color: #fff;
  text-decoration: none;
}
.inspiration-menu-container .content-asset-wrapper,
.inspiration-menu-container .position-relative,
.inspiration-menu-container .inspiration-menu {
  height: 100%;
}

.content-asset-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .content-asset-menu {
    border-right: 1px solid #ececec;
    flex-direction: column;
    margin: 0 8px 32px;
    min-width: 250px;
  }
}
@media (min-width: 1024px) {
  .content-asset-menu li {
    margin-bottom: 16px;
  }
}
.content-asset-menu li a {
  display: block;
  font-family: "sofia-pro";
  font-size: 0.875rem;
  padding: 8px 16px;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .content-asset-menu li a {
    font-weight: 800;
    line-height: 120%;
    padding: 0;
  }
}

.content-asset-image-menu {
  display: none;
}
@media (min-width: 1024px) {
  .content-asset-image-menu {
    display: flex;
    flex-direction: column;
  }
}
.content-asset-image-menu img {
  border-radius: 8px;
  max-width: 250px;
}
.content-asset-image-menu a {
  align-items: center;
  color: #262626;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  margin-top: 8px;
  text-decoration: none;
}

.dropdown-menu .nav-menu .close-menu {
  align-items: center;
  background-color: #fefaef;
  border-bottom: 1px solid #ececec;
  display: flex;
}
.dropdown-menu .nav-menu .close-menu .close-button {
  display: none;
}

.subcategory-container {
  border-top: 1px solid #ececec;
  display: block;
  margin-bottom: 16px;
  padding-left: 0;
  padding-top: 18px;
}
@media (min-width: 1024px) {
  .subcategory-container {
    border: 0;
    height: 100%;
    margin: 0;
    padding: 0 24px;
    width: 80%;
  }
}
.subcategory-container .dropdown-item {
  padding: 12px 16px;
  width: auto;
}
@media (min-width: 1024px) {
  .subcategory-container .dropdown-item {
    border: 0;
    border-radius: unset;
    padding: 0 16px;
    position: static;
    white-space: normal;
  }
  .subcategory-container .dropdown-item a {
    font-size: 0.875rem;
    font-weight: 800;
  }
  .subcategory-container .dropdown-item svg {
    display: none;
  }
}
@media (min-width: 1920px) {
  .subcategory-container .dropdown-item {
    margin-right: 48px;
  }
}
.subcategory-container .dropdown-item .category-name {
  line-height: 120%;
  max-width: 90%;
  overflow-x: clip;
  text-overflow: ellipsis;
}
@media (min-width: 1024px) {
  .subcategory-container .dropdown-item .category-name {
    display: block;
    line-height: 120%;
    margin-bottom: 16px;
    overflow: visible;
  }
}
.subcategory-container .dropdown.show > .subnav,
.subcategory-container .subnav-item.show > .subnav {
  left: 0;
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
}

.subnav {
  background-color: #fff;
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  width: 100%;
}
@media (min-width: 1024px) {
  .subnav {
    background-color: #fcfcfc;
    font-family: "sofia-pro";
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    left: initial;
    line-height: 110%;
    list-style-type: none;
    margin: 0;
    margin-top: 16px;
    padding: 0 0 16px;
    position: static;
    top: 0;
    width: auto;
  }
}
.subnav .close-menu {
  background-color: #fefaef;
  border-bottom: 1px solid #ececec;
  padding: 16px 0;
}
.subnav .close-menu span {
  color: #262626;
  font-family: "sofia-pro";
  font-weight: 800;
  padding-left: 8px;
}
.subnav .subnav-item {
  border-bottom: 1px solid #ececec;
  padding: 12px 16px;
}
@media (min-width: 1024px) {
  .subnav .subnav-item {
    border: 0;
    padding: 0;
  }
  .subnav .subnav-item a {
    color: #4b4b4b;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 120%;
    padding: 0 0 14px;
  }
  .subnav .subnav-item svg {
    display: none;
  }
}

.food-games-wrapper {
  background: var(--banner-bg-img) no-repeat center center/cover;
  background-color: var(--banner-bg-color);
  border: 1px solid #496a76;
  border-radius: 8px;
}

.punch-cards-block {
  display: inline-block;
  margin: 16px 0;
  position: relative;
}

.punch-cards-wrapper {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  padding: 24px;
}
.punch-cards-wrapper .punch-card {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}
.punch-cards-wrapper .punch-card .progress-text {
  color: #000;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}
@media (min-width: 992px) {
  .punch-cards-wrapper .punch-card .progress-text {
    font-size: 1.25rem;
  }
}
.punch-cards-wrapper .punch-card .progress-text b {
  color: var(--accent-color, inherit);
}
.punch-cards-wrapper .punch-card-header {
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  .punch-cards-wrapper .punch-card-header {
    font-size: 1.625rem;
  }
}
.punch-cards-wrapper .icon-wrapper {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  padding: 0 6px;
  width: 24px;
}
.punch-cards-wrapper .icon-wrapper .feature-icon {
  height: 24px;
  width: 18px;
}
.punch-cards-wrapper .icon-wrapper .award-icon {
  height: 24px;
  width: 24px;
}

.punch-banner-wrapper .progress-text {
  display: none;
}
.punch-banner-wrapper .punch-card-header {
  border-top: 1px solid #dbdbdb;
  display: block;
  margin: 12px 0;
  padding-top: 12px;
}

@media (max-width: 767.98px) {
  .reward-bronze .bronze-medal,
  .reward-bronze .silver-medal,
  .reward-bronze .gold-medal,
  .reward-bronze .grey-medal,
  .reward-silver .bronze-medal,
  .reward-silver .silver-medal,
  .reward-silver .gold-medal,
  .reward-silver .grey-medal,
  .reward-gold .bronze-medal,
  .reward-gold .silver-medal,
  .reward-gold .gold-medal,
  .reward-gold .grey-medal {
    height: 40px;
    width: 32px;
  }
}
.reward-bronze .bronze-medal.modal-medal,
.reward-bronze .silver-medal.modal-medal,
.reward-bronze .gold-medal.modal-medal,
.reward-bronze .grey-medal.modal-medal,
.reward-silver .bronze-medal.modal-medal,
.reward-silver .silver-medal.modal-medal,
.reward-silver .gold-medal.modal-medal,
.reward-silver .grey-medal.modal-medal,
.reward-gold .bronze-medal.modal-medal,
.reward-gold .silver-medal.modal-medal,
.reward-gold .gold-medal.modal-medal,
.reward-gold .grey-medal.modal-medal {
  height: 40px;
  width: 32px;
}
.reward-bronze .bronze-medal,
.reward-bronze .silver-medal,
.reward-bronze .gold-medal,
.reward-silver .bronze-medal,
.reward-silver .silver-medal,
.reward-silver .gold-medal,
.reward-gold .bronze-medal,
.reward-gold .silver-medal,
.reward-gold .gold-medal {
  display: none;
}
.reward-bronze.filled .bronze-medal,
.reward-bronze.filled .silver-medal,
.reward-bronze.filled .gold-medal,
.reward-silver.filled .bronze-medal,
.reward-silver.filled .silver-medal,
.reward-silver.filled .gold-medal,
.reward-gold.filled .bronze-medal,
.reward-gold.filled .silver-medal,
.reward-gold.filled .gold-medal {
  display: block;
}
.reward-bronze.filled .grey-medal,
.reward-silver.filled .grey-medal,
.reward-gold.filled .grey-medal {
  display: none;
}

.space-bar {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 4px;
  max-width: 100%;
  padding: 0;
}
.space-bar:first-of-type {
  display: none;
}
.space-bar .space-bar-fill {
  background-color: #ececec;
  border-radius: 8px;
  flex-grow: 1;
  height: 100%;
}
.space-bar .space-bar-fill .animated-bar {
  height: 100%;
  transition: width 1.5s linear;
  width: 0%;
}

.qualified-purchase.filled {
  color: #262626;
}
.qualified-purchase.filled span {
  color: #262626;
}

.checkpoints-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
  width: 100%;
}
.checkpoints-wrapper .checkpoint {
  max-width: fit-content;
  transition: transform 0.5s ease-in-out;
}
.checkpoints-wrapper .checkpoint:last-of-type {
  position: relative;
}
.checkpoints-wrapper .checkpoint.is-transformed {
  transform: scale3d(1.2, 1.2, 1.2);
  transition: transform 0.5s ease-in-out;
}
.checkpoints-wrapper .checkpoint .checkpoint-bullet {
  background-color: #ececec;
  border-radius: 100%;
  height: 24px;
  margin: 0 8px;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  width: 24px;
}
.checkpoints-wrapper .checkpoint .punch-card-img {
  max-width: 100%;
  transition: visibility 0.5s linear, opacity 0.5s linear, transform 0.5s ease-in-out;
}
.checkpoints-wrapper .checkpoint .punch-card-img.completed {
  bottom: 32px;
  left: 36px;
  max-width: 100px;
  position: absolute;
}
.checkpoints-wrapper .checkpoint span {
  white-space: nowrap;
}

.punch-cards-wrapper-green {
  background-color: #f3f9fa;
  border: 1px solid #50797b;
}

.punch-cards-wrapper-green .punch-card,
.punch-banner-wrapper-green .punch-card {
  border: 1px solid #cce8e9;
}
.punch-cards-wrapper-green .punch-card .checkpoint.filled .checkpoint-bullet,
.punch-banner-wrapper-green .punch-card .checkpoint.filled .checkpoint-bullet {
  background-color: #496a76;
}
.punch-cards-wrapper-green .punch-card .checkpoint.filled span,
.punch-banner-wrapper-green .punch-card .checkpoint.filled span {
  color: #19353f;
}
.punch-cards-wrapper-green .punch-card .space-bar .animated-bar,
.punch-banner-wrapper-green .punch-card .space-bar .animated-bar {
  background-color: #496a76;
}

.punch-cards-wrapper-red {
  background-color: #f8eaea;
  border: 1px solid #561c27;
}

.punch-cards-wrapper-red .punch-card,
.punch-banner-wrapper-red .punch-card {
  border: 1px solid #d48386;
}
.punch-cards-wrapper-red .punch-card .checkpoint.filled .checkpoint-bullet,
.punch-banner-wrapper-red .punch-card .checkpoint.filled .checkpoint-bullet {
  background-color: #c14d52;
}
.punch-cards-wrapper-red .punch-card .checkpoint.filled span,
.punch-banner-wrapper-red .punch-card .checkpoint.filled span {
  color: #c14d52;
}
.punch-cards-wrapper-red .punch-card .space-bar .animated-bar,
.punch-banner-wrapper-red .punch-card .space-bar .animated-bar {
  background-color: #c14d52;
}

.punch-cards-wrapper-yellow {
  background-color: #fefaef;
  border: 1px solid #967a3c;
}

.punch-cards-wrapper-yellow .punch-card,
.punch-banner-wrapper-yellow .punch-card {
  border: 1px solid #fbe8bf;
}
.punch-cards-wrapper-yellow .punch-card .checkpoint.filled .checkpoint-bullet,
.punch-banner-wrapper-yellow .punch-card .checkpoint.filled .checkpoint-bullet {
  background-color: #ac8c45;
}
.punch-cards-wrapper-yellow .punch-card .checkpoint.filled span,
.punch-banner-wrapper-yellow .punch-card .checkpoint.filled span {
  color: #ac8c45;
}
.punch-cards-wrapper-yellow .punch-card .space-bar .animated-bar,
.punch-banner-wrapper-yellow .punch-card .space-bar .animated-bar {
  background-color: #ac8c45;
}

.collapsible-punch-card .punch-card-button .chevron-down {
  display: none;
}
.collapsible-punch-card .punch-card-button .chevron-up {
  display: block;
}
.collapsible-punch-card .punch-card-button.collapsed .chevron-down {
  display: block;
}
.collapsible-punch-card .punch-card-button.collapsed .chevron-up {
  display: none;
}

.t2-punch-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 32px 0;
}
.t2-punch-cards-wrapper:last-of-type {
  margin-bottom: 0;
}

.t2-punch-card-title {
  font-size: 1.25rem;
}

.t2-punch-card-container {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}
@media (min-width: 375px) {
  .t2-punch-card-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
  }
}
@media (min-width: 1440px) {
  .t2-punch-card-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
  }
}
.t2-punch-card-container .t2-punch-card {
  border: 1px solid #f6c862;
  border-radius: 4px;
  min-height: 103px;
  width: 100%;
}
.t2-punch-card-container .t2-punch-card.punch-card-highlight {
  grid-column: 1/-1;
}
.t2-punch-card-container .t2-punch-card.punch-card-highlight .t2-punch-card-body {
  display: flex;
  gap: 8px;
}
.t2-punch-card-container .t2-punch-card.punch-card-highlight .t2-punch-card-body .wmcc-img {
  height: 34px;
  margin-left: auto;
  width: 59px;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header {
  align-items: center;
  background-color: #fefaef;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header .eyebrow-text {
  color: #4b4b4b;
  font-size: 0.75rem;
  font-weight: 400;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header .t2-punch-card-points {
  color: #262626;
  font-size: 1rem;
  font-weight: 500;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header .punch-card-img {
  height: 32px;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header .checkpoint-bullet {
  align-items: center;
  background-color: #fff;
  border: 1px dashed #f6c862;
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header .checkpoint-bullet .icon-check {
  display: none;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header .checkpoint-bullet.filled {
  background: #f6c862;
  border: 2px solid #f6c862;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-header .checkpoint-bullet.filled .icon-check {
  display: block;
}
.t2-punch-card-container .t2-punch-card .t2-punch-card-body {
  background-color: #fff;
  padding: 8px 16px 12px;
}

.popover-authenticated .t2-punch-card-container,
.pd-punch-card .t2-punch-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.header-nav {
  background-color: #fff;
  z-index: 11;
}
.header-nav.sticky-nav {
  position: sticky;
  top: 0;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991.98px) {
  .header-nav.sticky-nav .container .header {
    padding: 8px 0;
  }
}
@media (min-width: 768px) {
  .header-nav.sticky-nav .main-menu,
  .header-nav.sticky-nav .multilevel-dropdown {
    display: none !important;
  }
}
.header-nav.sticky-nav .promotion-banner,
.header-nav.sticky-nav .announcement-banner {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.header-nav.header-above:not(.sticky-nav) {
  position: relative;
  z-index: 100;
}

.header {
  align-items: center;
  background-color: #fff;
  display: grid;
  grid-template-columns: 44px 1fr 12fr 1fr 44px;
  max-height: fit-content;
  padding: 8px 0 16px;
  position: relative;
}
@media (min-width: 1024px) {
  .header {
    column-gap: 24px;
    grid-template-columns: 4fr 6fr 1fr 1fr 1fr;
    padding: 16px 0;
    row-gap: 0;
  }
}
.header .btn-menu {
  background: none;
  border: 0;
  display: flex;
  padding: 4px;
}
@media (min-width: 1024px) {
  .header .btn-menu {
    padding: 0s;
  }
}
.header .btn-menu svg {
  vertical-align: top;
}
.header .nav-item {
  line-height: 0;
  margin: 0;
  padding: 0;
}
.header .popover {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3), 0 6px 10px 4px rgba(0, 0, 0, 0.08);
  left: -30px;
  max-height: calc(100vh - 150px);
  max-width: 100dvw;
  overflow: auto;
  padding: 16px 0;
  position: absolute;
  right: 0;
  top: 56px;
  transition: ease-in-out;
  width: 375px;
}
@media (min-width: 375px) {
  .header .popover {
    left: -16px;
    max-width: 375px;
  }
}
@media (min-width: 768px) {
  .header .popover {
    left: unset;
    right: -22px;
    top: 57px;
  }
}
@media (min-width: 1024px) {
  .header .popover {
    right: -4px;
    top: 70px;
  }
}
@media (min-width: 1440px) {
  .header .popover {
    max-width: 383px;
    right: -32px;
    width: 100%;
  }
}
@media (min-width: 1920px) {
  .header .popover {
    right: 7px;
  }
}
@media (max-width: 991.98px) {
  .header .popover-authenticated {
    justify-content: flex-start;
    max-height: 70vh;
    overflow-y: auto;
  }
}
@media (min-width: 1024px) {
  .header .popover-authenticated {
    right: 29px;
  }
}
@media (min-width: 1440px) {
  .header .popover-authenticated {
    right: 1px;
  }
}
@media (min-width: 1920px) {
  .header .popover-authenticated {
    right: 33px;
  }
}
.header .popover-header {
  background-color: unset;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0 16px;
  width: 100%;
}
.header .popover-header .t2-punch-card-entries-total {
  display: none;
}
.header .popover-header .popover-header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.header .popover-header .popover-header-top .close {
  position: relative;
  right: -6px;
  top: -6px;
}
.header .popover-header .reward-block {
  align-items: center;
  background-color: #fefaef;
  border: 1px solid #f6c862;
  border-radius: 8px;
  display: flex;
  height: 78px;
  justify-content: space-between;
}
.header .popover-header .reward-block .reward-content {
  max-width: 340px;
  width: 100%;
}
.header .popover-header .reward-block.logged {
  height: 100%;
}
.header .popover-header .reward-block.guest-user {
  display: block;
  height: auto;
}
.header .popover-header .link-rewards {
  text-decoration: underline;
}
.header .popover-header .reward-bar-container {
  width: 100%;
}
.header .popover-header .reward-bar-container .reward-bar {
  background-color: #fcfcfc;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  height: 24px;
  width: 100%;
}
.header .popover-header .reward-bar-container .reward-bar-fill {
  background-color: #f6c862;
  border-radius: 3px 0 0 3px;
  height: 100%;
  text-align: right;
}
.header .popover-header .reward-bar-container .reward-bar-fill div {
  font-size: 0.875rem;
  min-width: 25px;
  padding-right: 8px;
}
.header .popover-header .reward-bar-container .reward-bar-counters {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 120%;
}
.header .popover-header .reward-actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-location {
  align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  display: grid;
  grid-column: 2/span 1;
  grid-row: 1/span 1;
  justify-content: flex-start;
}
@media (min-width: 1024px) {
  .navbar-location {
    border-width: 1px;
    grid-column: 3/span 1;
    grid-row: 1/span 1;
  }
}
.navbar-location:hover, .navbar-location:active, .navbar-location.active {
  background-color: #f3f3f3;
  border-radius: 4px;
}
.navbar-location:active, .navbar-location.active {
  border-color: #537c7f;
}
.navbar-location .location .location-anchor {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 4px;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .navbar-location .location .location-anchor {
    padding: 8px;
  }
}
.navbar-location .location .location-anchor .icon-map-pin {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
  height: 28px;
  width: 24px;
}
.navbar-location .location .location-anchor .location-text {
  display: none;
  flex-direction: column;
  grid-column: 2/span 1;
  grid-row: 1/span 1;
  margin: 0 0 0 12px;
  padding: 0;
}
@media (min-width: 1024px) {
  .navbar-location .location .location-anchor .location-text {
    display: flex;
  }
}
.navbar-location .location .location-anchor .location-text .find-location-link {
  text-decoration: underline;
}
.navbar-location .location .btn-store-locator {
  width: 100%;
}

.navbar-logo {
  align-items: center;
  grid-column: 3/span 1;
  grid-row: 1/span 1;
  justify-self: center;
  margin: 0 20px;
  max-width: 149px;
  padding: 0;
  width: auto;
}
@media (min-width: 1024px) {
  .navbar-logo {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
    justify-self: start;
    margin: 0 24px 0 0;
    max-width: 220px;
  }
}
@media (min-width: 1440px) {
  .navbar-logo {
    margin: 0 48px 0 0;
  }
}

.navbar-search {
  grid-column: 1/span 5;
  grid-row: 2/span 1;
  margin-top: 8px;
}
@media (min-width: 1024px) {
  .navbar-search {
    grid-column: 2/span 1;
    grid-row: 1/span 1;
    margin: 0;
  }
}
@media (min-width: 1260px) {
  .navbar-search {
    justify-self: center;
  }
}
.navbar-search .site-search {
  margin-top: 0;
  padding: 0 4px;
  width: 100%;
}
@media (min-width: 768px) {
  .navbar-search .site-search {
    min-width: 245px;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .navbar-search .site-search {
    padding: 0;
    width: 100%;
  }
}
@media (min-width: 1260px) {
  .navbar-search .site-search {
    min-width: 443px;
  }
}

.navbar-login {
  border: 2px solid transparent;
  grid-column: 4/span 1;
  grid-row: 1/span 1;
  justify-self: end;
  margin-right: 4px;
  padding: 4px;
}
@media (min-width: 768px) {
  .navbar-login {
    align-items: center;
    display: flex;
    grid-column: 4/span 1;
    grid-row: 1/span 1;
    width: fit-content;
  }
}
@media (min-width: 1024px) {
  .navbar-login {
    align-self: stretch;
    border-width: 1px;
    height: 100%;
    margin-right: 12px;
    padding: 4px;
  }
}
@media (min-width: 1440px) {
  .navbar-login {
    margin-right: 0;
  }
}
.navbar-login:hover, .navbar-login:active, .navbar-login.active {
  background-color: #f3f3f3;
  border-radius: 4px;
  text-decoration: underline;
}
.navbar-login:active, .navbar-login.active {
  border-color: #537c7f;
}
.navbar-login .user .popover:not(.d-none) + .user-anchor::before {
  border-bottom: 10px solid #dbdbdb;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: 50px;
  width: 0;
}
.navbar-login .user .popover:not(.d-none) + .user-anchor::after {
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: 51px;
  width: 0;
  z-index: 2000;
}
.navbar-login .user .popover-header .close {
  position: relative;
  right: -6px;
  top: -6px;
}
.navbar-login .user .popover-header .buttons-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
}
.navbar-login .user .popover-header .buttons-container button {
  padding: 12px 16px;
}
.navbar-login .user .popover-header .earn-up-to-message {
  display: none;
}
.navbar-login .user .popover-bottom {
  border-top: 1px solid #dbdbdb;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px);
  letter-spacing: 0.05em;
  margin: 16px 0 8px 0;
  text-transform: uppercase;
  width: 100%;
}
.navbar-login .user .popover::before,
.navbar-login .user .popover::after {
  right: 81px;
}
@media (min-width: 375px) {
  .navbar-login .user .popover::before,
  .navbar-login .user .popover::after {
    right: 66px;
  }
}
@media (min-width: 768px) {
  .navbar-login .user .popover::before,
  .navbar-login .user .popover::after {
    right: 88px;
  }
}
@media (min-width: 1024px) {
  .navbar-login .user .popover::before,
  .navbar-login .user .popover::after {
    right: 180px;
  }
}
@media (min-width: 1440px) {
  .navbar-login .user .popover::before,
  .navbar-login .user .popover::after {
    right: 195px;
  }
}
@media (min-width: 1920px) {
  .navbar-login .user .popover::before,
  .navbar-login .user .popover::after {
    right: 184px;
  }
}
.navbar-login .user .user-message {
  color: #262626;
  font-family: "sofia-pro";
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 8px;
}
.navbar-login .user-anchor {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 0;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .navbar-login .user-anchor {
    min-width: 90px;
    padding: 8px;
  }
}
.navbar-login .user-anchor .account-text {
  display: none;
}
@media (min-width: 1024px) {
  .navbar-login .user-anchor .account-text {
    display: inline-block;
  }
}
.navbar-login .user-anchor .logged-user {
  background-color: #83c6c9;
  border-radius: 50%;
  height: 24px;
  text-align: center;
  width: 24px;
}
.navbar-login .nav-user {
  align-items: center;
  display: grid;
  width: 100%;
}
.navbar-login .nav-user .nav-item {
  margin: 0;
  padding: 0;
  width: 100%;
}
.navbar-login .nav-user .nav-item.item-1 {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
  width: 100%;
}
.navbar-login .nav-user .nav-item.item-2 {
  grid-column: 1/span 1;
  grid-row: 2/span 1;
  width: 100%;
}
.navbar-login .nav-user .nav-item.item-3 {
  grid-column: 1/span 1;
  grid-row: 3/span 1;
  width: 100%;
}
.navbar-login .nav-user .nav-item.item-4 {
  grid-column: 1/span 1;
  grid-row: 4/span 1;
  width: 100%;
}
.navbar-login .nav-user .nav-item.item-5 {
  grid-column: 1/span 1;
  grid-row: 5/span 1;
  width: 100%;
}
.navbar-login .nav-user .nav-item.item-6 {
  grid-column: 1/span 1;
  grid-row: 6/span 1;
  width: 100%;
}
.navbar-login .nav-user .nav-item .nav-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 4px 16px;
}

.navbar-minicart {
  align-self: stretch;
  background-color: transparent;
  border: 2px solid transparent;
  grid-column: 5/span 1;
  grid-row: 1/span 1;
  height: 40px;
  justify-self: end;
  line-height: 0;
  margin: 0;
  padding: 8px;
}
@media (min-width: 1024px) {
  .navbar-minicart {
    border-width: 1px;
    grid-column: 5/span 1;
    grid-row: 1/span 1;
    height: 100%;
    width: fit-content;
  }
}
.navbar-minicart:hover, .navbar-minicart:active, .navbar-minicart.active {
  background-color: #f3f3f3;
  border-radius: 4px;
  text-decoration: underline;
}
.navbar-minicart:active, .navbar-minicart.active {
  border-color: #537c7f;
}
.navbar-minicart .minicart-total {
  display: flex;
  justify-content: flex-end;
}
.navbar-minicart .minicart-total .minicart-link {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: grid;
  padding: 0;
  width: 24px;
}
@media (min-width: 1024px) {
  .navbar-minicart .minicart-total .minicart-link {
    justify-self: end;
    width: fit-content;
  }
}
.navbar-minicart .minicart-total .minicart-link .minicart-icon {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}
.navbar-minicart .minicart-total .minicart-link .minicart-message {
  align-items: center;
  color: #262626;
  display: flex;
  font-family: "sofia-pro";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 120%;
  margin: 0 8px;
  padding: 0;
}
@media (min-width: 1024px) {
  .navbar-minicart .minicart-total .minicart-link .minicart-message {
    grid-column: 2/span 1;
    grid-row: 1/span 1;
  }
}
.navbar-minicart .minicart-total .minicart-link .minicart-quantity-wrapper {
  background-color: #b4292f;
  border-radius: 50%;
  color: #fff;
  font-family: "sofia-pro";
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  grid-column: 2/span 1;
  grid-row: 1/span 1;
  height: 14px;
  left: -14px;
  position: relative;
  top: -8px;
  width: 14px;
}
@media (min-width: 1024px) {
  .navbar-minicart .minicart-total .minicart-link .minicart-quantity-wrapper {
    font-size: 12px;
    font-weight: 800;
    grid-column: 3/span 1;
    grid-row: 1/span 1;
    height: 16px;
    left: 0;
    top: 0;
    width: 16px;
  }
}
.navbar-minicart .minicart-total .minicart-link .minicart-quantity {
  bottom: 0;
  height: 8px;
  left: 0;
  line-height: 0.8;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 1024px) {
  .navbar-minicart .minicart-total .minicart-link .minicart-quantity {
    height: 12px;
  }
}

.header-banner {
  background-color: unset;
}
.header-banner .banner-content {
  padding: 8px 0;
  position: relative;
}
.header-banner .banner-content .banner-link {
  cursor: pointer;
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .header-banner .banner-content .banner-link {
    text-decoration: none;
  }
}
@media (min-width: 1024px) {
  .header-banner .banner-content .banner-link {
    line-height: 18px;
    width: 279px;
  }
}
.header-banner .banner-content .rewards-link {
  border-right: 1px solid #6d6d6d;
  font-weight: 800;
  margin-left: 16px;
  text-decoration: underline;
}
@media (min-width: 1024px) {
  .header-banner .banner-content .rewards-link {
    border-right: 0;
    margin-left: 0;
  }
}
.header-banner .slick-carousel .arrow-list {
  position: relative;
  z-index: 1;
}
.header-banner .slick-carousel .slick-arrow {
  position: absolute;
  top: 32%;
  z-index: 2;
}
.header-banner .slick-carousel .slick-arrow.arrow-right {
  right: 8px;
}
.header-banner .slick-carousel .slick-arrow.arrow-left {
  left: 8px;
}
.header-banner .slick-carousel .slick-autoplay-toggle-button {
  right: -9px;
  top: 9px;
}
@media (min-width: 1024px) {
  .header-banner .slick-carousel:not(.slick-initialized) {
    display: flex;
  }
  .header-banner .slick-carousel:not(.slick-initialized) .slide {
    display: none;
    max-width: none;
    padding: 0 24px;
  }
  .header-banner .slick-carousel:not(.slick-initialized) .slide:nth-child(1), .header-banner .slick-carousel:not(.slick-initialized) .slide:nth-child(2), .header-banner .slick-carousel:not(.slick-initialized) .slide:nth-child(3) {
    display: block;
  }
  .header-banner .slick-carousel:not(.slick-initialized) .slide:not(:first-child) {
    border-left: 1px solid #6d6d6d;
  }
  .header-banner .slick-carousel:not(.slick-initialized) .slide:last-child {
    padding-right: 0;
  }
}
.header-banner .banner-cta {
  color: #561c27;
  text-decoration: underline;
}
@media (min-width: 1024px) {
  .header-banner .banner-cta {
    margin-left: 8px;
  }
}
.header-banner .btn-menu {
  background: none;
  border: 0;
  position: unset;
}

.promotion-banner .promo-content .slick-track {
  display: flex !important;
}
.promotion-banner .promo-content .slick-track .slick-slide {
  height: inherit !important;
}
.promotion-banner .promo-content .slick-track .slick-slide div {
  height: 100%;
}
.promotion-banner .promo-content .slide {
  align-items: center;
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  width: 100%;
}
@media (min-width: 1024px) {
  .promotion-banner .promo-content .slide.promo-border {
    border-left: 1px solid #dbdbdb;
  }
  .promotion-banner .promo-content .slide.extra-content {
    display: none !important;
  }
}

.announcement-banner {
  max-height: none;
}
@media (max-width: 991.98px) {
  .announcement-banner p {
    margin: 8px 45px 8px 0;
  }
}
.announcement-banner .close-button {
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: auto;
  margin: auto 8px;
  position: absolute;
  right: 0;
  top: 0;
}
.announcement-banner .close-button .close {
  display: flex;
  opacity: 1;
}
.announcement-banner .close-button .close:hover {
  opacity: inherit;
}

.main-menu {
  background-color: #fff;
  border-color: #dbdbdb;
  border-style: solid;
  border-width: 1px 0;
}
@media (min-width: 768px) {
  .main-menu {
    position: relative;
  }
  .main-menu .navbar {
    position: inherit;
  }
}
@media (max-width: 991.98px) {
  .main-menu .menu-wrapper {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.no-menu-header {
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  position: relative;
  z-index: 12;
}
@media (min-width: 1024px) {
  .no-menu-header {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 2px 6px 2px rgba(0, 0, 0, 0.1);
    max-height: 63px;
  }
}
.no-menu-header .no-menu-logo {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 16px;
}
.no-menu-header .no-menu-logo .logo-home {
  max-width: 149px;
}
@media (min-width: 1024px) {
  .no-menu-header .no-menu-logo .logo-home {
    max-width: auto;
  }
}

.banner-legacy-customers {
  background-color: #f5cccc;
  border: 1px solid #f1b8b8;
  border-radius: 4px;
  color: #6a0000;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
}
.banner-legacy-customers button,
.banner-legacy-customers a {
  color: #6a0000;
}

.plcc-header .ep-text-placement {
  font-family: "sofia-pro";
  font-style: normal;
  line-height: 120%;
}
.plcc-header .ep-text-placement .epjs-body {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 8px;
}
.plcc-header .ep-text-placement .epjs-body-action {
  background-color: #f6c862;
  border: 0;
  border-radius: 4px;
  color: #262626;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: 800;
  margin-bottom: 8px;
  margin-top: 8px;
  max-width: 132px;
  padding: 16px 24px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}
.plcc-header .ep-text-placement .epjs-body-action-link {
  text-decoration: none;
}

.header-container {
  margin: 0 16px;
}
@media (min-width: 1024px) {
  .header-container {
    margin: 0 56px;
  }
}
@media (min-width: 1920px) {
  .header-container {
    margin: 0 auto;
    max-width: 1328px;
  }
}
@media (max-width: 1023.98px) {
  .header-container .rewards-link {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .header-container .rewards-link.margin-fixed {
    margin-left: 32px;
  }
}
@media (min-width: 1024px) {
  .header-container .navbar-minicart {
    padding-right: 0;
  }
}

.email-not-exist-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.email-not-exist-container .send-email-btn {
  width: 90%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262626;
  font-family: "sofia-pro", sans-serif;
  font-style: normal;
  line-height: 120%;
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.8125rem;
}

h4,
.h4 {
  font-size: 1.625rem;
}

h5,
.h5 {
  font-size: 1.4375rem;
}

h6,
.h6 {
  font-size: 1.25rem;
}

.product-tile-wrapper {
  max-width: 50%;
}
@media (max-width: 991.98px) {
  .product-tile-wrapper-odd {
    padding: 8px 8px 16px 0;
  }
  .product-tile-wrapper-odd .wishlist-tile {
    right: 8px;
  }
  .product-tile-wrapper-even {
    padding: 8px 0 16px 8px;
  }
  .product-tile-wrapper-even .wishlist-tile {
    right: 0;
  }
}
@media (min-width: 992px) {
  .product-tile-wrapper {
    border: 0;
    max-width: 33.3333333333%;
    padding: 8px 12px 16px;
    width: 100%;
  }
}

.product-tile {
  width: 100%;
}
@media (min-width: 992px) {
  .product-tile .image-container:hover .button-addtocart {
    display: flex;
  }
}
.product-tile .image-container {
  position: relative;
  transition: all 0.5s ease-in-out;
  width: 100%;
}
@media (min-width: 992px) {
  .product-tile .image-container .tile-image {
    width: 100%;
  }
  .product-tile .image-container:hover .tile-image:not(.single) {
    display: none;
  }
  .product-tile .image-container:hover .tile-alt-image {
    display: block;
  }
  .product-tile .image-container:hover + .tile-body > .pdp-link > .link {
    color: #4b4b4b;
    text-decoration: underline;
  }
}
.product-tile .image-container .tile-image,
.product-tile .image-container .tile-alt-image {
  border-radius: 4px;
  height: auto;
  width: 100%;
}
.product-tile .image-container .tile-alt-image {
  display: none;
}
.product-tile .image-container .availability-tag {
  align-items: center;
  background-color: #f6f4f1;
  border-radius: 4px;
  bottom: 0;
  color: #244b5a;
  display: flex;
  height: 26px;
  justify-content: center;
  padding: 6px 8px;
  position: absolute;
  white-space: nowrap;
  width: fit-content;
}
.product-tile .image-container .wishlist-tile {
  align-items: center;
  background-color: #fff;
  border-radius: 0 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 4px;
  position: absolute;
  right: 0;
  top: 0;
}
.product-tile .image-container .wishlist-tile .icon-heart {
  height: 16px;
  width: 16px;
}
@media (min-width: 992px) {
  .product-tile .image-container .wishlist-tile .icon-heart {
    height: 24px;
    width: 24px;
  }
}
.product-tile .image-container .button-addtocart {
  align-items: center;
  background-color: #fefefe;
  border: 1px solid #cacaca;
  border-radius: 4px;
  bottom: 8px;
  display: none;
  height: 40px;
  justify-content: center;
  padding: 6px 0;
  position: absolute;
  right: 8px;
  transition: all 0.2s ease-in-out;
  width: 120px;
  z-index: 2;
}
.product-tile .image-container .button-addtocart:hover {
  text-decoration: underline;
}
.product-tile .image-container .button-addtocart .icon-sm {
  height: fit-content;
}
.product-tile .tile-body .link:hover {
  color: #4b4b4b;
  text-decoration: underline;
}
.product-tile .price {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;
}
.product-tile .member-price {
  background-color: #f6c862;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 41px;
  justify-content: center;
  margin: 8px 0;
  padding: 4px 8px;
  width: 164px;
}
@media (min-width: 992px) {
  .product-tile .member-price {
    height: 46px;
    width: 100%;
  }
}
.product-tile .member-price .member-price-value {
  font-size: 1rem;
}
@media (min-width: 992px) {
  .product-tile .member-price .member-price-value {
    font-size: 1.125rem;
  }
}
.product-tile .member-price-supratext {
  font-size: 0.75rem;
}
.product-tile .swatch-circle {
  border: 1px solid #ececec;
  border-radius: 100%;
  height: 16px;
  margin-right: 4px;
  overflow: hidden;
  position: relative;
  width: 16px;
}
.product-tile .swatch-circle.selected {
  background-color: #ececec;
  border-color: #9f9f9f;
  height: 18px;
  width: 18px;
}
.product-tile .swatch-circle.selected .swatch-img {
  padding: 1px;
}
.product-tile .swatch-circle .swatch-img {
  border-radius: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.product-tile .list-price-text,
.product-tile .sale-price-text {
  font-size: 1rem;
}
@media (min-width: 992px) {
  .product-tile .list-price-text,
  .product-tile .sale-price-text {
    font-size: 1.125rem;
  }
}

@media (min-width: 992px) {
  .container-product-pagination {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .container-product-pagination .grid-header-pagination {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.pd-product-tile-wrapper .image-container,
.recommendations-slider .image-container {
  aspect-ratio: 1/1;
  text-align: center;
}
@media (min-width: 992px) {
  .pd-product-tile-wrapper .image-container:hover .tile-image,
  .recommendations-slider .image-container:hover .tile-image {
    display: block !important;
    width: 100%;
  }
}
.pd-product-tile-wrapper .image-container .tile-image,
.recommendations-slider .image-container .tile-image {
  display: block;
  margin: auto;
  transition: all 0.5s;
  width: 100%;
}
@media (min-width: 992px) {
  .pd-product-tile-wrapper .image-container .tile-image,
  .recommendations-slider .image-container .tile-image {
    width: 96%;
  }
}
.pd-product-tile-wrapper .image-container .tile-alt-image,
.recommendations-slider .image-container .tile-alt-image {
  display: none !important;
}

.tab-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 20px;
}
.tab-wrapper .tab {
  align-items: center;
  background-color: #fefefe;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  order: 2;
  padding: 2px 6px;
  width: fit-content;
}
.tab-wrapper .tab.highlight {
  background-color: #f6f4f1;
}

.product-tile .merch-callout {
  color: #244b5a;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}
@media (min-width: 992px) {
  .product-tile .merch-callout {
    gap: 16px;
  }
}
.product-tile .merch-callout span {
  font-size: 0.75rem;
  font-weight: 500;
  overflow: hidden;
  text-transform: uppercase;
  white-space: nowrap;
}
.product-tile .promo-callout {
  color: #b4292f;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}
.product-tile .promo-callout span {
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 27.4ch;
  overflow: hidden;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .product-tile .promo-callout span {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .items-per-page-fix-position {
    position: absolute;
    top: -59px;
  }
}

.form-nav .nav-tabs .nav-link {
  color: #818181;
  text-transform: uppercase;
}

.form-nav .nav-tabs .nav-link.active,
.form-nav .nav-tabs .nav-link.active:focus,
.form-nav .nav-tabs .nav-link.active:hover {
  background-color: #fff;
  color: #262626;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  color: #262626;
}

@media (min-width: 992px) {
  .form-nav .nav-item > .nav-link {
    color: #818181;
  }
}
.breadcrumb {
  background-color: transparent;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0 0 4px;
  white-space: nowrap;
  width: calc(100vw - 32px);
}
@media (min-width: 992px) {
  .breadcrumb {
    flex-wrap: wrap;
    padding: 0;
    white-space: auto;
    width: unset;
  }
}
.breadcrumb::-webkit-scrollbar {
  display: none;
}
.breadcrumb-custom {
  position: relative;
}
.breadcrumb-custom a:hover {
  text-decoration: none;
}
.breadcrumb-custom::after {
  color: #cacaca;
  content: "/";
  float: left;
  padding-left: 8px;
  padding-right: 8px;
}
.breadcrumb-single {
  align-items: center;
  display: flex;
  margin-top: 16px;
}
.breadcrumb-item-count {
  padding-bottom: 2px;
  position: relative;
}
.breadcrumb-item-count::after {
  background-color: #cacaca;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
}
.breadcrumb-item-count.current::after {
  background-color: #b4292f;
}
.breadcrumb-item-count a:hover {
  text-decoration: none;
}

.veil {
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;
}
.veil .underlay {
  background-color: #fff;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
}

.spinner {
  animation: sk-rotate 2s infinite linear;
  bottom: 0;
  height: 72px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 72px;
  z-index: 2;
}
.spinner svg {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.item-attributes {
  vertical-align: top;
  padding-left: 0;
}

.line-item-attributes,
.line-item-option {
  font-size: 0.813rem;
  margin: 0;
}

.line-item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #222;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.313em;
}

.line-item-pricing-info {
  margin-bottom: 0;
}
.line-item-pricing-info + .price {
  font-size: 1em;
  font-weight: bolder;
}
.line-item-pricing-info + .unit-price .price {
  font-size: 1em;
  font-weight: bolder;
}

.line-item-price-quantity-info {
  margin-top: 0.625em;
  border-top: 1px solid #ccc;
}

.line-item-total-text {
  font-size: 0.813em;
}

.pricing {
  font-size: 1em;
  font-weight: bolder;
}

.item-image {
  height: 5.625em;
  width: 5.625em;
  margin-right: 0.938em;
  flex-grow: 0;
  flex-shrink: 0;
}
.item-image img.product-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  max-height: 5.625em;
}

.non-adjusted-price {
  display: none;
}

.line-item-promo {
  color: #28a745;
  font-size: 0.813em;
}

.line-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bundled-line-item + .bundled-line-item {
  margin-top: 0.625em;
}

.bundle-includes {
  font-size: 0.813em;
  margin-bottom: 0.625em;
}

.line-item-divider {
  margin: 0.625em -1.225em 0.625em -1.325em;
}

.line-dotted {
  border-top: 0.063em dashed #ccc;
}

.line-item-availability {
  font-size: 0.813rem;
}

.product-line-item-details {
  overflow-y: auto;
}

.invisible {
  display: none;
}

.minicart,
.summary-order-product {
  position: relative;
}
.minicart .minicart-popover,
.summary-order-product .minicart-popover {
  background: transparent;
  border: 0;
  box-shadow: none;
  left: -321px;
  padding: 0;
  top: 43px;
}
.minicart .minicart-popover::before, .minicart .minicart-popover::after,
.summary-order-product .minicart-popover::before,
.summary-order-product .minicart-popover::after {
  display: none;
}
.minicart .minicart-overlay,
.summary-order-product .minicart-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.minicart .minicart-container,
.summary-order-product .minicart-container {
  background: #fff;
  bottom: -100%;
  height: calc(100% - 65px);
  position: absolute;
  transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);
  width: 100%;
}
@media (min-width: 992px) {
  .minicart .minicart-container,
  .summary-order-product .minicart-container {
    bottom: unset;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    right: -100%;
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
    width: 474px;
  }
}
.minicart .minicart-container .recommendations-title,
.summary-order-product .minicart-container .recommendations-title {
  margin-left: 0;
}
.minicart .minicart-container .slide-container .slide-carousel:not(.slick-initialized) > div:first-child,
.summary-order-product .minicart-container .slide-container .slide-carousel:not(.slick-initialized) > div:first-child {
  margin-left: 0;
}
.minicart .eistein-carousel-container,
.summary-order-product .eistein-carousel-container {
  margin-bottom: 0;
}
.minicart .custom-slick-arrows .slick-arrow,
.summary-order-product .custom-slick-arrows .slick-arrow {
  top: 122px;
}
.minicart .minicart-wrapper,
.summary-order-product .minicart-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.minicart .minicart-wrapper.active .minicart-overlay,
.summary-order-product .minicart-wrapper.active .minicart-overlay {
  display: block;
}
.minicart .minicart-wrapper.active .minicart-container,
.summary-order-product .minicart-wrapper.active .minicart-container {
  bottom: 0;
  transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);
}
@media (min-width: 992px) {
  .minicart .minicart-wrapper.active .minicart-container,
  .summary-order-product .minicart-wrapper.active .minicart-container {
    bottom: unset;
    right: 0;
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
  }
}
.minicart .minicart-header,
.summary-order-product .minicart-header {
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
  padding: 20px 0;
}
@media (min-width: 992px) {
  .minicart .minicart-header,
  .summary-order-product .minicart-header {
    margin: 0 32px;
    padding: 32px 0 12px;
  }
}
.minicart .minicart-body,
.summary-order-product .minicart-body {
  height: calc(100% - 75px);
  margin-right: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px 7px 16px 16px;
}
@media (min-width: 992px) {
  .minicart .minicart-body,
  .summary-order-product .minicart-body {
    height: calc(100vh - 205px);
    margin-right: 11px;
    padding: 16px 17px 32px 32px;
  }
}
.minicart .reward-block,
.summary-order-product .reward-block {
  align-items: center;
  background-color: #fefaef;
  border: 1px solid #f6c862;
  border-radius: 8px;
  display: flex;
  height: 78px;
  justify-content: space-between;
}
.minicart .reward-block.logged,
.summary-order-product .reward-block.logged {
  height: fit-content;
}
.minicart .reward-block.guest-user,
.summary-order-product .reward-block.guest-user {
  display: block;
  height: auto;
}
.minicart .reward-block-wrapper,
.summary-order-product .reward-block-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.minicart .link-rewards,
.summary-order-product .link-rewards {
  text-decoration: underline;
}
.minicart .reward-block-button .btn-reward-login,
.summary-order-product .reward-block-button .btn-reward-login {
  background-color: #fff;
  border-color: #9f9f9f;
  color: #262626;
  letter-spacing: 0.05em;
  line-height: 14px;
  padding: 12px 24px;
}
.minicart .reward-block-button .btn-reward-login:hover,
.summary-order-product .reward-block-button .btn-reward-login:hover {
  background-color: #fff;
}
.minicart .reward-bar-container,
.summary-order-product .reward-bar-container {
  width: 100%;
}
.minicart .reward-bar-container .reward-bar,
.summary-order-product .reward-bar-container .reward-bar {
  background-color: #fcfcfc;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  height: 24px;
  width: 100%;
}
.minicart .reward-bar-container .reward-bar-fill,
.summary-order-product .reward-bar-container .reward-bar-fill {
  background-color: #f6c862;
  border-radius: 3px 0 0 3px;
  height: 100%;
  text-align: right;
}
.minicart .reward-bar-container .reward-bar-fill div,
.summary-order-product .reward-bar-container .reward-bar-fill div {
  min-width: 25px;
  padding-right: 8px;
}
.minicart .reward-bar-container .reward-bar-counters,
.summary-order-product .reward-bar-container .reward-bar-counters {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 120%;
}
.minicart .reward-actions,
.summary-order-product .reward-actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.minicart .coupon-aplied-container,
.summary-order-product .coupon-aplied-container {
  border: 1px solid #ececec;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  height: 97px;
  padding: 16px;
}
.minicart .line-item-promo,
.summary-order-product .line-item-promo {
  color: #268055;
}
.minicart .product-line-item-details,
.summary-order-product .product-line-item-details {
  overflow-y: unset;
}
.minicart .minicart-product,
.summary-order-product .minicart-product {
  border-bottom: 1px solid #dbdbdb;
}
.minicart .minicart-product .remove-btn,
.summary-order-product .minicart-product .remove-btn {
  display: none;
}
.minicart .item-image,
.summary-order-product .item-image {
  border: 1px solid #ececec;
  border-radius: 4px;
  max-height: 74px;
  max-width: 74px;
}
.minicart .item-image .product-image,
.summary-order-product .item-image .product-image {
  border-radius: 4px;
  max-height: 72px;
}
.minicart .line-item,
.summary-order-product .line-item {
  color: #262626;
  font-weight: 400;
}
.minicart .minicart-price,
.summary-order-product .minicart-price {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.minicart .minicart-price .list-price,
.summary-order-product .minicart-price .list-price {
  font-size: 0.875rem;
  margin-left: 72px;
  margin-top: -16px;
}
.minicart .minicart-price .sale-from,
.summary-order-product .minicart-price .sale-from {
  margin-left: 72px;
  margin-top: -16px;
}
.minicart .minicart-price .sale-price,
.summary-order-product .minicart-price .sale-price {
  font-size: 0.875rem;
  margin-left: 72px;
}
.minicart .minicart-price .member-price,
.summary-order-product .minicart-price .member-price {
  align-items: center;
  background-color: #f6c862;
  border-radius: 4px;
  display: flex;
  margin-top: 10px;
  min-width: 220px;
  padding: 4px 8px;
  width: fit-content;
}
@media (min-width: 992px) {
  .minicart .minicart-price .member-price,
  .summary-order-product .minicart-price .member-price {
    min-width: 180px;
  }
}
.minicart .minicart-price .member-price .sale-price,
.summary-order-product .minicart-price .member-price .sale-price {
  margin: 0;
}
.minicart .minicart-price .member-price-supratext,
.summary-order-product .minicart-price .member-price-supratext {
  color: #262626;
  font-size: 12px;
  margin-right: 4px;
}
.minicart .minicart-price .member-price .member-price-value,
.summary-order-product .minicart-price .member-price .member-price-value {
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .minicart .minicart-price .member-price .member-price-value,
  .summary-order-product .minicart-price .member-price .member-price-value {
    font-size: 1rem;
  }
}
.minicart .minicart-price .member-price .text,
.summary-order-product .minicart-price .member-price .text {
  margin-right: 4px;
}
.minicart .minicart-footer,
.summary-order-product .minicart-footer {
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.08), 0 -2px 6px 2px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
@media (min-width: 992px) {
  .minicart .minicart-footer,
  .summary-order-product .minicart-footer {
    padding: 16px 32px 32px;
  }
}
.minicart .checkout-btn,
.summary-order-product .checkout-btn {
  height: 56px;
  line-height: 14px;
  padding: 20px 24px;
}
.minicart .notification-separator,
.summary-order-product .notification-separator {
  align-items: center;
  display: none;
  gap: 16px;
}
.minicart .notification-separator .line-separator,
.summary-order-product .notification-separator .line-separator {
  background-color: #dbdbdb;
  flex-grow: 1;
  height: 1px;
}
.minicart .minicart-notification-button button,
.summary-order-product .minicart-notification-button button {
  text-transform: uppercase;
}
.minicart .approaching-discount,
.summary-order-product .approaching-discount {
  background-color: #fbfaf9;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  display: none;
  gap: 8px;
}
.minicart .approaching-discount .icon-info,
.summary-order-product .approaching-discount .icon-info {
  background-color: #268055;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  min-width: 8px;
  width: 8px;
}
.minicart .notification-title,
.minicart .estimated-total-notification,
.minicart .reward-notification,
.minicart .minicart-notification-button,
.summary-order-product .notification-title,
.summary-order-product .estimated-total-notification,
.summary-order-product .reward-notification,
.summary-order-product .minicart-notification-button {
  display: none;
}
.minicart .add-to-cart-notification .notification-separator,
.minicart .add-to-cart-notification .estimated-total-notification,
.minicart .add-to-cart-notification .approaching-discount,
.summary-order-product .add-to-cart-notification .notification-separator,
.summary-order-product .add-to-cart-notification .estimated-total-notification,
.summary-order-product .add-to-cart-notification .approaching-discount {
  display: flex;
}
.minicart .add-to-cart-notification .notification-title,
.minicart .add-to-cart-notification .reward-notification,
.minicart .add-to-cart-notification .minicart-notification-button,
.summary-order-product .add-to-cart-notification .notification-title,
.summary-order-product .add-to-cart-notification .reward-notification,
.summary-order-product .add-to-cart-notification .minicart-notification-button {
  display: block;
}
.minicart .add-to-cart-notification .minicart-title,
.minicart .add-to-cart-notification .reward-block-minicart,
.minicart .add-to-cart-notification .minicart-footer,
.minicart .add-to-cart-notification .minicart-product:not(:first-child),
.minicart .add-to-cart-notification .coupon-aplied-container,
.summary-order-product .add-to-cart-notification .minicart-title,
.summary-order-product .add-to-cart-notification .reward-block-minicart,
.summary-order-product .add-to-cart-notification .minicart-footer,
.summary-order-product .add-to-cart-notification .minicart-product:not(:first-child),
.summary-order-product .add-to-cart-notification .coupon-aplied-container {
  display: none;
}
.minicart .add-to-cart-notification .minicart-container,
.summary-order-product .add-to-cart-notification .minicart-container {
  justify-content: unset;
}
@media (min-width: 992px) {
  .minicart .add-to-cart-notification .minicart-body,
  .summary-order-product .add-to-cart-notification .minicart-body {
    height: calc(100vh - 77px);
    padding-bottom: 0;
  }
}
.minicart .add-to-cart-notification.set-notification .minicart-product,
.summary-order-product .add-to-cart-notification.set-notification .minicart-product {
  display: none;
}
.minicart .promocode-title,
.minicart .last-applied-promo,
.summary-order-product .promocode-title,
.summary-order-product .last-applied-promo {
  display: none;
}
.minicart .applied-promos .code-wrapper:not(.applied),
.summary-order-product .applied-promos .code-wrapper:not(.applied) {
  display: none;
}
.minicart .promocode-notification .notification-separator,
.summary-order-product .promocode-notification .notification-separator {
  display: flex;
}
.minicart .promocode-notification .promocode-title,
.minicart .promocode-notification .reward-notification,
.minicart .promocode-notification .last-applied-promo,
.minicart .promocode-notification .minicart-notification-button,
.summary-order-product .promocode-notification .promocode-title,
.summary-order-product .promocode-notification .reward-notification,
.summary-order-product .promocode-notification .last-applied-promo,
.summary-order-product .promocode-notification .minicart-notification-button {
  display: block;
}
.minicart .promocode-notification .minicart-title,
.minicart .promocode-notification .reward-block-minicart,
.minicart .promocode-notification .applied-promos,
.minicart .promocode-notification .minicart-footer,
.minicart .promocode-notification .minicart-product,
.summary-order-product .promocode-notification .minicart-title,
.summary-order-product .promocode-notification .reward-block-minicart,
.summary-order-product .promocode-notification .applied-promos,
.summary-order-product .promocode-notification .minicart-footer,
.summary-order-product .promocode-notification .minicart-product {
  display: none;
}
.minicart .promocode-notification .minicart-container,
.summary-order-product .promocode-notification .minicart-container {
  justify-content: unset;
}
@media (min-width: 992px) {
  .minicart .promocode-notification .minicart-body,
  .summary-order-product .promocode-notification .minicart-body {
    height: calc(100vh - 77px);
    padding-bottom: 0;
  }
}
.minicart .recommendations-title,
.summary-order-product .recommendations-title {
  border: 0;
}

.promo-code-form {
  padding-bottom: 16px;
}

.code-wrapper:not(:first-child) .promo-code {
  margin-top: 16px;
}
.code-wrapper.applied .promo-code:first-of-type {
  margin-top: 16px;
}
.code-wrapper.applied .promo-code .applied-msg {
  display: block;
}
.code-wrapper.applied .promo-code .code-expires {
  display: none;
}
.code-wrapper.applied .promo-code .btn-apply-code {
  display: none;
}
.code-wrapper.applied .promo-code .remove-btn {
  margin: 0;
  margin-left: 16px;
}
.code-wrapper.applied .promo-code .code-title {
  margin-bottom: 0;
}
.code-wrapper .promo-code {
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}
.code-wrapper .promo-code .applied-msg {
  display: none;
}
.code-wrapper .error-wrapper {
  align-items: flex-start;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
  width: 100%;
}

.minicart .promo-code {
  margin-bottom: 16px;
  margin-top: 0;
}
.minicart .error-wrapper {
  margin-bottom: 16px;
  padding: 0;
}

.minicart .punch-cards-block {
  position: relative;
}
.minicart .punch-banner-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.minicart .punch-card {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 100%;
}
.minicart .punch-card .progress-text {
  display: none;
}

@media (max-width: 767.98px) {
  .mini-cart .reward-bronze .bronze-medal,
  .mini-cart .reward-bronze .silver-medal,
  .mini-cart .reward-bronze .gold-medal,
  .mini-cart .reward-bronze .grey-medal,
  .mini-cart .reward-silver .bronze-medal,
  .mini-cart .reward-silver .silver-medal,
  .mini-cart .reward-silver .gold-medal,
  .mini-cart .reward-silver .grey-medal,
  .mini-cart .reward-gold .bronze-medal,
  .mini-cart .reward-gold .silver-medal,
  .mini-cart .reward-gold .gold-medal,
  .mini-cart .reward-gold .grey-medal {
    height: 40px;
    width: 32px;
  }
}
.mini-cart .reward-bronze .bronze-medal.modal-medal,
.mini-cart .reward-bronze .silver-medal.modal-medal,
.mini-cart .reward-bronze .gold-medal.modal-medal,
.mini-cart .reward-bronze .grey-medal.modal-medal,
.mini-cart .reward-silver .bronze-medal.modal-medal,
.mini-cart .reward-silver .silver-medal.modal-medal,
.mini-cart .reward-silver .gold-medal.modal-medal,
.mini-cart .reward-silver .grey-medal.modal-medal,
.mini-cart .reward-gold .bronze-medal.modal-medal,
.mini-cart .reward-gold .silver-medal.modal-medal,
.mini-cart .reward-gold .gold-medal.modal-medal,
.mini-cart .reward-gold .grey-medal.modal-medal {
  height: 40px;
  width: 32px;
}
.mini-cart .reward-bronze .bronze-medal,
.mini-cart .reward-bronze .silver-medal,
.mini-cart .reward-bronze .gold-medal,
.mini-cart .reward-silver .bronze-medal,
.mini-cart .reward-silver .silver-medal,
.mini-cart .reward-silver .gold-medal,
.mini-cart .reward-gold .bronze-medal,
.mini-cart .reward-gold .silver-medal,
.mini-cart .reward-gold .gold-medal {
  display: none;
}
.mini-cart .reward-bronze.filled .bronze-medal,
.mini-cart .reward-bronze.filled .silver-medal,
.mini-cart .reward-bronze.filled .gold-medal,
.mini-cart .reward-silver.filled .bronze-medal,
.mini-cart .reward-silver.filled .silver-medal,
.mini-cart .reward-silver.filled .gold-medal,
.mini-cart .reward-gold.filled .bronze-medal,
.mini-cart .reward-gold.filled .silver-medal,
.mini-cart .reward-gold.filled .gold-medal {
  display: block;
}
.mini-cart .reward-bronze.filled .grey-medal,
.mini-cart .reward-silver.filled .grey-medal,
.mini-cart .reward-gold.filled .grey-medal {
  display: none;
}

.minicart .space-bar {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 4px;
  max-width: 100%;
  padding: 0;
}
.minicart .space-bar:first-of-type {
  display: none;
}
.minicart .space-bar .space-bar-fill {
  background-color: #ececec;
  border-radius: 8px;
  flex-grow: 1;
  height: 100%;
}
.minicart .space-bar .space-bar-fill .animated-bar {
  height: 100%;
  transition: width 0.5s ease-in-out;
  width: 0%;
}
.minicart .qualified-purchase.filled {
  color: #262626;
}
.minicart .qualified-purchase.filled span {
  color: #262626;
}
.minicart .checkpoints-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
  width: 100%;
}
.minicart .checkpoints-wrapper .checkpoint {
  max-width: fit-content;
  transition: transform 0.5s ease-in-out;
}
.minicart .checkpoints-wrapper .checkpoint.is-transformed {
  transform: scale3d(1.2, 1.2, 1.2);
  transition: transform 0.5s ease-in-out;
}
.minicart .checkpoints-wrapper .checkpoint .checkpoint-bullet {
  background-color: #ececec;
  border-radius: 100%;
  height: 24px;
  margin: 0 8px;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  width: 24px;
}
.minicart .checkpoints-wrapper .checkpoint img {
  max-width: 100%;
  transition: visibility 0.5s linear, opacity 0.5s linear, transform 0.5s ease-in-out;
}

.punch-banner-wrapper-green .punch-card {
  border: 1px solid #cce8e9;
  padding: 16px;
}
.punch-banner-wrapper-green .punch-card .checkpoint.filled .checkpoint-bullet {
  background-color: #496a76;
}
.punch-banner-wrapper-green .punch-card .checkpoint.filled span {
  color: #19353f;
}
.punch-banner-wrapper-green .punch-card .space-bar .animated-bar {
  background-color: #496a76;
}

.punch-banner-wrapper-red .punch-card {
  border: 1px solid #d48386;
}
.punch-banner-wrapper-red .punch-card .checkpoint.filled .checkpoint-bullet {
  background-color: #c14d52;
}
.punch-banner-wrapper-red .punch-card .checkpoint.filled span {
  color: #c14d52;
}
.punch-banner-wrapper-red .punch-card .space-bar .animated-bar {
  background-color: #c14d52;
}

.punch-banner-wrapper-yellow .punch-card {
  border: 1px solid #fbe8bf;
}
.punch-banner-wrapper-yellow .punch-card .checkpoint.filled .checkpoint-bullet {
  background-color: #ac8c45;
}
.punch-banner-wrapper-yellow .punch-card .checkpoint.filled span {
  color: #ac8c45;
}
.punch-banner-wrapper-yellow .punch-card .space-bar .animated-bar {
  background-color: #ac8c45;
}

.info-icon {
  background: inherit;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  position: relative;
}
.info-icon .icon {
  background-color: var(--skin-primary-color-1);
  border-radius: 1.25rem;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  width: 1.5625rem;
}
.info-icon .tooltip {
  animation: fade-in 0.5s linear forwards;
  background-color: #7d7d7d;
  border-radius: 8px;
  bottom: 100%;
  color: #fff;
  font-size: 0.875rem;
  left: 50%;
  min-height: 50px;
  min-width: 279px;
  padding: 8px 18px;
  position: absolute;
  transform: translate(-50%, -9px);
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.info-icon .tooltip::before {
  bottom: -18px;
  content: " ";
  display: block;
  height: 18px;
  left: 0;
  position: absolute;
  width: 100%;
}
.info-icon .tooltip::after {
  border-left: solid transparent 9px;
  border-right: solid transparent 9px;
  border-top: solid #7d7d7d 9px;
  bottom: -9px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

/**
 * Targets iOS devices
 */
.site-search {
  height: 48px;
  position: relative;
  width: 100%;
}
.site-search .search-form {
  height: 48px;
  position: relative;
}
.site-search .search-form .spinner {
  height: 20px;
  left: auto;
  right: 50px;
  width: 20px;
}
.site-search .search-form .search-field {
  background: #f9f9f9;
  border: 1px solid #dbdbdb;
  color: #4b4b4b;
  font-size: 1rem;
  padding: 8px 48px 8px 16px;
}
.site-search .search-form .search-field:focus {
  border: 2px solid #6d6d6d;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  outline: 0;
}
.site-search .close-search,
.site-search .icon-search {
  background-color: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  right: 16px; /* 9/16 */
  top: 10px; /* 9/16 */
}
.site-search .close-search svg,
.site-search .icon-search svg {
  height: 24px;
  width: 24px;
}
.site-search .reset-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  top: 0.5625em; /* 9/16 */
}
.site-search input {
  height: 48px;
  padding-right: 16px;
}

@media (max-width: 374.98px) {
  .header-search .site-search {
    display: none;
  }
}
.suggestions-wrapper {
  position: relative;
}

.suggestions {
  background-color: #fff;
  border: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  padding: 0 16px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 5;
}
@media (min-width: 992px) {
  .suggestions {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.08);
    display: block;
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.3));
    height: fit-content;
    max-height: none;
    max-width: 442px;
    position: absolute;
    top: 0;
    width: auto;
  }
}
.suggestions .swatch-img {
  background: #fff;
  border: 0;
  border-radius: 4px;
  display: inline-block;
  height: 32px;
  margin-right: 14px;
  position: relative;
  width: 32px;
}
.suggestions .suggestions-heading {
  border-bottom: 1px solid #dbdbdb;
  margin-top: 16px;
  padding-bottom: 8px;
}
.suggestions .list {
  list-style-type: none;
  padding: 0;
}
.suggestions .no-ellipsis {
  overflow: initial;
  padding: 0;
  text-overflow: initial;
}
.suggestions .container {
  list-style-type: none;
}

.js-a-suggestion-click > * {
  pointer-events: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.form-group.floating-label.required .form-control-label::before {
  color: #4b4b4b;
  content: "*";
}

.native-arrow-select-container {
  margin-bottom: 0;
}

.custom-radio [type=radio]:checked,
.custom-radio [type=radio]:not(:checked) {
  left: -9999px;
  position: absolute;
}
.custom-radio [type=radio]:checked + label,
.custom-radio [type=radio]:not(:checked) + label {
  cursor: pointer;
  position: relative;
}
.custom-radio [type=radio]:checked + label::before,
.custom-radio [type=radio]:not(:checked) + label::before {
  border: 1px solid #8f8f8f;
  border-radius: 12px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  min-width: 24px;
  top: 0;
}
.custom-radio [type=radio]:checked + label::after,
.custom-radio [type=radio]:not(:checked) + label::after {
  background: #fff;
  border-radius: 10px;
  content: "";
  height: 10px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 10px;
}
.custom-radio [type=radio]:checked + label::before {
  background-color: #537c7f;
  border-color: #537c7f;
}

.pseudo-checkbox {
  background: none;
  border: 0;
}
.pseudo-checkbox .pseudo-checkbox-inner {
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.pseudo-checkbox .pseudo-checkbox-inner::before {
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  content: "";
  cursor: pointer;
  display: inline-block;
  padding: 11px;
  vertical-align: middle;
}
.pseudo-checkbox.selected .pseudo-checkbox-inner::before {
  background-color: #537c7f;
  border-color: #537c7f;
}
.pseudo-checkbox.selected .pseudo-checkbox-inner::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}

.pseudo-radio {
  background: none;
  border: 0;
}
.pseudo-radio .pseudo-radio-inner {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.pseudo-radio .pseudo-radio-inner::before {
  border: 1px solid #8f8f8f;
  border-radius: 12px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  top: 0;
  width: 24px;
}
.pseudo-radio.selected .pseudo-radio-inner::before {
  background-color: #537c7f;
  border-color: #537c7f;
}
.pseudo-radio.selected .pseudo-radio-inner::after {
  background: #fff;
  border-radius: 10px;
  content: "";
  height: 10px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 10px;
}

.location {
  position: relative;
}
.location .location-anchor {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 8px;
  white-space: nowrap;
}
.location .location-anchor .icon-map-pin {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}
.location .location-anchor .location-text {
  display: none;
  flex-direction: column;
  grid-column: 2/span 1;
  grid-row: 1/span 1;
  margin: 0 0 0 12px;
  padding: 0;
}
@media (min-width: 992px) {
  .location .location-anchor .location-text {
    display: flex;
  }
}
.location .location-anchor .location-text .find-location-link {
  text-decoration: underline;
}
.location .btn-store-locator {
  width: 100%;
}

.location-modal {
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3), 0 6px 10px 4px rgba(0, 0, 0, 0.08);
  display: none;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  left: -64px;
  margin-left: 0;
  max-width: 100vw;
  padding: 24px 0 24px;
  position: absolute;
  top: 48px;
  transition: ease-in-out;
  width: 375px;
  z-index: 1060;
}
@media (min-width: 375px) {
  .location-modal {
    left: -51px;
    padding: 18px 0 16px;
  }
}
@media (min-width: 768px) {
  .location-modal {
    left: auto;
    margin-left: -30px;
  }
}
@media (min-width: 992px) {
  .location-modal {
    top: auto;
    width: 400px;
  }
}
@media (max-width: 991.98px) {
  .location-modal {
    padding-bottom: 0;
  }
}
.location-modal.show {
  display: flex;
}
.location-modal .close {
  opacity: 1;
}
.location-modal .modal-dialog {
  margin: 0;
  max-width: none;
}
.location-modal .modal-content {
  border: 0;
}
@media (min-width: 768px) {
  .location-modal .locations-list-container {
    padding-right: 12px;
  }
}
.location-modal .main-stores-list {
  max-height: calc(100dvh - 350px);
  overflow: auto;
}
@media (min-width: 992px) {
  .location-modal .main-stores-list {
    max-height: 514px;
  }
}
.location-modal .location-modal-header {
  background-color: unset;
  border: 0;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .location-modal .location-modal-header {
    padding: 0 24px;
  }
}
.location-modal .location-modal-header .location-modal-header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.location-modal .location-modal-header .btn-storelocator-search {
  border-radius: 0 4px 4px 0;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 106px;
}
.location-modal .location-modal-header .card-body {
  padding: 0;
}
.location-modal .location-modal-header .form-control {
  font-size: 1rem;
  height: 48px;
}
.location-modal .btn-store-locator-container {
  border-top: 1px solid #dbdbdb;
  padding: 16px;
}
@media (min-width: 768px) {
  .location-modal .btn-store-locator-container {
    padding: 16px 24px;
  }
}
@media (min-width: 992px) {
  .location-modal .btn-store-locator-container {
    padding: 16px 24px 0;
  }
}
.location-modal .store-item {
  padding: 0 12px 0 16px;
  position: relative;
}
@media (min-width: 768px) {
  .location-modal .store-item {
    padding: 0 8px 0 24px;
  }
}
.location-modal .store-item-selected {
  background-color: #f3f9fa;
}
.location-modal .store-item .selected-text {
  display: none;
}
.location-modal .store-item.store-item-selected {
  background-color: #f3f9fa;
}
.location-modal .store-item.store-item-selected .selected-text {
  display: flex;
}
.location-modal .store-item.store-item-selected .selected-text span {
  min-height: 20px;
}
.location-modal .store-item.store-item-selected .select-button {
  display: none;
}
.location-modal .store-item.store-item-selected .store-card {
  border: 0;
}
.location-modal .store-item.store-item-selected + .store-item .store-card {
  border: 0;
}
.location-modal .store-item:first-of-type .store-card {
  border-top: 0;
}
.location-modal .store-item:last-of-type .store-card {
  border-bottom: 1px solid #dbdbdb;
}
@media (max-width: 991.98px) {
  .location-modal .store-item:last-of-type .store-card {
    margin-bottom: 12px;
  }
}
.location-modal .store-item .store-card {
  align-items: center;
  border-top: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  padding: 8px 0;
  width: 100%;
}
.location-modal .store-item .store-card .store-card-top {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 8px;
  width: 100%;
}
.location-modal .store-item .store-card .store-card-bottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 8px;
  width: 100%;
}
.location-modal .store-item .store-card .store-card-bottom .store-details-btn {
  text-decoration: none;
}
.location-modal .store-item .store-card .store-card-bottom button {
  margin-left: auto;
}
.location-modal .store-item .expanded svg {
  transform: rotate(180deg);
}
.location-modal .store-item .store-address {
  color: #4b4b4b;
}
.location-modal .list .store-phone-link {
  color: #50797b;
  font-weight: 400;
}
.location-modal .store-card .store-details-list li span {
  color: #4b4b4b;
}
.location-modal.location-modal-centered {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 100vh;
  max-width: 400px;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
}
@media (max-width: 991.98px) {
  .location-modal.location-modal-centered {
    height: 100%;
    justify-content: flex-start;
    max-width: 100%;
    overflow: auto;
    position: fixed;
    width: 100%;
  }
  .location-modal.location-modal-centered .list {
    max-height: calc(100vh - 180px);
  }
  @supports (-webkit-touch-callout: none) {
    .location-modal.location-modal-centered .list {
      max-height: calc(100vh - 180px);
    }
  }
}
.location-modal.location-modal-centered::after, .location-modal.location-modal-centered::before {
  display: none;
}
.location-modal .form-control-label {
  pointer-events: none;
}
.location-modal .store-locator-no-location {
  line-height: 16.8px;
}

.location-modal::before {
  border-bottom: 10px solid #dbdbdb;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: -21px;
  width: 0;
}

.location-modal::before,
.location-modal::after {
  left: 70px;
}
@media (min-width: 375px) {
  .location-modal::before,
  .location-modal::after {
    left: 57px;
    top: -20px;
  }
}
@media (min-width: 768px) {
  .location-modal::before,
  .location-modal::after {
    left: 38px;
  }
}

.location-modal::after {
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: -18px;
  width: 0;
}

.store-phone {
  display: none;
}

.in-store-inventory-dialog .main-stores-list {
  max-height: 635px;
}
.in-store-inventory-dialog .stock-alert-wrapper {
  flex-flow: row;
  justify-content: space-between;
  margin: 10px 0;
}
.in-store-inventory-dialog .hide-store-select {
  display: none;
}

.pdp-store-modal .stock-alert-wrapper-cart {
  display: none;
}

.cart-store-modal .stock-alert-wrapper-pdp {
  display: none;
}

.recommendations-title {
  margin-bottom: 8px;
  margin-left: 16px;
}
@media (min-width: 992px) {
  .recommendations-title {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 16px;
    margin-left: 0;
    padding-bottom: 16px;
  }
}
.recommendations-title h2 {
  font-size: 1.4375rem;
}
@media (min-width: 992px) {
  .recommendations-title h2 {
    font-size: 1.8125rem;
  }
}

.recommendations-slider {
  margin-bottom: 56px;
  position: relative;
}
.recommendations-slider .eistein-carousel:not(.slick-initialized) {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}
.recommendations-slider .eistein-carousel:not(.slick-initialized) .product {
  margin: 0 16px 0 0;
  min-width: 256px;
  padding: 0;
  width: 33vw;
}
.recommendations-slider .slick-list {
  overflow: visible;
}
.recommendations-slider .slick-track {
  margin: 0;
}
.recommendations-slider .slick-initialized .slick-slide {
  margin-right: 24px;
}

.slide-wrapper-minicart .product {
  margin: 0 16px 0 0;
  padding: 0;
  width: 100%;
}

.custom-slick-arrows .slick-arrow {
  background-color: #fff;
  border-radius: 100px;
  bottom: auto;
  cursor: pointer;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  margin: auto;
  padding: 4px;
  position: absolute;
  top: calc((940px - (24px * 5)) / 11.5);
  z-index: 2;
}
@media (min-width: 1440px) {
  .custom-slick-arrows .slick-arrow {
    top: calc((1328px - (24px * 5)) / 11.5);
  }
}
@media (min-width: 1920px) {
  .custom-slick-arrows .slick-arrow {
    top: calc((1696px - (24px * 5)) / 11.5);
  }
}
.custom-slick-arrows .slick-arrow.arrow-right {
  right: 16px;
}
.custom-slick-arrows .slick-arrow.arrow-left {
  left: 16px;
}
.custom-slick-arrows .slick-arrow.slick-disabled {
  display: none !important;
}

.related-searches-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin: 0 auto;
  max-width: 710px;
}

.related-searches-link {
  border: 1px solid #dbdbdb;
  border-radius: 100px;
  display: block;
  font-size: 0.875rem;
  padding: 8px 16px;
}

.slide-container[data-carousel-behavior=true] .slide-carousel:not(.slick-initialized) {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 16px;
}
@media (min-width: 992px) {
  .slide-container[data-carousel-behavior=true] .slide-carousel:not(.slick-initialized) {
    margin: 0;
    padding-bottom: 24px;
  }
}
.slide-container[data-carousel-behavior=true] .slide-carousel:not(.slick-initialized) .experience-component:not(:first-child) {
  display: block;
}
.slide-container[data-carousel-behavior=true] .slide-carousel:not(.slick-initialized) > div {
  width: 33vw;
}
@media (max-width: 991.98px) {
  .slide-container[data-carousel-behavior=true] .slide-carousel:not(.slick-initialized) > div {
    margin-right: 16px;
  }
}
@media (min-width: 992px) {
  .slide-container[data-carousel-behavior=true] .slide-carousel:not(.slick-initialized) > div {
    width: 18.1818181818%;
  }
}
.slide-container[data-carousel-behavior=true] .slide-carousel:not(.slick-initialized) > div:first-child {
  margin-left: 16px;
}

.slide-container {
  max-height: fit-content;
  position: relative;
}
.slide-container .title-container {
  color: #262626;
  font-size: 2rem;
}
.slide-container .title-container.title-key-line {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 16px;
}
.slide-container .title-container .carousel-heading {
  line-height: 120%;
}
.slide-container .title-container .carousel-heading * {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
}
.slide-container .slide-carousel .slick-track {
  margin: 0 auto;
}
.slide-container .slide-carousel .slick-track .slick-slide {
  height: auto;
}
.slide-container .slide-carousel .slick-track .slick-slide div {
  vertical-align: middle;
}
.slide-container .slide-carousel .slick-arrow {
  background-color: #fff;
  border-radius: 100px;
  cursor: pointer;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  margin: auto;
  padding: 4px;
  position: absolute;
  z-index: 2;
}
.slide-container .slide-carousel .slick-arrow.arrow-right {
  right: 16px;
}
.slide-container .slide-carousel .slick-arrow.arrow-left {
  left: 16px;
}
.slide-container .slide-carousel .slick-arrow.slick-disabled {
  display: none !important;
}
.slide-container .slide-carousel.container .slick-arrow.arrow-right {
  right: 0;
}
.slide-container .slide-carousel.container .slick-arrow.arrow-left {
  left: 0;
}
.slide-container .slide-carousel .slick-dots {
  align-items: center;
  bottom: 24px;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  right: 0;
}
.slide-container .slide-carousel .slick-dots li button {
  background-color: #b0b0b0;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  height: 8px;
  margin: 0 4px;
  opacity: 0.5;
  padding: 0;
  width: 8px;
}
.slide-container .slide-carousel .slick-dots .slick-active button {
  background-color: #fff;
  height: 12px;
  width: 12px;
}
.slide-container .slide-carousel .photo-tile-container .row {
  margin: 0;
  padding: 0;
}
.slide-container .slide-carousel .photo-tile-container .col-12 {
  margin: 0;
  padding: 0;
}
.slide-container .slide-carousel .photo-tile-container .photo-tile-figure {
  margin: 0;
  padding: 0;
}
.slide-container .slide-carousel:not(.slick-initialized) .experience-component:not(:first-child) {
  display: none;
}
.slide-container.peek-slider .slick-list {
  overflow: visible;
}
.slide-container.peek-slider .slick-list .slick-track {
  margin: 0;
}
@media (max-width: 991.98px) {
  .slide-container[data-slides-to-display-sm="1"]:not(.slick-initialized) .slide-carousel > div {
    min-width: 60vw;
  }
}
@media (max-width: 991.98px) {
  .slide-container[data-slides-to-display-sm="2"] .slick-list {
    margin-left: -12px;
    margin-right: -12px;
  }
  .slide-container[data-slides-to-display-sm="2"] .slick-slide {
    padding: 0 12px;
  }
}
.slide-container[data-slides-to-display-sm="2"] .slide-carousel:not(.slick-initialized) > div {
  margin: 0 12px;
}
@media (min-width: 992px) {
  .slide-container[data-slides-to-display-lg="5"] .slick-initialized .slick-slide {
    margin: 0 12px;
  }
}
@media (min-width: 1440px) {
  .slide-container[data-slides-to-display-lg="5"] .slick-initialized .slick-arrow {
    bottom: auto;
    top: calc((1328px - (24px * 5)) / 11.5);
  }
}
@media (min-width: 1920px) {
  .slide-container[data-slides-to-display-lg="5"] .slick-initialized .slick-arrow {
    bottom: auto;
    top: calc((1696px - (24px * 5)) / 11.5);
  }
}
@media (min-width: 992px) {
  .slide-container[data-slides-to-display-lg="2"] .slick-slide, .slide-container[data-slides-to-display-lg="7"] .slick-slide {
    padding: 0 12px;
  }
}

@media (max-width: 991.98px) {
  .arrow-mobile-top .slide-carousel .slick-arrow {
    bottom: 50%;
    top: 0;
  }
  .arrow-mobile-center .slide-carousel .slick-arrow {
    bottom: 0;
    top: 0;
  }
  .arrow-mobile-bottom .slide-carousel .slick-arrow {
    bottom: 0;
    top: 50%;
  }
}

@media (min-width: 992px) {
  .arrow-desktop-top .slide-carousel .slick-arrow {
    bottom: 50%;
    top: 0;
  }
  .arrow-desktop-center .slide-carousel .slick-arrow {
    bottom: 0;
    top: 0;
  }
  .arrow-desktop-bottom .slide-carousel .slick-arrow {
    bottom: 0;
    top: 50%;
  }
}

.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.slick-autoplay-toggle-button {
  bottom: 24px;
  position: absolute;
  right: 24px;
  z-index: 1;
}
@media (min-width: 992px) {
  .slick-autoplay-toggle-button {
    right: 16px;
  }
}

.carousel-container img:not([src]) {
  opacity: 0;
}

.badge {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 26px;
  justify-content: center;
  position: absolute;
  top: 0;
}
.badge-new {
  background-color: #cce8e9;
  color: #50797b;
  width: 44px;
}
.badge-sale {
  background-color: #b4292f;
  color: #fff;
  width: 45px;
}
.badge-multiple-size {
  background-color: #244b5a;
  color: #fff;
  width: 107px;
}
.badge-member-deal {
  background-color: #f6c862;
  color: #244b5a;
  width: 105px;
}
.badge-clearance {
  background-color: #b4292f;
  color: #fff;
  width: 82px;
}
.badge-clearance .text {
  line-height: 114%;
}

.modal.modal-aside {
  transition: none;
}
.modal.modal-aside .modal-dialog {
  bottom: 0;
  height: 100%;
  height: auto;
  margin: auto;
  position: fixed;
  transition: opacity 0.3s linear, right 0.3s ease-out;
  width: 100%;
}
@media (min-width: 992px) {
  .modal.modal-aside .modal-dialog {
    right: -525px;
    top: 0;
    width: 525px;
  }
}
@media (min-width: 992px) {
  .modal.modal-aside-small .modal-dialog {
    height: 100%;
    right: -472px;
    width: 472px;
  }
}
.modal.modal-aside.show .modal-dialog {
  right: 0;
}
.modal.modal-aside .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
  overflow-y: auto;
}
.modal.modal-aside .modal-header {
  border-bottom: 1px solid #dbdbdb;
  margin: 20px 32px 20px;
  padding: 0 0 20px;
}
@media (min-width: 992px) {
  .modal.modal-aside .modal-header {
    margin: 96px 32px 20px;
  }
}
.modal.modal-aside .modal-body {
  padding: 8px 32px 32px;
}
.modal.modal-aside .close {
  opacity: 1;
}

.stock-alert-wrapper,
.alcohol-product-alert {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
}

.stock-alert-green {
  background-color: #268055;
}
.stock-alert-yellow {
  background-color: #ffab00;
}
.stock-alert-red {
  background-color: #b4292f;
}

.availability-dot {
  border-radius: 100%;
  content: "";
  height: 8px;
  margin-right: 8px;
  min-width: 8px;
}

.swatch-circle-grey {
  background: #b0b0b0;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-grey.color-value[data-selected=true], .swatch-circle-grey.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-grey.color-value[data-selected=true]::after, .swatch-circle-grey.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-grey.color-value[data-selected=true].swatch-circle-white, .swatch-circle-grey.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-grey.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-grey.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-grey.color-value.selected.swatch-circle-white, .swatch-circle-grey.color-value.selected.swatch-circle-yellow, .swatch-circle-grey.color-value.selected.swatch-circle-beige, .swatch-circle-grey.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-grey.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-grey.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-grey.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-grey.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-grey.color-value.selected.swatch-circle-white::after, .swatch-circle-grey.color-value.selected.swatch-circle-yellow::after, .swatch-circle-grey.color-value.selected.swatch-circle-beige::after, .swatch-circle-grey.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-grey.color-value[data-selected=true].disabled, .swatch-circle-grey.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-grey.color-value[data-selected=true].disabled::after, .swatch-circle-grey.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-grey.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-grey.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-grey.disabled {
  opacity: 1;
}
.swatch-circle-grey.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-grey {
  background: #b0b0b0;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #b0b0b0;
  display: block;
  position: relative;
}
.swatch-filter-grey.color-value[data-selected=true], .swatch-filter-grey.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-grey.color-value[data-selected=true]::after, .swatch-filter-grey.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-grey.color-value[data-selected=true].swatch-circle-white, .swatch-filter-grey.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-grey.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-grey.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-grey.color-value.selected.swatch-circle-white, .swatch-filter-grey.color-value.selected.swatch-circle-yellow, .swatch-filter-grey.color-value.selected.swatch-circle-beige, .swatch-filter-grey.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-grey.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-grey.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-grey.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-grey.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-grey.color-value.selected.swatch-circle-white::after, .swatch-filter-grey.color-value.selected.swatch-circle-yellow::after, .swatch-filter-grey.color-value.selected.swatch-circle-beige::after, .swatch-filter-grey.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-grey.color-value[data-selected=true].disabled, .swatch-filter-grey.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-grey.color-value[data-selected=true].disabled::after, .swatch-filter-grey.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-grey.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-grey.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-grey.disabled {
  opacity: 0.2;
}

.swatch-circle-blue {
  background: #607c98;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-blue.color-value[data-selected=true], .swatch-circle-blue.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-blue.color-value[data-selected=true]::after, .swatch-circle-blue.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-blue.color-value[data-selected=true].swatch-circle-white, .swatch-circle-blue.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-blue.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-blue.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-blue.color-value.selected.swatch-circle-white, .swatch-circle-blue.color-value.selected.swatch-circle-yellow, .swatch-circle-blue.color-value.selected.swatch-circle-beige, .swatch-circle-blue.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-blue.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-blue.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-blue.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-blue.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-blue.color-value.selected.swatch-circle-white::after, .swatch-circle-blue.color-value.selected.swatch-circle-yellow::after, .swatch-circle-blue.color-value.selected.swatch-circle-beige::after, .swatch-circle-blue.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-blue.color-value[data-selected=true].disabled, .swatch-circle-blue.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-blue.color-value[data-selected=true].disabled::after, .swatch-circle-blue.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-blue.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-blue.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-blue.disabled {
  opacity: 1;
}
.swatch-circle-blue.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-blue {
  background: #607c98;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #607c98;
  display: block;
  position: relative;
}
.swatch-filter-blue.color-value[data-selected=true], .swatch-filter-blue.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-blue.color-value[data-selected=true]::after, .swatch-filter-blue.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-blue.color-value[data-selected=true].swatch-circle-white, .swatch-filter-blue.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-blue.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-blue.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-blue.color-value.selected.swatch-circle-white, .swatch-filter-blue.color-value.selected.swatch-circle-yellow, .swatch-filter-blue.color-value.selected.swatch-circle-beige, .swatch-filter-blue.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-blue.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-blue.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-blue.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-blue.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-blue.color-value.selected.swatch-circle-white::after, .swatch-filter-blue.color-value.selected.swatch-circle-yellow::after, .swatch-filter-blue.color-value.selected.swatch-circle-beige::after, .swatch-filter-blue.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-blue.color-value[data-selected=true].disabled, .swatch-filter-blue.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-blue.color-value[data-selected=true].disabled::after, .swatch-filter-blue.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-blue.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-blue.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-blue.disabled {
  opacity: 0.2;
}

.swatch-circle-natural {
  background: #d7c9a4;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-natural.color-value[data-selected=true], .swatch-circle-natural.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-natural.color-value[data-selected=true]::after, .swatch-circle-natural.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-natural.color-value[data-selected=true].swatch-circle-white, .swatch-circle-natural.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-natural.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-natural.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-natural.color-value.selected.swatch-circle-white, .swatch-circle-natural.color-value.selected.swatch-circle-yellow, .swatch-circle-natural.color-value.selected.swatch-circle-beige, .swatch-circle-natural.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-natural.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-natural.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-natural.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-natural.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-natural.color-value.selected.swatch-circle-white::after, .swatch-circle-natural.color-value.selected.swatch-circle-yellow::after, .swatch-circle-natural.color-value.selected.swatch-circle-beige::after, .swatch-circle-natural.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-natural.color-value[data-selected=true].disabled, .swatch-circle-natural.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-natural.color-value[data-selected=true].disabled::after, .swatch-circle-natural.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-natural.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-natural.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-natural.disabled {
  opacity: 1;
}
.swatch-circle-natural.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-natural {
  background: #d7c9a4;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #d7c9a4;
  display: block;
  position: relative;
}
.swatch-filter-natural.color-value[data-selected=true], .swatch-filter-natural.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-natural.color-value[data-selected=true]::after, .swatch-filter-natural.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-natural.color-value[data-selected=true].swatch-circle-white, .swatch-filter-natural.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-natural.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-natural.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-natural.color-value.selected.swatch-circle-white, .swatch-filter-natural.color-value.selected.swatch-circle-yellow, .swatch-filter-natural.color-value.selected.swatch-circle-beige, .swatch-filter-natural.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-natural.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-natural.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-natural.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-natural.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-natural.color-value.selected.swatch-circle-white::after, .swatch-filter-natural.color-value.selected.swatch-circle-yellow::after, .swatch-filter-natural.color-value.selected.swatch-circle-beige::after, .swatch-filter-natural.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-natural.color-value[data-selected=true].disabled, .swatch-filter-natural.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-natural.color-value[data-selected=true].disabled::after, .swatch-filter-natural.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-natural.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-natural.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-natural.disabled {
  opacity: 0.2;
}

.swatch-circle-green {
  background: #73a56b;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-green.color-value[data-selected=true], .swatch-circle-green.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-green.color-value[data-selected=true]::after, .swatch-circle-green.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-green.color-value[data-selected=true].swatch-circle-white, .swatch-circle-green.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-green.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-green.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-green.color-value.selected.swatch-circle-white, .swatch-circle-green.color-value.selected.swatch-circle-yellow, .swatch-circle-green.color-value.selected.swatch-circle-beige, .swatch-circle-green.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-green.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-green.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-green.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-green.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-green.color-value.selected.swatch-circle-white::after, .swatch-circle-green.color-value.selected.swatch-circle-yellow::after, .swatch-circle-green.color-value.selected.swatch-circle-beige::after, .swatch-circle-green.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-green.color-value[data-selected=true].disabled, .swatch-circle-green.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-green.color-value[data-selected=true].disabled::after, .swatch-circle-green.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-green.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-green.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-green.disabled {
  opacity: 1;
}
.swatch-circle-green.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-green {
  background: #73a56b;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #73a56b;
  display: block;
  position: relative;
}
.swatch-filter-green.color-value[data-selected=true], .swatch-filter-green.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-green.color-value[data-selected=true]::after, .swatch-filter-green.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-green.color-value[data-selected=true].swatch-circle-white, .swatch-filter-green.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-green.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-green.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-green.color-value.selected.swatch-circle-white, .swatch-filter-green.color-value.selected.swatch-circle-yellow, .swatch-filter-green.color-value.selected.swatch-circle-beige, .swatch-filter-green.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-green.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-green.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-green.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-green.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-green.color-value.selected.swatch-circle-white::after, .swatch-filter-green.color-value.selected.swatch-circle-yellow::after, .swatch-filter-green.color-value.selected.swatch-circle-beige::after, .swatch-filter-green.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-green.color-value[data-selected=true].disabled, .swatch-filter-green.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-green.color-value[data-selected=true].disabled::after, .swatch-filter-green.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-green.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-green.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-green.disabled {
  opacity: 0.2;
}

.swatch-circle-gray {
  background: #808080;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-gray.color-value[data-selected=true], .swatch-circle-gray.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-gray.color-value[data-selected=true]::after, .swatch-circle-gray.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-gray.color-value[data-selected=true].swatch-circle-white, .swatch-circle-gray.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-gray.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-gray.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-gray.color-value.selected.swatch-circle-white, .swatch-circle-gray.color-value.selected.swatch-circle-yellow, .swatch-circle-gray.color-value.selected.swatch-circle-beige, .swatch-circle-gray.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-gray.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-gray.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-gray.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-gray.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-gray.color-value.selected.swatch-circle-white::after, .swatch-circle-gray.color-value.selected.swatch-circle-yellow::after, .swatch-circle-gray.color-value.selected.swatch-circle-beige::after, .swatch-circle-gray.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-gray.color-value[data-selected=true].disabled, .swatch-circle-gray.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-gray.color-value[data-selected=true].disabled::after, .swatch-circle-gray.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-gray.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-gray.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-gray.disabled {
  opacity: 1;
}
.swatch-circle-gray.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-gray {
  background: #808080;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #808080;
  display: block;
  position: relative;
}
.swatch-filter-gray.color-value[data-selected=true], .swatch-filter-gray.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-gray.color-value[data-selected=true]::after, .swatch-filter-gray.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-gray.color-value[data-selected=true].swatch-circle-white, .swatch-filter-gray.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-gray.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-gray.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-gray.color-value.selected.swatch-circle-white, .swatch-filter-gray.color-value.selected.swatch-circle-yellow, .swatch-filter-gray.color-value.selected.swatch-circle-beige, .swatch-filter-gray.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-gray.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-gray.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-gray.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-gray.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-gray.color-value.selected.swatch-circle-white::after, .swatch-filter-gray.color-value.selected.swatch-circle-yellow::after, .swatch-filter-gray.color-value.selected.swatch-circle-beige::after, .swatch-filter-gray.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-gray.color-value[data-selected=true].disabled, .swatch-filter-gray.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-gray.color-value[data-selected=true].disabled::after, .swatch-filter-gray.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-gray.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-gray.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-gray.disabled {
  opacity: 0.2;
}

.swatch-circle-gold {
  background: #d4af37;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-gold.color-value[data-selected=true], .swatch-circle-gold.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-gold.color-value[data-selected=true]::after, .swatch-circle-gold.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-gold.color-value[data-selected=true].swatch-circle-white, .swatch-circle-gold.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-gold.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-gold.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-gold.color-value.selected.swatch-circle-white, .swatch-circle-gold.color-value.selected.swatch-circle-yellow, .swatch-circle-gold.color-value.selected.swatch-circle-beige, .swatch-circle-gold.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-gold.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-gold.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-gold.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-gold.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-gold.color-value.selected.swatch-circle-white::after, .swatch-circle-gold.color-value.selected.swatch-circle-yellow::after, .swatch-circle-gold.color-value.selected.swatch-circle-beige::after, .swatch-circle-gold.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-gold.color-value[data-selected=true].disabled, .swatch-circle-gold.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-gold.color-value[data-selected=true].disabled::after, .swatch-circle-gold.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-gold.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-gold.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-gold.disabled {
  opacity: 1;
}
.swatch-circle-gold.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-gold {
  background: #d4af37;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #d4af37;
  display: block;
  position: relative;
}
.swatch-filter-gold.color-value[data-selected=true], .swatch-filter-gold.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-gold.color-value[data-selected=true]::after, .swatch-filter-gold.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-gold.color-value[data-selected=true].swatch-circle-white, .swatch-filter-gold.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-gold.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-gold.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-gold.color-value.selected.swatch-circle-white, .swatch-filter-gold.color-value.selected.swatch-circle-yellow, .swatch-filter-gold.color-value.selected.swatch-circle-beige, .swatch-filter-gold.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-gold.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-gold.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-gold.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-gold.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-gold.color-value.selected.swatch-circle-white::after, .swatch-filter-gold.color-value.selected.swatch-circle-yellow::after, .swatch-filter-gold.color-value.selected.swatch-circle-beige::after, .swatch-filter-gold.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-gold.color-value[data-selected=true].disabled, .swatch-filter-gold.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-gold.color-value[data-selected=true].disabled::after, .swatch-filter-gold.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-gold.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-gold.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-gold.disabled {
  opacity: 0.2;
}

.swatch-circle-brown {
  background: #827363;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-brown.color-value[data-selected=true], .swatch-circle-brown.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-brown.color-value[data-selected=true]::after, .swatch-circle-brown.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-brown.color-value[data-selected=true].swatch-circle-white, .swatch-circle-brown.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-brown.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-brown.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-brown.color-value.selected.swatch-circle-white, .swatch-circle-brown.color-value.selected.swatch-circle-yellow, .swatch-circle-brown.color-value.selected.swatch-circle-beige, .swatch-circle-brown.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-brown.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-brown.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-brown.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-brown.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-brown.color-value.selected.swatch-circle-white::after, .swatch-circle-brown.color-value.selected.swatch-circle-yellow::after, .swatch-circle-brown.color-value.selected.swatch-circle-beige::after, .swatch-circle-brown.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-brown.color-value[data-selected=true].disabled, .swatch-circle-brown.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-brown.color-value[data-selected=true].disabled::after, .swatch-circle-brown.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-brown.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-brown.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-brown.disabled {
  opacity: 1;
}
.swatch-circle-brown.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-brown {
  background: #827363;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #827363;
  display: block;
  position: relative;
}
.swatch-filter-brown.color-value[data-selected=true], .swatch-filter-brown.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-brown.color-value[data-selected=true]::after, .swatch-filter-brown.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-brown.color-value[data-selected=true].swatch-circle-white, .swatch-filter-brown.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-brown.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-brown.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-brown.color-value.selected.swatch-circle-white, .swatch-filter-brown.color-value.selected.swatch-circle-yellow, .swatch-filter-brown.color-value.selected.swatch-circle-beige, .swatch-filter-brown.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-brown.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-brown.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-brown.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-brown.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-brown.color-value.selected.swatch-circle-white::after, .swatch-filter-brown.color-value.selected.swatch-circle-yellow::after, .swatch-filter-brown.color-value.selected.swatch-circle-beige::after, .swatch-filter-brown.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-brown.color-value[data-selected=true].disabled, .swatch-filter-brown.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-brown.color-value[data-selected=true].disabled::after, .swatch-filter-brown.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-brown.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-brown.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-brown.disabled {
  opacity: 0.2;
}

.swatch-circle-white {
  background: #edeeef;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-white.color-value[data-selected=true], .swatch-circle-white.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-white.color-value[data-selected=true]::after, .swatch-circle-white.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-white.color-value[data-selected=true].swatch-circle-white, .swatch-circle-white.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-white.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-white.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-white.color-value.selected.swatch-circle-white, .swatch-circle-white.color-value.selected.swatch-circle-yellow, .swatch-circle-white.color-value.selected.swatch-circle-beige, .swatch-circle-white.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-white.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-white.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-white.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-white.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-white.color-value.selected.swatch-circle-white::after, .swatch-circle-white.color-value.selected.swatch-circle-yellow::after, .swatch-circle-white.color-value.selected.swatch-circle-beige::after, .swatch-circle-white.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-white.color-value[data-selected=true].disabled, .swatch-circle-white.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-white.color-value[data-selected=true].disabled::after, .swatch-circle-white.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-white.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-white.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-white.disabled {
  opacity: 1;
}
.swatch-circle-white.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-white {
  background: #edeeef;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #edeeef;
  display: block;
  position: relative;
}
.swatch-filter-white.color-value[data-selected=true], .swatch-filter-white.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-white.color-value[data-selected=true]::after, .swatch-filter-white.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-white.color-value[data-selected=true].swatch-circle-white, .swatch-filter-white.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-white.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-white.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-white.color-value.selected.swatch-circle-white, .swatch-filter-white.color-value.selected.swatch-circle-yellow, .swatch-filter-white.color-value.selected.swatch-circle-beige, .swatch-filter-white.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-white.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-white.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-white.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-white.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-white.color-value.selected.swatch-circle-white::after, .swatch-filter-white.color-value.selected.swatch-circle-yellow::after, .swatch-filter-white.color-value.selected.swatch-circle-beige::after, .swatch-filter-white.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-white.color-value[data-selected=true].disabled, .swatch-filter-white.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-white.color-value[data-selected=true].disabled::after, .swatch-filter-white.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-white.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-white.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-white.disabled {
  opacity: 0.2;
}

.swatch-circle-black {
  background: #333;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-black.color-value[data-selected=true], .swatch-circle-black.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-black.color-value[data-selected=true]::after, .swatch-circle-black.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-black.color-value[data-selected=true].swatch-circle-white, .swatch-circle-black.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-black.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-black.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-black.color-value.selected.swatch-circle-white, .swatch-circle-black.color-value.selected.swatch-circle-yellow, .swatch-circle-black.color-value.selected.swatch-circle-beige, .swatch-circle-black.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-black.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-black.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-black.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-black.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-black.color-value.selected.swatch-circle-white::after, .swatch-circle-black.color-value.selected.swatch-circle-yellow::after, .swatch-circle-black.color-value.selected.swatch-circle-beige::after, .swatch-circle-black.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-black.color-value[data-selected=true].disabled, .swatch-circle-black.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-black.color-value[data-selected=true].disabled::after, .swatch-circle-black.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-black.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-black.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-black.disabled {
  opacity: 1;
}
.swatch-circle-black.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-black {
  background: #333;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #333;
  display: block;
  position: relative;
}
.swatch-filter-black.color-value[data-selected=true], .swatch-filter-black.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-black.color-value[data-selected=true]::after, .swatch-filter-black.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-black.color-value[data-selected=true].swatch-circle-white, .swatch-filter-black.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-black.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-black.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-black.color-value.selected.swatch-circle-white, .swatch-filter-black.color-value.selected.swatch-circle-yellow, .swatch-filter-black.color-value.selected.swatch-circle-beige, .swatch-filter-black.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-black.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-black.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-black.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-black.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-black.color-value.selected.swatch-circle-white::after, .swatch-filter-black.color-value.selected.swatch-circle-yellow::after, .swatch-filter-black.color-value.selected.swatch-circle-beige::after, .swatch-filter-black.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-black.color-value[data-selected=true].disabled, .swatch-filter-black.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-black.color-value[data-selected=true].disabled::after, .swatch-filter-black.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-black.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-black.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-black.disabled {
  opacity: 0.2;
}

.swatch-circle-orange {
  background: #d29b60;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-orange.color-value[data-selected=true], .swatch-circle-orange.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-orange.color-value[data-selected=true]::after, .swatch-circle-orange.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-orange.color-value[data-selected=true].swatch-circle-white, .swatch-circle-orange.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-orange.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-orange.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-orange.color-value.selected.swatch-circle-white, .swatch-circle-orange.color-value.selected.swatch-circle-yellow, .swatch-circle-orange.color-value.selected.swatch-circle-beige, .swatch-circle-orange.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-orange.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-orange.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-orange.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-orange.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-orange.color-value.selected.swatch-circle-white::after, .swatch-circle-orange.color-value.selected.swatch-circle-yellow::after, .swatch-circle-orange.color-value.selected.swatch-circle-beige::after, .swatch-circle-orange.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-orange.color-value[data-selected=true].disabled, .swatch-circle-orange.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-orange.color-value[data-selected=true].disabled::after, .swatch-circle-orange.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-orange.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-orange.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-orange.disabled {
  opacity: 1;
}
.swatch-circle-orange.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-orange {
  background: #d29b60;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #d29b60;
  display: block;
  position: relative;
}
.swatch-filter-orange.color-value[data-selected=true], .swatch-filter-orange.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-orange.color-value[data-selected=true]::after, .swatch-filter-orange.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-orange.color-value[data-selected=true].swatch-circle-white, .swatch-filter-orange.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-orange.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-orange.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-orange.color-value.selected.swatch-circle-white, .swatch-filter-orange.color-value.selected.swatch-circle-yellow, .swatch-filter-orange.color-value.selected.swatch-circle-beige, .swatch-filter-orange.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-orange.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-orange.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-orange.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-orange.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-orange.color-value.selected.swatch-circle-white::after, .swatch-filter-orange.color-value.selected.swatch-circle-yellow::after, .swatch-filter-orange.color-value.selected.swatch-circle-beige::after, .swatch-filter-orange.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-orange.color-value[data-selected=true].disabled, .swatch-filter-orange.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-orange.color-value[data-selected=true].disabled::after, .swatch-filter-orange.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-orange.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-orange.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-orange.disabled {
  opacity: 0.2;
}

.swatch-circle-pink {
  background: #e5aaf3;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-pink.color-value[data-selected=true], .swatch-circle-pink.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-pink.color-value[data-selected=true]::after, .swatch-circle-pink.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-pink.color-value[data-selected=true].swatch-circle-white, .swatch-circle-pink.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-pink.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-pink.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-pink.color-value.selected.swatch-circle-white, .swatch-circle-pink.color-value.selected.swatch-circle-yellow, .swatch-circle-pink.color-value.selected.swatch-circle-beige, .swatch-circle-pink.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-pink.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-pink.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-pink.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-pink.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-pink.color-value.selected.swatch-circle-white::after, .swatch-circle-pink.color-value.selected.swatch-circle-yellow::after, .swatch-circle-pink.color-value.selected.swatch-circle-beige::after, .swatch-circle-pink.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-pink.color-value[data-selected=true].disabled, .swatch-circle-pink.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-pink.color-value[data-selected=true].disabled::after, .swatch-circle-pink.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-pink.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-pink.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-pink.disabled {
  opacity: 1;
}
.swatch-circle-pink.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-pink {
  background: #e5aaf3;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #e5aaf3;
  display: block;
  position: relative;
}
.swatch-filter-pink.color-value[data-selected=true], .swatch-filter-pink.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-pink.color-value[data-selected=true]::after, .swatch-filter-pink.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-pink.color-value[data-selected=true].swatch-circle-white, .swatch-filter-pink.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-pink.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-pink.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-pink.color-value.selected.swatch-circle-white, .swatch-filter-pink.color-value.selected.swatch-circle-yellow, .swatch-filter-pink.color-value.selected.swatch-circle-beige, .swatch-filter-pink.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-pink.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-pink.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-pink.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-pink.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-pink.color-value.selected.swatch-circle-white::after, .swatch-filter-pink.color-value.selected.swatch-circle-yellow::after, .swatch-filter-pink.color-value.selected.swatch-circle-beige::after, .swatch-filter-pink.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-pink.color-value[data-selected=true].disabled, .swatch-filter-pink.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-pink.color-value[data-selected=true].disabled::after, .swatch-filter-pink.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-pink.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-pink.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-pink.disabled {
  opacity: 0.2;
}

.swatch-circle-beige {
  background: #f5f5dc;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-beige.color-value[data-selected=true], .swatch-circle-beige.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-beige.color-value[data-selected=true]::after, .swatch-circle-beige.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-beige.color-value[data-selected=true].swatch-circle-white, .swatch-circle-beige.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-beige.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-beige.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-beige.color-value.selected.swatch-circle-white, .swatch-circle-beige.color-value.selected.swatch-circle-yellow, .swatch-circle-beige.color-value.selected.swatch-circle-beige, .swatch-circle-beige.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-beige.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-beige.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-beige.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-beige.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-beige.color-value.selected.swatch-circle-white::after, .swatch-circle-beige.color-value.selected.swatch-circle-yellow::after, .swatch-circle-beige.color-value.selected.swatch-circle-beige::after, .swatch-circle-beige.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-beige.color-value[data-selected=true].disabled, .swatch-circle-beige.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-beige.color-value[data-selected=true].disabled::after, .swatch-circle-beige.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-beige.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-beige.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-beige.disabled {
  opacity: 1;
}
.swatch-circle-beige.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-beige {
  background: #f5f5dc;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #f5f5dc;
  display: block;
  position: relative;
}
.swatch-filter-beige.color-value[data-selected=true], .swatch-filter-beige.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-beige.color-value[data-selected=true]::after, .swatch-filter-beige.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-beige.color-value[data-selected=true].swatch-circle-white, .swatch-filter-beige.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-beige.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-beige.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-beige.color-value.selected.swatch-circle-white, .swatch-filter-beige.color-value.selected.swatch-circle-yellow, .swatch-filter-beige.color-value.selected.swatch-circle-beige, .swatch-filter-beige.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-beige.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-beige.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-beige.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-beige.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-beige.color-value.selected.swatch-circle-white::after, .swatch-filter-beige.color-value.selected.swatch-circle-yellow::after, .swatch-filter-beige.color-value.selected.swatch-circle-beige::after, .swatch-filter-beige.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-beige.color-value[data-selected=true].disabled, .swatch-filter-beige.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-beige.color-value[data-selected=true].disabled::after, .swatch-filter-beige.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-beige.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-beige.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-beige.disabled {
  opacity: 0.2;
}

.swatch-circle-metallic {
  background: #a8a9ad;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-metallic.color-value[data-selected=true], .swatch-circle-metallic.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-metallic.color-value[data-selected=true]::after, .swatch-circle-metallic.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-metallic.color-value[data-selected=true].swatch-circle-white, .swatch-circle-metallic.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-metallic.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-metallic.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-metallic.color-value.selected.swatch-circle-white, .swatch-circle-metallic.color-value.selected.swatch-circle-yellow, .swatch-circle-metallic.color-value.selected.swatch-circle-beige, .swatch-circle-metallic.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-metallic.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-metallic.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-metallic.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-metallic.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-metallic.color-value.selected.swatch-circle-white::after, .swatch-circle-metallic.color-value.selected.swatch-circle-yellow::after, .swatch-circle-metallic.color-value.selected.swatch-circle-beige::after, .swatch-circle-metallic.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-metallic.color-value[data-selected=true].disabled, .swatch-circle-metallic.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-metallic.color-value[data-selected=true].disabled::after, .swatch-circle-metallic.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-metallic.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-metallic.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-metallic.disabled {
  opacity: 1;
}
.swatch-circle-metallic.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-metallic {
  background: #a8a9ad;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #a8a9ad;
  display: block;
  position: relative;
}
.swatch-filter-metallic.color-value[data-selected=true], .swatch-filter-metallic.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-metallic.color-value[data-selected=true]::after, .swatch-filter-metallic.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-metallic.color-value[data-selected=true].swatch-circle-white, .swatch-filter-metallic.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-metallic.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-metallic.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-metallic.color-value.selected.swatch-circle-white, .swatch-filter-metallic.color-value.selected.swatch-circle-yellow, .swatch-filter-metallic.color-value.selected.swatch-circle-beige, .swatch-filter-metallic.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-metallic.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-metallic.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-metallic.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-metallic.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-metallic.color-value.selected.swatch-circle-white::after, .swatch-filter-metallic.color-value.selected.swatch-circle-yellow::after, .swatch-filter-metallic.color-value.selected.swatch-circle-beige::after, .swatch-filter-metallic.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-metallic.color-value[data-selected=true].disabled, .swatch-filter-metallic.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-metallic.color-value[data-selected=true].disabled::after, .swatch-filter-metallic.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-metallic.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-metallic.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-metallic.disabled {
  opacity: 0.2;
}

.swatch-circle-navy {
  background: #000080;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-navy.color-value[data-selected=true], .swatch-circle-navy.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-navy.color-value[data-selected=true]::after, .swatch-circle-navy.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-navy.color-value[data-selected=true].swatch-circle-white, .swatch-circle-navy.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-navy.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-navy.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-navy.color-value.selected.swatch-circle-white, .swatch-circle-navy.color-value.selected.swatch-circle-yellow, .swatch-circle-navy.color-value.selected.swatch-circle-beige, .swatch-circle-navy.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-navy.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-navy.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-navy.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-navy.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-navy.color-value.selected.swatch-circle-white::after, .swatch-circle-navy.color-value.selected.swatch-circle-yellow::after, .swatch-circle-navy.color-value.selected.swatch-circle-beige::after, .swatch-circle-navy.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-navy.color-value[data-selected=true].disabled, .swatch-circle-navy.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-navy.color-value[data-selected=true].disabled::after, .swatch-circle-navy.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-navy.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-navy.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-navy.disabled {
  opacity: 1;
}
.swatch-circle-navy.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-navy {
  background: #000080;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #000080;
  display: block;
  position: relative;
}
.swatch-filter-navy.color-value[data-selected=true], .swatch-filter-navy.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-navy.color-value[data-selected=true]::after, .swatch-filter-navy.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-navy.color-value[data-selected=true].swatch-circle-white, .swatch-filter-navy.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-navy.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-navy.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-navy.color-value.selected.swatch-circle-white, .swatch-filter-navy.color-value.selected.swatch-circle-yellow, .swatch-filter-navy.color-value.selected.swatch-circle-beige, .swatch-filter-navy.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-navy.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-navy.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-navy.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-navy.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-navy.color-value.selected.swatch-circle-white::after, .swatch-filter-navy.color-value.selected.swatch-circle-yellow::after, .swatch-filter-navy.color-value.selected.swatch-circle-beige::after, .swatch-filter-navy.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-navy.color-value[data-selected=true].disabled, .swatch-filter-navy.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-navy.color-value[data-selected=true].disabled::after, .swatch-filter-navy.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-navy.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-navy.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-navy.disabled {
  opacity: 0.2;
}

.swatch-circle-purple {
  background: #800080;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-purple.color-value[data-selected=true], .swatch-circle-purple.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-purple.color-value[data-selected=true]::after, .swatch-circle-purple.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-purple.color-value[data-selected=true].swatch-circle-white, .swatch-circle-purple.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-purple.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-purple.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-purple.color-value.selected.swatch-circle-white, .swatch-circle-purple.color-value.selected.swatch-circle-yellow, .swatch-circle-purple.color-value.selected.swatch-circle-beige, .swatch-circle-purple.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-purple.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-purple.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-purple.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-purple.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-purple.color-value.selected.swatch-circle-white::after, .swatch-circle-purple.color-value.selected.swatch-circle-yellow::after, .swatch-circle-purple.color-value.selected.swatch-circle-beige::after, .swatch-circle-purple.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-purple.color-value[data-selected=true].disabled, .swatch-circle-purple.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-purple.color-value[data-selected=true].disabled::after, .swatch-circle-purple.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-purple.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-purple.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-purple.disabled {
  opacity: 1;
}
.swatch-circle-purple.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-purple {
  background: #800080;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #800080;
  display: block;
  position: relative;
}
.swatch-filter-purple.color-value[data-selected=true], .swatch-filter-purple.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-purple.color-value[data-selected=true]::after, .swatch-filter-purple.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-purple.color-value[data-selected=true].swatch-circle-white, .swatch-filter-purple.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-purple.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-purple.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-purple.color-value.selected.swatch-circle-white, .swatch-filter-purple.color-value.selected.swatch-circle-yellow, .swatch-filter-purple.color-value.selected.swatch-circle-beige, .swatch-filter-purple.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-purple.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-purple.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-purple.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-purple.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-purple.color-value.selected.swatch-circle-white::after, .swatch-filter-purple.color-value.selected.swatch-circle-yellow::after, .swatch-filter-purple.color-value.selected.swatch-circle-beige::after, .swatch-filter-purple.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-purple.color-value[data-selected=true].disabled, .swatch-filter-purple.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-purple.color-value[data-selected=true].disabled::after, .swatch-filter-purple.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-purple.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-purple.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-purple.disabled {
  opacity: 0.2;
}

.swatch-circle-red {
  background: #f00;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-red.color-value[data-selected=true], .swatch-circle-red.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-red.color-value[data-selected=true]::after, .swatch-circle-red.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-red.color-value[data-selected=true].swatch-circle-white, .swatch-circle-red.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-red.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-red.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-red.color-value.selected.swatch-circle-white, .swatch-circle-red.color-value.selected.swatch-circle-yellow, .swatch-circle-red.color-value.selected.swatch-circle-beige, .swatch-circle-red.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-red.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-red.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-red.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-red.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-red.color-value.selected.swatch-circle-white::after, .swatch-circle-red.color-value.selected.swatch-circle-yellow::after, .swatch-circle-red.color-value.selected.swatch-circle-beige::after, .swatch-circle-red.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-red.color-value[data-selected=true].disabled, .swatch-circle-red.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-red.color-value[data-selected=true].disabled::after, .swatch-circle-red.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-red.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-red.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-red.disabled {
  opacity: 1;
}
.swatch-circle-red.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-red {
  background: #f00;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #f00;
  display: block;
  position: relative;
}
.swatch-filter-red.color-value[data-selected=true], .swatch-filter-red.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-red.color-value[data-selected=true]::after, .swatch-filter-red.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-red.color-value[data-selected=true].swatch-circle-white, .swatch-filter-red.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-red.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-red.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-red.color-value.selected.swatch-circle-white, .swatch-filter-red.color-value.selected.swatch-circle-yellow, .swatch-filter-red.color-value.selected.swatch-circle-beige, .swatch-filter-red.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-red.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-red.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-red.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-red.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-red.color-value.selected.swatch-circle-white::after, .swatch-filter-red.color-value.selected.swatch-circle-yellow::after, .swatch-filter-red.color-value.selected.swatch-circle-beige::after, .swatch-filter-red.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-red.color-value[data-selected=true].disabled, .swatch-filter-red.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-red.color-value[data-selected=true].disabled::after, .swatch-filter-red.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-red.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-red.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-red.disabled {
  opacity: 0.2;
}

.swatch-circle-silver {
  background: #c0c0c0;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-silver.color-value[data-selected=true], .swatch-circle-silver.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-silver.color-value[data-selected=true]::after, .swatch-circle-silver.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-silver.color-value[data-selected=true].swatch-circle-white, .swatch-circle-silver.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-silver.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-silver.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-silver.color-value.selected.swatch-circle-white, .swatch-circle-silver.color-value.selected.swatch-circle-yellow, .swatch-circle-silver.color-value.selected.swatch-circle-beige, .swatch-circle-silver.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-silver.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-silver.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-silver.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-silver.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-silver.color-value.selected.swatch-circle-white::after, .swatch-circle-silver.color-value.selected.swatch-circle-yellow::after, .swatch-circle-silver.color-value.selected.swatch-circle-beige::after, .swatch-circle-silver.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-silver.color-value[data-selected=true].disabled, .swatch-circle-silver.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-silver.color-value[data-selected=true].disabled::after, .swatch-circle-silver.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-silver.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-silver.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-silver.disabled {
  opacity: 1;
}
.swatch-circle-silver.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-silver {
  background: #c0c0c0;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #c0c0c0;
  display: block;
  position: relative;
}
.swatch-filter-silver.color-value[data-selected=true], .swatch-filter-silver.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-silver.color-value[data-selected=true]::after, .swatch-filter-silver.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-silver.color-value[data-selected=true].swatch-circle-white, .swatch-filter-silver.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-silver.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-silver.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-silver.color-value.selected.swatch-circle-white, .swatch-filter-silver.color-value.selected.swatch-circle-yellow, .swatch-filter-silver.color-value.selected.swatch-circle-beige, .swatch-filter-silver.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-silver.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-silver.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-silver.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-silver.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-silver.color-value.selected.swatch-circle-white::after, .swatch-filter-silver.color-value.selected.swatch-circle-yellow::after, .swatch-filter-silver.color-value.selected.swatch-circle-beige::after, .swatch-filter-silver.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-silver.color-value[data-selected=true].disabled, .swatch-filter-silver.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-silver.color-value[data-selected=true].disabled::after, .swatch-filter-silver.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-silver.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-silver.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-silver.disabled {
  opacity: 0.2;
}

.swatch-circle-yellow {
  background: #ff0;
  border-radius: 23px;
  border-radius: 23px;
  border-width: 0;
  height: 46px;
  width: 46px;
  background-clip: content-box;
  background-size: cover;
  display: block;
  padding: 2px;
  position: relative;
}
.swatch-circle-yellow.color-value[data-selected=true], .swatch-circle-yellow.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-yellow.color-value[data-selected=true]::after, .swatch-circle-yellow.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-yellow.color-value[data-selected=true].swatch-circle-white, .swatch-circle-yellow.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-yellow.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-yellow.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-yellow.color-value.selected.swatch-circle-white, .swatch-circle-yellow.color-value.selected.swatch-circle-yellow, .swatch-circle-yellow.color-value.selected.swatch-circle-beige, .swatch-circle-yellow.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-yellow.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-yellow.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-yellow.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-yellow.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-yellow.color-value.selected.swatch-circle-white::after, .swatch-circle-yellow.color-value.selected.swatch-circle-yellow::after, .swatch-circle-yellow.color-value.selected.swatch-circle-beige::after, .swatch-circle-yellow.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-yellow.color-value[data-selected=true].disabled, .swatch-circle-yellow.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-yellow.color-value[data-selected=true].disabled::after, .swatch-circle-yellow.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-yellow.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-yellow.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-yellow.disabled {
  opacity: 1;
}
.swatch-circle-yellow.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-filter-yellow {
  background: #ff0;
  border-radius: 0.69em;
  border-radius: 0.69em;
  border-width: 0;
  height: 1.38em;
  width: 1.38em;
  background-color: #ff0;
  display: block;
  position: relative;
}
.swatch-filter-yellow.color-value[data-selected=true], .swatch-filter-yellow.color-value.selected {
  border: 1px solid #262626;
}
.swatch-filter-yellow.color-value[data-selected=true]::after, .swatch-filter-yellow.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-filter-yellow.color-value[data-selected=true].swatch-circle-white, .swatch-filter-yellow.color-value[data-selected=true].swatch-circle-yellow, .swatch-filter-yellow.color-value[data-selected=true].swatch-circle-beige, .swatch-filter-yellow.color-value[data-selected=true].swatch-circle-ivory, .swatch-filter-yellow.color-value.selected.swatch-circle-white, .swatch-filter-yellow.color-value.selected.swatch-circle-yellow, .swatch-filter-yellow.color-value.selected.swatch-circle-beige, .swatch-filter-yellow.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-filter-yellow.color-value[data-selected=true].swatch-circle-white::after, .swatch-filter-yellow.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-filter-yellow.color-value[data-selected=true].swatch-circle-beige::after, .swatch-filter-yellow.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-filter-yellow.color-value.selected.swatch-circle-white::after, .swatch-filter-yellow.color-value.selected.swatch-circle-yellow::after, .swatch-filter-yellow.color-value.selected.swatch-circle-beige::after, .swatch-filter-yellow.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-filter-yellow.color-value[data-selected=true].disabled, .swatch-filter-yellow.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-filter-yellow.color-value[data-selected=true].disabled::after, .swatch-filter-yellow.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-filter-yellow.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-filter-yellow.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-filter-yellow.disabled {
  opacity: 0.2;
}

.swatch-circle-miscellaneous {
  background: conic-gradient(from -18deg, #612d91 8.3%, #283897 0% 16.6%, #006cb8 0% 24.9%, #00a2b7 0% 33.2%, #00a666 0% 41.5%, #a6cf4f 0% 49.8%, #fcf100 0% 58.1%, #faae18 0% 66.4%, #f5811f 0% 74.7%, #f25822 0% 83%, #ed1b24 0% 91.3%, #bc0070 0%);
  background-clip: content-box;
  background-size: cover;
  border: 0;
  border-radius: 23px;
  display: block;
  height: 46px;
  padding: 2px;
  position: relative;
  transform: none;
  width: 46px;
}
.swatch-circle-miscellaneous.color-value[data-selected=true], .swatch-circle-miscellaneous.color-value.selected {
  border: 1px solid #262626;
}
.swatch-circle-miscellaneous.color-value[data-selected=true]::after, .swatch-circle-miscellaneous.color-value.selected::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 6px;
}
.swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-white, .swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-yellow, .swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-beige, .swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-ivory, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-white, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-yellow, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-beige, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-ivory {
  border: 1px solid #262626;
}
.swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-white::after, .swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-yellow::after, .swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-beige::after, .swatch-circle-miscellaneous.color-value[data-selected=true].swatch-circle-ivory::after, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-white::after, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-yellow::after, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-beige::after, .swatch-circle-miscellaneous.color-value.selected.swatch-circle-ivory::after {
  border-color: #244b5a;
}
.swatch-circle-miscellaneous.color-value[data-selected=true].disabled, .swatch-circle-miscellaneous.color-value.selected.disabled {
  border: 1px solid #262626;
}
.swatch-circle-miscellaneous.color-value[data-selected=true].disabled::after, .swatch-circle-miscellaneous.color-value.selected.disabled::after {
  border-color: #244b5a;
  border-width: 0 1px 1px 0;
  bottom: auto;
  left: -8px;
  top: 20px;
}
.swatch-circle-miscellaneous.color-value:hover {
  border: 1px solid #ececec;
}
.swatch-circle-miscellaneous.color-value.selected:hover {
  border: 1px solid #262626;
}
.swatch-circle-miscellaneous.disabled {
  opacity: 1;
}
.swatch-circle-miscellaneous.disabled::after {
  background: #244b5a;
  content: "";
  display: block;
  height: 1px;
  transform: rotate(-45deg);
  width: 60px;
  left: -6px;
  position: absolute;
  top: 22px;
}

.swatch-count {
  line-height: 0.75rem;
}

.modal.edit-product-modal .quick-view-dialog {
  background-color: #fff;
  height: calc(100% - 65px);
  max-width: 100%;
}
@media (min-width: 992px) {
  .modal.edit-product-modal .quick-view-dialog {
    height: 100%;
    width: 474px;
  }
}
.modal.edit-product-modal .modal-header {
  background-color: #fff;
  height: 64px;
  margin: 0 26px 8px 32px;
  padding: 20px 0;
}
@media (max-width: 767.98px) {
  .modal.edit-product-modal .modal-header {
    margin: 0 16px 8px;
  }
}
.modal.edit-product-modal .modal-content-wrapper {
  height: calc(100% - 64px);
  margin-right: 6px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px 26px 64px 32px;
}
@media (max-width: 767.98px) {
  .modal.edit-product-modal .modal-content-wrapper {
    padding: 16px 10px 64px 16px;
  }
}
.modal.edit-product-modal .modal-body {
  padding: 0;
}
@media (min-width: 992px) {
  .modal.edit-product-modal .quickview-images {
    padding: 0 16px;
  }
}
.modal.edit-product-modal .product-detail {
  margin: 0;
}
.modal.edit-product-modal .product-detail .badge {
  left: 16px;
}
@media (min-width: 992px) {
  .modal.edit-product-modal .product-detail .badge {
    left: 28px;
  }
}
.modal.edit-product-modal .pdp-details {
  border: 0;
  max-width: 100%;
  padding: 8px;
  top: unset;
}
@media (min-width: 992px) {
  .modal.edit-product-modal .pdp-details {
    padding: 8px 12px;
  }
}
.modal.edit-product-modal .pdp-details .product-name,
.modal.edit-product-modal .pdp-details .sale-price-text,
.modal.edit-product-modal .pdp-details .list-price-text {
  font-size: 1.625rem;
  line-height: 120%;
}
.modal.edit-product-modal .promotional-banner p {
  margin: 0;
}
.modal.edit-product-modal .modal-footer {
  display: block;
  padding: 0;
}
.modal.edit-product-modal .prices-add-to-cart-actions {
  padding: 0;
}
.modal.edit-product-modal .prices-add-to-cart-actions .pdp-buy-box .quantity {
  width: 132px;
}
.modal.edit-product-modal .custom-slick-arrows .slick-arrow {
  top: 50%;
  transform: translate(0, -50%);
}
.modal.edit-product-modal .pdp-carousel .slick-dots {
  bottom: 8px;
}
@media (min-width: 992px) {
  .modal.edit-product-modal .pdp-carousel .slick-dots {
    height: auto;
  }
}
.modal.edit-product-modal .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write a {
  color: #4b4b4b;
  font-size: 0.75rem !important;
  margin: 0;
}
.modal.edit-product-modal .bundle-items-header,
.modal.edit-product-modal .bundle-items {
  display: none;
}

.card-customer {
  padding: 8px 16px 24px;
}
@media (min-width: 992px) {
  .card-customer {
    padding: 8px 48px 32px;
  }
}
.card-customer .nav-tabs {
  margin-bottom: 16px;
}

.login .login-popover {
  background: transparent;
  border: 0;
  box-shadow: none;
  left: -321px;
  padding: 0;
  top: 43px;
}
.login .login-popover::before, .login .login-popover::after {
  display: none;
}
.login .login-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.login .login-page-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.login .login-page-wrapper.active .login-overlay {
  display: block;
}
.login .login-page-wrapper.active .login-container {
  bottom: 0;
  transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);
}
@media (min-width: 992px) {
  .login .login-page-wrapper.active .login-container {
    bottom: unset;
    right: 0;
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
  }
}

.drawer .drawer-wrapper .drawer-content.login-content {
  padding-top: 8px;
}

.customer-information-block .sign-in-redirect {
  cursor: pointer;
  text-decoration: underline;
}
.customer-information-block .clearfix {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 16px;
}
.customer-information-block .clearfix.options-wrapper {
  margin-bottom: 24px;
}
.customer-information-block .clearfix::after {
  display: none;
}
.customer-information-block .clearfix .reset-password {
  color: #244b5a;
  font-family: "sofia-pro";
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  text-decoration: underline;
}

.disclaimer-box {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
  max-width: 720px;
  padding-left: 32px;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

input::-ms-reveal {
  display: none;
}

.registered-form .alert,
.customer-error .alert {
  display: block;
}
.registered-form .alert .close,
.customer-error .alert .close {
  padding: 4px 10px;
}
@media (min-width: 992px) {
  .registered-form .alert .close,
  .customer-error .alert .close {
    padding: 8px 20px;
  }
}

.bread-financial-plcc {
  gap: 16px;
  margin-top: 4px;
}
.bread-financial-plcc .plcc-logo img {
  max-width: 42px;
  min-height: 27px;
}

.bread-financial-plcc-card {
  background-color: #fefaef;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-bottom: 64px;
  padding: 16px;
}
@media (min-width: 992px) {
  .bread-financial-plcc-card {
    padding: 16px 24px;
  }
}
.bread-financial-plcc-card .bread-financial-card-content {
  display: flex;
  gap: 16px;
  margin-top: 4px;
}
.bread-financial-plcc-card .bread-financial-card-content .plcc-logo img {
  border-radius: 4px;
  max-width: 53px;
  min-height: 34px;
}
.bread-financial-plcc-card .bread-financial-card-content .bread-financial-card-text svg {
  height: 16px;
  margin-bottom: 5px;
  width: 16px;
}
.bread-financial-plcc-card .bread-financial-card-ctas {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}
.bread-financial-plcc-card .bread-financial-card-ctas .bread-financial-btn:hover {
  background-color: transparent !important;
}

.bread-financial-plcc-banner {
  background-size: cover;
  border-radius: 8px;
}
.bread-financial-plcc-banner .bread-financial-banner-content {
  padding: 8px 16px;
}