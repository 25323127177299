@import "../utilities/swatch";

// Product Edit Drawer
.modal.edit-product-modal {
    .quick-view-dialog {
        background-color: $color-n0;
        height: calc(100% - 65px);
        max-width: 100%;

        @include media-breakpoint-up (lg) {
            height: 100%;
            width: 474px;
        }
    }

    .modal-header {
        background-color: $color-n0;
        height: $space-64;
        margin: 0 $space-26 $space-8 $space-32;
        padding: $space-20 0;

        @include media-breakpoint-down (sm) {
            margin: 0 $space-16 $space-8;
        }
    }

    .modal-content-wrapper {
        height: calc(100% - 64px);
        margin-right: 6px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: $space-16 $space-26 $space-64 $space-32;

        @include media-breakpoint-down (sm) {
            padding: $space-16 $space-10 $space-64 $space-16;
        }
    }

    .modal-body {
        padding: 0;
    }

    .quickview-images {
        @include media-breakpoint-up (lg) {
            padding: 0 $space-16;
        }
    }

    .product-detail {
        margin: 0;

        .badge {
            left: 16px;

            @include media-breakpoint-up (lg) {
                left: 28px;
            }
        }
    }

    .pdp-details {
        border: 0;
        max-width: 100%;
        padding: $space-8;
        top: unset;

        @include media-breakpoint-up (lg) {
            padding: $space-8 $space-12;
        }

        .product-name,
        .sale-price-text,
        .list-price-text {
            font-size: $font-26;
            line-height: 120%;
        }
    }

    .promotional-banner {
        p {
            margin: 0;
        }
    }

    .modal-footer {
        display: block;
        padding: 0;
    }

    .prices-add-to-cart-actions {
        padding: 0;

        .pdp-buy-box {
            .quantity {
                width: 132px;
            }
        }
    }

    .custom-slick-arrows {
        .slick-arrow {
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .pdp-carousel {
        .slick-dots {
            bottom: $space-8;

            @include media-breakpoint-up (lg) {
                height: auto;
            }
        }
    }

    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write a {
        color: $color-t200;
        font-size: $font-12 !important;
        margin: 0;
    }

    // Product Bundles
    .bundle-items-header,
    .bundle-items {
        display: none;
    }
}
