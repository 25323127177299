.food-games-wrapper {
    background: var(--banner-bg-img) no-repeat center center / cover;
    background-color: var(--banner-bg-color);
    border: 1px solid $color-b200;
    border-radius: 8px;
}

.punch-cards-block {
    display: inline-block;
    margin: $space-16 0;
    position: relative;
}

.punch-cards-wrapper {
    border-radius: $border-radius-4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $space-16;
    padding: $space-24;

    .punch-card {
        align-items: center;
        background-color: $color-n0;
        border-radius: $border-radius-4;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $space-16;

        .progress-text {
            color: #000;
            font-size: $font-16;
            text-align: left;
            width: 100%;

            @include media-breakpoint-up(lg) {
                font-size: $font-20;
            }

            b {
                color: var(--accent-color, inherit);
            }
        }
    }

    .punch-card-header {
        font-size: $font-18;

        @include media-breakpoint-up(md) {
            font-size: $font-26;
        }
    }

    .icon-wrapper {
        align-items: center;
        display: flex;
        height: $space-24;
        justify-content: center;
        margin-right: $space-8;
        padding: 0 $space-6;
        width: $space-24;

        .feature-icon {
            height: $space-24;
            width: $space-18;
        }

        .award-icon {
            height: $space-24;
            width: $space-24;
        }
    }
}

.punch-banner-wrapper {
    .progress-text {
        display: none;
    }

    .punch-card-header {
        border-top: 1px solid $color-n50;
        display: block;
        margin: $space-12 0;
        padding-top: $space-12;
    }
}

.reward-bronze,
.reward-silver,
.reward-gold {
    .bronze-medal,
    .silver-medal,
    .gold-medal,
    .grey-medal {
        @include media-breakpoint-down(sm) {
            height: $space-40;
            width: $space-32;
        }

        &.modal-medal {
            height: $space-40;
            width: $space-32;
        }
    }

    .bronze-medal,
    .silver-medal,
    .gold-medal {
        display: none;
    }

    &.filled {
        .bronze-medal,
        .silver-medal,
        .gold-medal {
            display: block;
        }

        .grey-medal {
            display: none;
        }
    }
}

.space-bar {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: $space-4;
    max-width: 100%;
    padding: 0;

    &:first-of-type {
        display: none;
    }

    .space-bar-fill {
        background-color: $color-n40;
        border-radius: $border-radius-8;
        flex-grow: 1;
        height: 100%;

        .animated-bar {
            height: 100%;
            transition: width 1.5s linear;
            width: 0%;
        }
    }
}

.qualified-purchase {
    &.filled {
        color: $color-t300;

        span {
            color: $color-t300;
        }
    }
}

.checkpoints-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: $space-10;
    width: 100%;

    .checkpoint {
        max-width: fit-content;
        transition: transform 0.5s ease-in-out;

        &:last-of-type {
            position: relative;
        }

        &.is-transformed {
            transform: scale3d(1.2, 1.2, 1.2);
            transition: transform 0.5s ease-in-out;
        }

        .checkpoint-bullet {
            background-color: $color-n40;
            border-radius: 100%;
            height: 24px;
            margin: 0 $space-8;
            transition: visibility 0.5s linear, opacity 0.5s linear;
            width: 24px;
        }

        .punch-card-img {
            max-width: 100%;
            transition: visibility 0.5s linear, opacity 0.5s linear, transform 0.5s ease-in-out;

            &.completed {
                bottom: $space-32;
                left: $space-36;
                max-width: 100px;
                position: absolute;
            }
        }

        span {
            white-space: nowrap;
        }
    }

}


.punch-cards-wrapper-green {
    background-color: $color-p50;
    border: 1px solid $color-p500;
}

.punch-cards-wrapper-green,
.punch-banner-wrapper-green {
    .punch-card {
        border: 1px solid $color-p75;

        .checkpoint {
            &.filled {
                .checkpoint-bullet {
                    background-color: $color-b200;
                }

                span {
                    color: $color-b400;
                }
            }
        }

        .space-bar {
            .animated-bar {
                background-color: $color-b200;
            }
        }
    }
}

.punch-cards-wrapper-red {
    background-color: $color-s50;
    border: 1px solid $color-s500;
}

.punch-cards-wrapper-red,
.punch-banner-wrapper-red {
    .punch-card {
        border: 1px solid $color-s100;

        .checkpoint {
            &.filled {
                .checkpoint-bullet {
                    background-color: $color-s200;
                }

                span {
                    color: $color-s200;
                }
            }
        }

        .space-bar {
            .animated-bar {
                background-color: $color-s200;
            }
        }
    }
}

.punch-cards-wrapper-yellow {
    background-color: $color-sa50;
    border: 1px solid $color-sa500;
}

.punch-cards-wrapper-yellow,
.punch-banner-wrapper-yellow {
    .punch-card {
        border: 1px solid $color-sa75;

        .checkpoint {
            &.filled {
                .checkpoint-bullet {
                    background-color: $color-sa400;
                }

                span {
                    color: $color-sa400;
                }
            }
        }

        .space-bar {
            .animated-bar {
                background-color: $color-sa400;
            }
        }
    }
}

.collapsible-punch-card {
    .punch-card-button {
        .chevron-down {
            display: none;
        }

        .chevron-up {
            display: block;
        }

        &.collapsed {
            .chevron-down {
                display: block;
            }

            .chevron-up {
                display: none;
            }
        }
    }
}


.t2-punch-cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: $space-8;
    margin: $space-32 0;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.t2-punch-card-title {
    font-size: $font-20;
}

.t2-punch-card-container {
    display: grid;
    grid-gap: $space-8;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto auto;
    }

    .t2-punch-card {
        border: 1px solid $color-sa300;
        border-radius: $border-radius-4;
        min-height: 103px;
        width: 100%;

        &.punch-card-highlight {
            grid-column: 1 / -1;

            .t2-punch-card-body {
                display: flex;
                gap: 8px;

                .wmcc-img {
                    height: 34px;
                    margin-left: auto;
                    width: 59px;
                }
            }
        }

        .t2-punch-card-header {
            align-items: center;
            background-color: $color-sa50;
            display: flex;
            justify-content: space-between;
            padding: $space-8 $space-16;
            width: 100%;

            .eyebrow-text {
                color: $color-t200;
                font-size: $font-12;
                font-weight: $font-regular;
            }

            .t2-punch-card-points {
                color: $color-t300;
                font-size: $font-16;
                font-weight: $font-medium;
            }

            .punch-card-img {
                height: $space-32;

            }

            .checkpoint-bullet {
                align-items: center;
                background-color: $color-n0;
                border: 1px dashed $color-sa300;
                border-radius: 100%;
                display: flex;
                height: $space-32;
                justify-content: center;
                width: $space-32;

                .icon-check {
                    display: none;
                }

                &.filled {
                    background: $color-sa300;
                    border: 2px solid $color-sa300;

                    .icon-check {
                        display: block;
                    }
                }
            }
        }

        .t2-punch-card-body {
            background-color: $color-n0;
            padding: 8px 16px 12px;
        }
    }
}

.popover-authenticated,
.pd-punch-card {
    .t2-punch-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
