.veil {
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 100;

    .underlay {
        background-color: $color-n0;
        height: 100%;
        left: 0;
        opacity: 0.8;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

$spinner-size: 72px;

.spinner {
    animation: sk-rotate 2s infinite linear;
    bottom: 0;
    height: $spinner-size;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: $spinner-size;
    z-index: 2;

    svg {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}

@keyframes sk-rotate {
    100% { transform: rotate(360deg); }
}
