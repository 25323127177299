@import "../variables";
@import "./menu";
@import "./punch-cards";

.header-nav {
    background-color: $color-n0;
    z-index: 11;

    &.sticky-nav {
        position: sticky;
        top: 0;
        transition: all 0.3s ease-in-out;

        @include media-breakpoint-down(md) {
            .container {
                .header {
                    padding: $space-8 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            .main-menu,
            .multilevel-dropdown {
                display: none !important;
            }
        }

        .promotion-banner,
        .announcement-banner {
            height: 0;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
        }
    }

    &.header-above:not(.sticky-nav) {
        position: relative;
        z-index: 100;
    }
}

.header {
    align-items: center;
    background-color: $color-n0;
    display: grid;
    grid-template-columns: 44px 1fr 12fr 1fr 44px;
    max-height: fit-content;
    padding: $space-8 0 $space-16;
    position: relative;

    @include media-breakpoint-up(lge) {
        column-gap: $space-24;
        grid-template-columns: 4fr 6fr 1fr 1fr 1fr;
        padding: $space-16 0;
        row-gap: 0;
    }

    .btn-menu {
        background: none;
        border: 0;
        display: flex;
        padding: $space-4;

        @include media-breakpoint-up(lge) {
            padding: 0s;
        }

        svg {
            vertical-align: top;
        }
    }

    .nav-item {
        line-height: 0;
        margin: 0;
        padding: 0;
    }

    .popover {
        border: 1px solid $color-n50;
        border-radius: $border-radius-4;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3), 0 6px 10px 4px rgba(0, 0, 0, 0.08);
        left: -30px;
        max-height: calc(100vh - 150px);
        max-width: 100dvw;
        overflow: auto;
        padding: $space-16 0;
        position: absolute;
        right: 0;
        top: 56px;
        transition: ease-in-out;
        width: 375px;

        @include media-breakpoint-up(sm) {
            left: -16px;
            max-width: 375px;
        }

        @include media-breakpoint-up(md) {
            left: unset;
            right: -22px;
            top: 57px;
        }

        @include media-breakpoint-up(lge) {
            right: -4px;
            top: 70px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 383px;
            right: -32px;
            width: 100%;
        }

        @include media-breakpoint-up(xxl) {
            right: 7px;
        }
    }

    .popover-authenticated {
        @include media-breakpoint-down(md) {
            justify-content: flex-start;
            max-height: 70vh;
            overflow-y: auto;
        }

        @include media-breakpoint-up(lge) {
            right: 29px;
        }

        @include media-breakpoint-up(xl) {
            right: 1px;
        }

        @include media-breakpoint-up(xxl) {
            right: 33px;
        }
    }

    .popover-header {
        background-color: unset;
        border: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 0 $space-16;
        width: 100%;

        .t2-punch-card-entries-total {
            display: none;
        }

        .popover-header-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: $space-20;

            .close {
                position: relative;
                right: -6px;
                top: -6px;
            }
        }

        .reward-block {
            align-items: center;
            background-color: $color-sa50;
            border: 1px solid $color-sa300;
            border-radius: $border-radius-8;
            display: flex;
            height: 78px;
            justify-content: space-between;

            .reward-content {
                max-width: 340px;
                width: 100%;
            }

            &.logged {
                height: 100%;
            }

            &.guest-user {
                display: block;
                height: auto;
            }
        }

        .link-rewards {
            text-decoration: underline;
        }

        // Rewards Bar
        .reward-bar-container {
            width: 100%;

            .reward-bar {
                background-color: $color-n10;
                border: 1px solid $color-n50;
                border-radius: $border-radius-4;
                box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
                height: $space-24;
                width: 100%;
            }

            .reward-bar-fill {
                background-color: $color-sa300;
                border-radius: $border-radius-4 - 1px 0 0 $border-radius-4 - 1px;
                height: 100%;
                text-align: right;

                div {
                    font-size: $font-14;
                    min-width: 25px;
                    padding-right: $space-8;
                }
            }

            .reward-bar-counters {
                align-items: center;
                display: flex;
                justify-content: space-between;
                line-height: 120%;
            }
        }

        .reward-actions {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.navbar-location {
    align-items: center;
    background-color: transparent;
    border: 2px solid transparent;
    display: grid;
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    justify-content: flex-start;

    @include media-breakpoint-up(lge) {
        border-width: 1px;
        grid-column: 3/span 1;
        grid-row: 1/span 1;
    }

    &:hover,
    &:active,
    &.active {
        background-color: $color-n30;
        border-radius: 4px;
    }

    &:active,
    &.active {
        border-color: $color-p400;
    }

    .location {
        .location-anchor {
            align-items: center;
            background-color: transparent;
            border: 0;
            display: flex;
            padding: $space-4;
            white-space: nowrap;

            @include media-breakpoint-up(lge) {
                padding: $space-8;
            }

            .icon-map-pin {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
                height: 28px;
                width: 24px;
            }

            .location-text {
                display: none;
                flex-direction: column;
                grid-column: 2 / span 1;
                grid-row: 1 / span 1;
                margin: 0 0 0 $space-12;
                padding: 0;

                @include media-breakpoint-up(lge) {
                    display: flex;
                }

                .find-location-link {
                    text-decoration: underline;
                }
            }
        }

        .btn-store-locator {
            width: 100%;
        }
    }
}

.navbar-logo {
    align-items: center;
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    justify-self: center;
    margin: 0 $space-20;
    max-width: 149px;
    padding: 0;
    width: auto;

    @include media-breakpoint-up(lge) {
        grid-column: 1/span 1;
        grid-row: 1/span 1;
        justify-self: start;
        margin: 0 $space-24 0 0;
        max-width: 220px;
    }

    @include media-breakpoint-up(xl) {
        margin: 0 $space-48 0 0;
    }
}

.navbar-search {
    grid-column: 1/span 5;
    grid-row: 2/span 1;
    margin-top: $space-8;

    @include media-breakpoint-up(lge) {
        grid-column: 2/span 1;
        grid-row: 1/span 1;
        margin: 0;
    }

    @include media-breakpoint-up(llg) {
        justify-self: center;
    }

    .site-search {
        margin-top: 0;
        padding: 0 $space-4;
        width: 100%;

        @include media-breakpoint-up(md) {
            min-width: 245px;
            width: auto;
        }

        @include media-breakpoint-up(lge) {
            padding: 0;
            width: 100%;
        }

        @include media-breakpoint-up(llg) {
            min-width: 443px;
        }
    }
}

.navbar-login {
    border: 2px solid transparent;
    grid-column: 4 / span 1;
    grid-row: 1 / span 1;
    justify-self: end;
    margin-right: $space-4;
    padding: $space-4;

    @include media-breakpoint-up(md) {
        align-items: center;
        display: flex;
        grid-column: 4/span 1;
        grid-row: 1/span 1;
        width: fit-content;
    }

    @include media-breakpoint-up(lge) {
        align-self: stretch;
        border-width: 1px;
        height: 100%;
        margin-right: $space-12;
        padding: $space-4;
    }

    @include media-breakpoint-up(xl) {
        margin-right: 0;
    }

    &:hover,
    &:active,
    &.active {
        background-color: $color-n30;
        border-radius: 4px;
        text-decoration: underline;
    }

    &:active,
    &.active {
        border-color: $color-p400;
    }

    .user {
        .popover:not(.d-none) + .user-anchor {
            &::before {
                border-bottom: 10px solid $color-n50;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid transparent;
                content: "";
                height: 0;
                position: absolute;
                top: 50px;
                width: 0;
            }

            &::after {
                border-bottom: 10px solid #fff;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid transparent;
                content: "";
                height: 0;
                position: absolute;
                top: 51px;
                width: 0;
                z-index: 2000;
            }
        }

        .popover-header {
            .close {
                position: relative;
                right: -6px;
                top: -6px;
            }

            .buttons-container {
                align-items: center;
                display: flex;
                justify-content: center;
                margin-bottom: $space-16;
                width: 100%;

                button {
                    padding: $space-12 $space-16;
                }
            }

            .earn-up-to-message {
                display: none;
            }
        }

        .popover-bottom {
            border-top: 1px solid $color-n50;
            border-top-left-radius: calc(0.1875rem - 1px);
            border-top-right-radius: calc(0.1875rem - 1px);
            letter-spacing: 0.05em;
            margin: $space-16 0 $space-8 0;
            text-transform: uppercase;
            width: 100%;
        }

        .popover::before,
        .popover::after {
            right: 81px;

            @include media-breakpoint-up(sm) {
                right: 66px;

            }

            @include media-breakpoint-up(md) {
                right: 88px;
            }

            @include media-breakpoint-up(lge) {
                right: 180px;
            }

            @include media-breakpoint-up(xl) {
                right: 195px;
            }

            @include media-breakpoint-up(xxl) {
                right: 184px;
            }
        }

        .user-message {
            color: $color-t300;
            font-family: $font-sofia;
            font-size: $font-14;
            font-weight: $font-medium;
            margin-left: $space-8;
        }
    }

    .user-anchor {
        align-items: center;
        background-color: transparent;
        border: 0;
        display: flex;
        padding: 0;
        white-space: nowrap;

        @include media-breakpoint-up(lge) {
            min-width: 90px;
            padding: $space-8;
        }

        .account-text {
            display: none;

            @include media-breakpoint-up(lge) {
                display: inline-block;
            }
        }

        .logged-user {
            background-color: $color-p300;
            border-radius: 50%;
            height: 24px;
            text-align: center;
            width: 24px;
        }
    }

    .nav-user {
        align-items: center;
        display: grid;
        width: 100%;

        .nav-item {
            margin: 0;
            padding: 0;
            width: 100%;

            &.item-1 {
                grid-column: 1/span 1;
                grid-row: 1/span 1;
                width: 100%;
            }

            &.item-2 {
                grid-column: 1/span 1;
                grid-row: 2/span 1;
                width: 100%;
            }

            &.item-3 {
                grid-column: 1/span 1;
                grid-row: 3/span 1;
                width: 100%;
            }

            &.item-4 {
                grid-column: 1/span 1;
                grid-row: 4/span 1;
                width: 100%;
            }

            &.item-5 {
                grid-column: 1/span 1;
                grid-row: 5/span 1;
                width: 100%;
            }

            &.item-6 {
                grid-column: 1/span 1;
                grid-row: 6/span 1;
                width: 100%;
            }

            .nav-text {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 0 4px 16px;
            }
        }
    }
}

.navbar-minicart {
    align-self: stretch;
    background-color: transparent;
    border: 2px solid transparent;
    grid-column: 5 / span 1;
    grid-row: 1 / span 1;
    height: 40px;
    justify-self: end;
    line-height: 0;
    margin: 0;
    padding: $space-8;

    @include media-breakpoint-up(lge) {
        border-width: 1px;
        grid-column: 5/span 1;
        grid-row: 1/span 1;
        height: 100%;
        width: fit-content;
    }

    &:hover,
    &:active,
    &.active {
        background-color: $color-n30;
        border-radius: 4px;
        text-decoration: underline;
    }

    &:active,
    &.active {
        border-color: $color-p400;
    }

    .minicart-total {
        display: flex;
        justify-content: flex-end;

        .minicart-link {
            align-items: center;
            background-color: transparent;
            border: 0;
            display: grid;
            padding: 0;
            width: 24px;

            @include media-breakpoint-up(lge) {
                justify-self: end;
                width: fit-content;
            }

            .minicart-icon {
                grid-column: 1/span 1;
                grid-row: 1/span 1;
            }

            .minicart-message {
                align-items: center;
                color: $color-t300;
                display: flex;
                font-family: $font-sofia;
                font-size: $font-14;
                font-style: normal;
                font-weight: $font-medium;
                justify-content: center;
                line-height: 120%;
                margin: 0 $space-8;
                padding: 0;

                @include media-breakpoint-up(lge) {
                    grid-column: 2/span 1;
                    grid-row: 1/span 1;
                }
            }

            .minicart-quantity-wrapper {
                background-color: $color-s300;
                border-radius: 50%;
                color: $color-n0;
                font-family: $font-sofia;
                font-size: 9px;
                font-style: normal;
                font-weight: 700;
                grid-column: 2/span 1;
                grid-row: 1/span 1;
                height: 14px;
                left: -$space-14;
                position: relative;
                top: -$space-8;
                width: 14px;

                @include media-breakpoint-up(lge) {
                    font-size: 12px;
                    font-weight: 800;
                    grid-column: 3/span 1;
                    grid-row: 1/span 1;
                    height: 16px;
                    left: 0;
                    top: 0;
                    width: 16px;
                }
            }

            .minicart-quantity {
                bottom: 0;
                height: 8px;
                left: 0;
                line-height: 0.8;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;

                @include media-breakpoint-up(lge) {
                    height: 12px;
                }
            }
        }
    }
}

.header-banner {
    background-color: unset;


    .banner-content {
        padding: $space-8 0;
        position: relative;

        .banner-link {
            cursor: pointer;
            display: block;
            font-size: $font-12;
            font-weight: $font-medium;
            max-width: 100%;
            overflow: hidden;
            text-decoration: underline;

            @include media-breakpoint-up(md) {
                text-decoration: none;
            }

            @include media-breakpoint-up(lge) {
                line-height: 18px;
                width: 279px;
            }
        }

        .rewards-link {
            border-right: 1px solid $color-n900;
            font-weight: $font-bold;
            margin-left: $space-16;
            text-decoration: underline;

            @include media-breakpoint-up(lge) {
                border-right: 0;
                margin-left: 0;
            }
        }
    }

    .slick-carousel {
        .arrow-list {
            position: relative;
            z-index: 1;
        }

        .slick-arrow {
            position: absolute;
            top: 32%;
            z-index: 2;

            &.arrow-right {
                right: $space-8;
            }

            &.arrow-left {
                left: $space-8;
            }
        }

        .slick-autoplay-toggle-button {
            right: -9px;
            top: 9px;
        }
    }

    .slick-carousel:not(.slick-initialized) {
        @include media-breakpoint-up(lge) {
            display: flex;

            .slide {
                display: none;
                max-width: none;
                padding: 0 $space-24;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }

                &:not(:first-child) {
                    border-left: 1px solid $color-n900;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .banner-cta {
        color: $color-s500;
        text-decoration: underline;

        @include media-breakpoint-up(lge) {
            margin-left: $space-8;
        }
    }

    .btn-menu {
        background: none;
        border: 0;
        position: unset;
    }
}

.promotion-banner {
    .promo-content {
        .slick-track {
            display: flex !important;

            .slick-slide {
                height: inherit !important;

                div {
                    height: 100%;
                }
            }
        }

        .slide {
            align-items: center;
            display: flex !important;
            flex-direction: column;
            flex-wrap: nowrap;
            height: 100%;
            justify-content: center;
            padding: 0 $space-16;
            text-align: center;
            width: 100%;

            @include media-breakpoint-up(lge) {
                &.promo-border {
                    border-left: 1px solid $color-n50;
                }

                &.extra-content {
                    display: none !important;
                }
            }
        }
    }
}

.announcement-banner {
    max-height: none;

    p {
        @include media-breakpoint-down(md) {
            margin: $space-8 calc($space-44 + 1px) $space-8 0;
        }
    }

    .close-button {
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: auto;
        margin: auto $space-8;
        position: absolute;
        right: 0;
        top: 0;

        .close {
            display: flex;
            opacity: 1;

            &:hover {
                opacity: inherit;
            }
        }
    }
}

.main-menu {
    background-color: $color-n0;
    border-color: $color-n50;
    border-style: solid;
    border-width: 1px 0;

    @include media-breakpoint-up(md) {
        position: relative;

        .navbar {
            position: inherit;
        }
    }

    .menu-wrapper {
        @include media-breakpoint-down(md) {
            margin-left: -$space-16;
            margin-right: -$space-16;
        }
    }
}

.no-menu-header {
    background-color: $color-n0;
    border-bottom: 1px solid $color-n40;
    position: relative;
    z-index: 12;

    @include media-breakpoint-up(lge) {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 2px 6px 2px rgba(0, 0, 0, 0.1);
        max-height: 63px;
    }

    .no-menu-logo {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: $space-16;

        .logo-home {
            max-width: 149px;

            @include media-breakpoint-up(lge) {
                max-width: auto;
            }
        }
    }
}

.banner-legacy-customers {
    background-color: #f5cccc;
    border: 1px solid #f1b8b8;
    border-radius: $border-radius-4;
    color: #6a0000;
    margin-bottom: $space-16;
    padding: $space-16;
    position: relative;

    button,
    a {
        color: #6a0000;
    }
}

.plcc-header {
    .ep-text-placement {
        font-family: $font-sofia;
        font-style: normal;
        line-height: 120%;

        .epjs-body {
            color: $color-n0;
            font-size: $font-18;
            font-weight: $font-bold;
            line-height: 120%;
            margin-bottom: $space-8;
        }

        .epjs-body-action {
            background-color: $color-sa300;
            border: 0;
            border-radius: $border-radius-4;
            color: $color-t300;
            cursor: pointer;
            display: block;
            font-size: $font-14;
            font-weight: $font-bold;
            margin-bottom: $space-8;
            margin-top: $space-8;
            max-width: 132px;
            padding: $space-16 $space-24;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
        }

        .epjs-body-action-link {
            text-decoration: none;
        }
    }
}

.header-container {
    margin: 0 $space-16;

    @include media-breakpoint-up(lge) {
        margin: 0 $space-56;
    }

    @include media-breakpoint-up(xxl) {
        margin: 0 auto;
        max-width: 1328px;
    }

    .rewards-link {
        @include media-breakpoint-down(lg) {
            padding-left: 0;
        }

        &.margin-fixed {
            @include media-breakpoint-up(md) {
                margin-left: 32px;
            }
        }
    }

    .navbar-minicart {
        @include media-breakpoint-up(lge) {
            padding-right: 0;
        }
    }
}

.email-not-exist-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .send-email-btn {
        width: 90%;
    }
}
