.stock-alert-wrapper,
.alcohol-product-alert {
    display: flex;
    flex-direction: column;
    margin-top: $space-16;
    width: 100%;
}

.stock-alert {
    &-green {
        background-color: $color-su400;
    }

    &-yellow {
        background-color: $color-w300;
    }

    &-red {
        background-color: $color-s300;
    }
}

.availability-dot {
    border-radius: 100%;
    content: "";
    height: 8px;
    margin-right: $space-8;
    min-width: 8px;
}
