@import "../utilities/shapes";
@import "./spacing";
@import "./formFields";

$color-list: (
    "red": $color-e300,
    "primary-green": $color-p400,
    "paprika": #ff595a,
    "merlot": $color-s500,
    "ship-lap": #f6f4f1,
    "new-adirondack": $color-p300,
    "teal": $color-p400,
    "blue-melamine": $color-b300,
    "saffron": #f6c862,
    "black": #000,
    "white": $color-n0,
    "primary": $color-p400
);

$color-list-style-guide: (
    "p50": $color-p50,
    "p75": $color-p75,
    "p100": $color-p100,
    "p200": $color-p200,
    "p300": $color-p300,
    "p400": $color-p400,
    "p500": $color-p500,
    "s50": $color-s50,
    "s75": $color-s75,
    "s100": $color-s100,
    "s200": $color-s200,
    "s300": $color-s300,
    "s400": $color-s400,
    "s500": $color-s500,
    "sl50": $color-sl50,
    "sl75": $color-sl75,
    "sl100": $color-sl100,
    "sl200": $color-sl200,
    "sl300": $color-sl300,
    "sl400": $color-sl400,
    "sl500": $color-sl500,
    "sl600": $color-sl600,
    "b50": $color-b50,
    "b75": $color-b75,
    "b100": $color-b100,
    "b200": $color-b200,
    "b300": $color-b300,
    "b400": $color-b400,
    "b500": $color-b500,
    "sa50": $color-sa50,
    "sa75": $color-sa75,
    "sa100": $color-sa100,
    "sa200": $color-sa200,
    "sa300": $color-sa300,
    "sa400": $color-sa400,
    "sa500": $color-sa500,
    "su50": $color-su50,
    "su200": $color-su200,
    "su400": $color-su400,
    "e50": $color-e50,
    "e300": $color-e300,
    "e500": $color-e500,
    "w50": $color-w50,
    "w300": $color-w300,
    "w400": $color-w400,
    "n0": $color-n0,
    "n10": $color-n10,
    "n20": $color-n20,
    "n30": $color-n30,
    "n40": $color-n40,
    "n50": $color-n50,
    "n70": $color-n70,
    "n100": $color-n100,
    "n300": $color-n300,
    "n500": $color-n500,
    "n700": $color-n700,
    "n900": $color-n900,
    "t0": $color-t0,
    "t50": $color-t50,
    "t75": $color-t75,
    "t100": $color-t100,
    "t200": $color-t200,
    "t300": $color-t300,
    "t400": $color-t400,
    "t500": $color-t500,
    "nsl100": $color-nsl100,
    "nsl300": $color-nsl300,
);

body,
html {
    background-color: $color-n0;
    transition: opacity 0.2s ease-in-out;
}

.prevent-scroll {
    height: 100dvh;
    overflow: hidden;
}

.page {
    &.prevent-scroll {
        width: 100vw;

        @include media-breakpoint-up(lg) {
            padding-right: $space-16;
        }
    }
}

strong {
    font-weight: $font-bold;
}

figure {
    margin: 0;
}

.loaded {
    opacity: 1;
}

.maincontent {
    overflow-x: hidden;
}

.no-overflow {
    @include media-breakpoint-down(md) {
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100vw;
    }
}

.full-width-desktop {
    @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        .region:first-of-type {
            padding-left: 0;
        }

        .region:last-of-type {
            padding-right: 0;
        }
    }
}

.full-width-mobile {
    @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        .region {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

button {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

p {
    margin: 0 0 $space-16 0;
    padding: 0;

    a {
        background: 0;
        border: 0;
        color: $color-t300;
        font-family: $font-sofia;
        font-size: $font-16;
        font-style: normal;
        font-weight: $font-bold;
        line-height: 120%;
        margin: 0;
        padding: 0;
        text-decoration-line: underline;
    }
}

.regular {
    font-weight: $font-regular;
}

.medium {
    font-weight: $font-medium;
}

.semi-bold {
    font-weight: $font-semi-bold;
}

.bold {
    font-weight: $font-bold;
}

.questa {
    font-family: $font-questa, serif !important;
    font-weight: $font-black;
}

.content-asset-wrapper {
    overflow: hidden;
    position: relative;

    .content-asset-bg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        .bg-img {
            height: auto;
            min-height: 100%;
            min-width: 100%;
            width: auto;
        }
    }
}

.livechat-links {
    bottom: 50px;
    position: fixed;
    right: 46px;
    z-index: 10;

    @include media-breakpoint-down(md) {
        right: 0;
    }

    a {
        border-radius: 2px;
        padding: $space-10 $space-10 calc($space-4 + 1px) $space-10;
        text-decoration: none;
        text-transform: none;

        &:hover {
            text-decoration: unset;
        }

        .livechat-text {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .livechat-icon {
            svg {
                margin: 0 0 calc($space-4 + 1px) $space-6;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }
        }
    }
}

// Basic text
.text {
    color: $color-t300;
    font-family: $font-sofia;
    font-style: normal;
    line-height: 120%;

    //font-sizes
    &-xxs {
        font-size: $font-12;
    }

    &-xs {
        font-size: $font-13;
    }

    &-sm {
        font-size: $font-14;
    }

    &-base {
        font-size: $font-16;
    }

    &-lg {
        font-size: $font-18;
    }

    &-xl {
        font-size: $font-20;
    }

    &-xxl {
        font-size: $font-23;
    }

    &-h1 {
        font-size: $font-36;
    }

    &-h2 {
        font-size: $font-32;
    }

    &-h3 {
        font-size: $font-29;
    }

    &-h4 {
        font-size: $font-26;
    }

    &-h5 {
        font-size: $font-23;
    }

    &-h6 {
        font-size: $font-20;
    }

    //text styles
    &-italic {
        font-style: italic;
    }

    &-underline {
        text-decoration: underline;
    }

    &-through {
        text-decoration: line-through;
    }

    &-nowrap {
        white-space: nowrap;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    // Inherit Color
    &-color-inherit {
        color: inherit;
    }

    &-vertical-middle {
        vertical-align: middle;
    }

    // Customer Configurable Colors
    @each $color in map-keys($color-list) {
        $color-value: #{map-get($color-list, $color)};
        &-color-#{$color} {
            color: $color-value;

            &:hover {
                color: $color-value;
            }
        }
    }

    // Customer Style Guide Colors
    @each $color in map-keys($color-list-style-guide) {
        $color-value: #{map-get($color-list-style-guide, $color)};
        &-color-#{$color} {
            color: $color-value;

            &:hover {
                color: $color-value;
            }
        }
    }
}

// banner background classes
.background {
    // Customer Configurable Colors
    @each $color in map-keys($color-list) {
        $color-value: #{map-get($color-list, $color)};
        &-#{$color} {
            background-color: $color-value;
        }
    }

    // Customer Style Guide Colors
    @each $color in map-keys($color-list-style-guide) {
        $color-value: #{map-get($color-list-style-guide, $color)};
        &-color-#{$color} {
            background-color: $color-value;
        }
    }
}

// icons
.icon {
    &-sm {
        height: $icon-sm;
        width: $icon-sm;
    }

    &-md {
        height: $icon-md;
        width: $icon-md;
    }

    &-lg {
        height: $icon-lg;
        width: $icon-lg;
    }

    &-xl {
        height: $icon-xl;
        width: $icon-xl;
    }

    &-xxl {
        height: $icon-xxl;
        width: $icon-xxl;
    }

    &-logo {
        height: $icon-md;
        width: 100%;

        @include media-breakpoint-up(sm) {
            height: $icon-xl;
        }
    }

    &-desktop {
        &-sm {
            @include media-breakpoint-up(lg) {
                height: $icon-sm;
                width: $icon-sm;
            }
        }

        &-md {
            @include media-breakpoint-up(lg) {
                height: $icon-md;
                width: $icon-md;
            }
        }

        &-lg {
            @include media-breakpoint-up(lg) {
                height: $icon-lg;
                width: $icon-lg;
            }
        }

        &-xl {
            @include media-breakpoint-up(lg) {
                height: $icon-xl;
                width: $icon-xl;
            }
        }

        &-xxl {
            @include media-breakpoint-up(lg) {
                height: $icon-xxl;
                width: $icon-xxl;
            }
        }

        &-medal {
            @include media-breakpoint-up(lg) {
                height: 70px;
                width: 70px;
            }
        }
    }

    // Customer Configurable Colors
    @each $color in map-keys($color-list) {
        $color-value: #{map-get($color-list, $color)};
        &-#{$color} {
            stroke: $color-value;

            &:hover {
                stroke: $color-value;
            }
        }
    }

    // Customer Style Guide Colors
    @each $color in map-keys($color-list-style-guide) {
        $color-value: #{map-get($color-list-style-guide, $color)};
        &-color-#{$color} {
            stroke: $color-value;

            &:hover {
                stroke: $color-value;
            }
        }
    }

}

.cursor {
    &-pointer {
        cursor: pointer;
    }
}

// button as link
.link-style {
    background: 0;
    border: 0;
    color: $color-t300;
    font-family: $font-sofia;
    font-size: $font-16;
    font-style: normal;
    font-weight: $font-bold;
    line-height: 120%;
    margin: 0;
    padding: 0;
    text-decoration-line: underline;

    &-primary {
        color: $color-p400;
    }
}

// button as pill
.pill-style {
    background: $color-nsl35;
    border: 1px solid $color-n50;
    border-radius: $space-20;
    color: $color-b300;
    font-family: $font-sofia;
    font-size: $font-16;
    font-style: normal;
    font-weight: $font-medium;
    line-height: 120%;
    padding: $space-8 $space-16;
}

// Clean list style
.list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.sync-height {
    .experience-component,
    .herobanner,
    .herobanner-wrapper,
    .imageAndText,
    .imageAndText-wrapper,
    .highlight-card,
    .highlight-card-wrapper {
        height: 100%;
    }

    .experience-component {
        &.experience-commerce {
            &_assets-spacing,
            &_assets-editorialRichText,
            &_assets-punchCardComponent,
            &_assets-ctaComponent,
            &_layouts-tableLayout {
                height: fit-content;
            }
        }
    }

    .herobanner-wrapper,
    .imageAndText-wrapper,
    .highlight-card-wrapper {
        display: flex;
        flex-direction: column;

        .herobanner-block,
        .imageAndText-block,
        .highlight-card-block {
            flex-grow: 1;
        }
    }
}

// Text separator
.separator {
    background-color: $color-n900;
    height: 24px;
    margin: 0 $space-16;
    width: 1px;

    &-small {
        height: 20px;
    }

    &-grey {
        background-color: $color-n50;
    }

    &-xs {
        height: 15px;
    }
}

.separator-hr {
    border-color: $color-n50;
    margin-bottom: $space-24;
    margin-top: $space-24;
}

.btn-separator {
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100%;

    .separator-line {
        background-color: $color-n50;
        display: block;
        flex-grow: 1;
        height: 1px;
        width: 100%;
    }
}

// Custom scrollbar
.custom-scrollbar {
    &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $color-n50;
        border-left: 1px solid $color-n0;
        border-radius: 4px;
        border-right: 1px solid $color-n0;
    }


    &::-webkit-scrollbar-thumb {
        background: $color-scrollbar;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track:horizontal {
        border-bottom: 1px solid $color-n0;
        border-top: 1px solid $color-n0;
    }
}

// Back to top button
.back-to-top {
    background-color: $color-n0;
    border: 0;
    border-radius: 100%;
    bottom: $space-16;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
    height: 50px;
    opacity: 0;
    position: fixed;
    right: $space-16;
    visibility: hidden;
    width: 50px;
    z-index: 9;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

// Prevent scroll class
.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.no-scroll-top {
    margin: 0;
    overflow: hidden;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding-right: $space-8;
    }
}

@include media-breakpoint-down(md) {
    .mobile-visibility {
        visibility: visible;

        &.mobile-hidden {
            visibility: hidden;
        }
    }
}

.custom-list {
    line-height: $font-20;
    list-style: none;
    max-width: 836px;
    padding-left: $space-4;

    li {
        padding: 0 0 $space-8 $space-20;
        position: relative;

        &::before {
            background-color: $color-t200;
            border-radius: 50%;
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            top: 10px;
            width: 5px;
        }
    }
}

.link-container {
    line-height: $font-16;
}

.drawer {
    background: transparent;
    border: 0;
    box-shadow: none;
    left: -321px;
    padding: 0;
    top: 43px;

    .drawer-wrapper {
        bottom: 0;
        display: none;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;

        .drawer-overlay {
            background: rgba(0, 0, 0, 0.6);
            bottom: 0;
            display: none;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        .drawer-container {
            background: $color-n0;
            bottom: -100%;
            max-height: calc(100% - 65px);
            position: absolute;
            transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);
            width: 100%;

            @include media-breakpoint-up(lg) {
                bottom: unset;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: flex-start;
                max-height: none;
                right: -100%;
                transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
                width: 474px;
            }

            &.login-page {
                overflow-y: auto;
            }

            .drawer-header {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 0 $space-16;
                padding: $space-16 0 0;

                @include media-breakpoint-up(lg) {
                    margin: 0 $space-32;
                }

                &-border {
                    border-bottom: 1px solid $color-n50;
                    padding-bottom: $space-16;
                }
            }

            .drawer-content {
                margin: 0 $space-8;
                max-height: calc(100vh - 130px);
                overflow-y: auto;
                padding: $space-20 $space-8;

                @include media-breakpoint-up(lg) {
                    height: calc(100% - 57px);
                    margin: 0 $space-16;
                    padding: $space-24 $space-16;
                }

                .btn-change-address,
                .btn-select-suggestion {
                    height: 48px;
                    width: calc(50% - 8px);
                }

                .thumb-image,
                .drawer-tile-container-image {
                    display: inline-block;
                    width: 72px;
                }

                .thumb-image {
                    border: 1px solid $color-n40;
                    border-radius: $space-4;
                }

                .wishlist-drawer-option {
                    border: 1px solid $color-n40;
                    border-radius: $border-radius-4;
                    padding: $space-16;
                }
            }

            .drawer-footer {
                border-top: 1px solid $color-n50;
                gap: $space-16;
                padding: $space-24 0;
            }
        }
    }

    &.active {
        .drawer-overlay {
            display: block;
        }

        .drawer-container {
            bottom: 0;
            transition: bottom 0.5s cubic-bezier(0, 1, 0.5, 1);

            @include media-breakpoint-up(lg) {
                bottom: unset;
                right: 0;
                transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
            }
        }
    }
}

.drawer-addresses-option {
    cursor: pointer;

    .drawer-address-label {
        align-items: flex-start;
        display: flex;
        padding-bottom: $space-16;
        width: 100%;

        .original-address-line,
        .original-address-line2 {
            max-width: 365px;
            width: 100%;
        }

        .drawer-address-input {
            align-self: flex-start;
            background-color: $color-n0;
            border: 1px solid $color-n500;
            border-radius: 100%;
            cursor: pointer;
            margin: 0;
            margin-right: $space-16;
            min-height: 24px;
            min-width: 24px;
            padding: 0;
            position: relative;
        }
    }
}

.suggested-addresses-wrapper {
    .drawer-address-label:last-of-type {
        border-bottom: 1px solid $color-n50;
        margin: 0;
    }
}

.unit-price {
    display: flex;
    flex-direction: column;
    width: 100%;

    .list-price {
        font-size: $font-14;
        margin-left: $space-72;
        margin-top: -$space-18;
    }

    .sale-from {
        margin-left: $space-72;
        margin-top: -$space-18;
    }

    .sale-price {
        font-size: $font-14;
        margin-left: $space-72;
    }


    .member-price {
        align-items: center;
        background-color: $color-sa300;
        border-radius: $border-radius-4;
        display: flex;
        margin-top: $space-10;
        min-width: 220px;
        padding: $space-4 $space-8;
        width: fit-content;

        @include media-breakpoint-up(lg) {
            min-width: 180px;
        }

        .sale-price {
            margin: 0;

            .member-price-value {
                font-size: $font-16;
            }
        }
    }
}

.wishlist-messages,
.promo-error-message,
.toast-alert-message {
    display: none;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 15%;
    white-space: nowrap;
    width: fit-content;
    z-index: 991;
}

.toast-alert-message {
    align-items: center;
    display: flex;
    justify-content: center;

    .toast-alert {
        align-items: center;
        border-radius: $border-radius-4;
        color: $color-t300;
        display: flex;
        filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
        font-size: $font-16;
        font-weight: $font-bold;
        height: $space-48;
        justify-content: center;
        line-height: 100%;
        max-width: 90vw;
        padding: $space-8 $space-16;

        &.alert-success {
            background-color: $color-p50;
        }

        &.alert-danger {
            background-color: $color-s50;
        }
    }
}

.add-to-wishlist-alert,
.update-wishlist-messages,
.remove-from-wishlist-alert {
    align-items: center;
    background-color: $color-p50;
    border-radius: $border-radius-4;
    color: $color-t300;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
    font-size: $font-16;
    font-weight: $font-bold;
    padding: $space-12 $space-16;

    &.show {
        display: block;
    }
}

.grecaptcha-badge {
    bottom: 80px !important;
    z-index: 8;
}

.wrapper-border-top {
    border-top: 1px solid $color-t50;
}

.opacity-zero {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.opacity-one {
    opacity: 1;
}
