@import "../variables";

.bread-financial-plcc {
    gap: 16px;
    margin-top: $space-4;

    .plcc-logo {
        img {
            max-width: 42px;
            min-height: 27px;
        }
    }
}

.bread-financial-plcc-card {
    background-color: $color-sa50;
    border: 1px solid $color-n40;
    border-radius: 4px;
    margin-bottom: $space-64;
    padding: $space-16;

    @include media-breakpoint-up(lg) {
        padding: $space-16 $space-24;
    }

    .bread-financial-card-content {
        display: flex;
        gap: 16px;
        margin-top: $space-4;

        .plcc-logo {
            img {
                border-radius: $border-radius-4;
                max-width: 53px;
                min-height: 34px;
            }
        }

        .bread-financial-card-text {
            svg {
                height: 16px;
                margin-bottom: calc($space-4 + 1px);
                width: 16px;
            }
        }
    }

    .bread-financial-card-ctas {
        display: flex;
        gap: 16px;
        margin-top: $space-16;

        .bread-financial-btn {
            &:hover {
                background-color: transparent !important;
            }
        }
    }
}

.bread-financial-plcc-banner {
    background-size: cover;
    border-radius: $border-radius-8;

    .bread-financial-banner-content {
        padding: $space-8 $space-16;
    }
}
