$spacing-sizes: (
    "auto": "auto",
    "0": 0,
    "2": $space-2,
    "4": $space-4,
    "6": $space-6,
    "7": $space-7,
    "8": $space-8,
    "10": $space-10,
    "12": $space-12,
    "14": $space-14,
    "16": $space-16,
    "18": $space-18,
    "20": $space-20,
    "24": $space-24,
    "26": $space-26,
    "28": $space-28,
    "32": $space-32,
    "36": $space-36,
    "38": $space-38,
    "40": $space-40,
    "44": $space-44,
    "48": $space-48,
    "56": $space-56,
    "60": $space-60,
    "64": $space-64,
    "68": $space-68,
    "72": $space-72,
    "80": $space-80,
    "88": $space-88,
    "96": $space-96,
    "98": $space-98,
    "100": $space-100,
    "102": $space-102,
    "104": $space-104,
    "106": $space-106,
    "108": $space-108,
    "110": $space-110,
    "112": $space-112,
    "120": $space-120,
    "186": $space-186
);

@each $size in map-keys($spacing-sizes) {
    $size-value: #{map-get($spacing-sizes, $size)};

    .pdd-#{$size} {
        padding: $size-value;

        &-vert {
            padding-bottom: $size-value;
            padding-top: $size-value;
        }

        &-hor {
            padding-left: $size-value;
            padding-right: $size-value;
        }

        &-top {
            padding-top: $size-value;
        }

        &-bot {
            padding-bottom: $size-value;
        }

        &-left {
            padding-left: $size-value;
        }

        &-right {
            padding-right: $size-value;
        }
    }

    .margin-#{$size} {
        margin: $size-value;

        &-vert {
            margin-bottom: $size-value;
            margin-top: $size-value;
        }

        &-hor {
            margin-left: $size-value;
            margin-right: $size-value;
        }

        &-top {
            margin-top: $size-value;
        }

        &-bot {
            margin-bottom: $size-value;
        }

        &-left {
            margin-left: $size-value;
        }

        &-right {
            margin-right: $size-value;
        }
    }

    .negative-margin-#{$size} {
        margin: -$size-value;

        &-vert {
            margin-bottom: -$size-value;
            margin-top: -$size-value;
        }

        &-hor {
            margin-left: -$size-value;
            margin-right: -$size-value;
        }

        &-top {
            margin-top: -$size-value;
        }

        &-bot {
            margin-bottom: -$size-value;
        }

        &-left {
            margin-left: -$size-value;
        }

        &-right {
            margin-right: -$size-value;
        }
    }

    .gap-#{$size} {
        gap: $size-value;
    }
}

@each $size in map-keys($spacing-sizes) {
    $size-value: #{map-get($spacing-sizes, $size)};

    .pdd-mobile-#{$size} {
        @include media-breakpoint-down(md) {
            padding-bottom: $size-value;
            padding-left: $size-value;
            padding-right: $size-value;
            padding-top: $size-value;

            &-vert {
                padding-bottom: $size-value;
                padding-top: $size-value;
            }

            &-hor {
                padding-left: $size-value;
                padding-right: $size-value;
            }

            &-top {
                padding-top: $size-value;
            }

            &-bot {
                padding-bottom: $size-value;
            }

            &-left {
                padding-left: $size-value;
            }

            &-right {
                padding-right: $size-value;
            }
        }
    }

    .pdd-desktop-#{$size} {
        @include media-breakpoint-up(lg) {
            padding-bottom: $size-value;
            padding-left: $size-value;
            padding-right: $size-value;
            padding-top: $size-value;

            &-vert {
                padding-bottom: $size-value;
                padding-top: $size-value;
            }

            &-hor {
                padding-left: $size-value;
                padding-right: $size-value;
            }

            &-top {
                padding-top: $size-value;
            }

            &-bot {
                padding-bottom: $size-value;
            }

            &-left {
                padding-left: $size-value;
            }

            &-right {
                padding-right: $size-value;
            }
        }
    }

    .margin-desktop-#{$size} {
        @include media-breakpoint-up(lg) {
            margin: $size-value;

            &-vert {
                margin-bottom: $size-value;
                margin-top: $size-value;
            }

            &-hor {
                margin-left: $size-value;
                margin-right: $size-value;
            }

            &-top {
                margin-top: $size-value;
            }

            &-bot {
                margin-bottom: $size-value;
            }

            &-left {
                margin-left: $size-value;
            }

            &-right {
                margin-right: $size-value;
            }
        }
    }

    .negative-margin-desktop-#{$size} {
        @include media-breakpoint-up(lg) {
            margin: -$size-value;

            &-vert {
                margin-bottom: -$size-value;
                margin-top: -$size-value;
            }

            &-hor {
                margin-left: -$size-value;
                margin-right: -$size-value;
            }

            &-top {
                margin-top: -$size-value;
            }

            &-bot {
                margin-bottom: -$size-value;
            }

            &-left {
                margin-left: -$size-value;
            }

            &-right {
                margin-right: -$size-value;
            }
        }
    }


    .margin-mobile-#{$size} {
        @include media-breakpoint-down(md) {
            margin: $size-value;

            &-vert {
                margin-bottom: $size-value;
                margin-top: $size-value;
            }

            &-hor {
                margin-left: $size-value;
                margin-right: $size-value;
            }

            &-top {
                margin-top: $size-value;
            }

            &-bot {
                margin-bottom: $size-value;
            }

            &-left {
                margin-left: $size-value;
            }

            &-right {
                margin-right: $size-value;
            }
        }
    }

    .negative-margin-mobile-#{$size} {
        @include media-breakpoint-down(md) {
            margin: -$size-value;

            &-vert {
                margin-bottom: -$size-value;
                margin-top: -$size-value;
            }

            &-hor {
                margin-left: -$size-value;
                margin-right: -$size-value;
            }

            &-top {
                margin-top: -$size-value;
            }

            &-bot {
                margin-bottom: -$size-value;
            }

            &-left {
                margin-left: -$size-value;
            }

            &-right {
                margin-right: -$size-value;
            }
        }
    }
}
