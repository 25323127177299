.badge {
    align-items: center;
    border-radius: $border-radius-4;
    display: flex;
    height: 26px;
    justify-content: center;
    position: absolute;
    top: 0;

    &-new {
        background-color: $color-p75;
        color: $color-p500;
        width: 44px;
    }

    &-sale {
        background-color: $color-s300;
        color: $color-n0;
        width: 45px;
    }

    &-multiple-size {
        background-color: $color-b300;
        color: $color-n0;
        width: 107px;
    }

    &-member-deal {
        background-color: $color-sa300;
        color: $color-b300;
        width: 105px;
    }

    &-clearance {
        background-color: $color-s300;
        color: $color-n0;
        width: 82px;

        .text {
            line-height: 114%;
        }
    }
}
