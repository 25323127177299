@import "../variables";

.breadcrumb {
    background-color: transparent;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 0 0 $space-4;
    white-space: nowrap;
    width: calc(100vw - $space-32);

    @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
        padding: 0;
        white-space: auto;
        width: unset;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &-custom {
        position: relative;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        &::after {
            color: $color-n70;
            content: "/";
            float: left;
            padding-left: $space-8;
            padding-right: $space-8;
        }
    }

    &-single {
        align-items: center;
        display: flex;
        margin-top: $space-16;
    }

    &-item-count {
        padding-bottom: $space-2;
        position: relative;

        &::after {
            background-color: $color-n70;
            bottom: 0;
            content: "";
            display: block;
            height: 1px;
            position: absolute;
            width: 100%;
        }

        &.current::after {
            background-color: $color-e300;
        }

        a:hover {
            text-decoration: none;
        }
    }
}
