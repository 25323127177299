@import "../variables";

.product-tile-wrapper {
    max-width: 50%;

    @include media-breakpoint-down(md) {
        &-odd {
            padding: 8px 8px 16px 0;

            .wishlist-tile {
                right: 8px;
            }
        }

        &-even {
            padding: 8px 0 16px 8px;

            .wishlist-tile {
                right: 0;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        border: 0;
        max-width: 33.3333333333%;
        padding: 8px 12px 16px;
        width: 100%;
    }
}

.product-tile {
    width: 100%;

    .image-container {
        @include media-breakpoint-up(lg) {
            &:hover {
                .button-addtocart {
                    display: flex;
                }
            }
        }
    }

    .image-container {
        position: relative;
        transition: all 0.5s ease-in-out;
        width: 100%;

        @include media-breakpoint-up(lg) {
            .tile-image {
                width: 100%;
            }

            &:hover {
                .tile-image:not(.single) {
                    display: none;
                }

                .tile-alt-image {
                    display: block;
                }

                + .tile-body > .pdp-link > .link {
                    color: $color-t200;
                    text-decoration: underline;
                }
            }
        }

        .tile-image,
        .tile-alt-image {
            border-radius: $border-radius-4;
            height: auto;
            width: 100%;
        }

        .tile-alt-image {
            display: none;
        }

        .availability-tag {
            align-items: center;
            background-color: $color-nsl300;
            border-radius: $border-radius-4;
            bottom: 0;
            color: $color-b300;
            display: flex;
            height: 26px;
            justify-content: center;
            padding: $space-6 $space-8;
            position: absolute;
            white-space: nowrap;
            width: fit-content;
        }

        .wishlist-tile {
            align-items: center;
            background-color: $color-n0;
            border-radius: 0 $space-4;
            display: flex;
            height: 32px;
            justify-content: center;
            padding: $space-4;
            position: absolute;
            right: 0;
            top: 0;

            .icon-heart {
                height: 16px;
                width: 16px;

                @include media-breakpoint-up(lg) {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .button-addtocart {
            align-items: center;
            background-color: $color-sl50;
            border: 1px solid $color-n70;
            border-radius: $border-radius-4;
            bottom: $space-8;
            display: none;
            height: 40px;
            justify-content: center;
            padding: $space-6 0;
            position: absolute;
            right: $space-8;
            transition: all 0.2s ease-in-out;
            width: 120px;
            z-index: 2;

            &:hover {
                text-decoration: underline;
            }

            .icon-sm {
                height: fit-content;
            }
        }
    }

    .tile-body {
        .link {
            &:hover {
                color: $color-t200;
                text-decoration: underline;
            }
        }
    }

    .price {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: $space-8;
    }

    .member-price {
        background-color: $color-sa300;
        border-radius: $border-radius-4;
        display: flex;
        flex-direction: column;
        height: 41px;
        justify-content: center;
        margin: $space-8 0;
        padding: $space-4 $space-8;
        width: 164px;

        @include media-breakpoint-up(lg) {
            height: 46px;
            width: 100%;
        }

        .member-price-value {
            font-size: $font-16;

            @include media-breakpoint-up(lg) {
                font-size: $font-18;
            }
        }

        &-supratext {
            font-size: $font-12;
        }
    }

    .swatch-circle {
        border: 1px solid $color-n40;
        border-radius: 100%;
        height: 16px;
        margin-right: $space-4;
        overflow: hidden;
        position: relative;
        width: 16px;

        &.selected {
            background-color: $color-n40;
            border-color: $color-n300;
            height: 18px;
            width: 18px;

            .swatch-img {
                padding: 1px;
            }
        }

        .swatch-img {
            border-radius: 100%;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .list-price-text,
    .sale-price-text {
        font-size: $font-16;

        @include media-breakpoint-up(lg) {
            font-size: $font-18;
        }
    }
}

.container-product-pagination {
    @include media-breakpoint-up(lg) {
        padding: 0;
    }

    .grid-header-pagination {
        @include media-breakpoint-up(lg) {
            padding-left: $space-24;
            padding-right: $space-24;
        }
    }
}

// hover PD

.pd-product-tile-wrapper,
.recommendations-slider {
    .image-container {
        aspect-ratio: 1/1;
        text-align: center;

        @include media-breakpoint-up(lg) {
            &:hover {
                .tile-image {
                    display: block !important;
                    width: 100%;
                }
            }
        }

        .tile-image {
            display: block;
            margin: auto;
            transition: all 0.5s;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 96%;
            }
        }

        .tile-alt-image {
            display: none !important;
        }
    }
}

.tab-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $space-4;
    margin-top: $space-20;

    .tab {
        align-items: center;
        background-color: $color-sl50;
        border-radius: $border-radius-4;
        display: flex;
        justify-content: center;
        margin-bottom: $space-4;
        order: 2;
        padding: $space-2 $space-6;
        width: fit-content;

        &.highlight {
            background-color: $color-nsl300;
        }
    }
}

$max-characters: 27.4;

.product-tile {
    .merch-callout {
        color: $color-b300;
        display: flex;
        flex-wrap: wrap;
        gap: $space-8;
        margin-bottom: $space-8;

        @include media-breakpoint-up(lg) {
            gap: $space-16;
        }

        span {
            font-size: $font-12;
            font-weight: $font-medium;
            overflow: hidden;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    .promo-callout {
        color: $color-s300;
        display: flex;
        flex-direction: column;
        gap: $space-4;
        margin-bottom: $space-8;

        span {
            font-size: $font-12;
            font-weight: $font-medium;
            max-width: $max-characters * 1ch;
            overflow: hidden;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                max-width: 100%;
            }
        }
    }
}

.items-per-page-fix-position {
    @include media-breakpoint-up(lg) {
        position: absolute;
        top: -59px;
    }
}
